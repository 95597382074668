import { Button } from 'antd';
import { ApplicantAdditionalDetailsForm } from '../../../components/ApplicantAdditionalDetailsForm';
import { useTranslation } from 'react-i18next';
import { useApplicantAdditionalDetailsPage } from './useApplicantAdditionalDetailsPage';

export const ApplicantAdditionalCollectionPageContainer = () => {
	const { t } = useTranslation();
	const {
		form,
		onSubmitClick,
		onCancelClick,
		submitButtonDisabled,
		application,
	} = useApplicantAdditionalDetailsPage();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<ApplicantAdditionalDetailsForm
				form={form}
				applicant={application?.applicant}
			/>
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
		</div>
	);
};
