import { Button, Collapse, Input, Typography } from 'antd';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ZoneDropdown from '../../../components/ZonesDropdown';
import { useAuth } from '../../../hooks/useAuth';
import {
	ApplicationsFilter,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

const { Text } = Typography;

interface FilterFunctionariesProps {
	value: ApplicationsFilter;
	onFilterChange: (value: ApplicationsFilter) => void;
}

export const FilterFunctionaries = ({
	value: { searchByProposerSrcmId, zoneNames },
	onFilterChange,
}: FilterFunctionariesProps) => {
	const { srcmId } = useAuth();
	const { t } = useTranslation();
	const [proposerSrcmId, setProposerSrcmId] = useState<string | undefined>(
		searchByProposerSrcmId
	);
	const [zones, setZones] = useState<Array<string> | undefined>(zoneNames);
	const { role } = useRedirectToLogin();

	const displayFunctionariesText = role === UserRole.ADMIN;

	const onProposerSRCMIdChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setProposerSrcmId(e.target.value);
		},
		[]
	);

	const handleZoneChange = useCallback((value: Array<{ value: string }>) => {
		setZones(value.map((z) => z.value));
	}, []);

	const fireFilterChange = useCallback(() => {
		onFilterChange({
			searchByProposerSrcmId: proposerSrcmId,
			zoneNames: zones,
		});
	}, [onFilterChange, proposerSrcmId, zones]);

	const handleFilterButtonClick = useCallback(() => {
		fireFilterChange();
	}, [fireFilterChange]);

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<Collapse collapsible="header">
				<Collapse.Panel
					header={
						displayFunctionariesText
							? t('filterFunctionaries')
							: t('filterProposers')
					}
					key="1"
				>
					<div className="mb-[8px]">
						<Text>{t('bySRCMId')}</Text>
						<Input
							className="flex min-w-[200px]"
							value={proposerSrcmId}
							allowClear
							onChange={onProposerSRCMIdChange}
						/>
					</div>
					<div className="mb-[8px] flex flex-col">
						<Text>{t('byZone')}</Text>
						<ZoneDropdown
							functionarySrcmId={srcmId}
							value={zones}
							onChange={handleZoneChange}
						/>
					</div>
					<div>
						<Button type="primary" onClick={handleFilterButtonClick}>
							{t('filter')}
						</Button>
					</div>
				</Collapse.Panel>
			</Collapse>
		</div>
	);
};
