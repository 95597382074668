import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

//Import all translation files
import translationEnglish from './locales/en.json';
import translationHindi from './locales/hi.json';
import translationTelugu from './locales/te.json';
import translationTamil from './locales/ta.json';

//---Using translation
const resources = {
	en: {
		translation: translationEnglish,
	},
	hi: {
		translation: translationHindi,
	},
	te: {
		translation: translationTelugu,
	},
	ta: {
		translation: translationTamil,
	},
};

// eslint-disable-next-line import/no-named-as-default-member
void i18next.use(initReactI18next).init({
	resources,
	lng: 'en',
	interpolation: {
		escapeValue: false,
	},
});

export default i18next;
