import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
	useEffect,
} from 'react';
import { Application } from '../../../shared/VolunteerApplicationServiceTypes';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { INTERVIEW_LANDING } from '../../../shared/Routes';
import { useInterviewWizard } from '../useInterviewWizard';

export interface ViewApplicantAnswersContextType {
	application: Partial<Application>;
	onSubmitClick: () => void;
	onCancelClick: () => void;
}

interface Props {
	children: ReactNode;
}

const ViewApplicantAnswersContext =
	createContext<ViewApplicantAnswersContextType>({
		application: {},
		onSubmitClick: () => {},
		onCancelClick: () => {},
	});

export const ViewApplicantAnswersProvider: FC<Props> = ({
	children,
}: Props) => {
	const { showToastMessage } = useToastMessage();
	const [application, setApplication] = useState<Partial<Application>>({});
	const {
		applicationId,
		navigateWithApplicationId,
		fetchApplication: callFetchApplication,
	} = useApplicationId();
	const { onCancelClick } = useInterviewWizard();

	const onSubmitClick = useCallback(() => {
		navigateWithApplicationId(INTERVIEW_LANDING);
	}, [navigateWithApplicationId]);

	const value = useMemo<ViewApplicantAnswersContextType>(() => {
		return {
			application,
			onSubmitClick,
			onCancelClick,
		};
	}, [application, onSubmitClick, onCancelClick]);

	const fetchApplication = useCallback(async () => {
		if (!applicationId) {
			showToastMessage('error', 'applicationId is missing');
		}

		try {
			const fetchedApplication = await callFetchApplication();
			setApplication(fetchedApplication);
		} catch (e) {
			//
		}
	}, [applicationId, callFetchApplication, showToastMessage]);

	useEffect(() => {
		void fetchApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ViewApplicantAnswersContext.Provider value={value}>
			{children}
		</ViewApplicantAnswersContext.Provider>
	);
};

export const useViewApplicantAnswers = () => {
	return useContext(ViewApplicantAnswersContext);
};
