import { proxy } from 'valtio';
import {
	PagingConfig,
	PreceptorsFilter,
} from '../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import { Exam } from '../../shared/ExamModuleTypes';

export const CandidatesListState = proxy<{
	filterBy: PreceptorsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const ExamState = proxy<{
	selectedExam: Exam | undefined;
}>({ selectedExam: undefined });
