import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { CharacterAndHabitsUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = CharacterAndHabitsUIConfig;

export const CharacterAndHabitsStep = () => {
	const { characterAndHabits, setCharacterAndHabits } =
		useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(
		characterAndHabits,
		setCharacterAndHabits
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
