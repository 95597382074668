import { Popover, Tag } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Feedback,
	PredefinedReason,
} from '../../shared/VolunteerApplicationServiceTypes';
import { ReviewAction } from '../../shared/Constants';
import {
	CheckCircleFilled,
	ClockCircleOutlined,
	CloseCircleFilled,
} from '@ant-design/icons';

const ResultTag = ({
	icon,
	color,
	reviewer,
	comments,
	predefinedReasons = [],
}: {
	icon: ReactElement;
	color: string;
	reviewer: string;
	predefinedReasons?: Array<PredefinedReason>;
	comments?: string;
}) => {
	const { t } = useTranslation();
	const popoverContent = (
		<>
			{predefinedReasons.map((r: PredefinedReason, index: number) => (
				<div key={index}>{r.reasonText}</div>
			))}
			<div>{comments}</div>
		</>
	);
	return (
		<Popover content={popoverContent} title={t(reviewer)} trigger="click">
			<Tag icon={icon} color={color}>
				{t(reviewer)}
			</Tag>
		</Popover>
	);
};

export const ReviewerResultTag = ({
	reviewer,
	result,
}: {
	reviewer: string;
	result?: Feedback;
}) => {
	switch (result?.review) {
		case ReviewAction.APPROVE:
			return (
				<ResultTag
					icon={<CheckCircleFilled />}
					color="success"
					reviewer={reviewer}
					comments={result?.comments}
				/>
			);

		case ReviewAction.APPROVE_IDK_CANDIDATE:
			return (
				<ResultTag
					icon={<CheckCircleFilled />}
					color="success"
					reviewer={reviewer}
					comments={result?.comments}
				/>
			);

		case ReviewAction.REJECT:
			return (
				<ResultTag
					icon={<CloseCircleFilled />}
					color="error"
					reviewer={reviewer}
					comments={result?.comments}
					predefinedReasons={result?.predefinedReasons}
				/>
			);

		case ReviewAction.PENDING:
			return (
				<ResultTag
					icon={<ClockCircleOutlined />}
					color="default"
					reviewer={reviewer}
					comments={result?.comments}
					predefinedReasons={result?.predefinedReasons}
				/>
			);
	}

	return null;
};
