import { useCallback, useEffect, useState } from 'react';
import { useCreateExam } from '../../exams-list/create-update-view-exam/useCreateExam';
import { useAuth } from '../../../../hooks/useAuth';
import { useCallApi } from '../../../../hooks/useCallApi';
import {
	ExaminationGraphicalViewResponse,
	GetExaminationGraphicalViewRequest,
} from '../../../../shared/ExamModuleTypes';
import { useSnapshot } from 'valtio';
import { ExamState } from '../../ExamModule.store';
import { getExaminationGraphicalView } from '../../../../service/ExamModuleService';

export const useRegistrationGraph = () => {
	const { examCenters } = useCreateExam();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const examSnap = useSnapshot(ExamState);
	const examInSnap = examSnap.selectedExam;
	const [selectedCenterId, setSelectedCenterId] = useState<number>();
	const [examGraphicalViewRequest, setExamGraphicalViewRequest] =
		useState<GetExaminationGraphicalViewRequest>();
	const [examGraphicalViewSummary, setExamGraphicalViewSummary] =
		useState<ExaminationGraphicalViewResponse>();

	useEffect(() => {
		if (srcmId && examInSnap) {
			setExamGraphicalViewRequest({
				functionarySrcmId: srcmId,
				examId: examInSnap.id,
				examinationCenterIds: selectedCenterId ? [selectedCenterId] : [],
			});
		}
	}, [srcmId, examInSnap, selectedCenterId]);

	const onCentersChange = useCallback((centerId: number) => {
		setSelectedCenterId(centerId);
	}, []);

	const fetchRegistrationData = useCallback(
		async () => {
			if (srcmId && examGraphicalViewRequest) {
				const registrationGraphicalView = await callApi(
					() => getExaminationGraphicalView(examGraphicalViewRequest),
					'errorOccurredWhileLoading'
				);
				if (registrationGraphicalView) {
					setExamGraphicalViewSummary(registrationGraphicalView);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[examGraphicalViewRequest, srcmId]
	);

	useEffect(() => {
		void fetchRegistrationData();
	}, [fetchRegistrationData]);

	return { examCenters, examGraphicalViewSummary, onCentersChange };
};
