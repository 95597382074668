import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessStep = () => {
	const {
		inspirationAndWillingness,
		setInspirationAndWillingness,
		application,
	} = useApplicantInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			inspirationAndWillingness,
			setInspirationAndWillingness
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
			answers={application?.userAnswers}
		/>
	);
};
