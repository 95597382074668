import { FC, useEffect, useState } from 'react';
import 'firebaseui/dist/firebaseui.css';
import { useFirebaseSignIn } from '../../hooks/useFirebaseLoginUI';
import { Spin } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useBatchId } from '../../hooks/useBatchId';
import {
	APPLICANT_HOME,
	FUNCTIONARY_HOME,
	SELECT_MODULE,
} from '../../shared/Routes';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import MeditationBG from '../../assets/images/meditation-bg.svg';
import HFNBlackLogo from '../../assets/images/hfn-black-logo.svg';
import { Banner } from '../../components/Banner';

const SignInPage: FC = () => {
	const { navigateWithBatchId } = useBatchId();
	const [showLogin, setShowLogin] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const { mountUI }: { mountUI: () => void } = useFirebaseSignIn(
		'#firebaseui-auth-container',
		() => {
			// TODO: Show toast message, if there is a error
		}
	);
	const { initiated, loading, isLoggedIn, role } = useAuth();

	useEffect(() => {
		if (showLogin) {
			mountUI();
		}

		if (initiated) {
			if (isLoggedIn && isLoggedIn()) {
				switch (role) {
					case UserRole.APPLICANT:
						navigateWithBatchId(APPLICANT_HOME);
						return;
					case UserRole.ADMIN:
						navigateWithBatchId(SELECT_MODULE);
						return;
					case UserRole.BATCH_ADMIN:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.FUNCTIONARY:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.APPROVER:
						navigateWithBatchId(SELECT_MODULE);
						return;
					case UserRole.CARE_TEAM:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.ZC:
						navigateWithBatchId(SELECT_MODULE);
						return;
				}
			} else {
				setShowLogin(true);
			}
		}
	}, [showLogin, mountUI, isLoggedIn, navigateWithBatchId, initiated, role]);

	return (
		<>
			<div className="hidden md:block">
				<Banner />
			</div>
			<div className="relative flex flex-col items-center justify-center h-screen w-65">
				<div className="absolute bottom-0 right-0 hidden md:block">
					<img
						src={MeditationBG}
						alt="Meditation"
						className="object-contain w-full h-full"
					/>
				</div>
				<div className="relative z-10 flex flex-col">
					<img src={HFNBlackLogo} alt="HFN Logo" className="w-auto mx-auto" />
					<div id="firebaseui-auth-container" />
				</div>
			</div>
			{loading && <Spin />}
		</>
	);
};

export default SignInPage;
