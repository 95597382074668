import cloneDeep from 'lodash/cloneDeep';
import { CategoryStep } from './CategoryStep';
import { PreWorkUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreWorkWizard } from './usePreWorkWizard';
import { useAuth } from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import usePreWorkFormHandling from './usePreWorkFormHandling';
import { Form } from 'antd';
import { PreWorkStepType } from './PreWorkStepType';
import { PreWorkQuestionId } from '../../../shared/Constants';
import { useCallback, useMemo } from 'react';
import { AnswerOptionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';

const UiConfig = PreWorkUIConfig;

export const CoverNote = () => {
	const { t } = useTranslation();
	const { mySrcmProfile } = useAuth();
	const candidateName = mySrcmProfile?.name;

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<div className="flex flex-col mb-[8px]">
				<p>{t('dearCandidateName', { candidateName })}</p>
				<p>{t('thankYouForVolunteering')}</p>
				<p>{t('beforeProceeding')}</p>
				<p>{t('pleaseAnswerBelowQuestions')}</p>
			</div>
		</div>
	);
};

export const PreWorkStep = () => {
	const clonedUiConfig = useMemo(() => {
		return cloneDeep(UiConfig);
	}, []);

	const { preWorkCategoryAnswers, setPreWorkCategoryAnswers } =
		usePreWorkWizard();

	const { form } = usePreWorkFormHandling(
		preWorkCategoryAnswers,
		setPreWorkCategoryAnswers
	);

	const values = Form.useWatch<PreWorkStepType>([], form);
	const { setFieldValue } = form;
	const candidateViewQuestionId =
		PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION;

	const clearCandidateViewAnswer = useCallback(() => {
		setFieldValue(candidateViewQuestionId, {
			selectedOption: undefined,
		});
	}, [candidateViewQuestionId, setFieldValue]);

	const updatedUiConfig = {
		...clonedUiConfig,
		questions: clonedUiConfig.questions.map((question) => {
			if (
				question.questionId !==
					PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION ||
				!values
			) {
				return question;
			}

			const systemGeneratedOption =
				values[`${PreWorkQuestionId.SYSTEM_GENERATED}`]?.selectedOption;
			const candidateSelectedFirstOption =
				values[candidateViewQuestionId].selectedOption === 1;
			const candidateSelectedSecondOption =
				values[candidateViewQuestionId].selectedOption === 2;
			const candidateSelectedThirdOption =
				values[candidateViewQuestionId].selectedOption === 3;
			let validOptions: AnswerOptionUIConfig[] = [];

			switch (systemGeneratedOption) {
				case 1:
					if (candidateSelectedThirdOption) {
						clearCandidateViewAnswer();
					}
					validOptions = [
						{ optionId: 1, optionText: 'completedPreWork' },
						{ optionId: 2, optionText: 'completedPreWorkBut' },
					];
					break;

				case 2:
					if (candidateSelectedFirstOption) {
						clearCandidateViewAnswer();
					}
					validOptions = [
						{ optionId: 2, optionText: 'completedPreWorkBut' },
						{ optionId: 3, optionText: 'partiallyCompletedPreWork' },
					];
					break;

				case 3:
					if (candidateSelectedFirstOption || candidateSelectedSecondOption) {
						clearCandidateViewAnswer();
					}
					validOptions = [
						{ optionId: 3, optionText: 'partiallyCompletedPreWork' },
					];
					break;

				default:
					break;
			}

			return {
				...question,
				answerOptions: validOptions,
			};
		}),
	};

	return (
		<>
			<CoverNote />
			<CategoryStep uiConfig={updatedUiConfig} form={form} />
		</>
	);
};
