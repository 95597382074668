/* eslint-disable no-unused-expressions */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Center } from '../shared/VolunteerApplicationServiceTypes';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { getZonesProfile, getCenterProfile } from '../service/MySrcmService';
import { getFunctionary } from '../service/VolunteerApplicationService';
const { Option } = Select;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: Center | null) => void;
	value?: Center;
	functionarySrcmId?: string;
	zoneValue?: string | number;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	functionarySrcmId,
	zoneValue,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			let parentID;

			if (functionarySrcmId) {
				const functionaryResponse = await getFunctionary({
					functionarySrcmId,
				});

				const zonesPage =
					functionaryResponse?.approverZone || functionaryResponse?.zcZone;

				if (zonesPage && zonesPage.length > 0) {
					const filteredZones = zoneValue ? zoneValue : zonesPage[0];
					if (filteredZones) {
						const zoneName = filteredZones;
						const zoneResponse = await getZonesProfile();

						if (
							zoneResponse?.results &&
							zoneName &&
							typeof zoneName === 'string'
						) {
							const matchedZone = zoneResponse.results.find((zoneData) =>
								zoneData?.name?.toLowerCase().includes(zoneName?.toLowerCase())
							);

							if (matchedZone) {
								parentID = matchedZone.id;

								if (parentID) {
									const centersPage = await getCenterProfile(
										parentID,
										searchValue
									);
									if (centersPage?.results) {
										const transformedOptions: OptionData[] =
											centersPage?.results?.map((item) => ({
												value: item.id,
												label: item.name,
											}));
										setOptions(transformedOptions);
									} else {
										setOptions([]);
									}
								}
							}
						}
					}
				}
			}

			setLoading(false);
		},
		[zoneValue, setLoading, setOptions, functionarySrcmId]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	useEffect(() => {
		if (onChange) {
			onChange(null);
		}
		setOptions([]);
		// eslint-disable-next-line
	}, [zoneValue]);

	const debouncedFetchData = useMemo(
		() =>
			debounce((searchValue: string) => {
				void fetchData(searchValue);
			}, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			if (searchKey && typeof searchKey === 'string') {
				void debouncedFetchData(searchKey);
			}
		},
		[debouncedFetchData]
	);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={false}
			showArrow={false}
			disabled={!zoneValue}
			placeholder={t('searchCenter')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default RemoteSingleSelect;
