import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useCallApi } from '../../../../hooks/useCallApi';
import {
	createExam,
	getExaminationCenters,
	updateExam,
} from '../../../../service/ExamModuleService';
import {
	Exam,
	ExamForCreation,
	ExamMarkRange,
	GetExaminationCentersResponse,
} from '../../../../shared/ExamModuleTypes';
import { ExamCreationFormType } from './ExamCreationFormType';
import { useConfirmationBeforeAction } from '../../../../hooks/useConfirmationBeforeAction';
import { useBatchId } from '../../../../hooks/useBatchId';
import { EXAM_MODULE_EXAMS_LIST } from '../../../../shared/Routes';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useExamAndCenterId } from '../../../../hooks/useExamAndCenterId';
import { convertDateToUTCString } from '../../../../shared/Utils';
import { useExamsList } from '../useExamsList';

export const useCreateExam = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { navigateWithBatchId } = useBatchId();
	const { examId } = useExamAndCenterId();
	const { callApi } = useCallApi();
	const { examById } = useExamsList({});
	const [examCenters, setExamCenters] = useState<GetExaminationCentersResponse>(
		{
			examinationCenters: [],
			pagination: { totalNoOfRecords: 0 },
		}
	);

	const fetchExamCenters = useCallback(async () => {
		if (srcmId) {
			const centers = await callApi(
				() =>
					getExaminationCenters({
						functionarySrcmId: srcmId,
						pageIndex: 0,
						pageSize: 2000,
					}),
				'errorOccurredWhileFetchingExamCenters'
			);
			setExamCenters(centers);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [srcmId]);

	useEffect(() => {
		if (srcmId) {
			void fetchExamCenters();
		}
	}, [srcmId, fetchExamCenters]);

	const generateRanges = (
		marks: number,
		rangeString: string
	): ExamMarkRange[] => {
		const [minRange, maxRange] = rangeString.split('-').map(Number); // Convert the range string to numbers
		const step = maxRange - minRange; // Calculate the step size from the range string

		const ranges = [];
		for (let i = 0; i < marks; i += step) {
			ranges.push({
				minimum: i,
				maximum: Math.min(i + step, marks), // Ensure the max value does not exceed the total marks
			});
		}

		return ranges;
	};

	const generateExamForCreation = useCallback(
		(examDetails: ExamCreationFormType) => {
			let examForCreation: ExamForCreation | Exam = {
				name: examDetails.examName,
				registrationStartDate: convertDateToUTCString(
					examDetails.registrationStartDate
				),
				registrationEndDate: convertDateToUTCString(
					examDetails.registrationEndDate,
					true
				),
				examDate: convertDateToUTCString(examDetails.examDate),
				sectionATotalMarksLimit: examDetails.sectionAMarks,
				sectionBTotalMarksLimit: examDetails.sectionBMarks,
				sectionCTotalMarksLimit: examDetails.sectionCMarks,
				totalMarksLimit: examDetails.totalMarks,
				sectionAMarkRanges: generateRanges(
					examDetails.sectionAMarks,
					examDetails.sectionAMarksRange
				),
				sectionBMarkRanges: generateRanges(
					examDetails.sectionBMarks,
					examDetails.sectionBMarksRange
				),
				sectionCMarkRanges: generateRanges(
					examDetails.sectionCMarks,
					examDetails.sectionCMarksRange
				),
				totalMarkRanges: generateRanges(
					examDetails.totalMarks,
					examDetails.totalMarksRange
				),
				passPercentage: examDetails.passPercentage,
			};
			if (examId) {
				examForCreation.id = examId;
				examForCreation = { ...examById, ...examForCreation };
			}
			return examForCreation;
		},
		[examId, examById]
	);

	const onConfirmSubmit = useCallback(
		async (examDetails: ExamCreationFormType) => {
			if (examDetails && srcmId) {
				try {
					const exam = generateExamForCreation(examDetails);
					const request = {
						functionarySrcmId: srcmId,
						exam,
						examinationCenterIds: examDetails.examCenters.map(
							(centerId) => centerId
						),
					};
					let response: Exam;
					if (examId > 0) {
						response = await callApi(
							() => updateExam(request),
							'errorOccurredWhileUpdating'
						);
					} else {
						response = await callApi(
							() => createExam(request),
							'errorOccurredWhileCreating'
						);
					}
					const successMessage =
						examId > 0
							? t('updatedExamSuccessfully')
							: t('createdExamSuccessfully');
					if (response) {
						Modal.success({
							title: t('successful'),
							content: successMessage,
							afterClose: () => {
								navigateWithBatchId(EXAM_MODULE_EXAMS_LIST);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[navigateWithBatchId, callApi, srcmId, t, generateExamForCreation, examId]
	);

	const onSubmitClick = useCallback(
		(examDetails: ExamCreationFormType) => {
			const confirmationMessage =
				examId > 0
					? t('examUpdationConfirmMessage')
					: t('examCreationConfirmMessage');
			confirmBeforeAction(
				'confirmSubmit',
				confirmationMessage,
				() => onConfirmSubmit(examDetails),
				true
			);
		},
		[confirmBeforeAction, onConfirmSubmit, t, examId]
	);

	return {
		examId,
		examById,
		examCenters,
		onSubmitClick,
	};
};
