import { useCallback, useEffect, useState } from 'react';
import {
	GraphicalViewResponse,
	PreceptorsFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import {
	GraphViewListState,
	PreceptorBatchState,
} from './PreceptorsList.store';
import { COUNTRY_CODE_INDIA, GroupTabs } from '../../../shared/Constants';
import { useAuth } from '../../../hooks/useAuth';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import useQueryParams from '../../../hooks/useQueryParams';
import { getGraphicalView } from '../../../service/VolunteerApplicationService';
import { useSnapshot } from 'valtio';

export const useGraphView = () => {
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const queryParams = useQueryParams();
	const tabKey = queryParams.get('activetab');
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const selectedBatchId = preceptorBatchSnap.selectedBatch?.id;
	const [graphViewData, setGraphViewData] = useState<GraphicalViewResponse>();
	const [graphViewFilter, setGraphViewFilter] = useState<PreceptorsFilter>({
		showAllPreceptors: true,
		countryIds: [COUNTRY_CODE_INDIA],
		preceptorBatchId: selectedBatchId,
	});

	useEffect(() => {
		if (preceptorBatchSnap) {
			setGraphViewFilter((prevState) => ({
				...prevState,
				preceptorBatchId: selectedBatchId,
			}));
		}
	}, [preceptorBatchSnap, selectedBatchId]);

	const onGraphViewFilterChange = useCallback(
		(filterParam: PreceptorsFilter) => {
			GraphViewListState.filterBy = { ...filterParam };
			setGraphViewFilter(filterParam);
		},
		[]
	);

	const fetchGraphViewData = useCallback(
		async (filter: PreceptorsFilter) => {
			if (srcmId && filter.preceptorBatchId) {
				setLoading(true);
				try {
					const graphData = await getGraphicalView({
						functionarySrcmId: srcmId,
						preceptorsFilter: filter,
					});
					setGraphViewData(graphData);
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, setLoading, showToastMessage]
	);

	useEffect(() => {
		if (tabKey === GroupTabs.GRAPHICAL_VIEW) {
			void fetchGraphViewData(graphViewFilter);
		}
	}, [graphViewFilter, fetchGraphViewData, tabKey]);

	return {
		graphViewData,
		graphViewFilter,
		onGraphViewFilterChange,
	};
};
