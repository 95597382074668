import { useState, useEffect, useCallback, useMemo } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { OTP_REGENERATION_INTERVAL_IN_SECONDS } from '../../../shared/Constants';
import {
	sendOtpForEmailVerification,
	validateOtpForVerification,
} from '../../../service/VolunteerApplicationService';
import { useNewApplication } from './useNewApplication';
import { EmailVerificationModalType } from './EmailVerificationModalType';

interface EmailVerificationModalProps {
	openModal: boolean;
	onCancel: () => void;
	email?: string;
	mobile?: string;
	onEmailVerificationComplete: ((value: boolean) => void) | null;
}

export const EmailVerificationModal: React.FC<EmailVerificationModalProps> = ({
	openModal,
	onCancel,
	email,
	onEmailVerificationComplete,
}: EmailVerificationModalProps) => {
	const { t } = useTranslation();
	const { setIsEmailVerified } = useNewApplication();

	const [disableVerify, setDisableVerify] = useState(true);
	const [showTimer, setShowTimer] = useState(false);
	const [showOtpInput, setShowOtpInput] = useState(false);
	const [generateOtpButtonLoading, setGenerateOtpButtonLoading] =
		useState(false);
	const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);
	const [otpResendIntervalInSeconds, setOtpResendIntervalInSeconds] =
		useState<number>(OTP_REGENERATION_INTERVAL_IN_SECONDS);
	const [form] = Form.useForm<EmailVerificationModalType>();
	const values = Form.useWatch<EmailVerificationModalType>([], form);

	useEffect(() => {
		if (otpResendIntervalInSeconds > 0 && showTimer) {
			const timerId = setInterval(() => {
				setOtpResendIntervalInSeconds((prevSeconds: number) => prevSeconds - 1);
			}, 1000);

			return () => {
				clearInterval(timerId);
			};
		} else if (otpResendIntervalInSeconds === 0) {
			setOtpResendIntervalInSeconds(59);
			setShowTimer(false);
		}
	}, [otpResendIntervalInSeconds, showTimer]);

	const minutes = useMemo(
		() => Math.floor(otpResendIntervalInSeconds / 60),
		[otpResendIntervalInSeconds]
	);

	const timerText = useMemo(() => {
		const remainingSeconds = otpResendIntervalInSeconds % 60;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const formattedSeconds =
			remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
		return `${t('regenerateOTP')} ${formattedMinutes}:${formattedSeconds}`;
	}, [minutes, otpResendIntervalInSeconds, t]);

	useEffect(() => {
		const canVerify = showOtpInput && !isEmpty(values?.verificationCode);
		setDisableVerify(!canVerify);
	}, [values, showOtpInput]);

	const sendOTP = useCallback(async () => {
		setGenerateOtpButtonLoading(true);
		const sendOTPResponse = await sendOtpForEmailVerification();
		if (sendOTPResponse) {
			setShowTimer(true);
			setShowOtpInput(true);
			setDisableVerify(false);
		} else {
			setShowTimer(false);
			setDisableVerify(true);
		}
		setGenerateOtpButtonLoading(false);
	}, []);

	const onVerifyAction = useCallback(async () => {
		setVerifyButtonLoading(true);
		const formValues = await form.validateFields();
		const request = {
			otp: formValues?.verificationCode,
		};
		const otpVerified: boolean = await validateOtpForVerification(request);
		if (otpVerified) {
			setIsEmailVerified(true);
			if (onEmailVerificationComplete) {
				onEmailVerificationComplete(true);
			}
			onCancel();
		} else {
			setIsEmailVerified(false);
			form.setFields([{ name: 'verificationCode', errors: [t('wrongOTP')] }]);
		}
		setVerifyButtonLoading(false);
	}, [form, onCancel, onEmailVerificationComplete, setIsEmailVerified, t]);

	return (
		<Modal
			open={openModal}
			okText={t('verify')}
			cancelText={t('cancel')}
			title={t('verifyEmail')}
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onOk={onVerifyAction}
			onCancel={onCancel}
			okButtonProps={{ disabled: disableVerify }}
			confirmLoading={verifyButtonLoading}
		>
			<div>
				{t('sendingOTP')} <b>{email}</b>
				{', '}
				{t('pleaseClickGenerateOtp')}
			</div>
			<br />
			<Form
				name="verify-email-form"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				{!showTimer ? (
					<Form.Item>
						<Button
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onClick={sendOTP}
							type="primary"
							loading={generateOtpButtonLoading}
						>
							{t('generateOTP')}
						</Button>
					</Form.Item>
				) : (
					<b>{timerText}</b>
				)}
				{showOtpInput && (
					<Form.Item
						label={t('enterVerificationCodeReceived')}
						name="verificationCode"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<Input minLength={6} maxLength={8} />
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
};
