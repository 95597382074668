import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	GetPreceptorBatchesResponse,
	PagingConfig,
	PreceptorBatchFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import {
	getPreceptorBatches,
	loadPreceptorsFromMySrcm,
} from '../../../service/VolunteerApplicationService';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';

export const useBatchView = () => {
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const [preceptorBatchData, setPreceptorBatchData] =
		useState<GetPreceptorBatchesResponse>({
			preceptorBatches: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [preceptorBatchFilter, setPreceptorBatchFilter] =
		useState<PreceptorBatchFilter>({});
	const [batchPaging, setBatchPaging] = useState<PagingConfig>({});

	const onBatchViewFilterChange = useCallback(
		(filterParam: PreceptorBatchFilter) => {
			setPreceptorBatchFilter(filterParam);
		},
		[]
	);

	const onBatchPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setBatchPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const fetchBatchViewData = useCallback(
		async (
			pageNumber: number,
			pageSize: number,
			filter: PreceptorBatchFilter
		) => {
			if (srcmId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const batchDataWithoutKey = await getPreceptorBatches({
						functionarySrcmId: srcmId,
						pageIndex,
						pageSize,
						preceptorBatchFilter: filter,
					});
					const batchData = {
						...batchDataWithoutKey,
						...(batchDataWithoutKey.preceptorBatches && {
							preceptorBatches: batchDataWithoutKey.preceptorBatches.map(
								(batch) => ({
									...batch,
									key: batch.id,
								})
							),
						}),
					};

					if (
						batchData.pagination.totalNoOfPages &&
						batchData.pagination.totalNoOfPages > 0 &&
						pageNumber > batchData.pagination.totalNoOfPages
					) {
						onBatchPageChange(
							batchData.pagination.totalNoOfPages ?? 1,
							pageSize
						);
					} else {
						setPreceptorBatchData(batchData);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, setLoading, showToastMessage, onBatchPageChange]
	);

	useEffect(() => {
		void fetchBatchViewData(
			batchPaging.pageNo ?? 1,
			batchPaging.pageSize ?? DEFAULT_PAGE_SIZE,
			preceptorBatchFilter
		);
	}, [preceptorBatchFilter, fetchBatchViewData, batchPaging]);

	const loadPreceptors = useCallback(
		async (batchId: number) => {
			if (srcmId) {
				setLoading(true);
				try {
					const response = await loadPreceptorsFromMySrcm({
						adminSrcmId: srcmId,
						preceptorBatchId: batchId,
					});
					if (typeof response === 'boolean' && response) {
						void fetchBatchViewData(
							batchPaging.pageNo ?? 1,
							batchPaging.pageSize ?? DEFAULT_PAGE_SIZE,
							preceptorBatchFilter
						);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[
			preceptorBatchFilter,
			fetchBatchViewData,
			batchPaging,
			srcmId,
			setLoading,
			showToastMessage,
		]
	);

	return {
		batchPaging,
		preceptorBatchFilter,
		preceptorBatchData,
		onBatchViewFilterChange,
		onBatchPageChange,
		loadPreceptors,
	};
};
