import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { PracticeUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = PracticeUIConfig;

export const PracticeAnswersReviewStep = () => {
	const { practice, setPractice, application } = useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(practice, setPractice);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
