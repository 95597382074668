import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useViewApplicantAnswers } from './useViewApplicantAnswers';
import { CategoryViewApplicantAnswersStep } from './CategoryViewApplicantAnswersStep';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramViewAnswersStep = () => {
	const { application } = useViewApplicantAnswers();

	const { form } = useCategoryFeedbackFormHandling();

	return (
		<CategoryViewApplicantAnswersStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
