import { InputNumber } from 'antd';

interface NumberInputType {
	max?: number;
	value?: number;
	onChange?: (value: number | null) => void;
	disabled?: boolean;
	rejectZero?: boolean;
}

export const NumberInput = ({
	max,
	value,
	onChange,
	disabled,
	rejectZero,
}: NumberInputType) => {
	const handleChange = (val: number | null) => {
		if (rejectZero && val === 0) {
			return;
		}
		onChange?.(val);
	};

	return (
		<InputNumber
			size="large"
			className="w-full"
			min={rejectZero ? 1 : 0}
			max={max}
			value={value}
			onChange={handleChange}
			disabled={disabled}
			onKeyDown={(e) => {
				const allowedKeys = [
					'Backspace',
					'Tab',
					'ArrowLeft',
					'ArrowRight',
					'Delete',
				];
				if (!allowedKeys.includes(e.key) && !/[0-9]/.test(e.key)) {
					e.preventDefault();
				}
			}}
		/>
	);
};
