import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { UnderstandingOfHeartfulnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useViewApplicantAnswers } from './useViewApplicantAnswers';
import { CategoryViewApplicantAnswersStep } from './CategoryViewApplicantAnswersStep';

const UiConfig = UnderstandingOfHeartfulnessUIConfig;

export const UnderstandingOfHeartfulnessViewAnswersStep = () => {
	const { application } = useViewApplicantAnswers();

	const { form } = useCategoryFeedbackFormHandling();

	return (
		<CategoryViewApplicantAnswersStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
