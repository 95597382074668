import { useCallback, useEffect } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CentersDropdown from '../../../components/NPSCentersDropdown';
import CountryDropdown from '../../../components/CountryDropdown';
import StateDropdown from '../../../components/StateDropdown';
import CityDropdown from '../../../components/CityDropdown';
import ZoneDropdown from '../../../components/NPSZonesDropdown';
import {
	Gender,
	PreceptorsFilter,
	Country,
} from '../../../shared/VolunteerApplicationServiceTypes';
import {
	COUNTRY_CODE_INDIA,
	Gender as GENDER,
	VALID_ABHYASI_ID_REGEX,
	VALID_BATCH_REGEX,
	VALID_NAME_REGEX,
	VALID_PINCODE_REGEX,
} from '../../../shared/Constants';
import { useAuth } from '../../../hooks/useAuth';
import { useToastMessage } from '../../../hooks/useToastMessage';

interface FilterProps {
	value: PreceptorsFilter;
	onFilterChange: (value: PreceptorsFilter) => void;
	onDashboardDetailFilterChange?: (value: PreceptorsFilter) => void;
	isMentorsFilter?: boolean;
	formName: string;
	activeTabKey?: string | null;
	selectedBatchId: number | undefined;
}

const { Text } = Typography;

interface PreceptorsFilterFormType {
	srcmId?: string;
	name?: string;
	email?: string;
	mobile?: string;
	country?: string;
	stateId?: Country;
	cityId?: Country;
	centerId?: Country;
	countryId?: Country;
	postalCode?: string;
	zoneNames?: Country;
	batch?: string;
	gender?: Gender;
}

export const FilterPreceptors = ({
	value,
	onFilterChange,
	onDashboardDetailFilterChange,
	isMentorsFilter,
	formName,
	selectedBatchId,
}: FilterProps) => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { showToastMessage } = useToastMessage();
	const [form] = Form.useForm<PreceptorsFilterFormType>();
	const values = Form.useWatch<PreceptorsFilterFormType>([], form);

	const handleFilterButtonClick = useCallback(() => {
		const errors = form.getFieldsError();
		const hasErrors = errors.some((field) => field.errors.length > 0);

		if (hasErrors) {
			showToastMessage('error', t('pleaseEnterValidData'));
			return;
		}

		const filterValues: PreceptorsFilter = {
			...values,
			countryIds: values?.countryId?.value
				? ([values?.countryId?.value] as Array<number>)
				: ([COUNTRY_CODE_INDIA] as Array<number>),
			centerIds: [values?.centerId?.value] as Array<number>,
			zoneNames: values?.zoneNames?.value as string,
			cityIds: [values?.cityId?.value] as Array<number>,
			stateIds: [values?.stateId?.value] as Array<number>,
			batch: values.batch as string,
		};

		if (isMentorsFilter) {
			const mentorsFilter = { ...value, ...filterValues };
			mentorsFilter.showMentors = true;
			onFilterChange(mentorsFilter);
		} else if (onDashboardDetailFilterChange) {
			const preceptorsFilter = { ...filterValues };
			preceptorsFilter.showAllPreceptors = true;
			preceptorsFilter.preceptorBatchId = selectedBatchId;
			onFilterChange(preceptorsFilter);

			const dashboardDetailFilter = { ...filterValues };
			dashboardDetailFilter.showPreceptorDashboardCardDetail = true;
			dashboardDetailFilter.preceptorBatchId = selectedBatchId;
			onDashboardDetailFilterChange(dashboardDetailFilter);
		} else {
			const preceptorsFilter = { ...filterValues };
			preceptorsFilter.showNewPreceptorsForGrouping = true;
			preceptorsFilter.preceptorBatchId = selectedBatchId;
			onFilterChange(preceptorsFilter);
		}
	}, [
		form,
		onFilterChange,
		onDashboardDetailFilterChange,
		value,
		values,
		isMentorsFilter,
		showToastMessage,
		t,
		selectedBatchId,
	]);

	const StateValue: string | number | undefined = values?.stateId?.value
		? values?.stateId?.value
		: '';

	const CountryValue: string | number | undefined = values?.countryId?.value
		? values?.countryId?.value
		: '';

	const ZoneValue: string | number | undefined = values?.zoneNames?.value;

	const handleResetForm = useCallback(() => {
		form.resetFields();
		const filterValues: PreceptorsFilter = {
			showAllPreceptors: value?.showAllPreceptors,
			showMentors: value?.showMentors,
			showNewPreceptorsForGrouping: value?.showNewPreceptorsForGrouping,
			showPreceptorDashboardCardDetail: value?.showPreceptorDashboardCardDetail,
			countryIds: value?.countryIds,
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
		if (onDashboardDetailFilterChange) {
			const dashboardDetailFilter = { ...filterValues };
			dashboardDetailFilter.showPreceptorDashboardCardDetail = true;
			dashboardDetailFilter.preceptorBatchId = selectedBatchId;
			onDashboardDetailFilterChange(dashboardDetailFilter);
		}
	}, [
		form,
		value,
		onFilterChange,
		onDashboardDetailFilterChange,
		selectedBatchId,
	]);

	useEffect(() => {
		form.resetFields();
		const filterValues: PreceptorsFilter = {
			countryIds: [COUNTRY_CODE_INDIA],
			preceptorBatchId: selectedBatchId,
		};

		if (isMentorsFilter) {
			const mentorsFilter = { ...filterValues };
			mentorsFilter.showMentors = true;
			onFilterChange(mentorsFilter);
		} else if (onDashboardDetailFilterChange) {
			const preceptorsFilter = { ...filterValues };
			preceptorsFilter.showAllPreceptors = true;
			onFilterChange(preceptorsFilter);

			const dashboardDetailFilter = { ...filterValues };
			dashboardDetailFilter.showPreceptorDashboardCardDetail = true;
			onDashboardDetailFilterChange(dashboardDetailFilter);
		} else {
			const preceptorsFilter = { ...filterValues };
			preceptorsFilter.showNewPreceptorsForGrouping = true;
			onFilterChange(preceptorsFilter);
		}
	}, [
		form,
		isMentorsFilter,
		onDashboardDetailFilterChange,
		onFilterChange,
		selectedBatchId,
	]);

	return (
		<div className="flex flex-col">
			<Form
				name={formName}
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				form={form}
			>
				<div className="flex flex-col mb-2 md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						{isMentorsFilter && (
							<>
								<div className="w-full md:w-1/6">
									<Text className="pl-1">{t('name')}</Text>
									<Form.Item
										name={'name'}
										rules={[
											{
												pattern: VALID_NAME_REGEX,
												message: (
													<span>
														{t('numbersAndSpecialCharactersNotAllowed')}
													</span>
												),
											},
										]}
										hasFeedback
									>
										<Input />
									</Form.Item>
								</div>
								<div
									className={`w-full ${
										isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'
									}`}
								>
									<Text className="pl-1">{t('abhyasiId')}</Text>
									<Form.Item
										name={'srcmId'}
										rules={[
											{
												pattern: VALID_ABHYASI_ID_REGEX,
												message: <span>{t('invalidAbhyasiId')}</span>,
											},
										]}
										hasFeedback
									>
										<Input />
									</Form.Item>
								</div>
							</>
						)}
						<div
							className={`w-full ${isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'}`}
						>
							<Text className="pl-1">{t('batch')}</Text>
							<Form.Item
								name={'batch'}
								rules={[
									{
										pattern: VALID_BATCH_REGEX,
										message: <span>{t('validBatch')}</span>,
									},
								]}
								hasFeedback
							>
								<Input maxLength={8} />
							</Form.Item>
						</div>
						<div
							className={`w-full ${isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'}`}
						>
							<Text className="pl-1">{t('zones')}</Text>
							<Form.Item name={'zoneNames'}>
								<ZoneDropdown functionarySrcmId={srcmId} />
							</Form.Item>
						</div>
						<div
							className={`w-full ${isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'}`}
						>
							<Text className="pl-1">{t('center')}</Text>
							<Form.Item name={'centerId'}>
								<CentersDropdown
									zoneValue={ZoneValue}
									functionarySrcmId={srcmId}
								/>
							</Form.Item>
						</div>
						<div
							className={`w-full ${isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'}`}
						>
							<Text className="pl-1">{t('gender')}</Text>
							<Form.Item name={'gender'}>
								<Select
									options={[
										{
											value: GENDER.MALE,
											label: t('male'),
										},
										{
											value: GENDER.FEMALE,
											label: t('female'),
										},
									]}
								/>
							</Form.Item>
						</div>
						<div
							className={`w-full ${isMentorsFilter ? 'md:w-1/6' : 'md:w-1/5'}`}
						>
							<Text className="pl-1">{t('pincode')}</Text>
							<Form.Item
								name="postalCode"
								rules={[
									{
										pattern: VALID_PINCODE_REGEX,
										message: <span>{t('validPincode')}</span>,
									},
								]}
								hasFeedback
							>
								<Input maxLength={7} />
							</Form.Item>
						</div>
					</div>
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col w-full min-w-[170px]">
							<Text className="pl-1">{t('country')}</Text>
							<Form.Item name={'countryId'}>
								<CountryDropdown />
							</Form.Item>
						</div>
						<div className="flex flex-col w-full min-w-[170px]">
							<Text className="pl-1">{t('state')}</Text>
							<Form.Item name={'stateId'}>
								<StateDropdown CountryValue={CountryValue} />
							</Form.Item>
						</div>
						<div className="flex flex-col w-full min-w-[170px]">
							<Text className="pl-1">{t('cityFilterName')}</Text>
							<Form.Item name={'cityId'}>
								<CityDropdown StateValue={StateValue && StateValue} />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start my-2">
					<Button type="primary" onClick={handleFilterButtonClick}>
						{t('search')}
					</Button>
				</div>
				<div className="self-start my-2">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
