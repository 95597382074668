import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { getCityProfile } from '../service/MySrcmService';

const { Option } = Select;

interface OptionData {
	value?: number;
	label?: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: string | null | OptionData) => void;
	value?: OptionData;
	StateValue: string | number;
}

const CitySingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	StateValue,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const fetchData = useCallback(
		async (searchValue: string | number) => {
			setLoading(true);
			const countryPage = await getCityProfile(StateValue, searchValue);

			if (countryPage) {
				const transformedOptions: OptionData[] = countryPage.results.map(
					(item) => ({
						value: item?.id,
						label: item?.name,
					})
				);

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}

			setLoading(false);
		},
		[StateValue, setLoading, setOptions]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	const debouncedFetchData = useMemo(
		() =>
			debounce((searchValue: string | number) => {
				void fetchData(searchValue);
			}, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			if (searchKey && typeof searchKey === 'string') {
				void debouncedFetchData(searchKey);
			}
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		// Reset the value when StateValue changes
		if (onChange) {
			onChange(null);
		}
		setOptions([]);
		// eslint-disable-next-line
	}, [StateValue]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={false}
			showArrow={false}
			filterOption={false}
			disabled={!StateValue}
			placeholder={t('searchCity')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default CitySingleSelect;
