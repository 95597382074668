import { useTranslation } from 'react-i18next';
import { BatchForm } from './BatchForm';

export const UpdateBatch = () => {
	const { t } = useTranslation();

	return (
		<div className="p-5">
			<h2>{t('updateBatch')}</h2>
			<BatchForm toUpdate />
		</div>
	);
};
