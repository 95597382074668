import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	ExaminationCenterFilter,
	GetExaminationCentersResponse,
} from '../../../shared/ExamModuleTypes';
import { PagingConfig } from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { getExaminationCenters } from '../../../service/ExamModuleService';

export const useCentersList = () => {
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const [centersList, setCentersList] = useState<GetExaminationCentersResponse>(
		{
			examinationCenters: [],
			pagination: { totalNoOfRecords: 0 },
		}
	);
	const [centersFilter, setCentersFilter] = useState<ExaminationCenterFilter>(
		{}
	);
	const [centersPaging, setCentersPaging] = useState<PagingConfig>({
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	});

	const onCentersPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setCentersPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onCentersFilterChange = useCallback(
		(filterParam: ExaminationCenterFilter) => {
			setCentersFilter(filterParam);
			onCentersPageChange(1, centersPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onCentersPageChange, centersPaging.pageSize]
	);

	const fetchCentersList = useCallback(
		async (
			pageNumber: number,
			pageSize: number,
			filter: ExaminationCenterFilter
		) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				const centersWithoutKey = await callApi(
					() =>
						getExaminationCenters({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							examinationCenterFilter: filter,
						}),
					'errorOccurredWhileLoading'
				);
				const CED = {
					...centersWithoutKey,
					...(centersWithoutKey.examinationCenters && {
						examinationCenters: centersWithoutKey.examinationCenters.map(
							(center) => ({
								...center,
								key: center.id,
							})
						),
					}),
				};

				if (
					CED.pagination.totalNoOfPages &&
					CED.pagination.totalNoOfRecords > 0 &&
					pageNumber > CED.pagination.totalNoOfPages
				) {
					onCentersPageChange(CED.pagination.totalNoOfPages ?? 1, pageSize);
				} else {
					setCentersList(CED);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onCentersPageChange, srcmId]
	);

	useEffect(() => {
		void fetchCentersList(
			centersPaging.pageNo ?? 1,
			centersPaging.pageSize ?? DEFAULT_PAGE_SIZE,
			centersFilter
		);
	}, [centersFilter, centersPaging, fetchCentersList]);

	return {
		centersFilter,
		centersList,
		centersPaging,
		onCentersPageChange,
		onCentersFilterChange,
	};
};
