import { useCallback, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	NPHHSortingRequest,
	PreceptorsGroupFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';

interface FilterProps {
	value?: PreceptorsGroupFilter;
	onFilterChange: (value: PreceptorsGroupFilter) => void;
	selectedBatchId: number | undefined;
}

const { Text } = Typography;

interface PreceptorsGroupFilterFormType {
	groupId?: string;
}

export const FilterGroups = ({
	value,
	onFilterChange,
	selectedBatchId,
}: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<PreceptorsGroupFilterFormType>();
	const values = Form.useWatch<PreceptorsGroupFilterFormType>([], form);

	const handleFilterButtonClick = useCallback(() => {
		const filterValues: PreceptorsGroupFilter = {
			...values,
			groupNumber: values.groupId as string,
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
	}, [onFilterChange, values, selectedBatchId]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({ groupId: undefined });
		const sortingRequest: NPHHSortingRequest = {
			sortingFieldName: 'GROUP_ID',
			sortingOrder: 'DESCENDING',
		};
		const filterValues: PreceptorsGroupFilter = {
			sortingRequests: [sortingRequest],
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
	}, [form, onFilterChange, selectedBatchId]);

	useEffect(() => {
		handleResetForm();
	}, [handleResetForm]);

	return (
		<div className="flex flex-col mb-4">
			<Form
				name="basic-group"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				initialValues={value}
				autoComplete="off"
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 min-w-[170px]">
							<Text className="pl-1">{t('groupId')}</Text>
							<Form.Item className="mb-2" name={'groupId'}>
								<Input />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start my-2">
					<Button type="primary" onClick={handleFilterButtonClick}>
						{t('search')}
					</Button>
				</div>
				<div className="self-start my-2">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
