import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramStep = () => {
	const { availabilityAfterProgram, setAvailabilityAfterProgram, application } =
		useApplicantInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			availabilityAfterProgram,
			setAvailabilityAfterProgram
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
			answers={application?.userAnswers}
		/>
	);
};
