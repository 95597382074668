import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { SupportUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useFamilyMemberInterviewWizard } from './useFamilyMemberInterviewWizard';

const UiConfig = SupportUIConfig;

export const SupportStep = () => {
	const { support, setSupport } = useFamilyMemberInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(support, setSupport);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
