import { useCallback, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useViewCandidates } from './useViewCandidates';
import { CandidateTableColumns } from '../../../shared/Columns';
import {
	ArrowLeftOutlined,
	InfoCircleOutlined,
	MailOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	EXAM_MODULE_EXAMS_LIST,
	SEND_ADMIT_CARD_EMAIL,
	SEND_RESULTS_EMAIL,
} from '../../../shared/Routes';
import { FilterCandidates } from './FilterCandidates';
import { ResultsUploadModal } from './results-upload/ResultsUploadModal';
import moment from 'moment';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { tablePaginationConfiguration } from '../../../shared/Utils';
import { DATE_DO_MMM_YYYY } from '../../../shared/Constants';
import { AttendanceModal } from './AttendanceModal';
import { HoldCandidateModal } from './HoldCandidateModal';
import {
	CandidateExaminationDetail,
	ChangeExaminationCenterRequest,
} from '../../../shared/ExamModuleTypes';
import { EditCandidateModal } from './EditCandidateModal';

export const ViewCandidates = () => {
	const {
		candidateExamDetailList,
		candidateExamDetailPaging,
		examById,
		examFilter,
		onCandidateExamDetailsPageChange,
		onCandidateExamDetailsFilterChange,
		downloadResultsTemplate,
		uploadResults,
		fetchExamById,
		callFetchCandidateExamDetailList,
		downloadAttendanceTemplate,
		uploadAttendance,
		exportCandidates,
		holdCandidate,
		fetchExamCenterDetail,
		updateCandidateCenter,
	} = useViewCandidates({ isViewRegistrationData: true });
	const { candidateExamDetails, pagination } = candidateExamDetailList;
	const [showResultsUploadModal, setShowResultsUploadModal] = useState(false);
	const [showAttendanceModal, setShowAttendanceModal] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [candidateToHold, setCandidateToHold] =
		useState<CandidateExaminationDetail | null>(null);
	const [candidateToEdit, setCandidateToEdit] =
		useState<CandidateExaminationDetail | null>(null);
	const [showHoldModal, setShowHoldModal] = useState(false);
	const [showEditCandidateModal, setShowEditCandidateModal] = useState(false);
	const { navigateWithBatchId } = useBatchId();
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const tablePaginationConfig = tablePaginationConfiguration(
		candidateExamDetailPaging,
		pagination,
		onCandidateExamDetailsPageChange
	);

	const onHoldOrEditSucess = useCallback(() => {
		void callFetchCandidateExamDetailList();
	}, [callFetchCandidateExamDetailList]);

	const handleEditCandidateClick = useCallback(
		(candidate: CandidateExaminationDetail) => {
			setCandidateToEdit(candidate);
			setShowEditCandidateModal(true);
		},
		[]
	);

	const handleEditCandidateModalClose = useCallback(() => {
		setShowEditCandidateModal(false);
		setCandidateToEdit(null);
	}, []);

	const handleEditCandidateModalSubmit = useCallback(
		(centerId: number, changeOfCenterReason: string) => {
			if (!candidateToEdit) return;

			const request: ChangeExaminationCenterRequest = {
				candidateExaminationDetailId: candidateToEdit.id,
				changeOfCenterReason,
				srcmId: candidateToEdit.profile.srcmId,
				examId: examById?.id,
				examinationCenterId: centerId,
				preferredLanguageForExamination:
					candidateToEdit.preferredLanguageForExamination,
				accommodationRequired: candidateToEdit.accommodationRequired,
				noOfAccommodations: candidateToEdit.noOfAccommodations,
				specialRequest: candidateToEdit.specialRequest,
			};

			updateCandidateCenter(request)
				.then((isCandidateUpdated) => {
					if (isCandidateUpdated) {
						setShowEditCandidateModal(false);
						const candidateName = candidateToEdit.profile.name;
						Modal.info({
							title: t('updatedCenter'),
							content: t('postEditCandidateMessage', { candidateName }),
							okText: t('ok'),
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
							onOk: onHoldOrEditSucess,
						});
					}
				})
				.catch((error: Error) => {
					showToastMessage('error', error.message);
				});
		},
		[
			candidateToEdit,
			examById,
			onHoldOrEditSucess,
			showToastMessage,
			t,
			updateCandidateCenter,
		]
	);

	const handleHoldCandidateClick = useCallback(
		(candidate: CandidateExaminationDetail) => {
			setCandidateToHold(candidate);
			setShowHoldModal(true);
		},
		[]
	);

	const handleHoldModalClose = useCallback(() => {
		setShowHoldModal(false);
		setCandidateToHold(null);
	}, []);

	const handleHoldModalSubmit = useCallback(
		(reason: string) => {
			if (!candidateToHold?.id) return;

			holdCandidate(reason, candidateToHold.id)
				.then((isCandidatePlacedOnHold) => {
					if (isCandidatePlacedOnHold) {
						setShowHoldModal(false);
						const candidateName = candidateToHold.profile.name;
						Modal.info({
							title: t('keptOnHold'),
							content: t('postHoldCandidateMessage', { candidateName }),
							okText: t('ok'),
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
							onOk: onHoldOrEditSucess,
						});
					}
				})
				.catch((error: Error) => {
					showToastMessage('error', error.message);
				});
		},
		[
			candidateToHold?.id,
			candidateToHold?.profile.name,
			holdCandidate,
			onHoldOrEditSucess,
			showToastMessage,
			t,
		]
	);

	const displayHoldOrEdit =
		moment().isBefore(examById?.examDate) && examById?.status === 'ACTIVE';

	const columns = CandidateTableColumns(
		handleHoldCandidateClick,
		handleEditCandidateClick,
		displayHoldOrEdit
	);

	const handleModalClose = useCallback(() => {
		fetchExamById().catch((error: Error) => {
			showToastMessage('error', error.message);
		});
		onCandidateExamDetailsFilterChange(examFilter);
	}, [
		examFilter,
		fetchExamById,
		onCandidateExamDetailsFilterChange,
		showToastMessage,
	]);

	const closeResultsUploadModal = useCallback(() => {
		handleModalClose();
		setShowResultsUploadModal(false);
	}, [handleModalClose]);

	const closeAttendanceModal = useCallback(() => {
		handleModalClose();
		setShowAttendanceModal(false);
	}, [handleModalClose]);

	const isCandidatesListEmpty =
		candidateExamDetails === undefined ||
		(candidateExamDetails.length && candidateExamDetails.length === 0)
			? true
			: false;

	const disableAdmitCardButton =
		isCandidatesListEmpty || moment().isBefore(examById?.registrationEndDate);
	const disableExportButton = isCandidatesListEmpty;
	const disableResultsButton =
		isCandidatesListEmpty || moment().isBefore(examById?.examDate);
	const disableUploadAttendance =
		isCandidatesListEmpty ||
		moment().isBefore(examById?.examDate) ||
		examById?.testAttendanceUploaded;
	const disableResultsUpload =
		isCandidatesListEmpty ||
		moment().isBefore(examById?.examDate) ||
		examById?.resultUploaded ||
		!examById?.testAttendanceUploaded;

	const onExportClick = useCallback(async () => {
		await exportCandidates();
	}, [exportCandidates]);

	const handleExportClick = useCallback(() => {
		setDownloadLoading(true);
		onExportClick()
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			})
			.finally(() => {
				setDownloadLoading(false);
			});
	}, [onExportClick, showToastMessage]);

	const DisplayCandidates = () => {
		return (
			<>
				{examById && (
					<FilterCandidates
						value={examFilter}
						onFilterChange={onCandidateExamDetailsFilterChange}
						examById={examById}
						disableFilters={isCandidatesListEmpty}
					/>
				)}
				<div className="flex gap-4 mt-4">
					<Button
						className="mb-5"
						icon={<MailOutlined />}
						size="middle"
						onClick={() => {
							navigateWithBatchId(SEND_ADMIT_CARD_EMAIL);
						}}
						disabled={disableAdmitCardButton}
					>
						{t('sendAdmitCardEmail')}
					</Button>
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						onClick={handleExportClick}
						loading={downloadLoading}
						disabled={disableExportButton}
					>
						{t('exportCandidates')}
					</Button>
					<Button
						className="mb-5"
						icon={<MailOutlined />}
						size="middle"
						onClick={() => {
							navigateWithBatchId(SEND_RESULTS_EMAIL);
						}}
						disabled={disableResultsButton}
					>
						{t('sendResultsEmail')}
					</Button>
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						size="middle"
						onClick={() => {
							setShowAttendanceModal(true);
						}}
						disabled={disableUploadAttendance}
					>
						{t('uploadAttendance')}
					</Button>
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						size="middle"
						disabled={disableResultsUpload}
						onClick={() => {
							setShowResultsUploadModal(true);
						}}
					>
						{t('resultUpload')}
					</Button>
					{examById?.resultUploaded && (
						<Tooltip
							className="text-gray-400 text-base"
							title={t('resultsUploadedAlready')}
						>
							<InfoCircleOutlined className="text-xl mb-4" />
						</Tooltip>
					)}
				</div>
				<Table
					dataSource={candidateExamDetails}
					columns={columns}
					pagination={tablePaginationConfig}
					scroll={{ x: 'max-content' }}
					className="overflow-x-auto"
				/>
				<ResultsUploadModal
					openModal={showResultsUploadModal}
					onCancel={closeResultsUploadModal}
					downloadResultsTemplate={downloadResultsTemplate}
					uploadResults={uploadResults}
				/>
				<AttendanceModal
					openModal={showAttendanceModal}
					onCancel={closeAttendanceModal}
					downloadResultsTemplate={downloadAttendanceTemplate}
					uploadAttendance={uploadAttendance}
				/>
				{candidateToHold && (
					<HoldCandidateModal
						openModal={showHoldModal}
						onClose={handleHoldModalClose}
						onSubmit={handleHoldModalSubmit}
						candidateExaminationDetail={candidateToHold}
					/>
				)}
				{candidateToEdit && examById && (
					<EditCandidateModal
						openModal={showEditCandidateModal}
						onClose={handleEditCandidateModalClose}
						onSubmit={handleEditCandidateModalSubmit}
						candidateExaminationDetail={candidateToEdit}
						examById={examById}
						fetchExamCenterDetail={fetchExamCenterDetail}
					/>
				)}
			</>
		);
	};

	return (
		<>
			<div className="flex justify-between items-center">
				<div className="flex gap-6 mb-4">
					<h3>
						{t('examGroup')}: {examById?.name}
					</h3>
					<h3>
						{t('examDate')}:{' '}
						{examById?.examDate &&
							moment(examById?.examDate).format(DATE_DO_MMM_YYYY)}
					</h3>
				</div>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={() => navigateWithBatchId(EXAM_MODULE_EXAMS_LIST)}
					icon={<ArrowLeftOutlined />}
				>
					{t('back')}
				</Button>
			</div>
			<DisplayCandidates />
		</>
	);
};
