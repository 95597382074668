import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { EXAM_MODULE_HOME, EXAM_REGISTRATION } from '../shared/Routes';

const LanguageDropdown = ({ className }: { className?: string }) => {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState('en');
	const location = useLocation();
	const onlyEnglish =
		location.pathname.includes('/nps') ||
		location.pathname.includes(EXAM_MODULE_HOME) ||
		location.pathname.includes(EXAM_REGISTRATION);

	const options = [
		{ value: 'en', label: 'English' },
		{ value: 'hi', label: 'हिन्दी' },
	];

	if (onlyEnglish) {
		options.pop();
	}

	const handleChange = (value: string) => {
		setLanguage(value);
		void i18n.changeLanguage(value);
	};

	return (
		<Select
			defaultValue="en"
			value={language}
			onChange={handleChange}
			className={className}
			options={options}
		/>
	);
};

export default LanguageDropdown;
