import { useMemo } from 'react';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';
import { ReviewAction } from '../../../shared/Constants';
import { theme } from 'antd';

export const useScoreCalculator = (feedbacks: Array<Feedback | undefined>) => {
	const {
		token: { green6, red5 },
	} = theme.useToken();

	const stats = useMemo(() => {
		const approvedCount = feedbacks.reduce((count, element) => {
			if (element?.review === ReviewAction.APPROVE) {
				return count + 1;
			}
			return count;
		}, 0);

		const totalCount = feedbacks.reduce((count, element) => {
			if (
				element &&
				element?.review !== ReviewAction.NOT_APPLICABLE &&
				element?.review !== ReviewAction.PENDING
			) {
				return count + 1;
			}
			return count;
		}, 0);

		const percent = 100;

		const strokeColor = feedbacks
			.map((feedback) => {
				if (feedback && feedback?.review === ReviewAction.REJECT) {
					return red5;
				} else if (feedback) {
					return green6;
				}
				return null as unknown as string;
			})
			.filter(Boolean);

		return { approvedCount, totalCount, percent, strokeColor };
	}, [feedbacks, green6, red5]);

	return stats;
};
