import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { SupportUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = SupportUIConfig;

export const SupportStep = () => {
	const { support, setSupport } = useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(support, setSupport);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
