import { Image, Typography } from 'antd';

const { Text } = Typography;

const DescriptionPhotoValue = ({
	value,
	errorMessage,
}: {
	value?: string;
	errorMessage: string;
}) => {
	if (value === undefined || value === null || value == '') {
		return <Text type="danger">{errorMessage}</Text>;
	}

	return <Image width={100} src={value} />;
};

export default DescriptionPhotoValue;
