import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { AvailabilityAfterProgramStep } from './AvailabilityAfterProgramStep';
import { CharacterAndHabitsStep } from './CharacterAndHabitsStep';
import { InspirationAndWillingnessStep } from './InspirationAndWillingnessStep';
import { LandingPage } from './LandingPage';
import { PracticeStep } from './PracticeStep';
import { PreceptorEmailAndSRCMIDCollectionStepContainer } from './PreceptorEmailSRCMIDCollectionStep';
import { PreceptorRecommendationStepContainer } from './PreceptorRecommendationCollectionStep';
import { SupportStep } from './SupportStep';
import { UnderstandingOfHeartfulnessStep } from './UnderstandingOfHeartfulnessStep';
import {
	PreceptorInterviewProvider,
	usePreceptorInterview,
} from './usePreceptorInterviewWizard';

export const PreceptorInterviewWizard = () => {
	const { onSubmitClick, application, onCancelClick } = usePreceptorInterview();

	const { preceptorReference, applicant } = application;

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={onSubmitClick}
				onCancelClick={onCancelClick}
				steps={[
					<LandingPage
						preceptorReference={preceptorReference}
						applicantInfo={applicant}
						key={1}
					/>,
					<PreceptorEmailAndSRCMIDCollectionStepContainer key={2} />,
					<InspirationAndWillingnessStep key={3} />,
					<PracticeStep key={4} />,
					<CharacterAndHabitsStep key={5} />,
					<AvailabilityAfterProgramStep key={6} />,
					<SupportStep key={7} />,
					<UnderstandingOfHeartfulnessStep key={8} />,
					<PreceptorRecommendationStepContainer key={10} />,
				]}
			/>
		</div>
	);
};

export const PreceptorInterviewWizardContainer = () => {
	return (
		<PreceptorInterviewProvider>
			<WizardProvider>
				<PreceptorInterviewWizard />
			</WizardProvider>
		</PreceptorInterviewProvider>
	);
};
