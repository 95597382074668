import { Typography, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { QuestionsToAsk } from '../QuestionsToAsk';
import { FeedbackComponent } from '../Feedback';
import { MandatoryQuestionsToAsk } from '../MandatoryQuestionsToAsk';

const { Text, Title } = Typography;

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
	mandatoryQuestionsAndAnswersForm: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mb-[8px]">
			<Text className="mt-[24px]" type="secondary">
				{t('candidatePreceptorInterview')}
			</Text>
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CategoryStep = ({
	uiConfig: { title, subTitle, questionsToAskInPreceptorInterview },
	form,
	mandatoryQuestionsAndAnswersForm,
}: CategoryStepProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="mandatoryQuestionsAndAnswersForm"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={mandatoryQuestionsAndAnswersForm}
			>
				<SectionHeader heading={t(title)} description={t(subTitle)} />
				<MandatoryQuestionsToAsk
					uiConfig={questionsToAskInPreceptorInterview.mandatoryQuestionsToAsk}
				/>
			</Form>
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<QuestionsToAsk
					uiConfig={questionsToAskInPreceptorInterview.suggestedQuestionsToAsk}
				/>
				<Title level={5}>
					{t('proposersFeedbackForCategory', { categoryTitle: t(title) })}
				</Title>
				<FeedbackComponent />
			</Form>
		</div>
	);
};
