import { Typography, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicantInfo } from '../../../shared/VolunteerApplicationServiceTypes';
import { useWizard } from '../../../components/wizard/useWizard';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';
import { useEffect } from 'react';

const { Title, Text } = Typography;

interface LandingPageProps {
	applicantInfo?: ApplicantInfo;
}

const SectionHeader = ({ heading }: { heading: string }) => {
	return (
		<div className="flex flex-col align-center">
			<Title level={4}>{heading}</Title>
		</div>
	);
};

export const LandingPage = ({ applicantInfo }: LandingPageProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<SectionHeader heading={t('candidateInterview')} />
			<div className="flex flex-col mb-[8px]">
				<Title level={5}>{t('setContextPolitely')}</Title>
				<Text type="secondary" className="pb-[8px]">
					{t('applicantInterviewMayITalkToYou')}
				</Text>
				<Text type="secondary" className="pb-[8px]">
					{t('thisConversationIsImportantPartOfProcess')}
				</Text>
				<Text type="secondary" className="pb-[8px]">
					{t('pleaseExcuseIfSomeQuestionsAppearSensitive')}
				</Text>
			</div>
			<Title level={5}>{t('candidateDetails')}</Title>
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('firstName')}>
					{applicantInfo?.firstName}
				</Descriptions.Item>
				<Descriptions.Item label={t('middleName')}>
					{applicantInfo?.middleName}
				</Descriptions.Item>
				<Descriptions.Item label={t('lastName')}>
					{applicantInfo?.lastName}
				</Descriptions.Item>
				<Descriptions.Item label={t('idCardNo')}>
					{applicantInfo?.srcmid}
				</Descriptions.Item>
				<Descriptions.Item label={t('age')}>
					{applicantInfo?.age}
				</Descriptions.Item>
				<Descriptions.Item label={t('email')}>
					{applicantInfo?.email}
				</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>
					{applicantInfo?.mobile}
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

export const LandingPageContainer = () => {
	const { setNextButtonDisabled } = useWizard();

	const { application } = useApplicantInterviewWizard();

	useEffect(() => {
		if (!application?.preceptorReference) {
			setNextButtonDisabled(true);
		}
	}, [application?.preceptorReference, setNextButtonDisabled]);

	return <LandingPage applicantInfo={application?.applicant} />;
};
