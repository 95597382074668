import { useCallback, useState } from 'react';
import {
	DownloadOutlined,
	InboxOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { Button, Divider, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { SignedUrlDetail } from '../../../../shared/ExamModuleTypes';

interface ResultsUploadModalProps {
	openModal: boolean;
	onCancel: () => void;
	downloadResultsTemplate: () => Promise<void>;
	uploadResults: (
		signedURLDetail: Array<SignedUrlDetail>,
		file: UploadFile<Blob>,
		fileExtension: string | undefined
	) => Promise<void>;
}

const { Dragger } = Upload;

export const ResultsUploadModal = ({
	openModal,
	onCancel,
	downloadResultsTemplate,
	uploadResults,
}: ResultsUploadModalProps) => {
	const [fileList, setFileList] = useState<UploadFile<Blob>[]>([]);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();

	const props: UploadProps = {
		multiple: false,
		accept:
			'.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		onRemove: (file: UploadFile<Blob>) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			if (
				file.type !==
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				showToastMessage('error', t('onlyExcelOrCsvFiles'));
				return Upload.LIST_IGNORE;
			}

			setFileList([file]);
			return false;
		},
		fileList,
	};

	const onDownloadClick = useCallback(async () => {
		await downloadResultsTemplate();
	}, [downloadResultsTemplate]);

	const handleDownloadClick = useCallback(() => {
		setDownloadLoading(true);
		onDownloadClick()
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			})
			.finally(() => {
				setDownloadLoading(false);
			});
	}, [onDownloadClick, showToastMessage]);

	const onUploadClick = useCallback(async () => {
		const { name: fileName, type: fileType } = fileList[0];
		const dotIndex = fileName.lastIndexOf('.');
		const csvFileName =
			dotIndex !== -1 ? fileName.slice(0, dotIndex) : fileName;
		const fileExtension =
			dotIndex !== -1
				? fileName.slice(dotIndex + 1)
				: fileType?.split('/').pop();

		const fileToUpload = [
			{
				fileName: csvFileName || '',
				fileExtension: fileExtension || '',
				fileType: fileType || '',
			},
		];

		if (fileToUpload.length) {
			await uploadResults(fileToUpload, fileList[0], fileExtension);
		}
	}, [fileList, uploadResults]);

	const handleUploadClick = useCallback(() => {
		setUploadLoading(true);
		onUploadClick()
			.then(() => {
				onCancel();
			})
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			})
			.finally(() => {
				setUploadLoading(false);
			});
	}, [onUploadClick, showToastMessage, onCancel]);

	return (
		<Modal
			open={openModal}
			onCancel={onCancel}
			footer={null}
			width={700}
			centered
		>
			<h2 className="text-center">{t('results')}</h2>
			<Divider className="h-0.5 bg-gray-400" />
			<h3 className="text-center">{t('downloadTemplate')}</h3>
			<div className="flex items-center justify-center">
				<Button
					className="w-52 text-xs font-semibold "
					size="large"
					icon={<DownloadOutlined />}
					onClick={handleDownloadClick}
					loading={downloadLoading}
				>
					{t('download')}
				</Button>
			</div>
			<Divider className="mt-8 h-0.5 bg-gray-400" />
			<h3 className="text-center">{t('uploadResults')}</h3>
			<div className="flex flex-col items-center justify-center">
				<Dragger className="m-4" {...props}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined className="!text-hover-black" />
					</p>
					<p className="ant-upload-text">{t('clickOrDragToUpload')}</p>
					<p className="ant-upload-hint">{t('uploadHint')}</p>
				</Dragger>
				<Button
					className="w-52 text-xs font-semibold "
					size="large"
					disabled={fileList.length === 0}
					icon={<UploadOutlined />}
					onClick={handleUploadClick}
					loading={uploadLoading}
				>
					{t('upload')}
				</Button>
			</div>
		</Modal>
	);
};
