import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { AvailabilityAfterProgramStep } from './AvailabilityAfterProgramStep';
import { CharacterAndHabitsStep } from './CharacterAndHabitsStep';
import { DietaryHabitsAndHealthStep } from './DietaryHabitsAndHealthStep';
import { LandingPageContainer } from './LandingPage';
import { SupportStep } from './SupportStep';
import {
	FamilyMemberInterviewProvider,
	useFamilyMemberInterviewWizard,
} from './useFamilyMemberInterviewWizard';

export const FamilyMemberInterviewWizard = () => {
	const { onSubmitClick, onCancelClick } = useFamilyMemberInterviewWizard();

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={onSubmitClick}
				onCancelClick={onCancelClick}
				steps={[
					<LandingPageContainer key={1} />,
					<CharacterAndHabitsStep key={5} />,
					<AvailabilityAfterProgramStep key={6} />,
					<SupportStep key={7} />,
					<DietaryHabitsAndHealthStep key={9} />,
				]}
			/>
		</div>
	);
};

export const FamilyMemberInterviewWizardContainer = () => {
	return (
		<FamilyMemberInterviewProvider>
			<WizardProvider>
				<FamilyMemberInterviewWizard />
			</WizardProvider>
		</FamilyMemberInterviewProvider>
	);
};
