import { Typography, Form, FormInstance, Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { RecommendationComponent } from '../RecommendationComponent';
import {
	PredefinedReasonCategory,
	ReviewAction,
} from '../../../shared/Constants';
import { useZCRecommendationPage } from './useZCRecommendationPage';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';

const { Text, Title } = Typography;

interface ZCRecommendationCollectionPageProps {
	form: FormInstance;
	zcRecommendation: Feedback | undefined;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const ZCRecommendationCollectionPage = ({
	form,
	zcRecommendation,
}: ZCRecommendationCollectionPageProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{
					remember: true,
					review: !zcRecommendation && ReviewAction.APPROVE,
				}}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={t('provideZCFinalRecommendation')}
					description={t('pleaseProvideBasedOnAllInterviews')}
				/>
				<div className="flex flex-row items-start">
					<div className="flex flex-col w-full">
						<Text>{t('iRecommendTheCandidatesApplicationToBe')}</Text>
						<RecommendationComponent
							commentsMandatory
							commentsLabel="yourCommentsToSupportAboveRecommendation"
							commentsPlaceholder="commentsAreMandatory"
							form={form}
							category={PredefinedReasonCategory.ZC_REJECT}
						/>
					</div>
				</div>
			</Form>
		</div>
	);
};

export const ZCRecommendationCollectionPageContainer = () => {
	const { t } = useTranslation();
	const {
		form,
		onSubmitClick,
		onCancelClick,
		submitButtonDisabled,
		application,
	} = useZCRecommendationPage();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<ZCRecommendationCollectionPage
				form={form}
				zcRecommendation={application?.zcRecommendation}
			/>
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled || application?.isApprover}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
			{application?.isApprover && (
				<Alert
					showIcon
					message={t('onlyZCIsAllowedToSubmitFeedback')}
					type="warning"
				/>
			)}
		</div>
	);
};
