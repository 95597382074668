import { Form, FormInstance, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import { MultipleChoiceQuestion } from '../NewApplicationWizard/MultipleChoiceQuestion';

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
}

export const CategoryStep = ({
	uiConfig: { questions },
	form,
}: CategoryStepProps) => {
	const { t } = useTranslation();

	const allQuestions = questions.map(
		(question: MultipleChoiceQuestionUIConfig) => (
			<div key={question.questionId} className="my-[8px]">
				<MultipleChoiceQuestion uiConfig={question} />
			</div>
		)
	);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				{allQuestions}
				<Form.Item
					name="foundationOfServingIsIntegrity"
					valuePropName="checked"
				>
					<Checkbox>{t('foundationOfServingIsIntegrity')}</Checkbox>
				</Form.Item>
			</Form>
		</div>
	);
};
