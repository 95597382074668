import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
	useEffect,
} from 'react';
import {
	Application,
	Feedback,
	FunctionaryFeedbackRequest,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { submitFeedbackFromProposer } from '../../../service/VolunteerApplicationService';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { useAuth } from '../../../hooks/useAuth';
import { INTERVIEW_LANDING } from '../../../shared/Routes';
import { useInterviewWizard } from '../useInterviewWizard';
import { Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	addMandatoryQuestionsToFunctionaryFeedback,
	transformToAnswersForMandatoryQuestionsFromGRPC,
} from '../../../shared/Utils';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

export interface ApplicantInterviewContextType {
	inspirationAndWillingness: Feedback;
	setInspirationAndWillingness: (feedback: Feedback) => void;
	practice: Feedback;
	setPractice: (feedback: Feedback) => void;
	characterAndHabits: Feedback;
	setCharacterAndHabits: (feedback: Feedback) => void;
	availabilityAfterProgram: Feedback;
	setAvailabilityAfterProgram: (feedback: Feedback) => void;
	support: Feedback;
	setSupport: (feedback: Feedback) => void;
	understandingOfHeartfulness: Feedback;
	setUnderstandingOfHeartfulness: (feedback: Feedback) => void;
	eligibilityCriteria: Feedback;
	setEligibilityCriteria: (feedback: Feedback) => void;
	dietaryHabitsAndHealth: Feedback;
	setDietaryHabitsAndHealth: (feedback: Feedback) => void;
	application: Partial<Application>;
	onSubmitClick: () => void;
	onCancelClick: () => void;
}

interface Props {
	children: ReactNode;
}

const ApplicantInterviewContext = createContext<ApplicantInterviewContextType>({
	inspirationAndWillingness: {},
	setInspirationAndWillingness: () => {},
	practice: {},
	setPractice: () => {},
	characterAndHabits: {},
	setCharacterAndHabits: () => {},
	availabilityAfterProgram: {},
	setAvailabilityAfterProgram: () => {},
	support: {},
	setSupport: () => {},
	understandingOfHeartfulness: {},
	setUnderstandingOfHeartfulness: () => {},
	eligibilityCriteria: {},
	setEligibilityCriteria: () => {},
	dietaryHabitsAndHealth: {},
	setDietaryHabitsAndHealth: () => {},
	application: {},
	onSubmitClick: () => {},
	onCancelClick: () => {},
});

export const ApplicantInterviewProvider: FC<Props> = ({ children }: Props) => {
	const { t, i18n } = useTranslation();
	const { srcmId } = useAuth();
	const { showToastMessage } = useToastMessage();
	const [inspirationAndWillingness, setInspirationAndWillingness] =
		useState<Feedback>({});
	const [practice, setPractice] = useState<Feedback>({});
	const [characterAndHabits, setCharacterAndHabits] = useState<Feedback>({});
	const [availabilityAfterProgram, setAvailabilityAfterProgram] =
		useState<Feedback>({});
	const [support, setSupport] = useState<Feedback>({});
	const [understandingOfHeartfulness, setUnderstandingOfHeartfulness] =
		useState<Feedback>({});
	const [eligibilityCriteria, setEligibilityCriteria] = useState<Feedback>({});
	const [dietaryHabitsAndHealth, setDietaryHabitsAndHealth] =
		useState<Feedback>({});
	const [application, setApplication] = useState<Partial<Application>>({});
	const {
		applicationId,
		navigateWithApplicationId,
		fetchApplication: callFetchApplication,
	} = useApplicationId();
	const { confirmAndSubmit, onCancelClick, callApi } = useInterviewWizard();
	const { role } = useRedirectToLogin();

	const saveFeedback = useCallback(async () => {
		const feedback: FunctionaryFeedbackRequest = {
			applicationId,
			categoryReviewFeedback: {
				availabilityAfterProgram,
				characterAndHabits,
				inspirationAndWillingness,
				practice,
				support,
				understandingOfHeartfulness,
				eligibilityCriteria,
				dietaryHabitsAndHealth,
			},
			feedbackCategory: 'APPLICANT_INTERVIEW',
			functionarySrcmId: srcmId as string,
		};

		const feedbackWithMandatoryQuestionsAndAnswers =
			addMandatoryQuestionsToFunctionaryFeedback(feedback, i18n.language);

		try {
			await callApi(
				() =>
					submitFeedbackFromProposer(feedbackWithMandatoryQuestionsAndAnswers),
				'errorOccurredWhileSavingFeedback'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(INTERVIEW_LANDING),
			});
		} catch (e) {
			/* empty */
		}
	}, [
		applicationId,
		availabilityAfterProgram,
		callApi,
		characterAndHabits,
		i18n.language,
		inspirationAndWillingness,
		navigateWithApplicationId,
		practice,
		srcmId,
		support,
		t,
		understandingOfHeartfulness,
		eligibilityCriteria,
		dietaryHabitsAndHealth,
	]);

	const onSubmitClick = useCallback(() => {
		confirmAndSubmit(saveFeedback);
	}, [confirmAndSubmit, saveFeedback]);

	const value = useMemo<ApplicantInterviewContextType>(() => {
		return {
			inspirationAndWillingness,
			setInspirationAndWillingness,
			practice,
			setPractice,
			characterAndHabits,
			setCharacterAndHabits,
			availabilityAfterProgram,
			setAvailabilityAfterProgram,
			support,
			setSupport,
			understandingOfHeartfulness,
			setUnderstandingOfHeartfulness,
			eligibilityCriteria,
			setEligibilityCriteria,
			dietaryHabitsAndHealth,
			setDietaryHabitsAndHealth,
			application,
			onSubmitClick,
			onCancelClick,
		};
	}, [
		inspirationAndWillingness,
		practice,
		characterAndHabits,
		availabilityAfterProgram,
		support,
		understandingOfHeartfulness,
		eligibilityCriteria,
		dietaryHabitsAndHealth,
		application,
		onSubmitClick,
		onCancelClick,
	]);

	const fetchApplication = useCallback(async () => {
		if (!applicationId) {
			showToastMessage('error', 'applicationId is missing');
		}

		try {
			const fetchedApplication = await callFetchApplication();

			const applicantInterviewFeedbackWithAnswersForMandatoryQuestions =
				transformToAnswersForMandatoryQuestionsFromGRPC(
					fetchedApplication.applicantInterviewFeedback
				);

			setInspirationAndWillingness(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.inspirationAndWillingness ??
					{}
			);
			setPractice(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.practice ??
					{}
			);
			setAvailabilityAfterProgram(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.availabilityAfterProgram ??
					{}
			);
			setCharacterAndHabits(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.characterAndHabits ??
					{}
			);
			setSupport(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.support ??
					{}
			);
			setUnderstandingOfHeartfulness(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.understandingOfHeartfulness ??
					{}
			);
			setDietaryHabitsAndHealth(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.dietaryHabitsAndHealth ??
					{}
			);
			setEligibilityCriteria(
				applicantInterviewFeedbackWithAnswersForMandatoryQuestions?.eligibilityCriteria ??
					{}
			);
			setApplication(fetchedApplication);
		} catch (e) {
			// Do Nothing
		}
	}, [applicationId, callFetchApplication, showToastMessage]);

	useEffect(() => {
		void fetchApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ApplicantInterviewContext.Provider value={value}>
			{application?.isApprover && (
				<Alert
					showIcon
					message="Approvers are not allowed to submit their interview feedback"
					type="warning"
				/>
			)}
			{role === UserRole.ZC && (
				<Alert
					showIcon
					message="ZC is not allowed to submit the interview feedback"
					type="warning"
				/>
			)}
			{children}
		</ApplicantInterviewContext.Provider>
	);
};

export const useApplicantInterviewWizard = () => {
	return useContext(ApplicantInterviewContext);
};
