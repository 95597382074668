import { useCallback, useEffect, useState } from 'react';
import {
	GetPreceptorsGroupResponse,
	GetPreceptorsResponse,
	NPHHSortingRequest,
	PagingConfig,
	PreceptorDashboardCardDetail,
	PreceptorsFilter,
	PreceptorsGroupFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useSnapshot } from 'valtio';
import {
	DashboardDetailState,
	DashboardListState,
	GroupsListState,
	PreceptorBatchState,
	PreceptorsListState,
} from './PreceptorsList.store';
import { useAuth } from '../../../hooks/useAuth';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	createPreceptorGroup,
	getPreceptorDashboardCardDetail,
	getPreceptorGroups,
	getPreceptors,
} from '../../../service/VolunteerApplicationService';
import {
	COUNTRY_CODE_INDIA,
	DEFAULT_PAGE_SIZE,
	GroupTabs,
} from '../../../shared/Constants';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useCallApi } from '../../../hooks/useCallApi';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { GROUPS } from '../../../shared/Routes';
import { useGroupId } from '../../../hooks/useGroupId';
import useQueryParams from '../../../hooks/useQueryParams';

export const useCreateGroup = () => {
	const [preceptorsList, setPreceptorsList] = useState<GetPreceptorsResponse>({
		preceptors: [],
		pagination: { totalNoOfRecords: 0 },
	});
	const [groupsList, setGroupsList] = useState<GetPreceptorsGroupResponse>({
		preceptorGroups: [],
		pagination: { totalNoOfRecords: 0 },
	});
	const [dashboardPreceptorsList, setDashboardPreceptorsList] =
		useState<GetPreceptorsResponse>({
			preceptors: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [dashboardDetail, setDashboardDetail] = useState<
		PreceptorDashboardCardDetail | undefined
	>();
	const { navigateWithBatchIdAndGroupTab } = useGroupId();
	const queryParams = useQueryParams();
	const groupId = queryParams.get('groupId');
	const tabKey = queryParams.get('activetab');
	const [activeTabKey, setActiveTabKey] = useState(tabKey);
	const { t } = useTranslation();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { callApi } = useCallApi();
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const preceptorsFilterStateSnap = useSnapshot(PreceptorsListState);
	const groupsFilterStateSnap = useSnapshot(GroupsListState);
	const dashboardFilterStateSnap = useSnapshot(DashboardListState);
	const dashboardDetailFilterStateSnap = useSnapshot(DashboardDetailState);
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const selectedBatchId = preceptorBatchSnap.selectedBatch?.id;
	const [preceptorPaging, setPreceptorPaging] = useState<PagingConfig>(() => {
		return { ...preceptorsFilterStateSnap.paging } as PagingConfig;
	});
	const [groupPaging, setGroupPaging] = useState<PagingConfig>(() => {
		return { ...groupsFilterStateSnap.paging } as PagingConfig;
	});
	const [dashboardPaging, setDashboardPaging] = useState<PagingConfig>(() => {
		return { ...dashboardFilterStateSnap.paging } as PagingConfig;
	});
	const [preceptorsFilter, setPreceptorsFilter] = useState<PreceptorsFilter>({
		showNewPreceptorsForGrouping: true,
		countryIds: [COUNTRY_CODE_INDIA],
		preceptorBatchId: selectedBatchId,
	});
	const [groupsFilter, setGroupsFilter] = useState<PreceptorsGroupFilter>(
		() => {
			const sortingRequest: NPHHSortingRequest = {
				sortingFieldName: 'GROUP_ID',
				sortingOrder: 'DESCENDING',
			};
			if (groupsFilterStateSnap.filterBy) {
				return {
					...groupsFilterStateSnap.filterBy,
					preceptorBatchId: selectedBatchId,
				} as PreceptorsGroupFilter;
			} else {
				return {
					sortingRequests: [sortingRequest],
					preceptorBatchId: selectedBatchId,
				} as PreceptorsGroupFilter;
			}
		}
	);
	const [dashboardFilter, setDashboardFilter] = useState<PreceptorsFilter>(
		() => {
			if (dashboardFilterStateSnap.filterBy) {
				return {
					...dashboardFilterStateSnap.filterBy,
					showAllPreceptors: true,
					countryIds: [COUNTRY_CODE_INDIA],
					preceptorBatchId: selectedBatchId,
				} as PreceptorsFilter;
			} else {
				return {
					showAllPreceptors: true,
					countryIds: [COUNTRY_CODE_INDIA],
					preceptorBatchId: selectedBatchId,
				} as PreceptorsFilter;
			}
		}
	);
	const [dashboardDetailFilter, setDashboardDetailFilter] =
		useState<PreceptorsFilter>(() => {
			if (dashboardDetailFilterStateSnap.filterBy) {
				return {
					...dashboardDetailFilterStateSnap.filterBy,
					showPreceptorDashboardCardDetail: true,
					countryIds: [COUNTRY_CODE_INDIA],
					preceptorBatchId: selectedBatchId,
				} as PreceptorsFilter;
			} else {
				return {
					showPreceptorDashboardCardDetail: true,
					countryIds: [COUNTRY_CODE_INDIA],
					preceptorBatchId: selectedBatchId,
				} as PreceptorsFilter;
			}
		});

	useEffect(() => {
		if (preceptorBatchSnap) {
			setPreceptorsFilter((prevState) => ({
				...prevState,
				preceptorBatchId: selectedBatchId,
			}));
			setGroupsFilter((prevState) => ({
				...prevState,
				preceptorBatchId: selectedBatchId,
			}));
			setDashboardFilter((prevState) => ({
				...prevState,
				preceptorBatchId: selectedBatchId,
			}));
			setDashboardDetailFilter((prevState) => ({
				...prevState,
				preceptorBatchId: selectedBatchId,
			}));
		}
	}, [preceptorBatchSnap, selectedBatchId]);

	const onGroupsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			GroupsListState.paging = { pageNo: pageNumber, pageSize };
			setGroupPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onGroupsFilterChange = useCallback(
		(filterParam: PreceptorsGroupFilter) => {
			GroupsListState.filterBy = { ...filterParam };
			setGroupsFilter(filterParam);
			onGroupsPageChange(1, groupPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onGroupsPageChange, groupPaging.pageSize]
	);

	const fetchGroupsList = useCallback(
		async (
			pageNumber: number,
			pageSize: number,
			filter: PreceptorsGroupFilter
		) => {
			if (srcmId && filter.preceptorBatchId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;

				try {
					const groupsWithoutKey = await getPreceptorGroups({
						functionarySrcmId: srcmId,
						pageIndex,
						pageSize,
						preceptorsGroupFilter: filter,
					});
					const groups = {
						...groupsWithoutKey,
						...(groupsWithoutKey.preceptorGroups && {
							preceptorGroups: groupsWithoutKey.preceptorGroups.map(
								(group) => ({
									...group,
									key: group.id,
								})
							),
						}),
					};

					if (
						groups.pagination.totalNoOfPages &&
						groups.pagination.totalNoOfPages > 0 &&
						pageNumber > groups.pagination.totalNoOfPages
					) {
						onGroupsPageChange(groups.pagination.totalNoOfPages ?? 1, pageSize);
					} else {
						setGroupsList(groups);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, onGroupsPageChange, setLoading, showToastMessage]
	);

	const onDashboardDetailFilterChange = useCallback(
		(filterParam: PreceptorsFilter) => {
			DashboardDetailState.filterBy = { ...filterParam };
			setDashboardDetailFilter(filterParam);
		},
		[]
	);

	const onDashboardPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			DashboardListState.paging = { pageNo: pageNumber, pageSize };
			setDashboardPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onDashboardFilterChange = useCallback(
		(filterParam: PreceptorsFilter) => {
			DashboardListState.filterBy = { ...filterParam };
			setDashboardFilter(filterParam);
			onDashboardPageChange(1, dashboardPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onDashboardPageChange, dashboardPaging.pageSize]
	);

	const fetchDashboardList = useCallback(
		async (pageNumber: number, pageSize: number, filter: PreceptorsFilter) => {
			if (srcmId && filter.preceptorBatchId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const preceptorsWithoutKey = await getPreceptors({
						functionarySrcmId: srcmId,
						pageIndex,
						pageSize,
						preceptorsFilter: filter,
					});
					const preceptors = {
						...preceptorsWithoutKey,
						...(preceptorsWithoutKey.preceptors && {
							preceptors: preceptorsWithoutKey.preceptors
								.filter((preceptor) => preceptor.prefectStatus === 'ACTIVE')
								.map((preceptor) => ({
									...preceptor,
									key: preceptor.id,
								})),
						}),
					};

					if (
						preceptors.pagination.totalNoOfPages &&
						preceptors.pagination.totalNoOfPages > 0 &&
						pageNumber > preceptors.pagination.totalNoOfPages
					) {
						onDashboardPageChange(
							preceptors.pagination.totalNoOfPages ?? 1,
							pageSize
						);
					} else {
						setDashboardPreceptorsList(preceptors);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, onDashboardPageChange, setLoading, showToastMessage]
	);

	const fetchDashboardCardDetails = useCallback(
		async (filter: PreceptorsFilter) => {
			if (srcmId && filter.preceptorBatchId) {
				setLoading(true);
				try {
					setDashboardDetail(undefined);
					const preceptorDashboardCardDetail =
						await getPreceptorDashboardCardDetail({
							functionarySrcmId: srcmId,
							preceptorsFilter: filter,
						});
					setDashboardDetail(preceptorDashboardCardDetail);
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, setLoading, showToastMessage]
	);

	const onPreceptorsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			PreceptorsListState.paging = { pageNo: pageNumber, pageSize };
			setPreceptorPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onPreceptorFilterChange = useCallback(
		(filterParam: PreceptorsFilter) => {
			// PreceptorsListState.filterBy = { ...filterParam };
			setPreceptorsFilter(filterParam);
			onPreceptorsPageChange(1, preceptorPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onPreceptorsPageChange, preceptorPaging.pageSize]
	);

	const fetchPreceptorsList = useCallback(
		async (pageNumber: number, pageSize: number, filter: PreceptorsFilter) => {
			if (srcmId && filter.preceptorBatchId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const preceptorsWithoutKey = await getPreceptors({
						functionarySrcmId: srcmId,
						pageIndex,
						pageSize,
						preceptorsFilter: filter,
					});
					const preceptors = {
						...preceptorsWithoutKey,
						...(preceptorsWithoutKey.preceptors && {
							preceptors: preceptorsWithoutKey.preceptors
								.filter((preceptor) => preceptor.prefectStatus === 'ACTIVE')
								.map((preceptor) => ({
									...preceptor,
									key: preceptor.id,
								})),
						}),
					};

					if (
						preceptors.pagination.totalNoOfPages &&
						preceptors.pagination.totalNoOfPages > 0 &&
						pageNumber > preceptors.pagination.totalNoOfPages
					) {
						onPreceptorsPageChange(
							preceptors.pagination.totalNoOfPages ?? 1,
							pageSize
						);
					} else {
						setPreceptorsList(preceptors);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, onPreceptorsPageChange, setLoading, showToastMessage]
	);

	const onConfirmCreateGroup = useCallback(
		async (preceptorIds: Array<number>) => {
			try {
				await callApi(
					() =>
						createPreceptorGroup({
							functionarySrcmId: srcmId as string,
							preceptorIds,
							preceptorBatchId: selectedBatchId,
						}),
					'errorOccurredWhileCreatingGroup'
				);
				Modal.success({
					content: t('createdGroupSuccessfully'),
					afterClose: () => {
						navigateWithBatchIdAndGroupTab(GROUPS, GroupTabs.GROUPS_LIST_VIEW);
						setActiveTabKey(GroupTabs.GROUPS_LIST_VIEW);
						void fetchGroupsList(
							groupPaging.pageNo ?? 1,
							groupPaging.pageSize ?? DEFAULT_PAGE_SIZE,
							groupsFilter
						);

						void fetchPreceptorsList(
							preceptorPaging.pageNo ?? 1,
							preceptorPaging.pageSize ?? DEFAULT_PAGE_SIZE,
							preceptorsFilter
						);
					},
				});
			} catch (e) {
				// Do Nothing
			}
		},
		[
			callApi,
			srcmId,
			navigateWithBatchIdAndGroupTab,
			t,
			fetchGroupsList,
			fetchPreceptorsList,
			groupPaging,
			groupsFilter,
			preceptorPaging,
			preceptorsFilter,
			selectedBatchId,
		]
	);

	const onCreateGroupClick = useCallback(
		(preceptorIds: Array<number>) => {
			void confirmBeforeAction(
				'confirmUpdateGroup',
				'areYouSureToCreateGroup',
				() => {
					setPreceptorsFilter({
						showNewPreceptorsForGrouping: true,
						countryIds: [COUNTRY_CODE_INDIA],
					});
					void onConfirmCreateGroup(preceptorIds);
				}
			);
		},
		[confirmBeforeAction, onConfirmCreateGroup]
	);

	useEffect(() => {
		if (activeTabKey === GroupTabs.CREATE_GROUP || groupId) {
			void fetchPreceptorsList(
				preceptorPaging.pageNo ?? 1,
				preceptorPaging.pageSize ?? DEFAULT_PAGE_SIZE,
				preceptorsFilter
			);
		}
	}, [
		activeTabKey,
		preceptorPaging,
		preceptorsFilter,
		fetchPreceptorsList,
		groupId,
	]);

	useEffect(() => {
		if (activeTabKey === GroupTabs.GROUPS_LIST_VIEW) {
			void fetchGroupsList(
				groupPaging.pageNo ?? 1,
				groupPaging.pageSize ?? DEFAULT_PAGE_SIZE,
				groupsFilter
			);
		}
	}, [activeTabKey, groupPaging, groupsFilter, fetchGroupsList]);

	useEffect(() => {
		if (
			activeTabKey === GroupTabs.VIEW_ALL_NEW_PRECEPTORS ||
			activeTabKey === GroupTabs.GRAPHICAL_VIEW
		) {
			void fetchDashboardCardDetails(dashboardDetailFilter);
		}
	}, [dashboardDetailFilter, fetchDashboardCardDetails, activeTabKey]);

	useEffect(() => {
		if (activeTabKey === GroupTabs.VIEW_ALL_NEW_PRECEPTORS) {
			void fetchDashboardList(
				dashboardPaging.pageNo ?? 1,
				dashboardPaging.pageSize ?? DEFAULT_PAGE_SIZE,
				dashboardFilter
			);
		}
	}, [activeTabKey, dashboardPaging, dashboardFilter, fetchDashboardList]);

	return {
		preceptorsList,
		preceptorsFilter,
		onPreceptorFilterChange,
		onPreceptorsPageChange,
		preceptorPaging,
		onCreateGroupClick,
		groupsList,
		groupsFilter,
		onGroupsFilterChange,
		onGroupsPageChange,
		groupPaging,
		dashboardDetail,
		onDashboardDetailFilterChange,
		dashboardPreceptorsList,
		dashboardFilter,
		onDashboardFilterChange,
		onDashboardPageChange,
		dashboardPaging,
		activeTabKey,
		setActiveTabKey,
	};
};
