import { useCallback, useMemo } from 'react';
import { useBatchId } from './useBatchId';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useToastMessage } from './useToastMessage';
import { useCallApi } from './useCallApi';
import { useAuth } from './useAuth';
import { getPreceptorGroup } from '../service/VolunteerApplicationService';

export const useGroupId = () => {
	const navigate = useNavigate();
	const { navigateWithBatchId, queryParams, getRouteWithBatchId } =
		useBatchId();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { srcmId } = useAuth();

	const groupId = useMemo(() => {
		const groupIdParam = Number(queryParams.get('groupId'));

		if (typeof groupIdParam !== 'number') {
			return -1;
		}

		return groupIdParam;
	}, [queryParams]);

	const navigateWithGroupId = useCallback(
		(route: string, groupIdParam?: number, params?: NavigateOptions) => {
			const groupIdToUse = groupIdParam ? `${groupIdParam}` : groupId;
			queryParams.set('groupId', `${groupIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[groupId, navigateWithBatchId, queryParams]
	);

	const navigateWithBatchIdAndGroupTab = useCallback(
		(route: string, activetab?: string) => {
			if (activetab) {
				queryParams.set('activetab', activetab);
			}
			queryParams.delete('groupId');
			navigate(`${getRouteWithBatchId(route)}`);
		},
		[getRouteWithBatchId, navigate, queryParams]
	);

	const fetchGroup = useCallback(async () => {
		if (!groupId) {
			showToastMessage('error', 'groupId is missing');
			throw Error('Group Id is missing while fetching group info.');
		}
		if (srcmId) {
			const response = await callApi(
				() =>
					getPreceptorGroup({
						functionarySrcmId: srcmId,
						id: groupId,
					}),
				'errorOccurredWhileLoading'
			);

			return response;
		}
	}, [srcmId, groupId, callApi, showToastMessage]);

	return {
		groupId,
		navigateWithGroupId,
		fetchGroup,
		navigateWithBatchIdAndGroupTab,
	};
};
