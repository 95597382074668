import { Typography, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';

const { Text, Title } = Typography;

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CategoryStep = ({
	uiConfig: { title, subTitle, questions },
	form,
}: CategoryStepProps) => {
	const { t } = useTranslation();

	const allQuestions = questions.map(
		(question: MultipleChoiceQuestionUIConfig) => (
			<div key={question.questionId} className="my-[8px]">
				<MultipleChoiceQuestion uiConfig={question} />
			</div>
		)
	);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader heading={t(title)} description={t(subTitle)} />
				{allQuestions}
			</Form>
		</div>
	);
};
