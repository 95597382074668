import { useCallback, useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import { Form } from 'antd';
import { PreWorkStepType } from './PreWorkStepType';
import { PreWorkQuestionId } from '../../../shared/Constants';

export default function usePreWorkFormHandling(
	categoryValue: PreWorkStepType,
	setCategoryValue: (value: PreWorkStepType) => void
) {
	const { setSubmitButtonDisabled, setCancelButtonDisabled } = useWizard();
	const [form] = Form.useForm<PreWorkStepType>();
	const values = Form.useWatch<PreWorkStepType>([], form);
	const { setFieldValue } = form;

	const setSystemGeneratedStateOfPreWork = useCallback(() => {
		if (!values) {
			return;
		}
		const firstFiveSelectedOptions = Object.values(values)
			.slice(0, 5)
			.map(
				(item: {
					selectedOption: number | undefined;
					comments: string | undefined;
				}) => {
					if (item?.selectedOption) {
						return item.selectedOption;
					}
					return 0;
				}
			);

		const heartfulCommunicationQuestionId =
			PreWorkQuestionId.HEARTFUL_COMMUNICATION;
		const systemGeneratedQuestionId = PreWorkQuestionId.SYSTEM_GENERATED;

		const countSelectedOne = firstFiveSelectedOptions.filter(
			(option) => option === 1
		).length;
		const countSelectedThreeOrFour = firstFiveSelectedOptions.filter(
			(option) => option === 3 || option === 4
		).length;

		const completed =
			firstFiveSelectedOptions.every((option) => option === 1) ||
			(countSelectedOne === 4 &&
				values[heartfulCommunicationQuestionId].selectedOption === 2);
		const partiallyCompleted =
			(countSelectedOne >= 3 ||
				(countSelectedOne >= 2 &&
					values[`${PreWorkQuestionId.HEARTFUL_COMMUNICATION}`]
						.selectedOption === 2)) &&
			countSelectedThreeOrFour === 0;

		if (completed) {
			setFieldValue(systemGeneratedQuestionId, {
				selectedOption: 1,
			});
		} else {
			if (partiallyCompleted) {
				setFieldValue(systemGeneratedQuestionId, {
					selectedOption: 2,
				});
			} else {
				setFieldValue(systemGeneratedQuestionId, {
					selectedOption: 3,
				});
			}
		}
	}, [setFieldValue, values]);

	const handleFormValuesChange = useCallback(async () => {
		setSystemGeneratedStateOfPreWork();
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setCategoryValue(fieldValues);
			setSubmitButtonDisabled(false);
			if (!fieldValues.foundationOfServingIsIntegrity) {
				setSubmitButtonDisabled(true);
			}
		} catch (e) {
			const fields = e as { values: PreWorkStepType };
			setCategoryValue(fields.values);
			setSubmitButtonDisabled(true);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setCancelButtonDisabled,
		setCategoryValue,
		setSubmitButtonDisabled,
		setSystemGeneratedStateOfPreWork,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(categoryValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, categoryValue]);

	return { form };
}
