import { proxy } from 'valtio';
import {
	PagingConfig,
	PreceptorBatch,
	PreceptorsFilter,
	PreceptorsGroupFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';

export const PreceptorsListState = proxy<{
	filterBy: PreceptorsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const MentorsListState = proxy<{
	filterBy: PreceptorsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const DashboardListState = proxy<{
	filterBy: PreceptorsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const DashboardDetailState = proxy<{
	filterBy: PreceptorsFilter | undefined;
}>({
	filterBy: undefined,
});

export const GraphViewListState = proxy<{
	filterBy: PreceptorsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const GroupsListState = proxy<{
	filterBy: PreceptorsGroupFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});

export const PreceptorBatchState = proxy<{
	selectedBatch: PreceptorBatch | undefined;
}>({ selectedBatch: undefined });
