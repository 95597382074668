import React, { useCallback, useState } from 'react';
import { Preceptor } from '../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import { PreceptorTableColumns } from '../shared/Columns';
import { Button, Table } from 'antd';
import { ADD_MORE_PRECEPTORS, ASSIGN_MENTOR, GROUPS } from '../shared/Routes';
import { useEditGroup } from '../pages/nps/EditGroupPage/useEditGroup';
import { GroupTabs, MAX_PRECEPTORS_FOR_GROUP } from '../shared/Constants';
import { useGroupId } from '../hooks/useGroupId';

export const GroupPreceptorsTable = ({
	groupPreceptors,
	isEditGroupTable = false,
	mentor,
}: {
	groupPreceptors: Array<Preceptor> | undefined;
	isEditGroupTable?: boolean;
	mentor?: Preceptor;
}) => {
	const { t } = useTranslation();
	const { navigateWithGroupId, groupId, navigateWithBatchIdAndGroupTab } =
		useGroupId();
	const { updateGroup } = useEditGroup();
	const groupPreceptorIds =
		groupPreceptors && groupPreceptors.map((preceptor) => preceptor.id);
	const [selectedGroupPreceptors, setSelectedGroupPreceptors] = useState<
		React.Key[]
	>([]);

	const onSelectChange = (newSelectedPreceptorKeys: React.Key[]) => {
		setSelectedGroupPreceptors(newSelectedPreceptorKeys);
	};

	const groupPreceptorSelection = {
		selectedGroupPreceptors,
		onChange: onSelectChange,
	};

	const hasSelected = selectedGroupPreceptors.length > 0;
	const disableAddMore =
		groupPreceptorIds && groupPreceptorIds.length === MAX_PRECEPTORS_FOR_GROUP;

	const handleRemoveClick = useCallback(() => {
		const preceptorIdsToBeUpdated =
			groupPreceptorIds &&
			groupPreceptorIds.filter((id) => !selectedGroupPreceptors.includes(id));
		if (preceptorIdsToBeUpdated) {
			updateGroup(preceptorIdsToBeUpdated, 'areYouSureToRemoveGroup', false);
		}
	}, [groupPreceptorIds, updateGroup, selectedGroupPreceptors]);

	const handleAddMoreClick = useCallback(() => {
		navigateWithGroupId(ADD_MORE_PRECEPTORS, groupId);
	}, [navigateWithGroupId, groupId]);

	const handleAssignMentorClick = useCallback(() => {
		navigateWithGroupId(ASSIGN_MENTOR, groupId);
	}, [navigateWithGroupId, groupId]);

	const handleBackClick = useCallback(() => {
		navigateWithBatchIdAndGroupTab(GROUPS, GroupTabs.GROUPS_LIST_VIEW);
	}, [navigateWithBatchIdAndGroupTab]);

	const columns = PreceptorTableColumns(true);

	if (
		groupPreceptors &&
		groupPreceptors.length &&
		groupPreceptors.length === 0
	) {
		return <div>{t('noDataFound')}</div>;
	}

	return (
		<>
			{groupPreceptors && (
				<>
					<h4>{t('groupMembers')}</h4>
					<Table
						rowSelection={
							isEditGroupTable ? groupPreceptorSelection : undefined
						}
						scroll={{ x: true }}
						dataSource={groupPreceptors}
						pagination={false}
						columns={columns}
					/>
					{!isEditGroupTable && mentor && (
						<div className="mt-5">
							<h4>{t('mentor')}</h4>
							<Table
								scroll={{ x: true }}
								dataSource={[mentor]}
								pagination={false}
								columns={columns}
								rowKey={(record) => {
									return `mentor-row-${record.id}`;
								}}
							/>
						</div>
					)}
					<div className="flex flex-row-reverse items-center gap-4 my-8">
						{isEditGroupTable && (
							<>
								<Button
									type="primary"
									onClick={handleAddMoreClick}
									disabled={disableAddMore}
								>
									{t('addMore')}
								</Button>
								<Button
									type="primary"
									onClick={handleRemoveClick}
									disabled={!hasSelected || groupPreceptors.length <= 1}
								>
									{t('remove')}
								</Button>
							</>
						)}
						<>
							{!isEditGroupTable && (
								<Button type="primary" onClick={handleAssignMentorClick}>
									{t('assignMentor')}
								</Button>
							)}
							<Button type="default" onClick={handleBackClick}>
								{t('back')}
							</Button>
						</>
					</div>
				</>
			)}
		</>
	);
};
