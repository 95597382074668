import { Space, TableColumnsType, Tag, Tooltip } from 'antd';
import {
	Exam,
	ExaminationCenterMapping,
} from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import moment, { utc } from 'moment';
import { useCallback } from 'react';
import { useExamAndCenterId } from '../../../hooks/useExamAndCenterId';
import {
	EXAM_MODULE_ELIGIBLE_CANDIDATES,
	EXAM_MODULE_VIEW_CANDIDATES,
	EXAM_MODULE_VIEW_HELD_CANDIDATES,
	UPDATE_EXAM,
	VIEW_EXAM,
} from '../../../shared/Routes';
import { CalendarOutlined, EyeOutlined } from '@ant-design/icons';
import { DATE_DD_MM_YYYY } from '../../../shared/Constants';

export const ExamTableColumns = (): TableColumnsType<Exam> => {
	const { t } = useTranslation();
	const { navigateWithExamId } = useExamAndCenterId();

	const concatExamCenters = (
		examCenterMappings: Array<ExaminationCenterMapping>
	): string => {
		return examCenterMappings
			? examCenterMappings
					?.map((center) => center.examinationCenter.centerName)
					.join(', ')
			: '-';
	};

	const COLUMN_STYLE = 'w-48';

	const handleViewEligibleCandidatesClick = useCallback(
		(examId: number) => {
			navigateWithExamId(EXAM_MODULE_ELIGIBLE_CANDIDATES, examId);
		},
		[navigateWithExamId]
	);

	const handleViewRegistrationDataClick = useCallback(
		(examId: number) => {
			navigateWithExamId(EXAM_MODULE_VIEW_CANDIDATES, examId);
		},
		[navigateWithExamId]
	);

	const handleViewHeldCandidatesClick = useCallback(
		(examId: number) => {
			navigateWithExamId(EXAM_MODULE_VIEW_HELD_CANDIDATES, examId);
		},
		[navigateWithExamId]
	);

	const handleEditExamClick = useCallback(
		(examId: number) => {
			navigateWithExamId(UPDATE_EXAM, examId);
		},
		[navigateWithExamId]
	);

	const handleViewExamClick = useCallback(
		(examId: number) => {
			navigateWithExamId(VIEW_EXAM, examId);
		},
		[navigateWithExamId]
	);

	return [
		{
			title: t('examName'),
			dataIndex: 'name',
			key: 'examName',
			render: (__, record, index) => <span key={index}>{record.name}</span>,
		},
		{
			title: t('examDate'),
			dataIndex: 'examDate',
			key: 'examDate',
			render: (__, record, index) => (
				<span key={index} className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{moment(record.examDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('registrationStartDate'),
			dataIndex: 'registrationStartDate',
			key: 'registrationStartDate',
			render: (__, record, index) => (
				<span key={index} className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{moment(record.registrationStartDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('registrationEndDate'),
			dataIndex: 'registrationEndDate',
			key: 'registrationEndDate',
			render: (__, record, index) => (
				<span key={index} className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{utc(record.registrationEndDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('examCenters'),
			dataIndex: 'examCenters',
			key: 'examCenters',
			render: (__, record, index) => (
				<Tooltip title={concatExamCenters(record?.examinationCenterMappings)}>
					<div key={index} className={`truncate ${COLUMN_STYLE}`}>
						{concatExamCenters(record?.examinationCenterMappings)}
					</div>
				</Tooltip>
			),
		},
		{
			title: t('eligibleCandidates'),
			dataIndex: 'viewEligibleCandidates',
			key: 'operation',
			render: (__, record) => (
				<Tooltip title={t('viewEligibleCandidates')}>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewEligibleCandidatesClick(record.id)}
					>
						<EyeOutlined className="text-xl" />
					</a>
				</Tooltip>
			),
		},
		{
			title: t('registrationData'),
			dataIndex: 'viewRegistrationData',
			key: 'operation',
			render: (__, record) => (
				<Tooltip title={t('viewRegistrationData')}>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewRegistrationDataClick(record.id)}
					>
						<EyeOutlined className="text-xl" />
					</a>
				</Tooltip>
			),
		},
		{
			title: t('holdCandidates'),
			dataIndex: 'viewHoldCandidates',
			key: 'operation',
			render: (__, record) => (
				<Tooltip title={t('viewHoldCandidates')}>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewHeldCandidatesClick(record.id)}
					>
						<EyeOutlined className="text-xl" />
					</a>
				</Tooltip>
			),
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (__, record, index) => {
				if (record.status === 'ACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="success">
								{t('active')}
							</Tag>
						</span>
					);
				} else if (record.status === 'INACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="error">
								{t('inactive')}
							</Tag>
						</span>
					);
				}
			},
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleEditExamClick(record.id)}
					>
						{t('editOrUpdate')}
					</a>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewExamClick(record.id)}
					>
						{t('view')}
					</a>
				</Space>
			),
		},
	];
};
