import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessStep = () => {
	const { inspirationAndWillingness, setInspirationAndWillingness } =
		useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(
		inspirationAndWillingness,
		setInspirationAndWillingness
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
