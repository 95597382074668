import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestion } from '../applicant/NewApplicationWizard/MultipleChoiceQuestion';
import { MultipleChoiceQuestionUIConfig } from '../../shared/MultipleChoiceQuestionUIConfig';

const { Title } = Typography;

export interface MandatoryQuestionsToAskProps {
	uiConfig: MultipleChoiceQuestionUIConfig[];
}

export const MandatoryQuestionsToAsk = ({
	uiConfig,
}: MandatoryQuestionsToAskProps) => {
	const { t } = useTranslation();

	const mandatoryQuestions = uiConfig.map(
		(question: MultipleChoiceQuestionUIConfig) => (
			<div key={question.questionId} className="my-[8px] w-full">
				<MultipleChoiceQuestion uiConfig={question} />
			</div>
		)
	);

	return (
		<div className="flex flex-col items-start">
			{uiConfig.length > 0 && (
				<Title level={5}>{t('mandatoryQuestionsHeading')}</Title>
			)}
			{mandatoryQuestions}
		</div>
	);
};
