import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { useCallApi } from '../../../hooks/useCallApi';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useBatchId } from '../../../hooks/useBatchId';
import { useGroupId } from '../../../hooks/useGroupId';
import { EDIT_GROUP, GROUPS } from '../../../shared/Routes';
import { Modal } from 'antd';
import { updatePreceptorGroup } from '../../../service/VolunteerApplicationService';
import { PreceptorGroup } from '../../../shared/VolunteerApplicationServiceTypes';
import { useSnapshot } from 'valtio';
import { PreceptorBatchState } from '../CreateGroupPage/PreceptorsList.store';

export const useEditGroup = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { navigateWithBatchId } = useBatchId();
	const { groupId, fetchGroup, navigateWithGroupId } = useGroupId();
	const [group, setGroup] = useState<PreceptorGroup>();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);

	const onConfirmUpdateGroup = useCallback(
		async (preceptorIds: Array<number>, addMorePreceptors?: boolean) => {
			try {
				await callApi(
					() =>
						updatePreceptorGroup({
							functionarySrcmId: srcmId as string,
							preceptorGroupId: groupId,
							preceptorIds,
							preceptorBatchId: preceptorBatchSnap.selectedBatch?.id,
						}),
					'errorOccurredWhileUpdatingGroup'
				);
				Modal.success({
					content: t('updatedGroupSuccessfully'),
					afterClose: () => {
						if (addMorePreceptors) {
							navigateWithGroupId(EDIT_GROUP, groupId);
						} else {
							navigateWithBatchId(GROUPS);
						}
					},
				});
			} catch (e) {
				// Do Nothing
			}
		},
		[
			callApi,
			srcmId,
			t,
			groupId,
			navigateWithBatchId,
			navigateWithGroupId,
			preceptorBatchSnap.selectedBatch?.id,
		]
	);

	const updateGroup = useCallback(
		(
			preceptorIds: Array<number>,
			ConfirmationMessage: string,
			addMorePreceptors?: boolean
		) => {
			confirmBeforeAction('confirmUpdateGroup', ConfirmationMessage, () =>
				onConfirmUpdateGroup(preceptorIds, addMorePreceptors)
			);
		},
		[confirmBeforeAction, onConfirmUpdateGroup]
	);

	const loadGroup = useCallback(async () => {
		try {
			const fetchedGroup = await fetchGroup();
			setGroup(fetchedGroup);
		} catch (e) {
			//
		}
	}, [fetchGroup]);

	useEffect(() => {
		if (groupId) {
			void loadGroup();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupId]);

	return { updateGroup, group };
};
