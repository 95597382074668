import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { UnderstandingOfHeartfulnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = UnderstandingOfHeartfulnessUIConfig;

export const UnderstandingOfHeartfulnessAnswersReviewStep = () => {
	const {
		understandingOfHeartfulness,
		setUnderstandingOfHeartfulness,
		application,
	} = useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		understandingOfHeartfulness,
		setUnderstandingOfHeartfulness
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
