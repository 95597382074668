import { useCallback, useMemo } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useBatchId } from './useBatchId';

export const useExamAndCenterId = () => {
	const navigate = useNavigate();
	const { navigateWithBatchId, queryParams } = useBatchId();

	const examId = useMemo(() => {
		const examIdParam = Number(queryParams.get('examId'));

		if (typeof examIdParam !== 'number') {
			return -1;
		}

		return examIdParam;
	}, [queryParams]);

	const navigateWithExamId = useCallback(
		(route: string, examIdParam?: number, params?: NavigateOptions) => {
			const examIdToUse = examIdParam ? `${examIdParam}` : examId;
			queryParams.set('examId', `${examIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[examId, navigateWithBatchId, queryParams]
	);

	const centerId = useMemo(() => {
		const centerIdParam = Number(queryParams.get('centerId'));

		if (typeof centerIdParam !== 'number') {
			return -1;
		}

		return centerIdParam;
	}, [queryParams]);

	const navigateWithCenterId = useCallback(
		(route: string, centerIdParam?: number, params?: NavigateOptions) => {
			const centerIdToUse = centerIdParam ? `${centerIdParam}` : centerId;
			queryParams.set('centerId', `${centerIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[centerId, navigateWithBatchId, queryParams]
	);

	const batchId = useMemo(() => {
		const batchIdParam = Number(queryParams.get('batchId'));

		if (typeof batchIdParam !== 'number') {
			return -1;
		}

		return batchIdParam;
	}, [queryParams]);

	const getRouteWithBatchId = useCallback(
		(route: string) => {
			queryParams.set('batchId', `${batchId}`);
			queryParams.delete('examId');
			queryParams.delete('centerId');
			return `${route}?${queryParams.toString()}`;
		},
		[batchId, queryParams]
	);

	const navigateWithoutExamAndCenterIds = useCallback(
		(route: string, params?: NavigateOptions) => {
			navigate(`${getRouteWithBatchId(route)}`, params);
		},
		[getRouteWithBatchId, navigate]
	);

	return {
		examId,
		navigateWithExamId,
		centerId,
		navigateWithCenterId,
		navigateWithoutExamAndCenterIds,
	};
};
