import { ApplicationsList } from './ApplicationsList';
import { useApplicationsList } from './useApplicationsList';
import { useCallback, useState } from 'react';
import {
	ApplicationSummary,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { INTERVIEW_LANDING, VIEW_FUNCTIONARIES } from '../../../shared/Routes';
import { Filter } from './Filter';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBatchId } from '../../../hooks/useBatchId';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import { VerifyResultsModal } from './VerifyResultsModal';

export const ApplicationsListContainer = () => {
	const { applicationsList, onPageChange, filter, onFilterChange, paging } =
		useApplicationsList();
	const [openResultsModal, setOpenResultsModal] = useState(false);
	const [applicationToVerify, setApplicationToVerify] =
		useState<ApplicationSummary>();
	const { navigateWithApplicationId } = useApplicationId();
	const { navigateWithBatchId } = useBatchId();
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();

	const showManageFunctionaries =
		role === UserRole.APPROVER || role === UserRole.ADMIN;
	const displayFunctionariesLink = role === UserRole.ADMIN;

	const handleEvaluateClick = useCallback(
		(application: ApplicationSummary) => {
			navigateWithApplicationId(INTERVIEW_LANDING, application.applicationId);
		},
		[navigateWithApplicationId]
	);

	const handleManagerProposersClick = useCallback(() => {
		navigateWithBatchId(VIEW_FUNCTIONARIES);
	}, [navigateWithBatchId]);

	const handleVerifyResultsClick = useCallback(
		(application: ApplicationSummary) => {
			setApplicationToVerify(application);
			setOpenResultsModal(true);
		},
		[]
	);

	return (
		<div className="flex flex-col min-w-[370px] max-w-[480px] p-[8px]">
			{showManageFunctionaries && (
				<div className="flex justify-end">
					<Button type="link" onClick={handleManagerProposersClick}>
						{displayFunctionariesLink
							? t('manageFunctionaries')
							: t('manageProposers')}
					</Button>
				</div>
			)}
			<div className="mb-[16px]">
				<Filter value={filter} onFilterChange={onFilterChange} />
			</div>
			<ApplicationsList
				data={applicationsList}
				onPageChange={onPageChange}
				onEvaluateClick={handleEvaluateClick}
				onVerifyResultsClick={handleVerifyResultsClick}
				pageConfig={paging}
			/>
			<VerifyResultsModal
				openModal={openResultsModal}
				onClose={() => setOpenResultsModal(false)}
				application={applicationToVerify}
			/>
		</div>
	);
};
