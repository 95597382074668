import { useState, ReactNode, useCallback, FC, useEffect } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useWizard } from './useWizard';

const { Text } = Typography;

interface WizardProps {
	steps: Array<ReactNode>;
	disableNext?: boolean;
	disablePrev?: boolean;
	disableSubmit?: boolean;
	disableCancel?: boolean;
	nextButtonText?: string;
	backButtonText?: string;
	submitButtonText?: string;
	cancelButtonText?: string;
	loading?: boolean;
	onSubmitClick: () => void;
	onCancelClick?: (cardIndex: number) => void;
	onNextClick?: (cardIndex: number) => Promise<boolean>;
}

export const Wizard: FC<WizardProps> = ({
	steps,
	disableNext = false,
	disablePrev = false,
	disableSubmit = false,
	disableCancel = false,
	nextButtonText,
	backButtonText,
	submitButtonText,
	cancelButtonText,
	loading = false,
	onSubmitClick,
	onCancelClick,
	onNextClick,
}: WizardProps) => {
	const [current, setCurrent] = useState(0);
	const { t } = useTranslation();

	const handleNext = useCallback(async () => {
		if (onNextClick) {
			const proceed = await onNextClick(current);

			if (!proceed) {
				return;
			}
		}
		setCurrent(current + 1);
	}, [current, onNextClick]);

	const next = useCallback(() => {
		void handleNext();
	}, [handleNext]);

	const prev = useCallback(() => {
		setCurrent(current - 1);
	}, [current]);

	const cancel = useCallback(() => {
		if (onCancelClick) {
			onCancelClick(current);
		} else {
			setCurrent(0);
		}
	}, [current, onCancelClick]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [current]);

	const body = steps[current];
	const stepProgress = `${current + 1} of ${steps.length}`;
	const nextBtnText = nextButtonText ?? t('next');
	const backBtnText = backButtonText ?? t('back');
	const submitBtnText = submitButtonText ?? t('submit');
	const cancelBtnText = cancelButtonText ?? t('cancel');

	return (
		<div className={`flex flex-col px-[8px] max-w-[600px]`}>
			<div className="flex flex-col justify-center items-start">
				<Text className="text-xs" type="secondary">
					{stepProgress}
				</Text>
			</div>
			<div className="h-full min-h-0">{body}</div>
			<div className="flex space-x-8 items-center my-[8px] justify-center">
				{current > 0 && (
					<Button
						onClick={prev}
						disabled={disablePrev}
						icon={<DoubleLeftOutlined />}
					>
						{backBtnText}
					</Button>
				)}
				<Button onClick={cancel} disabled={disableCancel}>
					{cancelBtnText}
				</Button>
				{current < steps.length - 1 && (
					<Button
						type="primary"
						onClick={next}
						disabled={disableNext}
						loading={loading}
					>
						{nextBtnText}
						<DoubleRightOutlined />
					</Button>
				)}
				{current === steps.length - 1 && (
					<Button
						type="primary"
						disabled={disableSubmit}
						loading={loading}
						onClick={onSubmitClick}
					>
						{submitBtnText}
					</Button>
				)}
			</div>
		</div>
	);
};

export const WizardContainer = ({
	steps,
	onSubmitClick,
	onCancelClick,
	onNextClick,
}: {
	steps: Array<ReactNode>;
	onSubmitClick: () => void;
	onCancelClick?: (cardIndex: number) => void;
	onNextClick?: (cardIndex: number) => Promise<boolean>;
}) => {
	const { nextButtonDisabled, submitButtonDisabled, submitButtonText } =
		useWizard();

	return (
		<Wizard
			steps={steps}
			disableNext={nextButtonDisabled}
			disableSubmit={submitButtonDisabled}
			onSubmitClick={onSubmitClick}
			onCancelClick={onCancelClick}
			onNextClick={onNextClick}
			submitButtonText={submitButtonText}
		/>
	);
};
