import { withRedirectToLogin } from '../../hocs/withRedirectToLogin';
import { Layout } from 'antd';
import { AppHeader } from '../AppHeader';

import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MeProfile } from '../../shared/MySrcm';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { useBatchId } from '../../hooks/useBatchId';
import { APPLICANT_HOME, SELECT_MODULE } from '../../shared/Routes';

const { Content } = Layout;

interface LayoutProps {
	logout?: () => Promise<void>;
	firebaseId?: string | null;
	srcmId?: string;
	mySrcmProfile?: MeProfile | null;
	loading?: boolean;
	role?: UserRole;
}

export const FunctionaryLayout: FC<LayoutProps> = ({
	logout,
	firebaseId,
	srcmId,
	loading,
	role,
}: LayoutProps) => {
	const { navigateWithBatchId } = useBatchId();
	const location = useLocation();

	if (role && [UserRole.APPLICANT].includes(role)) {
		navigateWithBatchId(APPLICANT_HOME);
	}

	const isHeaderHidden = location.pathname === SELECT_MODULE;

	return (
		<>
			{isHeaderHidden ? (
				<Layout className="mainlayout rounded-md bg-white">
					<Content className="bg-white">
						{!loading && <Outlet context={{ srcmId, firebaseId, role }} />}
					</Content>
				</Layout>
			) : (
				<div className="flex justify-center bg-neutral-50">
					<div className="border max-wpx-[8px] max-w-[600px]">
						<Layout className="mainlayout rounded-md p-[8px] bg-white">
							<AppHeader logout={logout} />
							<Content className="bg-white">
								{!loading && <Outlet context={{ srcmId, firebaseId, role }} />}
							</Content>
						</Layout>
					</div>
				</div>
			)}
		</>
	);
};

export default withRedirectToLogin<object>(FunctionaryLayout);
