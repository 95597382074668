import React, { useCallback, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { searchZones } from '../service/VolunteerApplicationService';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface OptionData {
	value: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: Array<OptionData>) => void;
	value?: Array<string>;
	functionarySrcmId?: string;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	functionarySrcmId,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const values = useMemo(() => {
		return value?.map((s) => ({
			value: s,
		}));
	}, [value]);

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);

			if (functionarySrcmId) {
				const zonesPage = await searchZones({
					functionarySrcmId,
					pageIndex: 0,
					pageSize: 10,
					searchKey: searchValue,
				});

				if (zonesPage.zones) {
					const transformedOptions: OptionData[] = zonesPage.zones.map(
						(item: string) => ({
							value: item,
						})
					);

					setOptions(transformedOptions);
				} else {
					setOptions([]);
				}
			} else {
				setOptions([]);
			}

			setLoading(false);
		},
		[functionarySrcmId]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	return (
		<Select
			showSearch
			mode="multiple"
			defaultActiveFirstOption={false}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchZones')}
			notFoundContent={loading ? <Spin size="small" /> : null}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={values}
		>
			{options.map((option) => (
				// eslint-disable-next-line
				<Option key={option.value} value={option.value}>
					{option.value}
				</Option>
			))}
		</Select>
	);
};

export default RemoteSingleSelect;
