import { useCallback, useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import { Form } from 'antd';
import { CategoryAnswerType } from './CategoryAnswerType';

export default function useCategoryFormHandling(
	categoryValue: CategoryAnswerType,
	setCategoryValue: (value: CategoryAnswerType) => void
) {
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const [form] = Form.useForm<CategoryAnswerType>();
	const values = Form.useWatch<CategoryAnswerType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setCategoryValue(fieldValues);
			setNextButtonDisabled(false);
		} catch (e) {
			const fields = e as { values: CategoryAnswerType };
			setCategoryValue(fields.values);
			setNextButtonDisabled(true);
		}
		setCancelButtonDisabled(false);
	}, [form, setCancelButtonDisabled, setCategoryValue, setNextButtonDisabled]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(categoryValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return { form };
}
