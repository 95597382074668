import { Collapse, Progress, Typography } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';
import { useScoreCalculator } from './useCategoryScore';
import isEmpty from 'lodash/isEmpty';
import { ReviewerResultTag } from '../ReviewerResultTag';

export const Header = ({
	name,
	approvedCount,
	percent,
	strokeColor,
	totalCount,
	provided = true,
}: {
	name: string;
	approvedCount: number;
	percent: number;
	totalCount: number;
	strokeColor: string[];
	provided?: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-row items-center flex-nowrap whitespace-nowrap">
			<Typography.Text className="text-md mr-[8px]">{`${t(
				name
			)}`}</Typography.Text>
			{!provided && (
				<Typography.Text className="text-md mr-[8px]">{`${t(
					'notProvidedYet'
				)}`}</Typography.Text>
			)}
			{provided && (
				<Progress
					steps={totalCount}
					percent={percent}
					size={10}
					format={() => `${approvedCount} / ${totalCount}`}
					strokeColor={strokeColor}
				/>
			)}
		</div>
	);
};

export const CategoryScore = ({
	name,
	self,
	applicantInterview,
	preceptorInterview,
	familyMemberInterview,
}: {
	name: string;
	self?: Feedback;
	applicantInterview?: Feedback;
	preceptorInterview?: Feedback;
	familyMemberInterview?: Feedback;
}) => {
	const feedbacks = useMemo(() => {
		return [
			self,
			applicantInterview,
			preceptorInterview,
			familyMemberInterview,
		];
	}, [applicantInterview, familyMemberInterview, preceptorInterview, self]);
	const { approvedCount, percent, strokeColor, totalCount } =
		useScoreCalculator(feedbacks);

	return (
		<Collapse collapsible="header" className="mb-[8px]" ghost>
			<Collapse.Panel
				header={
					<Header
						name={name}
						approvedCount={approvedCount}
						totalCount={totalCount}
						percent={percent}
						strokeColor={strokeColor}
					/>
				}
				key="1"
			>
				<div className="flex flex-row">
					{self && <ReviewerResultTag reviewer="application" result={self} />}
					{applicantInterview && (
						<ReviewerResultTag
							reviewer="candidateInterview"
							result={applicantInterview}
						/>
					)}
					{preceptorInterview && (
						<ReviewerResultTag
							reviewer="preceptor"
							result={preceptorInterview}
						/>
					)}
					{familyMemberInterview && (
						<ReviewerResultTag
							reviewer="family"
							result={familyMemberInterview}
						/>
					)}
				</div>
			</Collapse.Panel>
		</Collapse>
	);
};

export const FinalRecommendationScore = ({
	name,
	preceptor,
	proposer,
	approver,
}: {
	name: string;
	preceptor?: Feedback;
	proposer?: Feedback;
	approver?: Feedback;
}) => {
	const feedbacks = useMemo(() => {
		return [preceptor, proposer, approver];
	}, [approver, preceptor, proposer]);
	const { approvedCount, percent, strokeColor, totalCount } =
		useScoreCalculator(feedbacks);

	return (
		<Collapse collapsible="header" className="mb-[8px]" ghost>
			<Collapse.Panel
				header={
					<Header
						name={name}
						approvedCount={approvedCount}
						totalCount={totalCount}
						percent={percent}
						strokeColor={strokeColor}
						provided={
							!(isEmpty(preceptor) && isEmpty(proposer) && isEmpty(approver))
						}
					/>
				}
				key="1"
			>
				<div className="flex flex-row">
					{preceptor && (
						<ReviewerResultTag reviewer="preceptor" result={preceptor} />
					)}
					{proposer && (
						<ReviewerResultTag reviewer="proposer" result={proposer} />
					)}
					{approver && (
						<ReviewerResultTag reviewer="approver" result={approver} />
					)}
				</div>
			</Collapse.Panel>
		</Collapse>
	);
};
