import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { DietaryHabitsAndHealthUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = DietaryHabitsAndHealthUIConfig;

export const DietaryHabitsAndHealthAnswersReviewStep = () => {
	const { dietaryHabitsAndHealth, setDietaryHabitsAndHealth, application } =
		useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		dietaryHabitsAndHealth,
		setDietaryHabitsAndHealth
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
