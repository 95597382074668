import { Routes, Route } from 'react-router-dom';
import ApplicantLayout from './components/layout/ApplicantLayout';
import FunctionaryLayout from './components/layout/FunctionaryLayout';
import SignInPage from './pages/login/SignIn';
import { ApplicantHome } from './pages/applicant/Home';
import { FunctionaryHome } from './pages/functionary/Home';

import {
	ROOT,
	LOGIN,
	APPLICANT_HOME,
	FUNCTIONARY_HOME,
	ANSWERS_REVIEW,
	INTERVIEW_LANDING,
	APPLICANT_INTERVIEW,
	PRECEPTOR_INTERVIEW,
	FAMILY_MEMBER_INTERVIEW,
	OTHER_REFERENCE_INTERVIEW,
	APPLICANT_ADDITIONAL_DETAILS,
	PROPOSER_RECOMMENDATION,
	APPROVER_RECOMMENDATION,
	SKIP_ALL_INTERVIEWS,
	CARE_TEAM_RECOMMENDATION,
	ZC_RECOMMENDATION,
	VIEW_ANSWERS,
	VIEW_FUNCTIONARIES,
	EDIT_FUNCTIONARY,
	ADD_FUNCTIONARY,
	SELECT_MODULE,
	PRE_WORK,
	GROUPS,
	ASSIGN_MENTOR,
	EDIT_GROUP,
	VIEW_GROUP,
	ADD_MORE_PRECEPTORS,
	EXAM_MODULE_HOME,
	VIEW_CANDIDATES,
	EXAM_REGISTRATION,
	ELIGIBLE_CANDIDATES,
	SEND_RESULTS_EMAIL,
	SEND_ADMIT_CARD_EMAIL,
	SEND_INVITE_EMAIL,
	CREATE_BATCH,
	EDIT_BATCH,
	EXAMS_LIST,
	CREATE_EXAM,
	CENTERS_LIST,
	CREATE_CENTER,
	UPDATE_EXAM,
	VIEW_EXAM,
	UPDATE_CENTER,
	VIEW_CENTER,
	VIEW_HELD_CANDIDATES,
} from './shared/Routes';
import { ApplicantAnswersReviewWizardContainer } from './pages/functionary/ApplicantAnswersReviewWizard';
import { ApplicantInterviewWizardContainer } from './pages/functionary/ApplicantInterviewWizard';
import { PreceptorInterviewWizardContainer } from './pages/functionary/PreceptorInterviewWizard';
import { LandingPageContainer } from './pages/functionary/InterviewLandingPage';
import { FamilyMemberInterviewWizardContainer } from './pages/functionary/FamilyMemberInterviewWizard';
import { OtherReferenceInterviewWizardContainer } from './pages/functionary/OtherReferenceInterviewWizard';
import { ApplicantAdditionalCollectionPageContainer } from './pages/functionary/ApplicantAdditionalDetailsPage';
import { ProposerRecommendationCollectionPageContainer } from './pages/functionary/ProposerRecommendationPage';
import { ApproverRecommendationCollectionPageContainer } from './pages/functionary/ApproverRecommendationPage';
import { SkipAllInterviewsCollectionPageContainer } from './pages/functionary/SkipAllInterviewsPage';
import { PredefinedReasonsProvider } from './hooks/usePredefinedReasons';
import { CareTeamRecommendationCollectionPageContainer } from './pages/functionary/CareTeamRecommendationPage';
import { ZCRecommendationCollectionPageContainer } from './pages/functionary/ZCRecommendationPage';
import { ViewApplicantAnswersWizardContainer } from './pages/functionary/ViewApplicantAnswersWizard';
import { FunctionariesListContainer } from './pages/functionary/FunctionariesList';
import { AddOrEditFunctionaryPageContainer } from './pages/functionary/AddOrEditFunctionaryPage';
import { SelectModule } from './pages/login/SelectModule';
import { PreWorkWizardContainer } from './pages/applicant/PreWorkWizard';
import { CreateGroupContainer } from './pages/nps/CreateGroupPage';
import NPSLayout from './components/layout/NPSLayout';
import { AssignMentorContainer } from './pages/nps/AssignMentorPage';
import { EditGroupContainer } from './pages/nps/EditGroupPage';
import { ViewGroupContainer } from './pages/nps/ViewGroupPage';
import { AddMorePreceptorsContainers } from './pages/nps/AddMorePreceptorsPage';
import { ExamModuleHome } from './pages/exam-module/Home';
import { Dashboard } from './pages/exam-module/dashboard';
import { ViewCandidates } from './pages/exam-module/view-candidates';
import { ExamRegistration } from './pages/applicant/ExamRegistration';
import { EligibleCandidates } from './pages/exam-module/eligible-candidates';
import { SendResultsEmail } from './pages/exam-module/view-candidates/SendResultsEmail';
import { SendAdmitCardEmail } from './pages/exam-module/view-candidates/SendAdmitCardEmail';
import { SendInviteEmail } from './pages/exam-module/eligible-candidates/SendInviteEmail';
import { ExamsList } from './pages/exam-module/exams-list';
import { CreateExam } from './pages/exam-module/exams-list/create-update-view-exam/CreateExam';
import { CentersList } from './pages/exam-module/centers-list';
import { CreateCenter } from './pages/exam-module/centers-list/create-update-view-center/CreateCenter';
import { UpdateExam } from './pages/exam-module/exams-list/create-update-view-exam/UpdateExam';
import { ViewExam } from './pages/exam-module/exams-list/create-update-view-exam/ViewExam';
import { UpdateCenter } from './pages/exam-module/centers-list/create-update-view-center/UpdateCenter';
import { ViewCenter } from './pages/exam-module/centers-list/create-update-view-center/ViewCenter';
import { CreateBatch } from './pages/nps/CreateOrUpdateBatch/CreateBatch';
import { UpdateBatch } from './pages/nps/CreateOrUpdateBatch/UpdateBatch';
import { ViewHeldCandidates } from './pages/exam-module/held-candidates';

export default function App() {
	return (
		<div>
			<Routes>
				<Route path={ROOT} element={<SignInPage />} />
				<Route path={LOGIN} element={<SignInPage />} />
				<Route element={<ApplicantLayout />}>
					<Route path={APPLICANT_HOME} element={<ApplicantHome />} />
					<Route path={PRE_WORK} element={<PreWorkWizardContainer />} />
				</Route>
				<Route
					element={
						<PredefinedReasonsProvider>
							<FunctionaryLayout />
						</PredefinedReasonsProvider>
					}
				>
					<Route path={FUNCTIONARY_HOME} element={<FunctionaryHome />} />
					<Route path={SELECT_MODULE} element={<SelectModule />} />
					<Route
						path={ANSWERS_REVIEW}
						element={<ApplicantAnswersReviewWizardContainer />}
					/>
					<Route
						path={APPLICANT_INTERVIEW}
						element={<ApplicantInterviewWizardContainer />}
					/>
					<Route
						path={PRECEPTOR_INTERVIEW}
						element={<PreceptorInterviewWizardContainer />}
					/>
					<Route
						path={FAMILY_MEMBER_INTERVIEW}
						element={<FamilyMemberInterviewWizardContainer />}
					/>
					<Route
						path={OTHER_REFERENCE_INTERVIEW}
						element={<OtherReferenceInterviewWizardContainer />}
					/>
					<Route
						path={PROPOSER_RECOMMENDATION}
						element={<ProposerRecommendationCollectionPageContainer />}
					/>
					<Route
						path={CARE_TEAM_RECOMMENDATION}
						element={<CareTeamRecommendationCollectionPageContainer />}
					/>
					<Route
						path={ZC_RECOMMENDATION}
						element={<ZCRecommendationCollectionPageContainer />}
					/>
					<Route
						path={APPROVER_RECOMMENDATION}
						element={<ApproverRecommendationCollectionPageContainer />}
					/>
					<Route path={INTERVIEW_LANDING} element={<LandingPageContainer />} />
					<Route
						path={APPLICANT_ADDITIONAL_DETAILS}
						element={<ApplicantAdditionalCollectionPageContainer />}
					/>
					<Route
						path={SKIP_ALL_INTERVIEWS}
						element={<SkipAllInterviewsCollectionPageContainer />}
					/>
					<Route
						path={VIEW_ANSWERS}
						element={<ViewApplicantAnswersWizardContainer />}
					/>
					<Route
						path={VIEW_FUNCTIONARIES}
						element={<FunctionariesListContainer />}
					/>
					<Route
						path={ADD_FUNCTIONARY}
						element={<AddOrEditFunctionaryPageContainer />}
					/>
					<Route
						path={EDIT_FUNCTIONARY}
						element={<AddOrEditFunctionaryPageContainer />}
					/>
				</Route>
				<Route element={<NPSLayout />}>
					<Route path={EXAM_REGISTRATION} element={<ExamRegistration />} />
				</Route>
				<Route element={<NPSLayout />}>
					<Route path={GROUPS} element={<CreateGroupContainer />} />
					<Route path={ASSIGN_MENTOR} element={<AssignMentorContainer />} />
					<Route path={EDIT_GROUP} element={<EditGroupContainer />} />
					<Route path={VIEW_GROUP} element={<ViewGroupContainer />} />
					<Route
						path={ADD_MORE_PRECEPTORS}
						element={<AddMorePreceptorsContainers />}
					/>
					<Route path={CREATE_BATCH} element={<CreateBatch />} />
					<Route path={EDIT_BATCH} element={<UpdateBatch />} />
					<Route path={EXAM_MODULE_HOME} element={<ExamModuleHome />}>
						<Route index element={<Dashboard />} />
						<Route path={EXAMS_LIST} element={<ExamsList />} />
						<Route path={CREATE_EXAM} element={<CreateExam />} />
						<Route path={UPDATE_EXAM} element={<UpdateExam />} />
						<Route path={VIEW_EXAM} element={<ViewExam />} />
						<Route path={CENTERS_LIST} element={<CentersList />} />
						<Route path={CREATE_CENTER} element={<CreateCenter />} />
						<Route path={UPDATE_CENTER} element={<UpdateCenter />} />
						<Route path={VIEW_CENTER} element={<ViewCenter />} />
						<Route
							path={ELIGIBLE_CANDIDATES}
							element={<EligibleCandidates />}
						/>
						<Route path={VIEW_CANDIDATES} element={<ViewCandidates />} />
						<Route
							path={VIEW_HELD_CANDIDATES}
							element={<ViewHeldCandidates />}
						/>
						<Route path={SEND_RESULTS_EMAIL} element={<SendResultsEmail />} />
						<Route
							path={SEND_ADMIT_CARD_EMAIL}
							element={<SendAdmitCardEmail />}
						/>
						<Route path={SEND_INVITE_EMAIL} element={<SendInviteEmail />} />
					</Route>
				</Route>
			</Routes>
		</div>
	);
}
