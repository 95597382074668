import { useCallback, useEffect, useState } from 'react';
import { UploadFile } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { PagingConfig } from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { useAuth } from '../../../hooks/useAuth';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	ChangeExaminationCenterRequest,
	ExamFilter,
	GetCandidateExamDetailsResponse,
	SignedUrlDetail,
} from '../../../shared/ExamModuleTypes';
import {
	changeExaminationCenter,
	downloadCandidateDetailsForAttendance,
	downloadCandidateDetailsForUploadingExamResult,
	downloadCandidateExaminationDetails,
	getAndDownloadFile,
	getCandidateExamDetails,
	getExaminationCenterDetailForRegistration,
	getSignedUrl,
	importCandidateExamRegistrationDetails,
	importEligibleCandidatesForExam,
	placeCandidateOnHold,
	uploadCandidateAttendance,
	uploadExamResults,
	uploadFilesToCloud,
} from '../../../service/ExamModuleService';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../shared/Utils';
import { useCallApi } from '../../../hooks/useCallApi';
import { useExamsList } from '../exams-list/useExamsList';
import { useExamModule } from '../useExamModule';

interface UseViewCandidatesConfig {
	isViewRegistrationData?: boolean;
}

export const useViewCandidates = ({
	isViewRegistrationData = false,
}: UseViewCandidatesConfig) => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { examById, fetchExamById } = useExamsList({});
	const { activeExam, fetchActiveExam } = useExamModule();
	const [candidateExamDetailList, setCandidateExamDetailList] =
		useState<GetCandidateExamDetailsResponse>({
			candidateExamDetails: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [examFilter, setExamFilter] = useState<ExamFilter>({
		examId: examById?.id,
	});
	const [candidateExamDetailPaging, setCandidateExamDetailPaging] =
		useState<PagingConfig>({ pageNo: 1, pageSize: DEFAULT_PAGE_SIZE });

	useEffect(() => {
		if (examById?.id) {
			const filter: ExamFilter = {
				examId: examById.id,
			};
			if (isViewRegistrationData) {
				filter.registrationStatusArray = ['REGISTERED'];
			}
			setExamFilter(filter);
		}
	}, [examById, isViewRegistrationData]);

	const onCandidateExamDetailsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setCandidateExamDetailPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onCandidateExamDetailsFilterChange = useCallback(
		(filterParam: ExamFilter) => {
			setExamFilter(filterParam);
			onCandidateExamDetailsPageChange(
				1,
				candidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE
			);
		},
		[onCandidateExamDetailsPageChange, candidateExamDetailPaging.pageSize]
	);

	const fetchCandidateExamDetailList = useCallback(
		async (pageNumber: number, pageSize: number, filter: ExamFilter) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;

				if (
					isViewRegistrationData &&
					(!filter.registrationStatusArray ||
						filter.registrationStatusArray.length === 0)
				) {
					return;
				}
				const candidatesFilter: ExamFilter = {
					...filter,
					notKeptHold: true,
				};
				const candidateExamDetailWithoutKey = await callApi(
					() =>
						getCandidateExamDetails({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							examFilter: candidatesFilter,
						}),
					'errorOccurredWhileLoading'
				);
				const CED = {
					...candidateExamDetailWithoutKey,
					...(candidateExamDetailWithoutKey.candidateExamDetails && {
						candidateExamDetails:
							candidateExamDetailWithoutKey.candidateExamDetails.map(
								(candidate) => ({
									...candidate,
									key: candidate.id,
								})
							),
					}),
				};

				if (
					CED.pagination.totalNoOfPages &&
					CED.pagination.totalNoOfRecords > 0 &&
					pageNumber > CED.pagination.totalNoOfPages
				) {
					onCandidateExamDetailsPageChange(
						CED.pagination.totalNoOfPages ?? 1,
						pageSize
					);
				} else {
					setCandidateExamDetailList(CED);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onCandidateExamDetailsPageChange, srcmId]
	);

	const callFetchCandidateExamDetailList = useCallback(() => {
		const examId = examById?.id;
		if (examId && examFilter.examId) {
			void fetchCandidateExamDetailList(
				candidateExamDetailPaging.pageNo ?? 1,
				candidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE,
				examFilter
			);
		}
	}, [
		examById,
		candidateExamDetailPaging,
		examFilter,
		fetchCandidateExamDetailList,
	]);

	useEffect(() => {
		void callFetchCandidateExamDetailList();
	}, [callFetchCandidateExamDetailList]);

	const uploadCandidates = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await importCandidateExamRegistrationDetails({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('candidatesUploadedSuccessfully'));
					void fetchCandidateExamDetailList(
						candidateExamDetailPaging.pageNo ?? 1,
						candidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE,
						examFilter
					);
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[
			setLoading,
			showToastMessage,
			srcmId,
			t,
			candidateExamDetailPaging,
			examFilter,
			fetchCandidateExamDetailList,
		]
	);

	const downloadResultsTemplate = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const downloadResultsFilter: ExamFilter = {
					...examFilter,
					notKeptHold: true,
					examNotCancelled: true,
					examAttendanceStatusArray: ['PRESENT'],
				};
				const downloadResponse =
					await downloadCandidateDetailsForUploadingExamResult({
						functionarySrcmId: srcmId,
						pageIndex: 1,
						pageSize: 2000,
						examFilter: downloadResultsFilter,
					});
				if (downloadResponse.errorMessage) {
					showToastMessage('error', downloadResponse.errorMessage);
					return;
				}
				if (downloadResponse.csvReportSignedUrl) {
					let fileName = 'Results_Template';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						downloadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [examFilter, setLoading, showToastMessage, srcmId]);

	const uploadResults = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await uploadExamResults({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
					examFilter,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('resultsUploadedSuccessfully'));
					void fetchExamById();
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[examFilter, fetchExamById, setLoading, showToastMessage, srcmId, t]
	);

	const uploadEligibleCandidates = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await importEligibleCandidatesForExam({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
					examFilter,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('candidatesUploadedSuccessfully'));
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
				void fetchCandidateExamDetailList(
					candidateExamDetailPaging.pageNo ?? 1,
					candidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE,
					examFilter
				);
				void fetchActiveExam();
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[
			examFilter,
			candidateExamDetailPaging,
			fetchActiveExam,
			fetchCandidateExamDetailList,
			setLoading,
			showToastMessage,
			srcmId,
			t,
		]
	);

	const downloadAttendanceTemplate = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const downloadAttendanceFilter: ExamFilter = {
					...examFilter,
					notKeptHold: true,
					examNotCancelled: true,
				};
				const downloadResponse = await downloadCandidateDetailsForAttendance({
					functionarySrcmId: srcmId,
					pageIndex: 1,
					pageSize: 2000,
					examFilter: downloadAttendanceFilter,
				});
				if (downloadResponse.errorMessage) {
					showToastMessage('error', downloadResponse.errorMessage);
					return;
				}
				if (downloadResponse.csvReportSignedUrl) {
					let fileName = 'Attendance_Template';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						downloadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [examFilter, setLoading, showToastMessage, srcmId]);

	const uploadAttendance = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await uploadCandidateAttendance({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
					examFilter,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('attendanceUploadedSuccessfully'));
					void fetchExamById();
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[examFilter, fetchExamById, setLoading, showToastMessage, srcmId, t]
	);

	const exportCandidates = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const downloadResponse = await downloadCandidateExaminationDetails({
					functionarySrcmId: srcmId,
					pageIndex: 1,
					pageSize: 2000,
					examFilter,
				});
				if (downloadResponse.errorMessage) {
					showToastMessage('error', downloadResponse.errorMessage);
					return;
				}
				if (downloadResponse.csvReportSignedUrl) {
					let fileName = 'Candidates_Details';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						downloadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [examFilter, setLoading, showToastMessage, srcmId]);

	const holdCandidate = useCallback(
		async (reasonToHold: string, candidateExaminationDetailId: number) => {
			if (srcmId) {
				setLoading(true);
				try {
					const candidateExaminationDetail = await placeCandidateOnHold({
						functionarySrcmId: srcmId,
						onHoldReason: reasonToHold,
						candidateExaminationDetailId,
					});
					if (candidateExaminationDetail.id) {
						return true;
					} else {
						return false;
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
					return false;
				} finally {
					setLoading(false);
				}
			}
		},
		[setLoading, showToastMessage, srcmId]
	);

	const fetchExamCenterDetail = useCallback(
		async (examinationCenterId: number) => {
			if (activeExam?.id) {
				try {
					setLoading(true);
					const response = await getExaminationCenterDetailForRegistration({
						examId: activeExam.id,
						examinationCenterId,
					});
					return response;
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[activeExam, setLoading, showToastMessage]
	);

	const updateCandidateCenter = useCallback(
		async (request: ChangeExaminationCenterRequest) => {
			try {
				setLoading(true);
				const response = await changeExaminationCenter(request);
				if (response.id) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
				return false;
			} finally {
				setLoading(false);
			}
		},
		[setLoading, showToastMessage]
	);

	return {
		candidateExamDetailList,
		candidateExamDetailPaging,
		examFilter,
		exportCandidates,
		onCandidateExamDetailsFilterChange,
		onCandidateExamDetailsPageChange,
		uploadCandidates,
		uploadEligibleCandidates,
		examById,
		fetchExamById,
		fetchCandidateExamDetailList,
		callFetchCandidateExamDetailList,
		downloadResultsTemplate,
		uploadResults,
		downloadAttendanceTemplate,
		uploadAttendance,
		holdCandidate,
		fetchExamCenterDetail,
		updateCandidateCenter,
	};
};
