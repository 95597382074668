import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface HorizontalBarGraphProps {
	title: string;
	titleAlign?: 'start' | 'center' | 'end';
	titleFontSize?: number;
	xAxisTitle?: string;
	yAxisTitle?: string;
	barColorInRGB: string;
	data: {
		yAxisLabels: string[];
		xAxisLabels: number[];
	};
}

export const HorizontalBarGraph: React.FC<HorizontalBarGraphProps> = ({
	title,
	titleAlign,
	titleFontSize,
	xAxisTitle,
	yAxisTitle,
	barColorInRGB,
	data,
}) => {
	const chartData: ChartData<'bar'> = {
		labels: data.yAxisLabels,
		datasets: [
			{
				label: 'Candidates',
				data: data.xAxisLabels,
				backgroundColor: barColorInRGB,
				borderColor: barColorInRGB,
				borderWidth: 1,
				barThickness: 25,
				minBarLength: 10,
			},
		],
	};

	const fontFamily =
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

	const options: ChartOptions<'bar'> = {
		indexAxis: 'y',
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: 'bottom',
				align: 'end',
				labels: {
					usePointStyle: true,
					font: {
						size: 12,
						family: fontFamily,
					},
				},
			},
			title: {
				display: true,
				text: title,
				align: titleAlign || 'start',
				font: {
					size: titleFontSize || 16,
					family: fontFamily,
				},
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				formatter: (_, context) => {
					return (
						context.chart.data.datasets[context.datasetIndex].data[
							context.dataIndex
						] || ''
					);
				},
				font: {
					size: 12,
					weight: 'bold',
					family: fontFamily,
				},
				color: 'white',
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const label = context.label || '';
						const value = context.raw as number;
						return `${label}: ${value}`;
					},
				},
			},
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					font: {
						family: fontFamily,
					},
				},
				title: {
					display: xAxisTitle ? true : false,
					text: xAxisTitle,
					font: {
						size: 14,
						weight: 'bold',
						family: fontFamily,
					},
					color: '#B2B2B2',
				},
			},
			y: {
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					maxRotation: 0,
					minRotation: 0,
					font: {
						family: fontFamily,
					},
				},
				title: {
					display: yAxisTitle ? true : false,
					text: yAxisTitle,
					font: {
						size: 14,
						weight: 'bold',
						family: fontFamily,
					},
					color: '#B2B2B2',
				},
			},
		},
		layout: {
			padding: {
				top: 10,
				bottom: 10,
			},
		},
	};

	const numberOfBars = data.yAxisLabels ? data.yAxisLabels.length : 0;
	const baseHeight = 300;
	const barHeight = 20;
	const calculatedHeight = Math.max(baseHeight + barHeight * numberOfBars, 300);

	return (
		<div style={{ height: `${calculatedHeight}px` }}>
			<Bar data={chartData} options={options} />
		</div>
	);
};
