import { withRedirectToLogin } from '../../hocs/withRedirectToLogin';
import { Layout } from 'antd';
import { NewAppHeader } from '../NewAppHeader';

import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MeProfile } from '../../shared/MySrcm';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { SELECT_MODULE } from '../../shared/Routes';

const { Content } = Layout;

interface LayoutProps {
	logout?: () => Promise<void>;
	firebaseId?: string | null;
	srcmId?: string;
	mySrcmProfile?: MeProfile | null;
	loading?: boolean;
	role?: UserRole;
}

export const NPSLayout: FC<LayoutProps> = ({
	logout,
	firebaseId,
	srcmId,
	loading,
	role,
}: LayoutProps) => {
	const location = useLocation();

	const isHeaderHidden = location.pathname === SELECT_MODULE;

	return (
		<>
			{isHeaderHidden ? (
				<Layout className="mainlayout rounded-md bg-white">
					<Content className="bg-white">
						{!loading && <Outlet context={{ srcmId, firebaseId, role }} />}
					</Content>
				</Layout>
			) : (
				<div className="bg-neutral-50 w-full">
					<div className="border max-wpx-[8px]">
						<Layout className="mainlayout rounded-md bg-white">
							<NewAppHeader logout={logout} />
							<Content className="bg-white">
								{!loading && <Outlet context={{ srcmId, firebaseId, role }} />}
							</Content>
						</Layout>
					</div>
				</div>
			)}
		</>
	);
};

export default withRedirectToLogin<object>(NPSLayout);
