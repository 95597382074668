import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { PreWorkStep } from './PreWorkStep';
import { PreWorkProvider, usePreWorkWizard } from './usePreWorkWizard';

export const PreWorkWizard = () => {
	const { onSubmitClick, onCancelClick } = usePreWorkWizard();

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={onSubmitClick}
				onCancelClick={onCancelClick}
				steps={[<PreWorkStep key={1} />]}
			/>
		</div>
	);
};

export const PreWorkWizardContainer = () => {
	return (
		<PreWorkProvider>
			<WizardProvider>
				<PreWorkWizard />
			</WizardProvider>
		</PreWorkProvider>
	);
};
