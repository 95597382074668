import { useCallback } from 'react';
import { getLoggedInUser } from '../service/HfnFirebase';
import firebase from 'firebase/compat/app';
import { useLocalStorage } from './useLocalStorage';
import { FIREBASE_ID_TOKEN } from '../shared/Constants';

export default function useFirebaseIdToken() {
	const { storedValue: idToken, setValue: setIdToken } = useLocalStorage<
		string | null
	>(FIREBASE_ID_TOKEN, null);

	const refreshIdToken = useCallback(async () => {
		const loggedInUser: firebase.User | null = await getLoggedInUser();
		if (loggedInUser) {
			const firebaseIdToken = await loggedInUser.getIdToken();
			setIdToken(firebaseIdToken);
			return loggedInUser.uid;
		} else {
			setIdToken(null);
			return null;
		}
	}, [setIdToken]);

	const clearIdToken = useCallback(() => {
		setIdToken(null);
	}, [setIdToken]);

	return { idToken, refreshIdToken, clearIdToken };
}
