import { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useAuth } from '../../hooks/useAuth';
import { useToastMessage } from '../../hooks/useToastMessage';
import { useRedirectToLogin } from '../../hooks/useRedirectToLogin';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { useBatchId } from '../../hooks/useBatchId';
import { getBatch } from '../../service/VolunteerApplicationService';
import { BatchInfoState } from '../../components/BatchInfo.store';

export const useFunctionaryHome = () => {
	const { mySrcmProfile } = useAuth();
	const { role } = useRedirectToLogin();
	const { batchId } = useBatchId();
	const { showToastMessage } = useToastMessage();
	const [messageToShow, setMessageToShow] = useState<
		| 'BATCH_CLOSED'
		| 'PAST_APPLICATION_UNDER_REVIEW'
		| 'APPLICATION_APPROVED'
		| 'INVALID_BATCH'
		| 'BATCH_NOT_STARTED_YET'
		| 'ALREADY_A_PRECEPTOR'
		| 'FUNCTIONARY_IS_NOT_A_PRECEPTOR'
		| { reapplyDate: string }
	>();

	const processBatch = useCallback(async () => {
		try {
			const batch = await getBatch({ value: batchId });
			BatchInfoState.batchInfo = batch;
		} catch (e) {
			const errorCode: number | undefined = get(e, 'response.status');

			if (errorCode === 404) {
				window.location.replace('https://hfn.link/preceptor-application');
			} else {
				const errorMessage: string | undefined = get(
					e,
					'response.data.message'
				);

				if (errorMessage) {
					showToastMessage('error', errorMessage);
				}
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				}
			}
		}
	}, [batchId, showToastMessage]);

	const processFunctionaryHome = useCallback(() => {
		if (
			(role === UserRole.FUNCTIONARY || role === UserRole.ZC) &&
			(!mySrcmProfile?.is_prefect || mySrcmProfile?.prefect_status !== 'active')
		) {
			setMessageToShow('FUNCTIONARY_IS_NOT_A_PRECEPTOR');
			return;
		}
	}, [mySrcmProfile?.is_prefect, mySrcmProfile?.prefect_status, role]);

	useEffect(() => {
		void processFunctionaryHome();
	}, [processFunctionaryHome]);

	useEffect(() => {
		if (!BatchInfoState.batchInfo) {
			void processBatch();
		}
	}, [processBatch]);

	return { messageToShow };
};
