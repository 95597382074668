import { useTranslation } from 'react-i18next';
import {
	GetPreceptorsGroupResponse,
	PagingConfig,
	PreceptorsGroupFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { GroupsTableColumns } from '../../../shared/Columns';
import { Table } from 'antd';
import { FilterGroups } from './FilterGroups';
import { tablePaginationConfiguration } from '../../../shared/Utils';

export const GroupsTable = ({
	data: { preceptorGroups, pagination },
	onGroupsPageChange,
	groupPageConfig,
	groupsFilter,
	onGroupsFilterChange,
	selectedBatchId,
}: {
	data: GetPreceptorsGroupResponse;
	onGroupsPageChange: (page: number, pageSize: number) => void;
	groupPageConfig: PagingConfig;
	groupsFilter: PreceptorsGroupFilter | undefined;
	onGroupsFilterChange: (value: PreceptorsGroupFilter) => void;
	selectedBatchId: number | undefined;
}) => {
	const { t } = useTranslation();
	const tablePaginationConfig = tablePaginationConfiguration(
		groupPageConfig,
		pagination,
		onGroupsPageChange
	);

	const columns = GroupsTableColumns();

	if (
		preceptorGroups &&
		preceptorGroups.length &&
		preceptorGroups.length === 0
	) {
		return <div>{t('noDataFound')}</div>;
	}

	return (
		<>
			<FilterGroups
				value={groupsFilter}
				onFilterChange={onGroupsFilterChange}
				selectedBatchId={selectedBatchId}
			/>
			<Table
				dataSource={preceptorGroups}
				columns={columns}
				pagination={tablePaginationConfig}
			/>
		</>
	);
};
