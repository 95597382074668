import { volunteerServiceClient } from './HTTPClient';
import {
	BooleanValue,
	GetRegionsResponse,
} from '../shared/VolunteerApplicationServiceTypes';
import {
	ApproveHeldCandidateRequest,
	CancelExamRequest,
	CandidateExaminationDetail,
	ChangeExaminationCenterRequest,
	CreateExaminationCenterRequest,
	CreateExamRequest,
	DownloadCSVReportResponse,
	Exam,
	ExaminationCenter,
	ExaminationGraphicalViewResponse,
	ExamResultGraphicalViewRequest,
	ExamResultGraphicalViewResponse,
	ExamTemplate,
	GetCandidateExamDetailRequest,
	GetCandidateExamDetailsRequest,
	GetCandidateExamDetailsResponse,
	GetCandidateModuleRequest,
	GetCandidateModuleResponse,
	GetDistinctPreferredExamLanguagesRequest,
	GetDistinctPreferredExamLanguagesResponse,
	GetEligibleCandidateDetailRequest,
	GetEligibleCandidateDetailResponse,
	GetExamCandidateZonesRequest,
	GetExaminationCenterDetailRequest,
	GetExaminationCenterDetailResponse,
	GetExaminationCenterRequest,
	GetExaminationCentersRequest,
	GetExaminationCentersResponse,
	GetExaminationGraphicalViewRequest,
	GetExaminationLanguagesRequest,
	GetExaminationLanguagesResponse,
	GetExamRequest,
	GetExamsRequest,
	GetExamsResponse,
	GetExamTemplatesRequest,
	GetExamTemplatesResponse,
	GetTemplatesRequest,
	GetTemplatesResponse,
	GetZonesResponse,
	ImportCSVReportRequest,
	PlaceCandidateOnHoldRequest,
	RegisterForExamRequest,
	SendEmailRequest,
	SignedUrlRequest,
	SignedUrlResponse,
	UpdateExamTemplateRequest,
	UploadExamResultsRequest,
} from '../shared/ExamModuleTypes';
import { UploadFile } from 'antd';
import axios from 'axios';

export const getActiveExam = async (
	request: GetCandidateModuleRequest
): Promise<Exam> => {
	const response = await volunteerServiceClient.post<Exam>(
		'getActiveExam',
		request
	);
	return response.data;
};

export const getCandidateExamDetails = async (
	request: GetCandidateExamDetailsRequest
): Promise<GetCandidateExamDetailsResponse> => {
	const response =
		await volunteerServiceClient.post<GetCandidateExamDetailsResponse>(
			'getCandidateExamDetails',
			request
		);
	return response.data;
};

export const downloadCandidateDetailsForUploadingExamResult = async (
	request: GetCandidateExamDetailsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'downloadCandidateDetailsForUploadingExamResult',
		request
	);
	return response.data;
};

export const getSignedUrl = async (
	request: SignedUrlRequest
): Promise<SignedUrlResponse> => {
	const response = await volunteerServiceClient.post<SignedUrlResponse>(
		'getSignedUrl',
		request
	);
	return response.data;
};

export const uploadExamResults = async (
	request: UploadExamResultsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'uploadExamResults',
		request
	);
	return response.data;
};

export const sendExamResultEmail = async (
	request: SendEmailRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendExamResultEmail',
		request
	);
	return response.data;
};

export const getExaminationCenters = async (
	request: GetExaminationCentersRequest
): Promise<GetExaminationCentersResponse> => {
	const response =
		await volunteerServiceClient.post<GetExaminationCentersResponse>(
			'getExaminationCenters',
			request
		);
	return response.data;
};

export const getTemplates = async (
	request: GetTemplatesRequest
): Promise<GetTemplatesResponse> => {
	const response = await volunteerServiceClient.post<GetTemplatesResponse>(
		'getTemplates',
		request
	);
	return response.data;
};

export const uploadFilesToCloud = async (
	file: UploadFile<Blob>,
	fileExtension: string | undefined,
	url?: string
) => {
	if (url) {
		const cloudUploadConfig = {
			headers: {
				'Content-Type': 'application/octet-stream',
				'x-goog-acl': 'public-read',
			},
		};
		await axios.put(url, file, cloudUploadConfig);
	}
};

export const getAndDownloadFile = async (url: string): Promise<string> => {
	const response = await axios.get<string>(url, { responseType: 'blob' });
	return response?.data;
};

export const getExamPreferredLanguages = async (
	request: GetDistinctPreferredExamLanguagesRequest
): Promise<GetDistinctPreferredExamLanguagesResponse> => {
	const response =
		await volunteerServiceClient.post<GetDistinctPreferredExamLanguagesResponse>(
			'getExamPreferredLanguages',
			request
		);
	return response.data;
};

export const getCandidateExamDetail = async (
	request: GetCandidateExamDetailRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'getCandidateExamDetail',
			request
		);
	return response.data;
};

export const getExamResultGraphicalView = async (
	request: ExamResultGraphicalViewRequest
): Promise<ExamResultGraphicalViewResponse> => {
	const response =
		await volunteerServiceClient.post<ExamResultGraphicalViewResponse>(
			'getExamResultGraphicalView',
			request
		);
	return response.data;
};

export const getRegionsOfExaminationCandidates = async (
	request: GetDistinctPreferredExamLanguagesRequest
): Promise<GetRegionsResponse> => {
	const response = await volunteerServiceClient.post<GetRegionsResponse>(
		'getRegionsOfExaminationCandidates',
		request
	);
	return response.data;
};

export const getZonesOfExaminationCandidates = async (
	request: GetExamCandidateZonesRequest
): Promise<GetZonesResponse> => {
	const response = await volunteerServiceClient.post<GetZonesResponse>(
		'getZonesOfExaminationCandidates',
		request
	);
	return response.data;
};

export const importCandidateExamRegistrationDetails = async (
	request: ImportCSVReportRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'importCandidateExamRegistrationDetails',
		request
	);
	return response.data;
};

export const getCandidateModule = async (
	request: GetCandidateModuleRequest
): Promise<GetCandidateModuleResponse> => {
	const response =
		await volunteerServiceClient.post<GetCandidateModuleResponse>(
			'getCandidateModule',
			request
		);
	return response.data;
};

export const registerForExam = async (
	request: RegisterForExamRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'registerForExam',
			request
		);
	return response.data;
};

export const importEligibleCandidatesForExam = async (
	request: ImportCSVReportRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'importEligibleCandidatesForExam',
		request
	);
	return response.data;
};

export const getEligibleCandidateDetails = async (
	request: GetEligibleCandidateDetailRequest
): Promise<GetEligibleCandidateDetailResponse> => {
	const response =
		await volunteerServiceClient.post<GetEligibleCandidateDetailResponse>(
			'getEligibleCandidateDetails',
			request
		);
	return response.data;
};

export const sendExamRegistrationInviteEmail = async (
	request: SendEmailRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendExamRegistrationInviteEmail',
		request
	);
	return response.data;
};

export const sendExamAdmitCardEmail = async (
	request: SendEmailRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendExamAdmitCardEmail',
		request
	);
	return response.data;
};

export const getExaminationCenterDetailForRegistration = async (
	request: GetExaminationCenterDetailRequest
): Promise<GetExaminationCenterDetailResponse> => {
	const response =
		await volunteerServiceClient.post<GetExaminationCenterDetailResponse>(
			'getExaminationCenterDetailForRegistration',
			request
		);
	return response.data;
};

export const getExaminationLanguages = async (
	request: GetExaminationLanguagesRequest
): Promise<GetExaminationLanguagesResponse> => {
	const response =
		await volunteerServiceClient.post<GetExaminationLanguagesResponse>(
			'getExaminationLanguages',
			request
		);
	return response.data;
};

export const getExams = async (
	request: GetExamsRequest
): Promise<GetExamsResponse> => {
	const response = await volunteerServiceClient.post<GetExamsResponse>(
		'getExams',
		request
	);
	return response.data;
};

export const createExam = async (request: CreateExamRequest): Promise<Exam> => {
	const response = await volunteerServiceClient.post<Exam>(
		'createExam',
		request
	);
	return response.data;
};

export const createExaminationCenter = async (
	request: CreateExaminationCenterRequest
): Promise<ExaminationCenter> => {
	const response = await volunteerServiceClient.post<ExaminationCenter>(
		'createExaminationCenter',
		request
	);
	return response.data;
};

export const getExamById = async (request: GetExamRequest): Promise<Exam> => {
	const response = await volunteerServiceClient.post<Exam>(
		'getExamById',
		request
	);
	return response.data;
};

export const getExaminationCenterById = async (
	request: GetExaminationCenterRequest
): Promise<ExaminationCenter> => {
	const response = await volunteerServiceClient.post<ExaminationCenter>(
		'getExaminationCenterById',
		request
	);
	return response.data;
};

export const updateExam = async (request: CreateExamRequest): Promise<Exam> => {
	const response = await volunteerServiceClient.post<Exam>(
		'updateExam',
		request
	);
	return response.data;
};

export const updateExaminationCenter = async (
	request: CreateExaminationCenterRequest
): Promise<ExaminationCenter> => {
	const response = await volunteerServiceClient.post<ExaminationCenter>(
		'updateExaminationCenter',
		request
	);
	return response.data;
};

export const changeExaminationCenter = async (
	request: ChangeExaminationCenterRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'changeExaminationCenter',
			request
		);
	return response.data;
};

export const cancelExam = async (
	request: CancelExamRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'cancelExam',
			request
		);
	return response.data;
};

export const downloadCandidateDetailsForAttendance = async (
	request: GetCandidateExamDetailsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'downloadCandidateDetailsForAttendance',
		request
	);
	return response.data;
};

export const uploadCandidateAttendance = async (
	request: ImportCSVReportRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'uploadCandidateAttendance',
		request
	);
	return response.data;
};

export const downloadCandidateExaminationDetails = async (
	request: GetCandidateExamDetailsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'downloadCandidateExaminationDetails',
		request
	);
	return response.data;
};

export const placeCandidateOnHold = async (
	request: PlaceCandidateOnHoldRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'placeCandidateOnHold',
			request
		);
	return response.data;
};

export const approveHeldCandidate = async (
	request: ApproveHeldCandidateRequest
): Promise<CandidateExaminationDetail> => {
	const response =
		await volunteerServiceClient.post<CandidateExaminationDetail>(
			'approveHeldCandidate',
			request
		);
	return response.data;
};

export const getExaminationGraphicalView = async (
	request: GetExaminationGraphicalViewRequest
): Promise<ExaminationGraphicalViewResponse> => {
	const response =
		await volunteerServiceClient.post<ExaminationGraphicalViewResponse>(
			'getExaminationGraphicalView',
			request
		);
	return response.data;
};

export const updateExamTemplate = async (
	request: UpdateExamTemplateRequest
): Promise<ExamTemplate> => {
	const response = await volunteerServiceClient.post<ExamTemplate>(
		'updateExamTemplate',
		request
	);
	return response.data;
};

export const getExamTemplates = async (
	request: GetExamTemplatesRequest
): Promise<GetExamTemplatesResponse> => {
	const response = await volunteerServiceClient.post<GetExamTemplatesResponse>(
		'getExamTemplates',
		request
	);
	return response.data;
};
