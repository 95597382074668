import { useCallback, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	CandidateExaminationDetail,
	Exam,
	GetExaminationCenterDetailResponse,
} from '../../../shared/ExamModuleTypes';
import { filterActiveCentersFromExam } from '../../../shared/Utils';
import { Gender } from '../../../shared/VolunteerApplicationServiceTypes';
import {
	VALID_ABHYASI_ID_REGEX,
	Gender as GENDER,
} from '../../../shared/Constants';
import { useToastMessage } from '../../../hooks/useToastMessage';

interface CandidateRegistrationFormType {
	srcmId: string;
	name: string;
	gender: Gender;
	center: string;
	language: string;
	accommodationRequired: string;
	accommodationNo: number;
	specialRequests: string;
	changeOfCenterReason: string;
}
interface EditCandidateModalProps {
	openModal: boolean;
	onClose: () => void;
	onSubmit: (centerId: number, reasonforChangingCenter: string) => void;
	candidateExaminationDetail: CandidateExaminationDetail;
	examById: Exam;
	fetchExamCenterDetail: (
		examCenterId: number
	) => Promise<GetExaminationCenterDetailResponse | undefined>;
}

export const EditCandidateModal = ({
	openModal,
	onClose,
	onSubmit,
	candidateExaminationDetail,
	examById,
	fetchExamCenterDetail,
}: EditCandidateModalProps) => {
	const [form] = Form.useForm<CandidateRegistrationFormType>();
	const formValues = Form.useWatch<CandidateRegistrationFormType>([], form);
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const [centerDetails, setCenterDetails] =
		useState<GetExaminationCenterDetailResponse | null>();
	const regFormInitialValues = {
		srcmId: candidateExaminationDetail.profile.srcmId,
		name: candidateExaminationDetail.profile.name,
		gender: candidateExaminationDetail.profile.gender,
		center: candidateExaminationDetail.examinationCenter.id,
		language: candidateExaminationDetail.preferredLanguageForExamination,
		accommodationRequired: candidateExaminationDetail.accommodationRequired
			? 'YES'
			: 'NO',
		accommodationNo: candidateExaminationDetail.noOfAccommodations,
		specialRequests: candidateExaminationDetail.specialRequest,
	};

	const candidateName = candidateExaminationDetail?.profile.name;

	useEffect(() => {
		fetchExamCenterDetail(candidateExaminationDetail.examinationCenter.id)
			.then((examCenterDetail) => setCenterDetails(examCenterDetail))
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			});
	}, [
		candidateExaminationDetail.examinationCenter.id,
		fetchExamCenterDetail,
		showToastMessage,
	]);

	const handleOk = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				const centerId = formValues.center;
				const reason = formValues.changeOfCenterReason;
				onSubmit(parseInt(centerId), reason);
				onClose();
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [form, onClose, onSubmit, formValues, showToastMessage, t]);

	const handleCancel = useCallback(() => {
		onClose();
	}, [onClose]);

	const onCenterChange = useCallback(
		(value: number) => {
			fetchExamCenterDetail(value)
				.then((examCenterDetail) => setCenterDetails(examCenterDetail))
				.catch((error: Error) => {
					showToastMessage('error', error.message);
				});
		},
		[fetchExamCenterDetail, showToastMessage]
	);

	return (
		<Modal
			title={t('updateCandidateRegistration')}
			open={openModal}
			onOk={handleOk}
			onCancel={handleCancel}
			okText={t('update')}
			cancelText={t('cancel')}
			okButtonProps={{
				className: 'bg-nav-blue hover:!bg-hover-nav-blue',
				size: 'large',
			}}
			cancelButtonProps={{ size: 'large' }}
			width={1200}
		>
			<p className="font-semibold text-lg">
				{t('editCandidateMessage', { candidateName })}
			</p>
			<Form
				name="updateRegistrationFormByAdmin"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				form={form}
				initialValues={regFormInitialValues}
			>
				<div className="flex flex-col md:flex-row justify-between">
					<div className="flex flex-col md:mb-0">
						<div className="flex flex-wrap gap-4">
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('abhyasiId')}
										</span>
									}
									name="srcmId"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
										{
											pattern: VALID_ABHYASI_ID_REGEX,
											message: <span>{t('invalidAbhyasiId')}</span>,
										},
									]}
								>
									<Input size="large" placeholder="Enter Abhyasi ID" disabled />
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('name')}
										</span>
									}
									name="name"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Input size="large" placeholder="Enter Name" disabled />
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('gender')}
										</span>
									}
									name="gender"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Select
										size="large"
										options={[
											{
												value: GENDER.MALE,
												label: t('male'),
											},
											{
												value: GENDER.FEMALE,
												label: t('female'),
											},
										]}
										disabled
									/>
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('selectCenter')}
										</span>
									}
									name="center"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Select
										size="large"
										options={filterActiveCentersFromExam(examById)}
										onChange={onCenterChange}
									/>
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('selectLanguage')}
										</span>
									}
									name="language"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Input size="large" disabled />
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('accommodation')}
										</span>
									}
									name="accommodationRequired"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Input size="large" disabled />
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('noOfAccommodations')}
										</span>
									}
									name="accommodationNo"
								>
									<InputNumber
										size="large"
										className="w-full"
										min={0}
										disabled
									/>
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('specialRequestsIfAny')}
										</span>
									}
									name="specialRequests"
								>
									<Input.TextArea rows={4} disabled />
								</Form.Item>
							</div>
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('reasonForChangingCenter')}
										</span>
									}
									name="changeOfCenterReason"
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Input.TextArea rows={6} />
								</Form.Item>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-center md:items-end">
						{centerDetails && (
							<div className="flex gap-4 items-center">
								<h3 className="text-center w-32">
									{t('availableSeats')}{' '}
									<span className="block">{t('atThisCenter')}</span>
								</h3>
								<div className="text-lg px-8 py-2 rounded bg-purple text-white">
									{centerDetails?.noOfSeatsAvailable || 0}
								</div>
							</div>
						)}
						{centerDetails && (
							<div className="flex gap-4 items-center">
								<h3 className="text-center">
									{t('accommodation')}{' '}
									<span className="block">{t('available')}</span>
								</h3>
								<div className="text-lg px-8 py-2 rounded bg-purple text-white">
									{centerDetails?.noOfAccommodationsAvailable || 0}
								</div>
							</div>
						)}
					</div>
				</div>
			</Form>
		</Modal>
	);
};
