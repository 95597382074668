import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { UnderstandingOfHeartfulnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = UnderstandingOfHeartfulnessUIConfig;

export const UnderstandingOfHeartfulnessStep = () => {
	const { understandingOfHeartfulness, setUnderstandingOfHeartfulness } =
		useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(
		understandingOfHeartfulness,
		setUnderstandingOfHeartfulness
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
