import { Pagination as PaginationBar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	PagingConfig,
	FunctionaryInfo,
	FunctionaryList,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import isEmpty from 'lodash/isEmpty';
import { FunctionaryCard } from './FunctionaryCard';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

const EmptyList = () => {
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();

	return (
		<div className="justify-center items-center border border-solid rounded-lg border-slate-200 w-[480px]">
			{role === UserRole.ADMIN ? t('noFunctionaries') : t('noProposers')}
		</div>
	);
};

export const FunctionariesList = ({
	data: { functionary, pagination },
	onPageChange,
	onEditClick,
	pageConfig,
}: {
	data: FunctionaryList;
	onPageChange: (page: number, pageSize: number) => void;
	onEditClick: (functionary: FunctionaryInfo) => void;
	pageConfig: PagingConfig;
}) => {
	if (
		isEmpty(pagination.totalNoOfRecords) ||
		pagination.totalNoOfRecords === 0
	) {
		return <EmptyList />;
	}

	const proposerCards = functionary?.map((proposer) => (
		<div className="mb-[16px]" key={proposer.srcmId}>
			<FunctionaryCard {...proposer} onEditClick={onEditClick} />{' '}
		</div>
	));

	return (
		<div className="flex flex-col">
			{proposerCards}
			<PaginationBar
				current={pageConfig.pageNo ?? 1}
				total={pagination.totalNoOfRecords}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				defaultPageSize={pageConfig.pageSize ?? DEFAULT_PAGE_SIZE}
				onChange={onPageChange}
			/>
		</div>
	);
};
