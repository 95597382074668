import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
	useEffect,
} from 'react';
import {
	Application,
	Feedback,
	FunctionaryFeedbackRequest,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { submitFeedbackFromProposer } from '../../../service/VolunteerApplicationService';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { useAuth } from '../../../hooks/useAuth';
import { INTERVIEW_LANDING } from '../../../shared/Routes';
import { useInterviewWizard } from '../useInterviewWizard';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export interface OtherReferenceInterviewContextType {
	inspirationAndWillingness: Feedback;
	setInspirationAndWillingness: (feedback: Feedback) => void;
	practice: Feedback;
	setPractice: (feedback: Feedback) => void;
	characterAndHabits: Feedback;
	setCharacterAndHabits: (feedback: Feedback) => void;
	availabilityAfterProgram: Feedback;
	setAvailabilityAfterProgram: (feedback: Feedback) => void;
	support: Feedback;
	setSupport: (feedback: Feedback) => void;
	understandingOfHeartfulness: Feedback;
	setUnderstandingOfHeartfulness: (feedback: Feedback) => void;
	application: Partial<Application>;
	onSubmitClick: () => void;
	onCancelClick: () => void;
}

interface Props {
	children: ReactNode;
}

const OtherReferenceInterviewContext =
	createContext<OtherReferenceInterviewContextType>({
		inspirationAndWillingness: {},
		setInspirationAndWillingness: () => {},
		practice: {},
		setPractice: () => {},
		characterAndHabits: {},
		setCharacterAndHabits: () => {},
		availabilityAfterProgram: {},
		setAvailabilityAfterProgram: () => {},
		support: {},
		setSupport: () => {},
		understandingOfHeartfulness: {},
		setUnderstandingOfHeartfulness: () => {},
		application: {},
		onSubmitClick: () => {},
		onCancelClick: () => {},
	});

export const OtherReferenceInterviewProvider: FC<Props> = ({
	children,
}: Props) => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const [inspirationAndWillingness, setInspirationAndWillingness] =
		useState<Feedback>({});
	const [practice, setPractice] = useState<Feedback>({});
	const [characterAndHabits, setCharacterAndHabits] = useState<Feedback>({});
	const [availabilityAfterProgram, setAvailabilityAfterProgram] =
		useState<Feedback>({});
	const [support, setSupport] = useState<Feedback>({});
	const [understandingOfHeartfulness, setUnderstandingOfHeartfulness] =
		useState<Feedback>({});
	const [application, setApplication] = useState<Partial<Application>>({});
	const {
		applicationId,
		navigateWithApplicationId,
		fetchApplication: callFetchApplication,
	} = useApplicationId();
	const { confirmAndSubmit, onCancelClick, callApi } = useInterviewWizard();

	const saveFeedback = useCallback(async () => {
		const feedback: FunctionaryFeedbackRequest = {
			applicationId,
			categoryReviewFeedback: {
				availabilityAfterProgram,
				characterAndHabits,
				inspirationAndWillingness,
				practice,
				support,
				understandingOfHeartfulness,
			},
			feedbackCategory: 'OTHER_REFERENCE_INTERVIEW',
			functionarySrcmId: srcmId as string,
		};

		try {
			await callApi(
				() => submitFeedbackFromProposer(feedback),
				'errorOccurredWhileSavingFeedback'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(INTERVIEW_LANDING),
			});
		} catch (e) {
			/* empty */
		}
	}, [
		applicationId,
		availabilityAfterProgram,
		callApi,
		characterAndHabits,
		inspirationAndWillingness,
		navigateWithApplicationId,
		practice,
		srcmId,
		support,
		t,
		understandingOfHeartfulness,
	]);

	const onSubmitClick = useCallback(() => {
		confirmAndSubmit(saveFeedback);
	}, [confirmAndSubmit, saveFeedback]);

	const value = useMemo<OtherReferenceInterviewContextType>(() => {
		return {
			inspirationAndWillingness,
			setInspirationAndWillingness,
			practice,
			setPractice,
			characterAndHabits,
			setCharacterAndHabits,
			availabilityAfterProgram,
			setAvailabilityAfterProgram,
			support,
			setSupport,
			understandingOfHeartfulness,
			setUnderstandingOfHeartfulness,
			application,
			onSubmitClick,
			onCancelClick,
		};
	}, [
		inspirationAndWillingness,
		practice,
		characterAndHabits,
		availabilityAfterProgram,
		support,
		understandingOfHeartfulness,
		application,
		onSubmitClick,
		onCancelClick,
	]);

	const fetchApplication = useCallback(async () => {
		try {
			const fetchedApplication = await callFetchApplication();

			setInspirationAndWillingness(
				fetchedApplication.otherReferenceInterviewFeedback
					?.inspirationAndWillingness ?? {}
			);
			setPractice(
				fetchedApplication.otherReferenceInterviewFeedback?.practice ?? {}
			);
			setAvailabilityAfterProgram(
				fetchedApplication.otherReferenceInterviewFeedback
					?.availabilityAfterProgram ?? {}
			);
			setCharacterAndHabits(
				fetchedApplication.otherReferenceInterviewFeedback
					?.characterAndHabits ?? {}
			);
			setSupport(
				fetchedApplication.otherReferenceInterviewFeedback?.support ?? {}
			);
			setUnderstandingOfHeartfulness(
				fetchedApplication.otherReferenceInterviewFeedback
					?.understandingOfHeartfulness ?? {}
			);
			setApplication(fetchedApplication);
		} catch (e) {
			// Do Nothing
		}
	}, [callFetchApplication]);

	useEffect(() => {
		void fetchApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<OtherReferenceInterviewContext.Provider value={value}>
			{children}
		</OtherReferenceInterviewContext.Provider>
	);
};

export const useOtherReferenceInterviewWizard = () => {
	return useContext(OtherReferenceInterviewContext);
};
