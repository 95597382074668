import { useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import { Form } from 'antd';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';

export default function useCategoryFeedbackFormHandling() {
	const { t } = useTranslation();
	const { setSubmitButtonText } = useWizard();
	const [form] = Form.useForm<Feedback>();

	useEffect(() => {
		setSubmitButtonText(`${t('ok')}`);
	}, [setSubmitButtonText, t]);

	return { form };
}
