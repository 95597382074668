export const setItem = <T>(key: string, value: T) => {
	try {
		window.localStorage.setItem(key, JSON.stringify(value));
	} catch (err) {
		// Handle Error
	}
};

export const getItem = <T>(key: string, defaultValue: T) => {
	try {
		const value = window.localStorage.getItem(key);

		if (value) {
			return JSON.parse(value) as T;
		}

		return defaultValue;
	} catch (err) {
		return defaultValue;
	}
};
