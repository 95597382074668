import { useTranslation } from 'react-i18next';
import { ExamForm } from './ExamForm';

export const UpdateExam = () => {
	const { t } = useTranslation();

	return (
		<>
			<h2>{t('examUpdation')}</h2>
			<ExamForm forUpdating />
		</>
	);
};
