import { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { PreceptorsTable } from '../../../components/PreceptorsTable';
import { useCreateGroup } from './useCreateGroup';
import { GroupsTable } from './GroupsTable';
import useQueryParams from '../../../hooks/useQueryParams';
import { GROUPS } from '../../../shared/Routes';
import { useGroupId } from '../../../hooks/useGroupId';
import { GroupTabs } from '../../../shared/Constants';
import { NPSGraphicalView } from './NPSGraphicalView';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import { UserRole } from '../../../shared/VolunteerApplicationServiceTypes';
import { BatchView } from './BatchView';
import { useBatchView } from './useBatchView';
import { PreceptorBatchState } from './PreceptorsList.store';
import { useSnapshot } from 'valtio';

export const CreateGroupContainer = () => {
	const { t } = useTranslation();
	const {
		preceptorsList,
		onPreceptorsPageChange,
		onPreceptorFilterChange,
		preceptorsFilter,
		preceptorPaging,
		onCreateGroupClick,
		activeTabKey,
		setActiveTabKey,
		groupsList,
		groupsFilter,
		onGroupsFilterChange,
		onGroupsPageChange,
		groupPaging,
		dashboardDetail,
		onDashboardDetailFilterChange,
		dashboardPreceptorsList,
		onDashboardPageChange,
		onDashboardFilterChange,
		dashboardPaging,
		dashboardFilter,
	} = useCreateGroup();
	const { navigateWithBatchIdAndGroupTab } = useGroupId();
	const queryParams = useQueryParams();
	const { role } = useRedirectToLogin();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const batchIdInSnap = preceptorBatchSnap.selectedBatch?.id;
	const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>(
		batchIdInSnap
	);
	const { preceptorBatchData } = useBatchView();
	const { preceptorBatches } = preceptorBatchData;
	const batchOptions = preceptorBatches.map((batch) => ({
		label: batch.batchName,
		value: batch.id,
	}));

	useEffect(() => {
		if (preceptorBatches.length && !batchIdInSnap) {
			PreceptorBatchState.selectedBatch = preceptorBatches[0];
		}
	}, [preceptorBatches, batchIdInSnap]);

	const activetab = useMemo(() => {
		const activeTabParam = queryParams.get('activetab');
		return activeTabParam;
	}, [queryParams]);

	const showSelectBatch = activetab !== GroupTabs.BATCHES;

	const functionaryTabs = [
		{
			key: GroupTabs.CREATE_GROUP,
			label: t('createGroup'),
			children: (
				<PreceptorsTable
					key={GroupTabs.CREATE_GROUP}
					data={preceptorsList}
					onPreceptorsPageChange={onPreceptorsPageChange}
					preceptorPageConfig={preceptorPaging}
					preceptorsFilter={preceptorsFilter}
					onPreceptorFilterChange={onPreceptorFilterChange}
					onCreateGroupClick={onCreateGroupClick}
					selectedBatchId={batchIdInSnap}
				/>
			),
		},
		{
			key: GroupTabs.GROUPS_LIST_VIEW,
			label: t('groupListView'),
			children: (
				<GroupsTable
					key={GroupTabs.GROUPS_LIST_VIEW}
					data={groupsList}
					onGroupsPageChange={onGroupsPageChange}
					groupPageConfig={groupPaging}
					groupsFilter={groupsFilter}
					onGroupsFilterChange={onGroupsFilterChange}
					selectedBatchId={batchIdInSnap}
				/>
			),
		},
		{
			key: GroupTabs.VIEW_ALL_NEW_PRECEPTORS,
			label: t('viewAllPreceptors'),
			children: (
				<PreceptorsTable
					key={GroupTabs.VIEW_ALL_NEW_PRECEPTORS}
					data={dashboardPreceptorsList}
					onPreceptorsPageChange={onDashboardPageChange}
					preceptorPageConfig={dashboardPaging}
					preceptorsFilter={dashboardFilter}
					onPreceptorFilterChange={onDashboardFilterChange}
					isDashboardTable={true}
					dashboardDetail={dashboardDetail}
					onDashboardDetailFilterChange={onDashboardDetailFilterChange}
					activeTabKey={activeTabKey}
					selectedBatchId={batchIdInSnap}
				/>
			),
		},
		{
			key: GroupTabs.GRAPHICAL_VIEW,
			label: t('graphicalView'),
			children: (
				<NPSGraphicalView
					key={GroupTabs.GRAPHICAL_VIEW}
					selectedBatchId={batchIdInSnap}
				/>
			),
		},
	];

	const groupTabs: TabsProps['items'] =
		role === UserRole.ADMIN
			? [
					...functionaryTabs,
					{
						key: GroupTabs.BATCHES,
						label: t('batches'),
						children: <BatchView key={GroupTabs.BATCHES} />,
					},
			  ]
			: functionaryTabs;

	const handleTabClick = useCallback(
		(key: string) => {
			setActiveTabKey(key);
			navigateWithBatchIdAndGroupTab(GROUPS, key);
		},
		[navigateWithBatchIdAndGroupTab, setActiveTabKey]
	);

	const handleBatchChange = useCallback(
		(value: number) => {
			setSelectedBatchId(value);
			const selectedBatch = preceptorBatches.find(
				(batch) => batch.id === value
			);
			PreceptorBatchState.selectedBatch = selectedBatch;
		},
		[preceptorBatches]
	);

	return (
		<div className="p-8">
			{showSelectBatch && (
				<div className="flex items-center gap-2">
					<p className="text-base font-medium">{t('selectBatch')}</p>
					<Select
						className="w-48"
						size="large"
						options={batchOptions}
						value={
							selectedBatchId || (batchOptions.length && batchOptions[0].value)
						}
						onChange={handleBatchChange}
					/>
				</div>
			)}
			<Tabs
				activeKey={activetab || GroupTabs.CREATE_GROUP}
				items={groupTabs}
				size="large"
				onTabClick={(key: string) => handleTabClick(key)}
				destroyInactiveTabPane={true}
			/>
		</div>
	);
};
