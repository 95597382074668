import { useEffect, useState } from 'react';
import { Card, Skeleton } from 'antd';
import { ReactComponent as TotalCountIcon } from '../assets/images/total-count-icon.svg';
import { ReactComponent as NotMappedIcon } from '../assets/images/not-mapped-icon.svg';
import { ReactComponent as MappedIcon } from '../assets/images/mapped-icon.svg';
import {
	PreceptorDashboardCardDetail,
	PreceptorDashboardCardFilter,
} from '../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import { TOTAL_NO_OF_NPS_CARDS } from '../shared/Constants';

const NPSDetailCard = ({
	icon,
	preceptorCount,
	preceptorText,
	onClick,
	isSelected,
	isHovered,
	onMouseEnter,
	onMouseLeave,
}: {
	icon: JSX.Element;
	preceptorCount?: number;
	preceptorText: string;
	onClick?: () => void;
	isSelected?: boolean;
	isHovered?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}) => {
	return (
		<div className={`w-full md:w-1/5 p-2`}>
			<Card
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={`flex justify-around items-center border-2 ${
					isSelected || isHovered ? 'border-nav-blue' : 'border-gray-300'
				} w-full h-full rounded-none transition-colors duration-300 cursor-pointer`}
			>
				<div className="flex w-full gap-5 items-center min-h-32">
					<div className="flex gap-5 min-w-16">
						<div className="flex flex-col items-center justify-center">
							<div className="mb-2">{icon}</div>
							<h1 className="text-gray my-1">{preceptorCount || 0}</h1>
						</div>
					</div>
					<p className="text-lg text-gray font-medium my-1 text-start">
						{preceptorText}
					</p>
				</div>
			</Card>
		</div>
	);
};

const NPSLoadingCard = () => {
	return (
		<>
			<Card className="w-1/5 rounded-none border-2 border-gray-300 mr-4">
				<Skeleton loading avatar active></Skeleton>
			</Card>
		</>
	);
};

export const NPSDetailCardsList = ({
	dashboardDetail,
	onCardClick,
}: {
	dashboardDetail?: PreceptorDashboardCardDetail;
	onCardClick?: (
		preceptorDashboardCardFilter: PreceptorDashboardCardFilter,
		cardIndex: number
	) => void;
}) => {
	const { t } = useTranslation();
	const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(0);
	const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);

	useEffect(() => {
		setSelectedCardIndex(0);
	}, [dashboardDetail]);

	const handleCardClick = (index: number) => {
		if (!onCardClick) {
			return;
		}
		setSelectedCardIndex(index);
		const preceptorDashboardCardFilter = {
			preceptorMappedToGroup: false,
			preceptorAssignedToMentor: false,
			preceptorNotMappedToGroup: false,
			preceptorNotAssignedToMentor: false,
		};

		switch (index) {
			case 0:
				onCardClick(preceptorDashboardCardFilter, index);
				return;
			case 1:
				preceptorDashboardCardFilter.preceptorNotMappedToGroup = true;
				onCardClick(preceptorDashboardCardFilter, index);
				return;
			case 2:
				preceptorDashboardCardFilter.preceptorMappedToGroup = true;
				onCardClick(preceptorDashboardCardFilter, index);
				return;
			case 3:
				preceptorDashboardCardFilter.preceptorNotAssignedToMentor = true;
				onCardClick(preceptorDashboardCardFilter, index);
				return;
			default:
				preceptorDashboardCardFilter.preceptorAssignedToMentor = true;
				onCardClick(preceptorDashboardCardFilter, index);
				return;
		}
	};

	const handleMouseEnter = (index: number) => {
		setHoveredCardIndex(index);
	};

	const handleMouseLeave = () => {
		setHoveredCardIndex(null);
	};

	const getIconByIndex = (index: number) => {
		if (index === 0) return <TotalCountIcon className="w-12 h-12" />;
		if (index === 1) return <NotMappedIcon className="w-12 h-12" />;
		return index % 2 === 0 ? (
			<MappedIcon className="w-12 h-12" />
		) : (
			<NotMappedIcon className="w-12 h-12" />
		);
	};

	const getPreceptorCountByIndex = (
		index: number,
		dashboardDetailParam?: PreceptorDashboardCardDetail
	) => {
		switch (index) {
			case 0:
				return dashboardDetailParam?.totalPreceptors;
			case 1:
				return dashboardDetailParam?.totalPreceptorsNotMappedWithGroup;
			case 2:
				return dashboardDetailParam?.totalPreceptorsMappedWithGroup;
			case 3:
				return dashboardDetailParam?.totalPreceptorsNotAssignedWithMentor;
			default:
				return dashboardDetailParam?.totalPreceptorsAssignedWithMentor;
		}
	};

	const getPreceptorTextByIndex = (index: number) => {
		switch (index) {
			case 0:
				return t('totalPreceptorCount');
			case 1:
				return t('preceptorsNotMappedToGroup');
			case 2:
				return t('preceptorsMappedToGroup');
			case 3:
				return t('preceptorsNotMappedWithMentor');
			default:
				return t('preceptorsMappedWithMentor');
		}
	};

	return (
		<div className="flex justify-between my-5">
			{(dashboardDetail
				? Array.from({ length: TOTAL_NO_OF_NPS_CARDS })
				: []
			).map((_, index) => (
				<NPSDetailCard
					key={index}
					icon={getIconByIndex(index)}
					preceptorCount={getPreceptorCountByIndex(index, dashboardDetail)}
					preceptorText={getPreceptorTextByIndex(index)}
					onClick={onCardClick ? () => handleCardClick(index) : undefined}
					isSelected={onCardClick ? selectedCardIndex === index : undefined}
					isHovered={onCardClick ? hoveredCardIndex === index : undefined}
					onMouseEnter={onCardClick ? () => handleMouseEnter(index) : undefined}
					onMouseLeave={onCardClick ? handleMouseLeave : undefined}
				/>
			))}
			{!dashboardDetail &&
				Array.from({ length: TOTAL_NO_OF_NPS_CARDS }).map((_, index) => (
					<NPSLoadingCard key={index} />
				))}
		</div>
	);
};
