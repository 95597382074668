import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels
);

interface StackedHorizontalBarProps {
	title: string;
	data: ChartData<'bar'>;
	displayPercentage: boolean;
}

const StackedHorizontalBar: React.FC<StackedHorizontalBarProps> = ({
	title,
	data,
	displayPercentage,
}) => {
	const labels = data.labels || [];
	const numberOfBars = labels.length;
	const baseHeight = numberOfBars <= 4 ? 100 : 300;
	const barHeight = 40;
	const calculatedHeight = Math.max(baseHeight + barHeight * numberOfBars, 300);

	const barThickness = numberOfBars > 3 ? 40 : 50;

	const totalCounts = labels.map((_, index) =>
		data.datasets.reduce((total, dataset) => {
			const value = dataset.data[index];
			return total + (typeof value === 'number' ? value : 0);
		}, 0)
	);

	const fontFamily =
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

	const options: ChartOptions<'bar'> = {
		indexAxis: 'y',
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'bottom',
				align: 'end',
				labels: {
					usePointStyle: true,
					font: {
						size: 14,
						family: fontFamily,
					},
				},
			},
			title: {
				display: true,
				text: title,
				font: {
					size: 20,
					family: fontFamily,
				},
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				formatter: (_, context) => {
					return (
						context.chart.data.datasets[context.datasetIndex].data[
							context.dataIndex
						] || ''
					);
				},
				font: {
					size: 12,
					weight: 'bold',
					family: fontFamily,
				},
				color: 'white',
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const total = totalCounts[context.dataIndex];
						const value = context.raw as number;
						if (!total || isNaN(value)) {
							return '';
						}
						const label = context.dataset.label || '';
						if (displayPercentage) {
							const percentage = ((value / total) * 100).toFixed(2);
							return `${label}: ${percentage}%`;
						} else {
							return `${label}: ${value}`;
						}
					},
				},
			},
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					font: {
						family: fontFamily,
					},
				},
			},
			y: {
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					maxRotation: 0,
					minRotation: 0,
					font: {
						family: fontFamily,
					},
				},
			},
		},
		layout: {
			padding: {
				top: 10,
				bottom: 10,
			},
		},
	};

	const transformedData = {
		...data,
		datasets: data.datasets.map((dataset) => ({
			...dataset,
			barThickness,
			maxBarThickness: barThickness,
			minBarLength: 10,
		})),
	};

	return (
		<div
			className="px-10 mt-10 mb-20"
			style={{ height: `${calculatedHeight}px` }}
		>
			<Bar data={transformedData} options={options} />
		</div>
	);
};

export default StackedHorizontalBar;
