import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramStep = () => {
	const { availabilityAfterProgram, setAvailabilityAfterProgram } =
		useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(
		availabilityAfterProgram,
		setAvailabilityAfterProgram
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
