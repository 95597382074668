import { Typography, Form, FormInstance, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { QuestionsToAsk } from '../QuestionsToAsk';
import { ApplicantAnswerToQuestion } from '../ApplicantAnswerToQuestion';
import { Answers } from '../../../shared/VolunteerApplicationServiceTypes';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import { FeedbackComponent } from '../Feedback';
import { MandatoryQuestionsToAsk } from '../MandatoryQuestionsToAsk';

const { Text, Title } = Typography;
const { Panel } = Collapse;

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
	mandatoryQuestionsAndAnswersForm: FormInstance;
	answers?: Answers;
	showApplicantAnswers?: boolean;
	areCommentsMandatory?: boolean;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mb-[8px]">
			<Text className="mt-[24px]" type="secondary">
				{t('candidateInterview')}
			</Text>
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CategoryStep = ({
	uiConfig: {
		title,
		functionaryTitle,
		subTitle,
		questionsToAskInApplicantInterview,
		questions,
	},
	form,
	mandatoryQuestionsAndAnswersForm,
	answers,
	showApplicantAnswers = true,
	areCommentsMandatory = false,
}: CategoryStepProps) => {
	const { t } = useTranslation();

	const allQuestions = questions.map(
		(question: MultipleChoiceQuestionUIConfig) => {
			const value = answers?.questionsAndAnswers.find(
				(answer) => answer.questionId === question.questionId
			);
			return (
				<div key={question.questionId} className="my-[8px]">
					<ApplicantAnswerToQuestion uiConfig={question} value={value} />
				</div>
			);
		}
	);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="mandatoryQuestionsAndAnswersForm"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={mandatoryQuestionsAndAnswersForm}
			>
				<SectionHeader
					heading={functionaryTitle ? t(functionaryTitle) : t(title)}
					description={t(subTitle)}
				/>
				{showApplicantAnswers && (
					<Collapse collapsible="header">
						<Panel header={t('candidateApplication')} key="1">
							{allQuestions}
						</Panel>
					</Collapse>
				)}
				<MandatoryQuestionsToAsk
					uiConfig={questionsToAskInApplicantInterview.mandatoryQuestionsToAsk}
				/>
			</Form>
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<QuestionsToAsk
					uiConfig={questionsToAskInApplicantInterview.suggestedQuestionsToAsk}
				/>
				<Title level={5}>
					{t('proposersFeedbackForCategory', { categoryTitle: t(title) })}
				</Title>
				<FeedbackComponent commentsMandatory={areCommentsMandatory} />
			</Form>
		</div>
	);
};
