import { useState, useCallback } from 'react';
import { WizardContainer } from '../../../components/wizard/Wizard';
import { HeartfulnessProfileStepContainer } from './HeartfulnessProfileStep';
import { ApplicantAdditionalDetailsStep } from './ApplicantAdditionalDetailsStep';
import { InspirationAndWillingnessStep } from './InspirationAndWillingnessStep';
import { PracticeStep } from './PracticeStep';
import { CharacterAndHabitsStep } from './CharacterAndHabitsStep';
import { AvailabilityAfterProgramStep } from './AvailabilityAfterProgramStep';
import { SupportStep } from './SupportStep';
import { UnderstandingOfHeartfulnessStep } from './UnderstandingOfHeartfulnessStep';
import { TermsAndConditionStepContainer } from './TermsAndConditionStep';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { NewApplicationProvider, useNewApplication } from './useNewApplication';
import { EmailVerificationModal } from './EmailVerificationModal';
import { WelcomeAndSelfConsciousnessContainer } from './WelcomeAndSelfConsciousnessStep';
import { BasicEligibilityStep } from './BasicEligibilityStep';
import { CategoryAnswerType } from './CategoryAnswerType';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardIndex } from '../../../shared/Constants';
import { DietaryHabitsAndHealthStep } from './DietaryHabitsAndHealthStep';

export const NewApplicationWizard = () => {
	const { t } = useTranslation();
	const {
		onSubmitClick,
		mySrcmProfile,
		isEmailVerified,
		basicEligibilityCategoryAnswers,
	} = useNewApplication();
	const [openEmailVerificationModal, setOpenEmailVerificationModal] =
		useState(false);
	const [resolveFunction, setResolveFunction] = useState<
		((value: boolean) => void) | null
	>(null);

	const closeEmailVerificationModal = useCallback(() => {
		setOpenEmailVerificationModal(false);
		if (resolveFunction) {
			resolveFunction(false);
		}
	}, [resolveFunction]);

	const hasAnswerOtherThanYes = useCallback(
		(categoryAnswers: CategoryAnswerType) => {
			return Object.values(categoryAnswers).some(
				(item: { selectedOption: number }) => item.selectedOption !== 1
			);
		},
		[]
	);

	const showModal = useCallback(
		(cardIndex: number): Promise<boolean> => {
			if (cardIndex === CardIndex.HEARTFULNESS_PROFILE && !isEmailVerified) {
				return new Promise((resolve) => {
					Modal.confirm({
						okText: t('yes'),
						cancelText: t('no'),
						title: t('confirmContactDetails'),
						content: (
							<div>
								{t('ourTeamWillReachYou')} <b>{mySrcmProfile?.mobile}</b>
								{', '}
								{t('pleaseConfirmTheNumber')}
							</div>
						),
						onOk: () => {
							setOpenEmailVerificationModal(true);
							setResolveFunction(() => resolve);
						},
						onCancel: () => resolve(false),
					});
				});
			} else if (cardIndex === CardIndex.BASIC_ELIGIBILITY) {
				const blockTheUserFromProceddingAhead = hasAnswerOtherThanYes(
					basicEligibilityCategoryAnswers
				);
				if (blockTheUserFromProceddingAhead) {
					return new Promise((resolve) => {
						Modal.warning({
							title: t('basicEligibilityCriteriaNotMet'),
							content: <div>{t('notMeetingBasicEligibilityCriteria')}</div>,
							onOk: () => resolve(false),
						});
					});
				}
			}

			return Promise.resolve(true);
		},
		[
			basicEligibilityCategoryAnswers,
			hasAnswerOtherThanYes,
			isEmailVerified,
			mySrcmProfile?.mobile,
			t,
		]
	);

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={onSubmitClick}
				onNextClick={showModal}
				steps={[
					<HeartfulnessProfileStepContainer key={1} />,
					<ApplicantAdditionalDetailsStep key={2} />,
					<WelcomeAndSelfConsciousnessContainer key={3} />,
					<BasicEligibilityStep key={4} />,
					<InspirationAndWillingnessStep key={5} />,
					<PracticeStep key={6} />,
					<CharacterAndHabitsStep key={7} />,
					<AvailabilityAfterProgramStep key={8} />,
					<SupportStep key={9} />,
					<UnderstandingOfHeartfulnessStep key={10} />,
					<DietaryHabitsAndHealthStep key={11} />,
					<TermsAndConditionStepContainer key={12} />,
				]}
			/>
			<EmailVerificationModal
				openModal={openEmailVerificationModal}
				onCancel={closeEmailVerificationModal}
				email={mySrcmProfile.email}
				mobile={mySrcmProfile?.mobile}
				onEmailVerificationComplete={resolveFunction}
			/>
		</div>
	);
};

export const NewApplicationWizardContainer = () => {
	return (
		<NewApplicationProvider>
			<WizardProvider>
				<NewApplicationWizard />
			</WizardProvider>
		</NewApplicationProvider>
	);
};
