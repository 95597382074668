import { useTranslation } from 'react-i18next';
import { useBatchView } from './useBatchView';
import { Button, Table, TablePaginationConfig } from 'antd';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { BatchTableColumns } from '../../../shared/Columns';
import { FilterBatches } from './FilterBatches';
import { useBatchId } from '../../../hooks/useBatchId';
import { CREATE_BATCH } from '../../../shared/Routes';
import { PlusOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

export const BatchView = () => {
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const {
		batchPaging,
		preceptorBatchData,
		onBatchPageChange,
		preceptorBatchFilter,
		onBatchViewFilterChange,
		loadPreceptors,
	} = useBatchView();
	const { preceptorBatches, pagination } = preceptorBatchData;
	const showTotal = (total: number, range: [number, number]) => (
		<>{`${range[0]}-${range[1]} of ${total} items`}</>
	);
	const tablePaginationConfig: TablePaginationConfig = {
		current: batchPaging.pageNo ?? 1,
		pageSize: batchPaging.pageSize ?? DEFAULT_PAGE_SIZE,
		total: pagination.totalNoOfRecords,
		showTotal,
		onChange: onBatchPageChange,
		showSizeChanger: true,
	};

	const loadPreceptorsClick = useCallback(
		(batchId: number) => {
			void loadPreceptors(batchId);
		},
		[loadPreceptors]
	);

	const columns = BatchTableColumns(loadPreceptorsClick);

	if (
		preceptorBatches &&
		preceptorBatches.length &&
		preceptorBatches.length === 0
	) {
		return <div>{t('noDataFound')}</div>;
	}

	return (
		<>
			<div className="flex flex-row-reverse">
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={() => navigateWithBatchId(CREATE_BATCH)}
					icon={<PlusOutlined />}
				>
					{t('createBatch')}
				</Button>
			</div>
			<FilterBatches
				value={preceptorBatchFilter}
				onFilterChange={onBatchViewFilterChange}
			/>
			<Table
				dataSource={preceptorBatches}
				columns={columns}
				pagination={tablePaginationConfig}
			/>
		</>
	);
};
