import { useState, useCallback, useEffect } from 'react';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { Form, Modal } from 'antd';
import { useApplicationId } from '../../../hooks/useApplicationId';
import {
	Application,
	Feedback,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	submitProposerRecommendation,
	skipFeedbackCollectionByProposer,
} from '../../../service/VolunteerApplicationService';
import { useAuth } from '../../../hooks/useAuth';
import { FUNCTIONARY_HOME, INTERVIEW_LANDING } from '../../../shared/Routes';
import { useTranslation } from 'react-i18next';
import { ReviewAction } from '../../../shared/Constants';

export const useProposerRecommendationPage = () => {
	const [recommendation, setRecommendation] = useState<Feedback>({});
	const [submitButtonDisabled, setSubmitButtonDisabled] =
		useState<boolean>(false);
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const [form] = Form.useForm<Feedback>();
	const values = Form.useWatch<Feedback>([], form);
	const { fetchApplication, applicationId, navigateWithApplicationId } =
		useApplicationId();
	const [application, setApplication] = useState<Application | undefined>();
	const [isApprover, setIsApprover] = useState<boolean | undefined>(false);
	const { callApi } = useCallApi();
	const { srcmId } = useAuth();
	const { t } = useTranslation();

	const onConfirmSubmitForSkipAllInterviews = useCallback(async () => {
		try {
			await callApi(
				() =>
					skipFeedbackCollectionByProposer({
						recommendation,
						applicationId,
						functionarySrcmId: srcmId as string,
					}),
				'errorOccurredWhileSubmitting'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(FUNCTIONARY_HOME),
			});
		} catch (r) {
			// Do Nothing
		}
	}, [
		applicationId,
		callApi,
		navigateWithApplicationId,
		recommendation,
		srcmId,
		t,
	]);

	const onSubmitClickForSkipAllInterviews = useCallback(() => {
		confirmBeforeAction(
			'confirmSubmit',
			'onceSubmittedFeedbackCannotBeChanged',
			onConfirmSubmitForSkipAllInterviews
		);
	}, [confirmBeforeAction, onConfirmSubmitForSkipAllInterviews]);

	const onConfirmSubmit = useCallback(async () => {
		try {
			await callApi(
				() =>
					submitProposerRecommendation({
						recommendation,
						applicationId,
						functionarySrcmId: srcmId as string,
					}),
				'errorOccurredWhileSubmitting'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(FUNCTIONARY_HOME),
			});
		} catch (r) {
			// Do Nothing
		}
	}, [
		applicationId,
		callApi,
		navigateWithApplicationId,
		recommendation,
		srcmId,
		t,
	]);

	const onSubmitClick = useCallback(() => {
		confirmBeforeAction(
			'confirmSubmit',
			'onceSubmittedFeedbackCannotBeChanged',
			onConfirmSubmit
		);
	}, [confirmBeforeAction, onConfirmSubmit]);

	const onCancelClick = useCallback(() => {
		navigateWithApplicationId(INTERVIEW_LANDING);
	}, [navigateWithApplicationId]);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setRecommendation(fieldValues);
			if (
				fieldValues.review === ReviewAction.REJECT &&
				!fieldValues.predefinedReasons?.length
			) {
				setSubmitButtonDisabled(true);
			} else {
				setSubmitButtonDisabled(false);
			}
		} catch (e) {
			const fields = e as { values: Feedback };
			setRecommendation(fields.values);
			setSubmitButtonDisabled(true);
		}
	}, [form]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	const loadApplication = useCallback(async () => {
		try {
			const fetchedApplication: Application = await fetchApplication();
			setRecommendation(fetchedApplication?.proposerRecommendation);
			setIsApprover(fetchedApplication?.isApprover);
			setApplication(fetchedApplication);

			form.setFieldsValue(fetchedApplication?.proposerRecommendation);
		} catch (e) {
			//
		}
	}, [fetchApplication, form]);

	useEffect(() => {
		void loadApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return {
		form,
		application,
		submitButtonDisabled,
		onSubmitClick,
		onSubmitClickForSkipAllInterviews,
		onCancelClick,
		isApprover,
	};
};
