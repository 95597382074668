import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramAnswersReviewStep = () => {
	const { availabilityAfterProgram, setAvailabilityAfterProgram, application } =
		useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		availabilityAfterProgram,
		setAvailabilityAfterProgram
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
