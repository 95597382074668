import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { PracticeUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useOtherReferenceInterviewWizard } from './useOtherReferenceInterviewWizard';

const UiConfig = PracticeUIConfig;

export const PracticeStep = () => {
	const { practice, setPractice } = useOtherReferenceInterviewWizard();

	const { form } = useCategoryFeedbackFormHandling(practice, setPractice);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
