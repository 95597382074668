import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { getZonesOfExaminationCandidates } from '../../service/ExamModuleService';
import { Zone } from '../../shared/ExamModuleTypes';
import isEmpty from 'lodash/isEmpty';
import { useCallApi } from '../../hooks/useCallApi';

const { Option } = Select;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

interface ZonesDropdownProps {
	onChange?: (value: OptionData) => void;
	value?: OptionData | null | undefined;
	examIdInSnap: number;
	regionIds?: Array<string | number | undefined>;
}

const ZonesDropdown: React.FC<ZonesDropdownProps> = ({
	onChange,
	value,
	examIdInSnap,
	regionIds,
}: ZonesDropdownProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			let examZones;
			const regions =
				regionIds &&
				regionIds
					.filter((id): id is string | number => id !== undefined)
					.map((id) => (typeof id === 'string' ? Number(id) : id));

			if (srcmId) {
				examZones = await callApi(
					() =>
						getZonesOfExaminationCandidates({
							functionarySrcmId: srcmId,
							examId: examIdInSnap,
							regionIds: regions,
						}),
					'errorOccurredWhileFetchingZones'
				);
			}

			if (examZones && examZones.zones && examZones.zones.length) {
				const transformedOptions: OptionData[] = examZones.zones
					.filter((item: Zone) => {
						if (!isEmpty(item)) {
							return item.zoneName
								.toLowerCase()
								.includes(searchValue.toLowerCase());
						}
					})
					.map((item: Zone) => ({
						value: item.zoneId,
						label: item.zoneName,
					}));

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}
			setLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[srcmId, examIdInSnap, regionIds]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={true}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchZones')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default ZonesDropdown;
