import { useTranslation } from 'react-i18next';
import { useDashboard } from './useDashboard';
import { Select, Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { GraphTabs } from '../../../shared/Constants';
import { RegistrationGraph } from './registration-graph/RegistrationGraph';
import { ExamResultsGraph } from './exam-results/ExamResultsGraph';
import { useSnapshot } from 'valtio';
import { ExamState } from '../ExamModule.store';

export const Dashboard = () => {
	const { t } = useTranslation();
	const { examsList } = useDashboard();
	const { exams } = examsList;
	const examOptions = exams.map((e) => ({ value: e.id, label: e.name }));
	const examSnap = useSnapshot(ExamState);
	const examIdInSnap = examSnap.selectedExam?.id;
	const [selectedExamId, setSelectedExamId] = useState<number | undefined>(
		examIdInSnap
	);

	useEffect(() => {
		if (exams.length) {
			ExamState.selectedExam = exams[0];
			setSelectedExamId(exams[0].id);
		}
	}, [exams]);

	const handleExamChange = useCallback(
		(value: number) => {
			setSelectedExamId(value);
			const selectedExam = exams.find((exam) => exam.id === value);
			ExamState.selectedExam = selectedExam;
		},
		[exams]
	);

	const graphTabs = [
		{
			key: GraphTabs.REGISTRATION_GRAPH,
			label: t('registrationGraph'),
			children: <RegistrationGraph />,
		},
		{
			key: GraphTabs.RESULTS_GRAPH,
			label: t('resultsGraph'),
			children: examIdInSnap && <ExamResultsGraph />,
		},
	];

	return (
		<>
			<div className="flex items-center gap-2">
				<p className="text-base font-medium">{t('selectExam')}</p>
				<Select
					className="w-48"
					size="large"
					showSearch
					options={examOptions}
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					}
					value={selectedExamId || (examOptions.length && examOptions[0].value)}
					onChange={handleExamChange}
				/>
			</div>
			<Tabs items={graphTabs} size="large" destroyInactiveTabPane={true} />
		</>
	);
};
