import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useBatchId } from '../../../hooks/useBatchId';
import { APPLICANT_HOME } from '../../../shared/Routes';
import { PreWorkStepType } from './PreWorkStepType';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import {
	Answer,
	PreworkAnswersRequest,
	PreworkCompletionStatusType,
} from '../../../shared/VolunteerApplicationServiceTypes';
import {
	transformAnswerToGRPC,
	transformGRPCToPreworkAnswers,
} from '../../../shared/Utils';
import {
	EvaluationCategoryID,
	PreWorkQuestionId,
	PreworkCompletionStatus,
} from '../../../shared/Constants';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { useTranslation } from 'react-i18next';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	captureCandidatePreworkAnswers,
	getPreworkDataByApplication,
} from '../../../service/VolunteerApplicationService';
import { Modal } from 'antd';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';

interface Props {
	children: ReactNode;
}

export interface PreWorkContextType {
	preWorkCategoryAnswers: PreWorkStepType;
	setPreWorkCategoryAnswers: (answers: PreWorkStepType) => void;
	onSubmitClick: () => void;
	onCancelClick: () => void;
}

const PreWorkContext = createContext<PreWorkContextType>({
	preWorkCategoryAnswers: { foundationOfServingIsIntegrity: false },
	setPreWorkCategoryAnswers: () => {},
	onSubmitClick: () => {},
	onCancelClick: () => {},
});

export const PreWorkProvider: FC<Props> = ({ children }: Props) => {
	const { navigateWithBatchId } = useBatchId();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { t, i18n } = useTranslation();
	const { batchId } = useBatchId();
	const { applicationId } = useApplicationId();
	const { callApi } = useCallApi();
	const [preWorkCategoryAnswers, setPreWorkCategoryAnswers] =
		useState<PreWorkStepType>({ foundationOfServingIsIntegrity: false });

	const onConfirmSubmit = useCallback(async () => {
		const answers: Array<Answer> = [];
		const preWorkAnswers = cloneDeep(preWorkCategoryAnswers);
		delete preWorkAnswers.foundationOfServingIsIntegrity;
		answers.push(
			...transformAnswerToGRPC(EvaluationCategoryID.PRE_WORK, preWorkAnswers)
		);
		let preworkStatus = '';
		let successMessage = '';

		if (
			preWorkAnswers[`${PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION}`]
				.selectedOption === 1
		) {
			preworkStatus = PreworkCompletionStatus.COMPLETED;
			successMessage = 'completedPreWorkAndReady';
		} else if (
			preWorkAnswers[`${PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION}`]
				.selectedOption === 2
		) {
			preworkStatus = PreworkCompletionStatus.PARTIALLY_COMPLETED;
			successMessage = 'completedPreWorkAndModulesLater';
		} else if (
			preWorkAnswers[`${PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION}`]
				.selectedOption === 3
		) {
			preworkStatus = PreworkCompletionStatus.PARTIALLY_COMPLETED;
			successMessage = 'partiallyCompletedPreWorkAndModulesLater';
		}

		const request: PreworkAnswersRequest = {
			batchId,
			applicationId,
			preworkAnswers: {
				questionsAndAnswers: answers,
				language: i18n.language,
			},
			completionStatus: preworkStatus as PreworkCompletionStatusType,
		};

		try {
			await callApi(
				() => captureCandidatePreworkAnswers(request),
				'errorOccurredWhileSubmittingApplication'
			);
			Modal.success({
				title: t('preworkSubmitted'),
				content: t(successMessage),
				afterClose: () => navigateWithBatchId(APPLICANT_HOME),
			});
		} catch (e) {
			// handled in callApi
		}
	}, [
		applicationId,
		batchId,
		callApi,
		i18n.language,
		navigateWithBatchId,
		preWorkCategoryAnswers,
		t,
	]);

	const onSubmitClick = useCallback(() => {
		confirmBeforeAction(
			'confirmSubmit',
			'applicationOnceSubmittedCanNotBeChanged',
			onConfirmSubmit
		);
	}, [confirmBeforeAction, onConfirmSubmit]);

	const value = useMemo<PreWorkContextType>(() => {
		const onCancelClick = () => {
			navigateWithBatchId(APPLICANT_HOME);
		};

		return {
			preWorkCategoryAnswers,
			setPreWorkCategoryAnswers,
			onSubmitClick,
			onCancelClick,
		};
	}, [preWorkCategoryAnswers, navigateWithBatchId, onSubmitClick]);

	const fetchPreworkDataByApplication = useCallback(async () => {
		if (!applicationId) {
			showToastMessage('error', 'applicationId is missing');
		}

		setLoading(true);

		try {
			const preworkData = await getPreworkDataByApplication({
				value: applicationId,
			});
			if (
				preworkData.completionStatus !== PreworkCompletionStatus.COMPLETED ||
				preworkData.completionStatus !== PreworkCompletionStatus.NOT_STARTED
			) {
				setPreWorkCategoryAnswers(transformGRPCToPreworkAnswers(preworkData));
			}
		} catch (e) {
			// Do Nothing
		} finally {
			setLoading(false);
		}
	}, [applicationId, setLoading, showToastMessage]);

	useEffect(() => {
		void fetchPreworkDataByApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PreWorkContext.Provider value={value}>{children}</PreWorkContext.Provider>
	);
};

export const usePreWorkWizard = () => {
	return useContext(PreWorkContext);
};
