import { useTranslation } from 'react-i18next';
import { ExamForm } from './ExamForm';

export const CreateExam = () => {
	const { t } = useTranslation();

	return (
		<>
			<h2>{t('examCreation')}</h2>
			<ExamForm />
		</>
	);
};
