import axios from 'axios';
import { FIREBASE_ID_TOKEN } from '../shared/Constants';
import { getItem } from './LocalStorageService';
import {
	mySRCMBaseURL,
	xClientId,
	backendServerURL,
	myStaticProfileUpdateURL,
} from './EnvService';

export const mySRCMClient = axios.create({
	baseURL: `${mySRCMBaseURL}/api/v2`,
});

export const myStaticProfileUpdateClient = axios.create({
	baseURL: `${myStaticProfileUpdateURL}/srcmapi`,
});

mySRCMClient.interceptors.request.use((config) => {
	const idToken = getItem<string | null>(FIREBASE_ID_TOKEN, null);

	if (idToken) {
		config.headers.Authorization = `Bearer ${idToken}`;
	}

	config.headers['x-client-id'] = xClientId;
	return config;
});

export const volunteerServiceClient = axios.create({
	baseURL: `${backendServerURL}/unifiedplatform.volunteer.application.VolunteerApplicationService`,
});

volunteerServiceClient.interceptors.request.use(function (config) {
	const idToken = getItem<string | null>(FIREBASE_ID_TOKEN, null);

	if (idToken) {
		config.headers.Authorization = `Bearer ${idToken}`;
	}

	return config;
});
