import { Typography, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	ApplicantInfo,
	ApplicantReference,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useWizard } from '../../../components/wizard/useWizard';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';
import { useEffect } from 'react';

const { Title, Text } = Typography;

interface LandingPageProps {
	applicantInfo?: ApplicantInfo;
	preceptorReference?: ApplicantReference;
}

const SectionHeader = ({ heading }: { heading: string }) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col align-center">
			<Text className="mt-[24px]" type="secondary">
				{t('candidatePreceptorInterview')}
			</Text>
			<Title level={4}>{heading}</Title>
		</div>
	);
};

export const LandingPage = ({
	applicantInfo,
	preceptorReference,
}: LandingPageProps) => {
	const { t } = useTranslation();

	if (!preceptorReference) {
		return (
			<div className="flex flex-col justify-center items-center">
				<Title level={4} type="danger">
					{t('preceptorContactDetailsAreNotAvailable')}
				</Title>
				<Text>{t('pleaseCollectReferencesOfCandidate')}</Text>
			</div>
		);
	}

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<SectionHeader heading={t('preceptorInterview')} />
			<div className="flex flex-col mb-[8px]">
				<Title level={5}>{t('setContextPolitely')}</Title>
				<Text type="secondary" className="pb-[8px]">
					{t('preceptorMayITalkToYou')}
				</Text>
				<Text type="secondary" className="pb-[8px]">
					{t('preceptorThisConversationIsImportantPartOfProcess')}
				</Text>
				<Text type="secondary" className="pb-[8px]">
					{t('pleaseExcuseIfSomeQuestionsAppearSensitive')}
				</Text>
			</div>
			<Title level={5}>{t('preceptorDetails')}</Title>
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('name')}>
					{preceptorReference?.name}
				</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>
					{preceptorReference?.mobile}
				</Descriptions.Item>
			</Descriptions>
			<Title level={5}>{t('candidateDetails')}</Title>
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('firstName')}>
					{applicantInfo?.firstName}
				</Descriptions.Item>
				<Descriptions.Item label={t('middleName')}>
					{applicantInfo?.middleName}
				</Descriptions.Item>
				<Descriptions.Item label={t('lastName')}>
					{applicantInfo?.lastName}
				</Descriptions.Item>
				<Descriptions.Item label={t('idCardNo')}>
					{applicantInfo?.srcmid}
				</Descriptions.Item>
				<Descriptions.Item label={t('age')}>
					{applicantInfo?.age}
				</Descriptions.Item>
				<Descriptions.Item label={t('email')}>
					{applicantInfo?.email}
				</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>
					{applicantInfo?.mobile}
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

export const LandingPageContainer = () => {
	const { setNextButtonDisabled } = useWizard();

	const { application } = usePreceptorInterview();

	useEffect(() => {
		if (!application?.preceptorReference) {
			setNextButtonDisabled(true);
		} else {
			setNextButtonDisabled(false);
		}
	}, [application?.preceptorReference, setNextButtonDisabled]);

	return (
		<LandingPage
			applicantInfo={application?.applicant}
			preceptorReference={application?.preceptorReference}
		/>
	);
};
