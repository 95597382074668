import { Typography, Form, FormInstance, Button, Alert, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	PredefinedReasonCategory,
	ReapplyCondition,
	ReviewAction,
} from '../../../shared/Constants';
import { useApproverRecommendationPage } from './useApproverRecommendationPage';
import { InterviewResult } from '../InterviewReportCard/InterviewResult';
import {
	Application,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useMemo } from 'react';
import { canSubmitApproverFeedback } from '../../../shared/Utils';
import { RecommendationComponent } from '../RecommendationComponent';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

const { Text, Title } = Typography;

interface ApproverRecommendationCollectionPageProps {
	form: FormInstance;
	application?: Application;
}

export const ApproverRecommendationCollectionPage = ({
	form,
	application,
}: ApproverRecommendationCollectionPageProps) => {
	const { t } = useTranslation();
	const review = Form.useWatch<string | undefined>('review', form);
	const { role } = useRedirectToLogin();

	const canReApply = useMemo(() => {
		return review === ReviewAction.REJECT;
	}, [review]);

	const canDisplayComments = role === UserRole.APPROVER;

	const reApplyOptions = useMemo(() => {
		return [
			{
				value: ReapplyCondition.AVAILABLE_FROM_TOMORROW,
				label: t('inNextBatch'),
			},
			{
				value: ReapplyCondition.AFTER_3_MONTHS,
				label: t('afterThreeMonths'),
			},
			{
				value: ReapplyCondition.AFTER_6_MONTHS,
				label: t('afterSixMonths'),
			},
			{
				value: ReapplyCondition.AFTER_12_MONTHS,
				label: t('afterTwelveMonths'),
			},
		];
	}, [t]);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
				disabled={!canSubmitApproverFeedback(application)}
			>
				<InterviewResult
					answersReviewFeedback={application?.answersReviewFeedback}
					applicantInterviewFeedback={application?.applicantInterviewFeedback}
					familyMemberInterviewFeedback={
						application?.familyMemberInterviewFeedback
					}
					preceptorInterviewFeedback={application?.preceptorInterviewFeedback}
					preceptorRecommendation={application?.preceptorRecommendation}
					proposerRecommendation={application?.proposerRecommendation}
					finalRecommendationText="overallRecommendation"
				/>
				<div className="flex flex-row items-start">
					<div className="flex flex-col w-full">
						<Title level={5}>{t('regionalCommitteeDecision')}</Title>
						<Text>{t('regionalCommitteeDecidedTheApplicationToBe')}</Text>
						<RecommendationComponent
							commentsMandatory
							commentsLabel={'regionalCommitteeCommentsForAboveRecommendation'}
							commentsPlaceholder="commentsAreMandatory"
							form={form}
							category={PredefinedReasonCategory.APPROVER_REJECT}
							displayComments={canDisplayComments}
							disableReview={!canDisplayComments}
						/>
						{canReApply && (
							<>
								<Text>{t('canReApply')}</Text>
								<Form.Item
									name={['reapplyCondition']}
									rules={[
										{
											required: canReApply,
											message: <span>{t('required')}</span>,
										},
									]}
								>
									<Select
										className="flex min-w-[200px]"
										options={reApplyOptions}
									/>
								</Form.Item>
							</>
						)}
					</div>
				</div>
			</Form>
		</div>
	);
};

export const ApproverRecommendationCollectionPageContainer = () => {
	const { t } = useTranslation();
	const {
		form,
		onSubmitClick,
		onCancelClick,
		submitButtonDisabled,
		application,
	} = useApproverRecommendationPage();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<ApproverRecommendationCollectionPage
				form={form}
				application={application}
			/>
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled || !application?.isApprover}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
			{!application?.isApprover && (
				<Alert
					showIcon
					message="Only Approvers are allowed to submit regional committee decision"
					type="warning"
				/>
			)}
		</div>
	);
};
