import { useTranslation } from 'react-i18next';
import { PreceptorsTable } from '../../../components/PreceptorsTable';
import { useCreateGroup } from '../CreateGroupPage/useCreateGroup';
import { useEditGroup } from '../EditGroupPage/useEditGroup';
import { useSnapshot } from 'valtio';
import { PreceptorBatchState } from '../CreateGroupPage/PreceptorsList.store';

export const AddMorePreceptorsContainers = () => {
	const { t } = useTranslation();
	const { group } = useEditGroup();
	const groupName = group?.groupName;
	const {
		preceptorsList,
		onPreceptorsPageChange,
		onPreceptorFilterChange,
		preceptorsFilter,
		preceptorPaging,
		onCreateGroupClick,
	} = useCreateGroup();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const batchIdInSnap = preceptorBatchSnap.selectedBatch?.id;

	const existingPreceptorsIds = group?.preceptorGroupMembers.map(
		(preceptor) => preceptor.id
	);
	const existingPreceptorsCount = existingPreceptorsIds?.length;

	return (
		<div className="p-8">
			<h2>
				{t('addMorePreceptorsToGroup', { groupName, existingPreceptorsCount })}
			</h2>
			<PreceptorsTable
				data={preceptorsList}
				onPreceptorsPageChange={onPreceptorsPageChange}
				preceptorPageConfig={preceptorPaging}
				preceptorsFilter={preceptorsFilter}
				onPreceptorFilterChange={onPreceptorFilterChange}
				existingPreceptorsIdsForAddMore={existingPreceptorsIds}
				onCreateGroupClick={onCreateGroupClick}
				selectedBatchId={batchIdInSnap}
			/>
		</div>
	);
};
