import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessStep = () => {
	const { inspirationAndWillingness, setInspirationAndWillingness } =
		usePreceptorInterview();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			inspirationAndWillingness,
			setInspirationAndWillingness
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
