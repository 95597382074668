import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { State } from '../shared/MySrcm';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { getStateProfile } from '../service/MySrcmService';

const { Option } = Select;

interface OptionData {
	value?: number;
	label?: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: State | null | string) => void;
	value?: State | null;
	CountryValue: string | number;
}
const StateSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	CountryValue,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const Country = CountryValue ? CountryValue : 358;

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			const countryPage = await getStateProfile(Country, searchValue);

			if (countryPage) {
				const transformedOptions: OptionData[] = countryPage.results.map(
					(item) => ({
						value: item?.id,
						label: item?.name,
					})
				);

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}

			setLoading(false);
		},
		[Country]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	useEffect(() => {
		// Reset the value when StateValue changes
		if (onChange) {
			onChange(null);
		}
		setOptions([]);
		// eslint-disable-next-line
	}, [CountryValue]);

	const debouncedFetchData = useMemo(
		() =>
			debounce((searchValue: string) => {
				void fetchData(searchValue);
			}, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			if (searchKey && typeof searchKey === 'string') {
				void debouncedFetchData(searchKey);
			}
		},
		[debouncedFetchData]
	);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={false}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchState')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default StateSingleSelect;
