import { NotAPreceptorCard } from '../applicant/NotAPreceptorCard';
import { ApplicationsListContainer } from './ApplicationsList';
import { useFunctionaryHome } from './useFunctionaryHome';

export const FunctionaryHome = () => {
	const { messageToShow } = useFunctionaryHome();

	switch (messageToShow) {
		case 'FUNCTIONARY_IS_NOT_A_PRECEPTOR':
			return <NotAPreceptorCard />;
	}

	return <ApplicationsListContainer />;
};
