import { useCallback, useEffect } from 'react';
import { Status } from '../../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useBatchId } from '../../../../hooks/useBatchId';
import { useCreateCenter } from './useCreateCenter';
import { Button, Form, Input, Select } from 'antd';
import { EXAM_MODULE_CENTERS_LIST } from '../../../../shared/Routes';
import { NumberInput } from '../../NumberInput';
import {
	VALID_EMAIL_REGEX,
	VALID_NAME_REGEX,
	VALID_PHONE_REGEX,
} from '../../../../shared/Constants';

interface CenterFormProps {
	forUpdating?: boolean;
	forViewing?: boolean;
}

export interface CenterCreationFormType {
	centerCode: string;
	centerName: string;
	seatsCapacity: number;
	accommodationsCapacity: number;
	status: Status;
	phone: string;
	contactPersonName: string;
	email: string;
	address: string;
}

const { TextArea } = Input;

export const CenterForm = ({ forUpdating, forViewing }: CenterFormProps) => {
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const { navigateWithBatchId } = useBatchId();
	const { fetchCenterById, onSubmitClick } = useCreateCenter();
	const [form] = Form.useForm<CenterCreationFormType>();
	const formValues = Form.useWatch<CenterCreationFormType>([], form);

	const initializeForm = useCallback(async () => {
		try {
			const fetchedCenter = await fetchCenterById();
			if (fetchedCenter) {
				const initialValues: CenterCreationFormType = {
					centerCode: fetchedCenter.centerCode,
					centerName: fetchedCenter.centerName,
					seatsCapacity: fetchedCenter.seatsCapacity,
					accommodationsCapacity: fetchedCenter.accommodationsCapacity,
					status: fetchedCenter.status,
					phone: fetchedCenter.phone || '',
					contactPersonName: fetchedCenter.contactPersonName || '',
					email: fetchedCenter.email || '',
					address: fetchedCenter.address || '',
				};
				form.setFieldsValue(initialValues);
			}
		} catch (e) {
			//
		}
	}, [fetchCenterById, form]);

	useEffect(() => {
		if (forUpdating || forViewing) {
			void initializeForm();
		}
	}, [forUpdating, forViewing, initializeForm]);

	const handleSubmitClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				onSubmitClick(formValues);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [formValues, onSubmitClick, form, showToastMessage, t]);

	const handleBackClick = useCallback(() => {
		navigateWithBatchId(EXAM_MODULE_CENTERS_LIST);
	}, [navigateWithBatchId]);

	const handleClearClick = useCallback(() => {
		const statusValue = form.getFieldValue('status') as Status;
		form.resetFields();
		form.setFieldsValue({ status: statusValue });
	}, [form]);

	return (
		<Form
			id="centerCreationForm"
			name="centerCreationForm"
			layout="horizontal"
			size="middle"
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			autoComplete="off"
			form={form}
			disabled={forViewing}
		>
			<div className="flex flex-col md:flex-row justify-between">
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-wrap gap-4">
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('centerCode')}
									</span>
								}
								name="centerCode"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Input size="large" maxLength={20} />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('centerName')}
									</span>
								}
								name="centerName"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Input size="large" maxLength={255} />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('seatsAvailable')}
									</span>
								}
								name="seatsCapacity"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput max={5000} rejectZero />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('accommodationAvailable')}
									</span>
								}
								name="accommodationsCapacity"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput max={5000} />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">{t('phone')}</span>
								}
								name="phone"
								rules={[
									{
										pattern: VALID_PHONE_REGEX,
										message: <span>{t('invalidPhoneNo')}</span>,
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('contactPerson')}
									</span>
								}
								name="contactPersonName"
								rules={[
									{
										pattern: VALID_NAME_REGEX,
										message: (
											<span>{t('numbersAndSpecialCharactersNotAllowed')}</span>
										),
									},
								]}
							>
								<Input size="large" maxLength={150} />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">{t('email')}</span>
								}
								name="email"
								rules={[
									{
										pattern: VALID_EMAIL_REGEX,
										message: <span>{t('invalidEmail')}</span>,
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('centerAddress')}
									</span>
								}
								name="address"
							>
								<TextArea rows={4} />
							</Form.Item>
						</div>
						{forUpdating && (
							<div className="w-full md:w-1/4">
								<Form.Item
									className="!mb-2"
									label={
										<span className="font-bold text-dark-gray">
											{t('status')}
										</span>
									}
									name="status"
								>
									<Select
										size="large"
										options={[
											{ value: 'ACTIVE', label: t('active') },
											{ value: 'INACTIVE', label: t('inactive') },
										]}
									/>
								</Form.Item>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="flex gap-4 mt-10">
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 text-base"
						htmlType="reset"
						onClick={handleBackClick}
						disabled={false}
					>
						{t('back')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 text-base"
						onClick={handleClearClick}
						danger
					>
						{t('clear')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 bg-nav-blue hover:!bg-hover-nav-blue text-base"
						type="primary"
						htmlType="submit"
						onClick={handleSubmitClick}
					>
						{t('submit')}
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};
