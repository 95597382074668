import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useViewApplicantAnswers } from './useViewApplicantAnswers';
import { CategoryViewApplicantAnswersStep } from './CategoryViewApplicantAnswersStep';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessViewAnswersStep = () => {
	const { application } = useViewApplicantAnswers();

	const { form } = useCategoryFeedbackFormHandling();

	return (
		<CategoryViewApplicantAnswersStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
