import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { PracticeUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';

const UiConfig = PracticeUIConfig;

export const PracticeStep = () => {
	const { practice, setPractice, application } = useApplicantInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(practice, setPractice);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
			answers={application?.userAnswers}
		/>
	);
};
