import { useCallback } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { ExaminationCenterFilter } from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';

interface FilterProps {
	value: ExaminationCenterFilter;
	onFilterChange: (value: ExaminationCenterFilter) => void;
}

interface CentersFilterFormType {
	centerName?: string;
	centerCode?: string;
}

const { Text } = Typography;

export const FilterCenters = ({ value, onFilterChange }: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<CentersFilterFormType>();
	const formValues = Form.useWatch<CentersFilterFormType>([], form);

	const handleFilterClick = useCallback(() => {
		const filterValues: ExaminationCenterFilter = {
			...formValues,
			centerName: formValues?.centerName,
			centerCode: formValues?.centerCode,
		};
		const centerFilters = { ...value, ...filterValues };
		onFilterChange(centerFilters);
	}, [onFilterChange, value, formValues]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({
			centerName: undefined,
			centerCode: undefined,
		});
		const filterValues: ExaminationCenterFilter = {};
		onFilterChange(filterValues);
	}, [form, onFilterChange]);

	return (
		<div className="flex flex-col">
			<Form
				name="centersFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('centerName')}</Text>
							<Form.Item className="!mb-2" name={'centerName'}>
								<Input placeholder="" />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('centerCode')}</Text>
							<Form.Item className="!mb-2" name={'centerCode'}>
								<Input placeholder="" />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
