import { useCallback } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export const useConfirmationBeforeAction = () => {
	const { t } = useTranslation();

	const confirmBeforeAction = useCallback(
		<T,>(
			title: string,
			content: string,
			action: () => Promise<T> | void,
			customConfirm?: boolean
		) => {
			return Modal.confirm({
				title: t(title),
				content: t(content),
				onOk: action,
				okButtonProps: {
					className: `${
						customConfirm ? 'bg-nav-blue hover:!bg-hover-nav-blue' : ''
					}`,
				},
			});
		},
		[t]
	);

	return { confirmBeforeAction };
};
