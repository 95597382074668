import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	FunctionaryList,
	PagingConfig,
	ApplicationsFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { FunctionariesListState } from './FunctionariesList.store';
import { useSnapshot } from 'valtio';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAuth } from '../../../hooks/useAuth';
import { getFunctionaryList } from '../../../service/VolunteerApplicationService';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	ApplicationStatus,
	DEFAULT_PAGE_SIZE,
} from '../../../shared/Constants';
import { NavigateOptions } from 'react-router-dom';

export const useFunctionariesList = () => {
	const [functionaryList, setFunctionaryList] = useState<FunctionaryList>({
		functionary: [],
		pagination: { totalNoOfRecords: 0 },
	});
	const { initiated, srcmId } = useAuth();
	const { batchId, navigateWithBatchId, queryParams } = useBatchId();
	const filterStateSnap = useSnapshot(FunctionariesListState);
	const [paging, setPaging] = useState<PagingConfig>(() => {
		return { ...filterStateSnap.paging } as PagingConfig;
	});
	const [filter, setFilter] = useState<ApplicationsFilter>(() => {
		if (filterStateSnap.filterBy) {
			return { ...filterStateSnap.filterBy } as ApplicationsFilter;
		} else {
			return {
				status: [ApplicationStatus.FUNCTIONARY_ASSIGNED],
			};
		}
	});
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();

	const functionarySrcmIdParam = useMemo(() => {
		const functionarySIdParam = queryParams.get('functionarySrcmIdParam');
		if (typeof functionarySIdParam !== 'string') {
			return '-1';
		}

		return functionarySIdParam;
	}, [queryParams]);

	const navigateWithFunctionarySrcmId = useCallback(
		(route: string, functionarySIdParam?: string, params?: NavigateOptions) => {
			const proposerSrcmIdToUse = functionarySIdParam
				? `${functionarySIdParam}`
				: functionarySrcmIdParam;
			queryParams.set('functionarySrcmIdParam', proposerSrcmIdToUse);
			navigateWithBatchId(route, params);
		},
		[navigateWithBatchId, functionarySrcmIdParam, queryParams]
	);

	const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
		FunctionariesListState.paging = { pageNo: pageNumber, pageSize };
		setPaging({ pageNo: pageNumber, pageSize });
	}, []);

	const fetchFunctionariesList = useCallback(
		async (
			pageNumber: number,
			pageSize: number,
			applicationFilter: ApplicationsFilter
		) => {
			if (initiated && srcmId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const response = await getFunctionaryList({
						batchId,
						filter: applicationFilter,
						pageIndex,
						pageSize,
						functionarySrcmId: srcmId,
					});

					if (
						response.pagination.totalNoOfPages &&
						response.pagination.totalNoOfPages > 0 &&
						pageNumber > response.pagination.totalNoOfPages
					) {
						onPageChange(response.pagination.totalNoOfPages ?? 1, pageSize);
					} else {
						setFunctionaryList(response);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				}
				setLoading(false);
			}
		},
		[batchId, initiated, onPageChange, setLoading, showToastMessage, srcmId]
	);

	const onFilterChange = useCallback(
		(filterParam: ApplicationsFilter) => {
			FunctionariesListState.filterBy = { ...filterParam };
			setFilter(filterParam);
			onPageChange(1, paging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onPageChange, paging.pageSize]
	);

	useEffect(() => {
		void fetchFunctionariesList(
			paging.pageNo ?? 1,
			paging.pageSize ?? DEFAULT_PAGE_SIZE,
			filter
		);
	}, [fetchFunctionariesList, filter, paging]);

	return {
		functionaryList,
		onPageChange,
		filter,
		onFilterChange,
		paging,
		functionarySrcmIdParam,
		navigateWithFunctionarySrcmId,
	};
};
