import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	GetPreceptorsResponse,
	PagingConfig,
	PreceptorsFilter,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useSnapshot } from 'valtio';
import { MentorsListState } from '../CreateGroupPage/PreceptorsList.store';
import {
	COUNTRY_CODE_INDIA,
	DEFAULT_PAGE_SIZE,
} from '../../../shared/Constants';
import { useAuth } from '../../../hooks/useAuth';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	assignMentor,
	getPreceptors,
} from '../../../service/VolunteerApplicationService';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useCallApi } from '../../../hooks/useCallApi';
import { useGroupId } from '../../../hooks/useGroupId';
import { useEditGroup } from '../EditGroupPage/useEditGroup';

export const useAssignMentor = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { groupId } = useGroupId();
	const { group } = useEditGroup();
	const [mentorsList, setMentorsList] = useState<GetPreceptorsResponse>({
		preceptors: [],
		pagination: { totalNoOfRecords: 0 },
	});
	const mentorsFilterStateSnap = useSnapshot(MentorsListState);
	const [mentorPaging, setMentorPaging] = useState<PagingConfig>(() => {
		return { ...mentorsFilterStateSnap.paging } as PagingConfig;
	});
	const [mentorsFilter, setMentorsFilter] = useState<PreceptorsFilter>({
		showMentors: true,
		countryIds: [COUNTRY_CODE_INDIA],
	});

	useEffect(() => {
		const excludeIds: Array<number> = [];
		if (group && group?.mentor) {
			excludeIds.push(Number(group.mentor.id));
		}
		setMentorsFilter((mentorFilter) => ({ ...mentorFilter, excludeIds }));
	}, [group]);

	const onConfirmAssignMentor = useCallback(
		async (mentorId: number) => {
			try {
				await callApi(
					() =>
						assignMentor({
							functionarySrcmId: srcmId as string,
							preceptorGroupId: groupId,
							mentorId,
						}),
					'errorOccurredWhileAssigningMentor'
				);
				Modal.success({
					content: t('assignedMentorSuccessfully'),
					afterClose: () => {
						navigate(-1);
					},
				});
			} catch (e) {
				// Do Nothing
			}
		},
		[callApi, srcmId, t, groupId, navigate]
	);

	const onAssignMentorClick = useCallback(
		(mentorId: number) => {
			confirmBeforeAction(
				'confirmAssignMentor',
				'areYouSureToAssignMentor',
				() => onConfirmAssignMentor(mentorId)
			);
		},
		[confirmBeforeAction, onConfirmAssignMentor]
	);

	const onMentorsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			MentorsListState.paging = { pageNo: pageNumber, pageSize };
			setMentorPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onMentorFilterChange = useCallback(
		(filterParam: PreceptorsFilter) => {
			MentorsListState.filterBy = { ...filterParam };
			setMentorsFilter(filterParam);
			onMentorsPageChange(1, mentorPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onMentorsPageChange, mentorPaging.pageSize]
	);

	const fetchMentorsList = useCallback(
		async (pageNumber: number, pageSize: number, filter: PreceptorsFilter) => {
			if (srcmId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const mentorsWithoutKey = await getPreceptors({
						functionarySrcmId: srcmId,
						pageIndex,
						pageSize,
						preceptorsFilter: filter,
					});
					const mentors = {
						...mentorsWithoutKey,
						...(mentorsWithoutKey.preceptors && {
							preceptors: mentorsWithoutKey.preceptors
								.filter((mentor) => mentor.prefectStatus === 'ACTIVE')
								.map((mentor) => ({
									...mentor,
									key: mentor.id,
								})),
						}),
					};

					if (
						mentors.pagination.totalNoOfPages &&
						mentors.pagination.totalNoOfPages > 0 &&
						pageNumber > mentors.pagination.totalNoOfPages
					) {
						onMentorsPageChange(
							mentors.pagination.totalNoOfPages ?? 1,
							pageSize
						);
					} else {
						setMentorsList(mentors);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[srcmId, onMentorsPageChange, setLoading, showToastMessage]
	);

	useEffect(() => {
		void fetchMentorsList(
			mentorPaging.pageNo ?? 1,
			mentorPaging.pageSize ?? DEFAULT_PAGE_SIZE,
			mentorsFilter
		);
	}, [mentorPaging, mentorsFilter, fetchMentorsList]);

	return {
		mentorsList,
		mentorsFilter,
		onMentorFilterChange,
		onMentorsPageChange,
		mentorPaging,
		onAssignMentorClick,
	};
};
