import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { AvailabilityAfterProgramViewAnswersStep } from './AvailabilityAfterProgramViewAnswersStep';
import { BasicEligibilityViewAnswersStep } from './BasicEligibilityViewAnswersStep';
import { CharacterAndHabitsViewAnswersStep } from './CharacterAndHabitsViewAnswersStep';
import { DietaryHabitsAndHealthViewAnswersStep } from './DietaryHabitsAndHealthViewAnswersStep';
import { InspirationAndWillingnessViewAnswersStep } from './InspirationAndWillingnessViewAnswersStep';
import { PracticeViewAnswersStep } from './PracticeViewAnswersStep';
import { SupportViewAnswersStep } from './SupportViewAnswersStep';
import { UnderstandingOfHeartfulnessViewAnswersStep } from './UnderstandingOfHeartfulnessViewAnswersStep';
import {
	ViewApplicantAnswersProvider,
	useViewApplicantAnswers,
} from './useViewApplicantAnswers';

export const ViewApplicantAnswersWizard = () => {
	const { onSubmitClick, onCancelClick } = useViewApplicantAnswers();

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onCancelClick={onCancelClick}
				onSubmitClick={onSubmitClick}
				steps={[
					<BasicEligibilityViewAnswersStep key={1} />,
					<InspirationAndWillingnessViewAnswersStep key={2} />,
					<PracticeViewAnswersStep key={3} />,
					<CharacterAndHabitsViewAnswersStep key={4} />,
					<AvailabilityAfterProgramViewAnswersStep key={5} />,
					<SupportViewAnswersStep key={6} />,
					<UnderstandingOfHeartfulnessViewAnswersStep key={7} />,
					<DietaryHabitsAndHealthViewAnswersStep key={8} />,
				]}
			/>
		</div>
	);
};

export const ViewApplicantAnswersWizardContainer = () => {
	return (
		<ViewApplicantAnswersProvider>
			<WizardProvider>
				<ViewApplicantAnswersWizard />
			</WizardProvider>
		</ViewApplicantAnswersProvider>
	);
};
