import { useCallback, useState } from 'react';
import {
	Form,
	FormInstance,
	Input,
	Radio,
	RadioChangeEvent,
	Select,
	Spin,
	Typography,
} from 'antd';
import {
	FunctionaryInfo,
	UserRole,
} from '../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import { FunctionaryType, VALID_STRING_INPUT_REGEX } from '../shared/Constants';
import { useFunctionariesList } from '../pages/functionary/FunctionariesList/useFunctionariesList';
import { useRedirectToLogin } from '../hooks/useRedirectToLogin';
import { useZonesDropdown } from '../hooks/useZonesDropdown';

const { Text, Title } = Typography;

interface FunctionaryDetailsFormProps {
	form: FormInstance;
	functionary?: FunctionaryInfo;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description?: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const FunctionaryDetailsForm: React.FC<FunctionaryDetailsFormProps> = ({
	form,
	functionary,
}: FunctionaryDetailsFormProps) => {
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();
	const {
		handleZonesSearch,
		zoneoptions,
		zonesloading,
		handleHomeZoneSearch,
		homeZoneOptions,
	} = useZonesDropdown();
	const [zones, setZones] = useState<Array<{ value: string }> | undefined>([]);
	const [homeZone, setHomeZone] = useState<{ value: string } | undefined>();
	const [isCareTeam, setIsCareTeam] = useState(
		functionary?.isCareTeam || false
	);
	const { functionarySrcmIdParam } = useFunctionariesList();

	const showFunctionaryType = role === UserRole.ADMIN;
	const disableSrcmIdField = functionarySrcmIdParam !== '-1';

	const handleFunctionaryTypeChange = (e: RadioChangeEvent) => {
		setIsCareTeam(e.target.value === FunctionaryType.IS_CARE_TEAM);
	};

	const handleZoneChange = useCallback((value: Array<{ value: string }>) => {
		const zs = value.map((z) => z.value);
		const zonesList = zs?.map((s) => ({
			value: s,
		}));
		setZones(zonesList);
	}, []);

	const handleHomeZoneChange = useCallback((value: { value: string }) => {
		setHomeZone(value);
	}, []);

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={
						functionarySrcmIdParam !== '-1'
							? showFunctionaryType
								? t('editFunctinary')
								: t('editProposer')
							: showFunctionaryType
							? t('addFunctionary')
							: t('addProposer')
					}
				/>
				<Form.Item
					label={t('name')}
					name="printName"
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: VALID_STRING_INPUT_REGEX,
							message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
						},
					]}
					hasFeedback
				>
					<Input maxLength={100} />
				</Form.Item>
				<Form.Item
					label={t('email')}
					name="email"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: <span>{t('invalidEmail')}</span>,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t('phoneNo')}
					name="mobile"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: /^([+]\d{2})?\d{10}$/,
							message: <span>{t('invalidPhoneNo')}</span>,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t('SRCMID')}
					name="srcmId"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: /^[a-zA-Z0-9]+$/,
							message: <span>{t('invalidSRCMID')}</span>,
						},
					]}
				>
					<Input maxLength={20} disabled={disableSrcmIdField} />
				</Form.Item>
				<Form.Item
					label={t('gender')}
					name="gender"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
					]}
				>
					<Select
						options={[
							{ value: 'MALE', label: 'MALE' },
							{ value: 'FEMALE', label: 'FEMALE' },
						]}
					/>
				</Form.Item>
				<Form.Item label={t('homeZone')} name="homeZoneToDisplay" hasFeedback>
					<Select
						showSearch
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						placeholder={t('searchZones')}
						notFoundContent={zonesloading ? <Spin size="small" /> : null}
						onSearch={handleHomeZoneSearch}
						onChange={handleHomeZoneChange}
						labelInValue
						value={homeZone}
						options={homeZoneOptions}
					/>
				</Form.Item>
				{showFunctionaryType && (
					<Form.Item
						label={t('functionaryType')}
						name="functionaryType"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<Radio.Group onChange={handleFunctionaryTypeChange}>
							<Radio value={FunctionaryType.IS_PROPOSER}>
								{t('isProposer')}
							</Radio>
							<Radio value={FunctionaryType.IS_APPROVER}>
								{t('isApprover')}
							</Radio>
							<Radio value={FunctionaryType.IS_ZC}>{t('isZC')}</Radio>
							<Radio value={FunctionaryType.IS_CARE_TEAM}>
								{t('isCareTeam')}
							</Radio>
						</Radio.Group>
					</Form.Item>
				)}
				<Form.Item label={t('zonesAuthorized')} name="zone" hasFeedback>
					<Select
						showSearch
						mode="multiple"
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						placeholder={t('searchZones')}
						notFoundContent={zonesloading ? <Spin size="small" /> : null}
						onSearch={handleZonesSearch}
						onChange={handleZoneChange}
						labelInValue
						disabled={isCareTeam}
						value={zones}
						options={zoneoptions}
					/>
				</Form.Item>
			</Form>
		</div>
	);
};
