import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { DietaryHabitsAndHealthUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';

const UiConfig = DietaryHabitsAndHealthUIConfig;

export const DietaryHabitsAndHealthStep = () => {
	const { dietaryHabitsAndHealth, setDietaryHabitsAndHealth, application } =
		useApplicantInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			dietaryHabitsAndHealth,
			setDietaryHabitsAndHealth
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
			answers={application?.userAnswers}
		/>
	);
};
