import { useTranslation } from 'react-i18next';
import {
	FunctionaryInfo,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { Button, Descriptions } from 'antd';
import { UserSwitchOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

interface FunctionaryCardProps extends FunctionaryInfo {
	onEditClick: (functionary: FunctionaryInfo) => void;
}

export const FunctionaryCard = (props: FunctionaryCardProps) => {
	const {
		printName,
		email,
		gender,
		mobile,
		srcmId,
		isApprover,
		isCareTeam,
		isZC,
		homeZone,
		onEditClick,
	} = props;
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();

	const displayFunctionariesType = role === UserRole.ADMIN;

	const handleEditClick = useCallback(() => {
		onEditClick(props);
	}, [onEditClick, props]);

	const getFunctionaryType = useCallback(() => {
		if (isApprover) {
			return t('approver');
		} else if (isZC) {
			return t('zc');
		} else if (isCareTeam) {
			return t('careTeam');
		} else {
			return t('proposer');
		}
	}, [isApprover, isCareTeam, isZC, t]);

	return (
		<div className="flex flex-col items-center border border-solid p-[8px] rounded-lg border-slate-200">
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('name')}>{printName}</Descriptions.Item>
				<Descriptions.Item label={t('email')}>{email}</Descriptions.Item>
				<Descriptions.Item label={t('gender')}>{gender}</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>{mobile}</Descriptions.Item>
				<Descriptions.Item label={t('SRCMID')}>{srcmId}</Descriptions.Item>
				<Descriptions.Item label={t('homeZone')}>{homeZone}</Descriptions.Item>
				{displayFunctionariesType && (
					<Descriptions.Item label={t('functionaryType')}>
						{getFunctionaryType()}
					</Descriptions.Item>
				)}
			</Descriptions>
			<Button
				type="primary"
				icon={<UserSwitchOutlined />}
				onClick={handleEditClick}
			>
				{t('editOrUpdate')}
			</Button>
		</div>
	);
};
