import BannerImage from '../assets/images/volunteer-app-banner.png';
import HFNLogo from '../assets/images/heartfulness-logo.svg';
import { useTranslation } from 'react-i18next';

export const Banner = () => {
	const { t } = useTranslation();
	return (
		<div className="w-35 relative">
			<img
				src={BannerImage}
				alt="Banner"
				className="h-screen mx-auto block line-loose float-left object-contain"
			/>
			<div className="absolute left-10 mt-5">
				<img
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					src={HFNLogo}
					alt="HFN Logo"
				/>
				<p className="text-white text-left text-3xl font-semibold">
					{t('appName')}
				</p>
			</div>
		</div>
	);
};
