import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { PracticeUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';

const UiConfig = PracticeUIConfig;

export const PracticeStep = () => {
	const { practice, setPractice } = usePreceptorInterview();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(practice, setPractice);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
