import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useBatchId } from './useBatchId';
import { LOGIN } from '../shared/Routes';

export const useRedirectToLogin = () => {
	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);
	const {
		initiated,
		isLoggedIn,
		loading,
		logout,
		firebaseId,
		mySrcmProfile,
		srcmId,
		role,
	} = useAuth();
	const { navigateWithBatchId } = useBatchId();

	useEffect(() => {
		if (redirectToLogin) {
			navigateWithBatchId(LOGIN);
			return;
		}

		if (initiated) {
			if (isLoggedIn && isLoggedIn()) {
				setRedirectToLogin(false);
			} else {
				setRedirectToLogin(true);
			}
		}
	}, [isLoggedIn, initiated, redirectToLogin, navigateWithBatchId]);

	return { loading, logout, firebaseId, srcmId, mySrcmProfile, role };
};
