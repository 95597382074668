import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { PredefinedReason } from '../shared/VolunteerApplicationServiceTypes';
import { usePredefinedReasons } from '../hooks/usePredefinedReasons';

const { Option } = Select;

interface OptionData {
	value: string;
	label: string;
}

interface RemoteSingleSelectProps {
	onChange?: (reasons: Array<PredefinedReason>) => void;
	value?: Array<PredefinedReason>;
	category?: string;
	disabled?: boolean;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	category,
	disabled = false,
}: RemoteSingleSelectProps) => {
	const { t } = useTranslation();
	const { predefinedReasons } = usePredefinedReasons();

	const transformPredefinedReasons = useCallback(
		(item: PredefinedReason) => ({
			value: JSON.stringify(item),
			label: item.reasonText,
		}),
		[]
	);

	const values = useMemo(() => {
		return value?.map(transformPredefinedReasons);
	}, [transformPredefinedReasons, value]);

	const [options, setOptions] = useState<OptionData[]>();

	useEffect(() => {
		if (predefinedReasons) {
			if (category !== '') {
				const categoryReasons = predefinedReasons.filter(
					(item) => item.category === category
				);
				setOptions(categoryReasons.map(transformPredefinedReasons));
			} else {
				setOptions(predefinedReasons.map(transformPredefinedReasons));
			}
		}
	}, [category, predefinedReasons, transformPredefinedReasons]);

	const handleOnChange = useCallback(
		(e: unknown) => {
			if (onChange && e) {
				const temp = e as string[];
				onChange(temp.map((s) => JSON.parse(s) as PredefinedReason));
			}
		},
		[onChange]
	);

	return (
		<Select
			showSearch
			mode="multiple"
			defaultActiveFirstOption={false}
			showArrow={true}
			filterOption={false}
			placeholder={t('selectReason')}
			notFoundContent={predefinedReasons ? <Spin size="small" /> : null}
			onChange={handleOnChange}
			value={values}
			disabled={disabled}
		>
			{options &&
				options.map((option) => (
					// eslint-disable-next-line
					<Option key={JSON.parse(option.value).id} value={option.value}>
						{option.label}
					</Option>
				))}
		</Select>
	);
};

export default RemoteSingleSelect;
