import { useCallback, useEffect, useState } from 'react';
import {
	ApplicationSummaryList,
	ApplicationsFilter,
	PagingConfig,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useAuth } from '../../../hooks/useAuth';
import { getApplications } from '../../../service/VolunteerApplicationService';
import { useBatchId } from '../../../hooks/useBatchId';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	ApplicationStatus,
	DEFAULT_PAGE_SIZE,
} from '../../../shared/Constants';
import { useSnapshot } from 'valtio';
import { ApplicationsListState } from './ApplicationsList.store';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BatchInfoState } from '../../../components/BatchInfo.store';

export const useApplicationsList = () => {
	const [applicationsList, setApplicationsList] =
		useState<ApplicationSummaryList>({
			applicationSummary: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const { initiated, firebaseId, srcmId } = useAuth();
	const { role } = useRedirectToLogin();
	const { batchId } = useBatchId();
	const { i18n } = useTranslation();
	const filterStateSnap = useSnapshot(ApplicationsListState);
	const batchStateSnap = useSnapshot(BatchInfoState);
	const batchInfo = batchStateSnap.batchInfo;
	const [paging, setPaging] = useState<PagingConfig>(() => {
		return { ...filterStateSnap.paging } as PagingConfig;
	});
	const [filter, setFilter] = useState<ApplicationsFilter>(() => {
		if (filterStateSnap.filterBy) {
			return { ...filterStateSnap.filterBy } as ApplicationsFilter;
		} else if (role === UserRole.CARE_TEAM) {
			return {
				status: [
					ApplicationStatus.FUNCTIONARY_ASSIGNED,
					ApplicationStatus.FUNCTIONARY_SUBMITTED,
					ApplicationStatus.WAITING_FOR_CARE_TEAM,
					ApplicationStatus.APPROVED,
				],
			};
		} else if (role === UserRole.ZC) {
			return {
				status: [
					ApplicationStatus.FUNCTIONARY_ASSIGNED,
					ApplicationStatus.FUNCTIONARY_SUBMITTED,
				],
			};
		} else {
			return {
				status: [ApplicationStatus.FUNCTIONARY_ASSIGNED],
			};
		}
	});
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();

	useEffect(() => {
		if (
			(role === UserRole.ZC &&
				batchInfo?.snpResultDateForZC &&
				batchInfo.snpResultDateForZC !== '1970-01-01T00:00:00Z' &&
				moment().isSameOrAfter(batchInfo?.snpResultDateForZC)) ||
			(role === UserRole.APPROVER &&
				batchInfo?.snpResultDateForApprover &&
				batchInfo.snpResultDateForApprover !== '1970-01-01T00:00:00Z' &&
				moment().isSameOrAfter(batchInfo?.snpResultDateForApprover))
		) {
			setFilter({
				status: [ApplicationStatus.APPROVED],
			});
		} else if (role === UserRole.CARE_TEAM) {
			setFilter({
				status: [
					ApplicationStatus.FUNCTIONARY_ASSIGNED,
					ApplicationStatus.FUNCTIONARY_SUBMITTED,
					ApplicationStatus.WAITING_FOR_CARE_TEAM,
					ApplicationStatus.APPROVED,
				],
			});
		} else if (role === UserRole.ZC) {
			setFilter({
				status: [
					ApplicationStatus.FUNCTIONARY_ASSIGNED,
					ApplicationStatus.FUNCTIONARY_SUBMITTED,
				],
			});
		} else {
			setFilter({
				status: [ApplicationStatus.FUNCTIONARY_ASSIGNED],
			});
		}
	}, [
		batchInfo?.snpResultDateForApprover,
		batchInfo?.snpResultDateForZC,
		role,
	]);

	const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
		ApplicationsListState.paging = { pageNo: pageNumber, pageSize };
		setPaging({ pageNo: pageNumber, pageSize });
	}, []);

	const fetchApplicationsList = useCallback(
		async (
			pageNumber: number,
			pageSize: number,
			applicationFilter: ApplicationsFilter
		) => {
			if (initiated && firebaseId && srcmId) {
				setLoading(true);
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				try {
					const applications = await getApplications({
						batchId,
						firebaseId,
						filter: applicationFilter,
						pageIndex,
						pageSize,
						functionarySrcmId: srcmId,
						predefinedReasonLanguage: i18n.language,
					});

					if (
						applications.pagination.totalNoOfPages &&
						applications.pagination.totalNoOfPages > 0 &&
						pageNumber > applications.pagination.totalNoOfPages
					) {
						onPageChange(applications.pagination.totalNoOfPages ?? 1, pageSize);
					} else {
						setApplicationsList(applications);
					}
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				}
				setLoading(false);
			}
		},
		[
			batchId,
			firebaseId,
			initiated,
			onPageChange,
			setLoading,
			showToastMessage,
			srcmId,
			i18n.language,
		]
	);

	const onFilterChange = useCallback(
		(filterParam: ApplicationsFilter) => {
			ApplicationsListState.filterBy = { ...filterParam };
			setFilter(filterParam);
			onPageChange(1, paging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onPageChange, paging.pageSize]
	);

	useEffect(() => {
		void fetchApplicationsList(
			paging.pageNo ?? 1,
			paging.pageSize ?? DEFAULT_PAGE_SIZE,
			filter
		);
	}, [paging, filter, fetchApplicationsList]);

	return { applicationsList, onPageChange, filter, onFilterChange, paging };
};
