import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { UnderstandingOfHeartfulnessUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = UnderstandingOfHeartfulnessUIConfig;

export const UnderstandingOfHeartfulnessStep = () => {
	const {
		understandingOfHeartfulnessCategoryAnswers,
		setUnderstandingOfHeartfulnessCategoryAnswers,
	} = useNewApplication();

	const { form } = useCategoryFormHandling(
		understandingOfHeartfulnessCategoryAnswers,
		setUnderstandingOfHeartfulnessCategoryAnswers
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
