import { Form } from 'antd';
import { ApplicantAdditionalDetailsType } from '../../../components/ApplicantAdditionalDetailsType';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect } from 'react';
import { ApplicantAdditionalDetailsForm } from '../../../components/ApplicantAdditionalDetailsForm';

export const ApplicantAdditionalDetailsStep = () => {
	const { additionalApplicantDetails, setAdditionalApplicantDetails } =
		useNewApplication();
	const [form] = Form.useForm<ApplicantAdditionalDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<ApplicantAdditionalDetailsType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setNextButtonDisabled(false);
			setAdditionalApplicantDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: ApplicantAdditionalDetailsType };
			setNextButtonDisabled(true);
			setAdditionalApplicantDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setAdditionalApplicantDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(additionalApplicantDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <ApplicantAdditionalDetailsForm form={form} />;
};
