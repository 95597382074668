import { useSnapshot } from 'valtio';
import { PreceptorsTable } from '../../../components/PreceptorsTable';
import { useAssignMentor } from './useAssignMentor';
import { PreceptorBatchState } from '../CreateGroupPage/PreceptorsList.store';

export const AssignMentorContainer = () => {
	const {
		mentorsList,
		onMentorsPageChange,
		onMentorFilterChange,
		mentorsFilter,
		mentorPaging,
		onAssignMentorClick,
	} = useAssignMentor();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const batchIdInSnap = preceptorBatchSnap.selectedBatch?.id;

	return (
		<div className="p-8">
			<PreceptorsTable
				data={mentorsList}
				onPreceptorsPageChange={onMentorsPageChange}
				preceptorPageConfig={mentorPaging}
				preceptorsFilter={mentorsFilter}
				onPreceptorFilterChange={onMentorFilterChange}
				isMentorsTable={true}
				onAssignMentorClick={onAssignMentorClick}
				selectedBatchId={batchIdInSnap}
			/>
		</div>
	);
};
