import { Spin } from 'antd';
import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface IndicatorProps {
	loading: boolean;
	text?: string | null;
}

export interface LoadingIndicatorType {
	setLoading: (loading: boolean, text?: string) => void;
}

interface Props {
	children: ReactNode;
}

const LoadingIndicatorContext = createContext<LoadingIndicatorType>({
	setLoading: () => {},
});

export const LoadingIndicatorProvider: FC<Props> = ({ children }: Props) => {
	const { t } = useTranslation();
	const [indicatorProps, setIndicatorProps] = useState<IndicatorProps>({
		loading: false,
		text: 'Loading...',
	});

	const setLoading = useCallback(
		(loading: boolean, text?: string) => {
			setIndicatorProps({ loading, text: text ?? t('loading') });
		},
		[t]
	);

	const value = useMemo<LoadingIndicatorType>(() => {
		return {
			setLoading,
		};
	}, [setLoading]);

	return (
		<LoadingIndicatorContext.Provider value={value}>
			<Spin
				className={`min-h-screen flex justify-center items-center ${
					indicatorProps.loading
						? 'fixed inset-0 bg-white bg-opacity-75 z-50'
						: ''
				}`}
				spinning={indicatorProps.loading}
				tip={indicatorProps.text}
			>
				{children}
			</Spin>
		</LoadingIndicatorContext.Provider>
	);
};

export const useLoadingIndicator = () => {
	return useContext(LoadingIndicatorContext);
};
