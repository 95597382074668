import { useTranslation } from 'react-i18next';
import { useBatchId } from '../../../hooks/useBatchId';
import { useViewHeldCandidates } from './useViewHeldCandidates';
import moment from 'moment';
import { DATE_DO_MMM_YYYY } from '../../../shared/Constants';
import { Button, Modal, Table } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { EXAM_MODULE_EXAMS_LIST } from '../../../shared/Routes';
import { HeldCandidateTableColumns } from './HeldCandidateColumns';
import { tablePaginationConfiguration } from '../../../shared/Utils';
import { useCallback } from 'react';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { CandidateExaminationDetail } from '../../../shared/ExamModuleTypes';
import { FilterHeldCandidates } from './FilterHeldCandidates';

export const ViewHeldCandidates = () => {
	const { navigateWithBatchId } = useBatchId();
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const {
		approveCandidate,
		examById,
		heldCandidatesFilter,
		heldCandidateExamDetailList,
		heldCandidateExamDetailPaging,
		onHeldCandidateExamDetailsPageChange,
		onHeldCandidateExamDetailsFilterChange,
		callFetchHeldCandidateExamDetailList,
	} = useViewHeldCandidates();
	const { candidateExamDetails, pagination } = heldCandidateExamDetailList;
	const tablePaginationConfig = tablePaginationConfiguration(
		heldCandidateExamDetailPaging,
		pagination,
		onHeldCandidateExamDetailsPageChange
	);

	const isCandidatesListEmpty =
		candidateExamDetails === undefined ||
		(candidateExamDetails.length && candidateExamDetails.length === 0)
			? true
			: false;

	const onApproveSucess = useCallback(() => {
		void callFetchHeldCandidateExamDetailList();
	}, [callFetchHeldCandidateExamDetailList]);

	const handleApproveClick = useCallback(
		(candidateExamDetail: CandidateExaminationDetail) => {
			approveCandidate(candidateExamDetail.id)
				.then((isCandidateApproved) => {
					if (isCandidateApproved) {
						const candidateName = candidateExamDetail.profile.name;
						Modal.success({
							title: t('approved'),
							content: t('approveCandidateMessage', { candidateName }),
							okText: t('ok'),
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
							onOk: onApproveSucess,
						});
					}
				})
				.catch((error: Error) => {
					showToastMessage('error', error.message);
				});
		},
		[approveCandidate, showToastMessage, t, onApproveSucess]
	);

	const columns = HeldCandidateTableColumns(
		handleApproveClick,
		moment().isBefore(examById?.examDate)
	);

	const DisplayHeldCandidates = () => {
		return (
			<>
				{examById && (
					<FilterHeldCandidates
						value={heldCandidatesFilter}
						onFilterChange={onHeldCandidateExamDetailsFilterChange}
						exambyId={examById}
						disableFilters={isCandidatesListEmpty}
					/>
				)}
				<Table
					dataSource={candidateExamDetails}
					columns={columns}
					pagination={tablePaginationConfig}
					scroll={{ x: 'max-content' }}
					className="overflow-x-auto mt-4"
				/>
			</>
		);
	};

	return (
		<>
			<div className="flex justify-between items-center">
				<div className="flex gap-6 mb-4">
					<h3>
						{t('examGroup')}: {examById?.name}
					</h3>
					<h3>
						{t('examDate')}:{' '}
						{examById?.examDate &&
							moment(examById?.examDate).format(DATE_DO_MMM_YYYY)}
					</h3>
				</div>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={() => navigateWithBatchId(EXAM_MODULE_EXAMS_LIST)}
					icon={<ArrowLeftOutlined />}
				>
					{t('back')}
				</Button>
			</div>
			<DisplayHeldCandidates />
		</>
	);
};
