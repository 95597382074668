import { useState, useCallback, useEffect } from 'react';
import { ApplicantAdditionalDetailsType } from '../../../components/ApplicantAdditionalDetailsType';
import {
	transformAdditionalDetailsToGRPC,
	transformGRPCApplicationToAdditionalDetails,
} from '../../../shared/Utils';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { Form, Modal } from 'antd';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { Application } from '../../../shared/VolunteerApplicationServiceTypes';
import { useCallApi } from '../../../hooks/useCallApi';
import { updateAdditionalDetailsOfApplicant } from '../../../service/VolunteerApplicationService';
import { useAuth } from '../../../hooks/useAuth';
import { INTERVIEW_LANDING } from '../../../shared/Routes';
import { useTranslation } from 'react-i18next';

export const useApplicantAdditionalDetailsPage = () => {
	const [additionalApplicantDetails, setAdditionalApplicantDetails] =
		useState<ApplicantAdditionalDetailsType>({});
	const [submitButtonDisabled, setSubmitButtonDisabled] =
		useState<boolean>(false);
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const [form] = Form.useForm<ApplicantAdditionalDetailsType>();
	const values = Form.useWatch<ApplicantAdditionalDetailsType>([], form);
	const [application, setApplication] = useState<Application>();
	const { fetchApplication, applicationId, navigateWithApplicationId } =
		useApplicationId();
	const { callApi } = useCallApi();
	const { srcmId } = useAuth();
	const { t } = useTranslation();

	const onConfirmSubmit = useCallback(async () => {
		const additionalDetails = transformAdditionalDetailsToGRPC(
			additionalApplicantDetails,
			application?.preceptorReference
		);

		try {
			await callApi(
				() =>
					updateAdditionalDetailsOfApplicant({
						additionalDetails,
						applicationId,
						functionarySrcmId: srcmId as string,
					}),
				'errorOccurredWhileSubmitting'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(INTERVIEW_LANDING),
			});
		} catch (r) {
			// Do Nothing
		}
	}, [
		additionalApplicantDetails,
		application?.preceptorReference,
		applicationId,
		callApi,
		navigateWithApplicationId,
		srcmId,
		t,
	]);

	const onSubmitClick = useCallback(() => {
		confirmBeforeAction(
			'confirmSubmit',
			'areYouSureYouWantToSubmit',
			onConfirmSubmit
		);
	}, [confirmBeforeAction, onConfirmSubmit]);

	const onCancelClick = useCallback(() => {
		navigateWithApplicationId(INTERVIEW_LANDING);
	}, [navigateWithApplicationId]);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setAdditionalApplicantDetails(fieldValues);
			setSubmitButtonDisabled(false);
		} catch (e) {
			const fields = e as {
				values: ApplicantAdditionalDetailsType;
				outOfDate: boolean;
			};

			if (!fields.outOfDate) {
				setAdditionalApplicantDetails(fields.values);
				setSubmitButtonDisabled(true);
			}
		}
	}, [form, setAdditionalApplicantDetails]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	const loadApplication = useCallback(async () => {
		try {
			const fetchedApplication = await fetchApplication();
			const fetchedAdditionalDetails =
				transformGRPCApplicationToAdditionalDetails(fetchedApplication);

			setAdditionalApplicantDetails(fetchedAdditionalDetails);
			form.setFieldsValue(fetchedAdditionalDetails);
			setApplication(fetchedApplication);
		} catch (e) {
			//
		}
	}, [fetchApplication, form]);

	useEffect(() => {
		void loadApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return {
		form,
		submitButtonDisabled,
		onSubmitClick,
		onCancelClick,
		application,
	};
};
