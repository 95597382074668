import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { BasicEligibilityUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = BasicEligibilityUIConfig;

export const BasicEligibilityAnswersReviewStep = () => {
	const { eligibilityCriteria, setEligibilityCriteria, application } =
		useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		eligibilityCriteria,
		setEligibilityCriteria
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
