import { CheckCircleFilled, SolutionOutlined } from '@ant-design/icons';
import { Button, Collapse, Tag, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const { Text } = Typography;

interface InterviewSectionProps {
	title: string;
	isCompleted?: boolean;
	description: string;
	buttonText: string;
	onButtonClick: () => void;
}

const Header = ({
	title,
	isCompleted,
}: {
	title: string;
	isCompleted?: boolean;
}) => {
	const {
		token: { green6, orange5 },
	} = theme.useToken();

	const { t } = useTranslation();

	const icon = isCompleted ? (
		<CheckCircleFilled style={{ fontSize: '16px', color: green6 }} />
	) : (
		<SolutionOutlined style={{ fontSize: '16px', color: orange5 }} />
	);

	return (
		<div className="flex flex-row items-center">
			<Text>{t(title)}</Text>
			<Tag color={isCompleted ? 'green' : 'orange'} className="mx-[8px]">
				{isCompleted ? t('done') : t('pending')}
			</Tag>
			{icon}
		</div>
	);
};

export const InterviewSection = ({
	title,
	description,
	isCompleted,
	buttonText,
	onButtonClick,
}: InterviewSectionProps) => {
	const { t } = useTranslation();

	return (
		<Collapse collapsible="header">
			<Panel
				header={<Header title={title} isCompleted={isCompleted} />}
				key="1"
			>
				<div className="flex flex-col">
					<Text>{t(description)}</Text>
					<div className="mt-[8px]">
						<Button type="primary" block={false} onClick={onButtonClick}>
							{t(buttonText)}
						</Button>
					</div>
				</div>
			</Panel>
		</Collapse>
	);
};
