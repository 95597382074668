import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessAnswersReviewStep = () => {
	const {
		inspirationAndWillingness,
		setInspirationAndWillingness,
		application,
	} = useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		inspirationAndWillingness,
		setInspirationAndWillingness
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
