import {
	CategoryReviewFeedback,
	Feedback,
} from '../../../shared/VolunteerApplicationServiceTypes';
import {
	AvailabilityAfterProgramUIConfig,
	CharacterAndHabitsUIConfig,
	DietaryHabitsAndHealthUIConfig,
	InspirationAndWillingnessUIConfig,
	PracticeUIConfig,
	SupportUIConfig,
	UnderstandingOfHeartfulnessUIConfig,
} from '../../../shared/CategoryStepUIConfig';
import { CategoryScore, FinalRecommendationScore } from './CategoryScore';

interface InterviewResultProps {
	answersReviewFeedback?: CategoryReviewFeedback;
	applicantInterviewFeedback?: CategoryReviewFeedback;
	preceptorInterviewFeedback?: CategoryReviewFeedback;
	familyMemberInterviewFeedback?: CategoryReviewFeedback;
	otherReferenceInterviewFeedback?: CategoryReviewFeedback;
	preceptorRecommendation?: Feedback;
	proposerRecommendation?: Feedback;
	finalRecommendationText?: string;
}

export const InterviewResult = ({
	answersReviewFeedback,
	applicantInterviewFeedback,
	preceptorInterviewFeedback,
	familyMemberInterviewFeedback,
	preceptorRecommendation,
	proposerRecommendation,
	finalRecommendationText = 'preceptorRecommendation',
}: InterviewResultProps) => {
	return (
		<div>
			<CategoryScore
				name={InspirationAndWillingnessUIConfig.title}
				self={answersReviewFeedback?.inspirationAndWillingness}
				applicantInterview={
					applicantInterviewFeedback?.inspirationAndWillingness
				}
				preceptorInterview={
					preceptorInterviewFeedback?.inspirationAndWillingness
				}
			/>
			<CategoryScore
				name={PracticeUIConfig.title}
				self={answersReviewFeedback?.practice}
				applicantInterview={applicantInterviewFeedback?.practice}
				preceptorInterview={preceptorInterviewFeedback?.practice}
			/>
			<CategoryScore
				name={CharacterAndHabitsUIConfig.title}
				self={answersReviewFeedback?.characterAndHabits}
				applicantInterview={applicantInterviewFeedback?.characterAndHabits}
				preceptorInterview={preceptorInterviewFeedback?.characterAndHabits}
				familyMemberInterview={
					familyMemberInterviewFeedback?.characterAndHabits
				}
			/>
			<CategoryScore
				name={AvailabilityAfterProgramUIConfig.title}
				self={answersReviewFeedback?.availabilityAfterProgram}
				applicantInterview={
					applicantInterviewFeedback?.availabilityAfterProgram
				}
				preceptorInterview={
					preceptorInterviewFeedback?.availabilityAfterProgram
				}
			/>
			<CategoryScore
				name={SupportUIConfig.title}
				self={answersReviewFeedback?.support}
				applicantInterview={applicantInterviewFeedback?.support}
				preceptorInterview={preceptorInterviewFeedback?.support}
				familyMemberInterview={familyMemberInterviewFeedback?.support}
			/>
			<CategoryScore
				name={UnderstandingOfHeartfulnessUIConfig.title}
				self={answersReviewFeedback?.understandingOfHeartfulness}
				applicantInterview={
					applicantInterviewFeedback?.understandingOfHeartfulness
				}
				preceptorInterview={
					preceptorInterviewFeedback?.understandingOfHeartfulness
				}
			/>
			<CategoryScore
				name={
					DietaryHabitsAndHealthUIConfig.functionaryTitle ||
					DietaryHabitsAndHealthUIConfig.title
				}
				applicantInterview={applicantInterviewFeedback?.dietaryHabitsAndHealth}
				familyMemberInterview={
					familyMemberInterviewFeedback?.dietaryHabitsAndHealth
				}
			/>
			<FinalRecommendationScore
				preceptor={preceptorRecommendation}
				proposer={proposerRecommendation}
				name={finalRecommendationText}
			/>
		</div>
	);
};
