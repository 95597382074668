import { useTranslation } from 'react-i18next';
import { CenterForm } from './CenterForm';

export const CreateCenter = () => {
	const { t } = useTranslation();

	return (
		<>
			<h2>{t('centerCreation')}</h2>
			<CenterForm />
		</>
	);
};
