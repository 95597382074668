import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { LoadingIndicatorProvider } from './hooks/useLoadingIndicator';
import { ToastMessageProvider } from './hooks/useToastMessage';
import './index.css';
import './i18n';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<StrictMode>
		<BrowserRouter>
			<LoadingIndicatorProvider>
				<ToastMessageProvider>
					<AuthProvider>
						<App />
					</AuthProvider>
				</ToastMessageProvider>
			</LoadingIndicatorProvider>
		</BrowserRouter>
	</StrictMode>
);
