import { useTranslation } from 'react-i18next';
import { useEditGroup } from './useEditGroup';
import { GroupPreceptorsTable } from '../../../components/GroupPreceptorsTable';

export const EditGroupContainer = () => {
	const { t } = useTranslation();
	const { group } = useEditGroup();
	const groupName = group?.groupName;
	const preceptorGroupMembersWithoutKey = group?.preceptorGroupMembers;
	const preceptorGroupMembers =
		preceptorGroupMembersWithoutKey &&
		preceptorGroupMembersWithoutKey.map((preceptor) => ({
			...preceptor,
			key: preceptor.id,
		}));

	return (
		<div className="p-8">
			<h2>{t('editGroupWithGroupName', { groupName })}</h2>
			<GroupPreceptorsTable
				groupPreceptors={preceptorGroupMembers}
				isEditGroupTable={true}
			/>
		</div>
	);
};
