import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EXAM_REGISTRATION } from '../../shared/Routes';
import { ApplicationApprovedCard } from './ApplicationApprovedCard';
import { ApplicationInReviewCard } from './ApplicationInReviewCard';
import { BatchClosedCard } from './BatchClosedCard';
import { ExamFailedCard } from './ExamFailedCard';
import { ExamPassedCard } from './ExamPassedCard';
import { InvalidBatchCard } from './InvalidBatchCard';
import { NewApplicationWizardContainer } from './NewApplicationWizard';
import { NotAllowedCard } from './NotAllowedCard';
import { ReApplyAfterSometimeCard } from './ReApplyAfterSometimeCard';
import { useApplicantHome } from './useApplicantHome';
import useQueryParams from '../../hooks/useQueryParams';

export const ApplicantHome = () => {
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const batchId = useMemo(() => {
		const batchIdParam = Number(queryParams.get('batchId'));

		if (typeof batchIdParam !== 'number') {
			return -1;
		}

		return batchIdParam;
	}, [queryParams]);
	const { messageToShow, showNewApplicationWizard } = useApplicantHome();

	const getRouteWithBatchId = useCallback(
		(route: string) => {
			queryParams.set('batchId', `${batchId}`);
			return `${route}?${queryParams.toString()}`;
		},
		[batchId, queryParams]
	);

	useEffect(() => {
		if (typeof messageToShow === 'object') {
			const examPhase =
				messageToShow?.candidateModule?.candidateExamPhaseDetail;
			const module = messageToShow.candidateModule?.module;

			if (
				module === 'EXAM_MODULE' &&
				(!examPhase?.registeredForExam ||
					(examPhase.registeredForExam &&
						!examPhase.candidateExaminationDetail.examResultStatus))
			) {
				navigate(getRouteWithBatchId(EXAM_REGISTRATION), {
					state: {
						candidateExamPhaseDetail: examPhase,
					},
				});
			}
		}
	}, [messageToShow, navigate, getRouteWithBatchId]);

	switch (messageToShow) {
		case 'BATCH_CLOSED':
		case 'BATCH_NOT_STARTED_YET':
			return <BatchClosedCard />;

		case 'INVALID_BATCH':
			return <InvalidBatchCard />;

		case 'PAST_APPLICATION_UNDER_REVIEW':
			return <ApplicationInReviewCard />;

		case 'ALREADY_A_PRECEPTOR':
			return <NotAllowedCard />;
	}

	const examDetail =
		messageToShow?.candidateModule?.candidateExamPhaseDetail
			.candidateExaminationDetail;

	if (examDetail?.examResultStatus === 'CLEARED') {
		return <ExamPassedCard candidateExamDetail={examDetail} />;
	} else if (examDetail?.examResultStatus === 'NOT_CLEARED') {
		return <ExamFailedCard candidateExamDetail={examDetail} />;
	}

	if (messageToShow?.pastApplicationId) {
		return (
			<ApplicationApprovedCard
				pastApplicationId={messageToShow.pastApplicationId}
				preworkCompletionStatus={messageToShow.preworkCompletionStatus}
			/>
		);
	}

	if (messageToShow?.pastApplicationRejectedReasons) {
		return (
			<ReApplyAfterSometimeCard
				allowed={messageToShow?.allowed}
				reApplyDate={messageToShow?.reapplyDate}
				pastApplicationRejectedReasons={
					messageToShow.pastApplicationRejectedReasons
				}
				pastApplicationBatch={messageToShow.pastApplicationBatch}
				currentBatch={messageToShow.currentBatch}
			/>
		);
	}

	return <>{showNewApplicationWizard && <NewApplicationWizardContainer />}</>;
};
