import { useCallback, useMemo, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import { useAuth } from './useAuth';
import { searchZones } from '../service/VolunteerApplicationService';

interface OptionData {
	value: string;
	label: string;
}

export const useZonesDropdown = () => {
	const { srcmId } = useAuth();
	const [zonesloading, setZonesLoading] = useState(false);
	const [zoneoptions, setZoneOptions] = useState<OptionData[]>([]);
	const [homeZoneOptions, setHomeZoneOptions] = useState<OptionData[]>([]);

	const fetchData = useCallback(
		async (searchValue: string) => {
			setZonesLoading(true);

			if (srcmId) {
				const zonesPage = await searchZones({
					functionarySrcmId: srcmId,
					pageIndex: 0,
					pageSize: 10,
					searchKey: searchValue,
					includeOnlyZonesOfApprover: true,
				});

				if (zonesPage.zones) {
					const transformedOptions: OptionData[] = zonesPage.zones.map(
						(item: string) => ({
							value: item,
							label: item,
						})
					);

					setZoneOptions(transformedOptions);
					setZonesLoading(false);
					return;
				}
			}

			setZoneOptions([]);
			setZonesLoading(false);
		},
		[srcmId]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleZonesSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	const fetchHomeZoneData = useCallback(
		async (searchValue: string) => {
			setZonesLoading(true);

			if (srcmId) {
				const zonesPage = await searchZones({
					functionarySrcmId: srcmId,
					pageIndex: 0,
					pageSize: 10,
					searchKey: searchValue,
					includeOnlyZonesOfApprover: true,
					displayAllZones: true,
				});

				if (zonesPage.zones) {
					const transformedOptions: OptionData[] = zonesPage.zones.map(
						(item: string) => ({
							value: item,
							label: item,
						})
					);

					setHomeZoneOptions(transformedOptions);
					setZonesLoading(false);
					return;
				}
			}

			setHomeZoneOptions([]);
			setZonesLoading(false);
		},
		[srcmId]
	);

	const debouncedHomeZoneData = useMemo(
		() => debounce(fetchHomeZoneData, 500),
		[fetchHomeZoneData]
	);

	const handleHomeZoneSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedHomeZoneData(searchKey as string);
		},
		[debouncedHomeZoneData]
	);

	return {
		handleZonesSearch,
		handleHomeZoneSearch,
		zoneoptions,
		homeZoneOptions,
		zonesloading,
	};
};
