import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { CharacterAndHabitsUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';

const UiConfig = CharacterAndHabitsUIConfig;

export const CharacterAndHabitsStep = () => {
	const { characterAndHabits, setCharacterAndHabits } = usePreceptorInterview();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(characterAndHabits, setCharacterAndHabits);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
