import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';
import * as firebaseui from 'firebaseui';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAuth } from './useAuth';

export const useFirebaseSignIn = (
	elementId: string,
	onLoginFailure: (message: string) => void
): { mountUI: () => void } => {
	const uiRef = useRef<firebaseui.auth.AuthUI>();
	const { onLogin } = useAuth();

	const uiConfig = useMemo(
		() => ({
			signInFlow: 'popup',
			signInOptions: [
				{
					provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
					defaultCountry: 'IN',
				},
				{
					provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
					requireDisplayName: true,
				},
				firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				{
					provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
					scopes: ['public_profile', 'email'],
				},
			],
			credentialHelper: 'none',
			callbacks: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				signInSuccessWithAuthResult: (authResult: any) => {
					if (onLogin) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						void onLogin(authResult);
					}
					return false;
				},
				signInFailure: (e: firebaseui.auth.AuthUIError) => {
					onLoginFailure(e.message);
				},
				uiShown: () => {},
			},
			tosUrl: 'https://heartfulness.org/in/terms/',
			privacyPolicyUrl: 'https://heartfulness.org/in/privacy-policy/',
		}),
		[onLogin, onLoginFailure]
	);

	const mountUI = useCallback(() => {
		if (uiRef.current) uiRef.current.start(elementId, uiConfig);
	}, [elementId, uiConfig]);

	useEffect(() => {
		uiRef.current =
			firebaseui.auth.AuthUI.getInstance() ??
			new firebaseui.auth.AuthUI(firebase.auth());
	}, []);

	return { mountUI };
};
