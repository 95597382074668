import { Typography, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';
import { useWizard } from '../../../components/wizard/useWizard';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';
import { useCallback, useEffect } from 'react';
import { RecommendationComponent } from '../RecommendationComponent';
import {
	PredefinedReasonCategory,
	ReviewAction,
} from '../../../shared/Constants';

const { Text, Title } = Typography;

interface PreceptorRecommendationStepProps {
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]">{description}</Text>
		</div>
	);
};

export const PreceptorRecommendationStep = ({
	form,
}: PreceptorRecommendationStepProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={t('collectPreceptorRecommendation')}
					description={t('askPreceptorForHisOverallRecommendation')}
				/>
				<RecommendationComponent
					commentsMandatory
					commentsLabel="commentsFromPreceptorToSupportAboveRecommendation"
					commentsPlaceholder="commentsAreMandatory"
					form={form}
					category={PredefinedReasonCategory.PRECEPTOR_REJECT}
				/>
				<div className="py-[24px]">
					<Text className="font-medium">
						{t('preceptorWillReceiveAnEmail')}
					</Text>
				</div>
			</Form>
		</div>
	);
};

export const PreceptorRecommendationStepContainer = () => {
	const [form] = Form.useForm<Feedback>();
	const { setSubmitButtonDisabled } = useWizard();
	const values = Form.useWatch<Feedback>([], form);
	const { preceptorRecommendation, setPreceptorRecommendation } =
		usePreceptorInterview();

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setPreceptorRecommendation(fieldValues);
			if (
				fieldValues.review === ReviewAction.REJECT &&
				!fieldValues.predefinedReasons?.length
			) {
				setSubmitButtonDisabled(true);
			} else {
				setSubmitButtonDisabled(false);
			}
		} catch (e) {
			const fields = e as { values: Feedback };
			setSubmitButtonDisabled(true);
			setPreceptorRecommendation(fields.values);
		}
	}, [form, setPreceptorRecommendation, setSubmitButtonDisabled]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(preceptorRecommendation);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <PreceptorRecommendationStep form={form} />;
};
