import { CheckCircleFilled, SolutionOutlined } from '@ant-design/icons';
import { Button, Descriptions, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	ApplicationSummary,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useApplicationStatusTranslation } from '../../../hooks/useApplicationStatusTranslation';
import { useCallback, useMemo } from 'react';
import { ReviewerResultTag } from '../ReviewerResultTag';
import { ReviewAction } from '../../../shared/Constants';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import moment from 'moment';
import { useSnapshot } from 'valtio';
import { BatchInfoState } from '../../../components/BatchInfo.store';

const { Text } = Typography;

const InterviewNotStarted = () => {
	const { t } = useTranslation();
	return (
		<div className="w-[260px]">
			<Text type="danger">{t('interviewNotStarted')}</Text>
		</div>
	);
};

const InterviewItem = ({
	completed,
	text,
}: {
	completed: boolean;
	text: string;
}) => {
	const {
		token: { green6, orange5 },
	} = theme.useToken();
	const icon = completed ? (
		<CheckCircleFilled style={{ fontSize: '16px', color: green6 }} />
	) : (
		<SolutionOutlined style={{ fontSize: '16px', color: orange5 }} />
	);

	return (
		<div className="flex flex-row items-center">
			{icon}
			<Text className="pl-[8px]">{text}</Text>
		</div>
	);
};

const InterviewProgress = ({
	candidateInterviewCompleted = false,
	preceptorInterviewCompleted = false,
	familyMemberInterviewCompleted = false,
	otherReferenceInterviewCompleted = false,
	applicantAnswersReviewed = false,
}: {
	applicantAnswersReviewed?: boolean;
	candidateInterviewCompleted?: boolean;
	preceptorInterviewCompleted?: boolean;
	familyMemberInterviewCompleted?: boolean;
	otherReferenceInterviewCompleted?: boolean;
}) => {
	const { t } = useTranslation();

	if (
		!candidateInterviewCompleted &&
		!preceptorInterviewCompleted &&
		!familyMemberInterviewCompleted &&
		!otherReferenceInterviewCompleted &&
		!applicantAnswersReviewed
	) {
		return <InterviewNotStarted />;
	}
	return (
		<div className="grid grid-cols-2 grid-rows-3 gap-[16px] w-[260px]">
			<InterviewItem
				completed={applicantAnswersReviewed}
				text={t('application')}
			/>
			<InterviewItem
				completed={candidateInterviewCompleted}
				text={t('candidate')}
			/>
			<InterviewItem
				completed={preceptorInterviewCompleted}
				text={t('preceptor')}
			/>
			<InterviewItem
				completed={familyMemberInterviewCompleted}
				text={t('family')}
			/>
			<InterviewItem
				completed={otherReferenceInterviewCompleted}
				text={t('otherReference')}
			/>
		</div>
	);
};

interface ApplicationSummaryCardProps extends ApplicationSummary {
	onEvaluateClick: (application: ApplicationSummary) => void;
	onVerifyResultsClick: (application: ApplicationSummary) => void;
}

export const ApplicationSummaryCard = (props: ApplicationSummaryCardProps) => {
	const {
		applicant: { firstName, middleName, lastName, srcmid, gender },
		center,
		applicationStatus,
		proposerName,
		proposerSrcmId,
		applicantAnswersReviewed,
		familyMemberInterviewDone,
		applicantInterviewDone,
		preceptorInterviewDone,
		otherReferenceInterviewDone,
		preceptorRecommendation = { review: ReviewAction.REJECT },
		proposerRecommendation = { review: ReviewAction.APPROVE },
		careTeamRecommendation = { review: ReviewAction.APPROVE },
		zcRecommendation = { review: ReviewAction.APPROVE },
		onEvaluateClick,
		onVerifyResultsClick,
	} = props;
	const { t } = useTranslation();
	const { getTranslatedValue } = useApplicationStatusTranslation();
	const { role } = useRedirectToLogin();
	const batchStateSnap = useSnapshot(BatchInfoState);
	const batchInfo = batchStateSnap.batchInfo;
	const handleEvaluateClick = useCallback(() => {
		onEvaluateClick(props);
	}, [onEvaluateClick, props]);
	const handleVerifyResultsClick = useCallback(() => {
		onVerifyResultsClick(props);
	}, [onVerifyResultsClick, props]);
	const isInterviewDone = useMemo(() => {
		if (
			['USER_SUBMITTED_APPLICATION', 'FUNCTIONARY_ASSIGNED'].includes(
				applicationStatus
			)
		) {
			return false;
		} else {
			return true;
		}
	}, [applicationStatus]);
	const showVerifyResults =
		(role === UserRole.ZC &&
			batchInfo?.snpResultDateForZC &&
			batchInfo.snpResultDateForZC !== '1970-01-01T00:00:00Z' &&
			moment().isSameOrAfter(batchInfo?.snpResultDateForZC)) ||
		(role === UserRole.APPROVER &&
			batchInfo?.snpResultDateForApprover &&
			batchInfo.snpResultDateForApprover !== '1970-01-01T00:00:00Z' &&
			moment().isSameOrAfter(batchInfo?.snpResultDateForApprover));

	return (
		<div className="flex flex-col items-center border border-solid p-[8px] rounded-lg border-slate-200">
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('name')}>{`${firstName ?? ''} ${
					middleName ?? ''
				} ${lastName ?? ''}`}</Descriptions.Item>
				<Descriptions.Item label={t('idCardNo')}>{srcmid}</Descriptions.Item>
				<Descriptions.Item label={t('center')}>{center.name}</Descriptions.Item>
				<Descriptions.Item label={t('gender')}>{gender}</Descriptions.Item>
				<Descriptions.Item label={t('zone')}>{center.zone}</Descriptions.Item>
				{role !== UserRole.CARE_TEAM && (
					<>
						{role !== UserRole.ZC && (
							<Descriptions.Item label={t('applicationStatus')}>
								{getTranslatedValue(applicationStatus)}
							</Descriptions.Item>
						)}
						<Descriptions.Item label={t('proposerName')}>
							{proposerName}
						</Descriptions.Item>
						<Descriptions.Item label={t('proposerSRCMID')}>
							{proposerSrcmId}
						</Descriptions.Item>
						{!isInterviewDone && (
							<Descriptions.Item label={t('interviewProgress')}>
								<InterviewProgress
									applicantAnswersReviewed={applicantAnswersReviewed}
									candidateInterviewCompleted={applicantInterviewDone}
									preceptorInterviewCompleted={preceptorInterviewDone}
									familyMemberInterviewCompleted={familyMemberInterviewDone}
									otherReferenceInterviewCompleted={otherReferenceInterviewDone}
								/>
							</Descriptions.Item>
						)}
					</>
				)}
				{isInterviewDone && role !== UserRole.CARE_TEAM && (
					<Descriptions.Item label={t('recommendation')}>
						<div className="flex flex-col">
							<div className="flex flex-col">
								<ReviewerResultTag
									reviewer="preceptorRecommendation"
									result={preceptorRecommendation}
								/>
							</div>
							<div className="flex flex-col mt-[8px]">
								<ReviewerResultTag
									reviewer="proposerRecommendation"
									result={proposerRecommendation}
								/>
							</div>
							<div className="flex flex-col mt-[8px]">
								<ReviewerResultTag
									reviewer="careTeamRecommendation"
									result={careTeamRecommendation}
								/>
							</div>
							<div className="flex flex-col mt-[8px]">
								<ReviewerResultTag
									reviewer="ZCRecommendation"
									result={zcRecommendation}
								/>
							</div>
						</div>
					</Descriptions.Item>
				)}
			</Descriptions>

			{showVerifyResults === true ? (
				<Button
					type="primary"
					icon={<SolutionOutlined />}
					onClick={handleVerifyResultsClick}
				>
					{t('verifyResults')}
				</Button>
			) : (
				<Button
					type="primary"
					icon={<SolutionOutlined />}
					onClick={handleEvaluateClick}
				>
					{t('evaluate')}
				</Button>
			)}
		</div>
	);
};
