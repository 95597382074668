import { Typography, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicantInfo } from '../../../shared/VolunteerApplicationServiceTypes';

const { Title, Text } = Typography;

interface ApplicantAnswersLandingPageProps {
	applicantInfo?: ApplicantInfo;
}

const SectionHeader = ({ heading }: { heading: string }) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col align-center mb-[8px]">
			<Text className="mt-[24px]" type="secondary">
				{t('candidateApplicationReview')}
			</Text>
			<Title level={4}>{heading}</Title>
		</div>
	);
};

export const ApplicantAnswersReviewLandingPage = ({
	applicantInfo,
}: ApplicantAnswersLandingPageProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<SectionHeader heading={t('applicantAnswersReview')} />
			<div className="flex flex-col mb-[8px]">
				<Text className="pb-[8px]" type="secondary">
					{t('followingAreAnswersProvidedByCandidate')}
				</Text>
				<Text type="secondary">
					{t('reviewTheAnswersInDifferentCategories')}
				</Text>
			</div>
			<Title level={5}>{t('candidateDetails')}</Title>
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="my-[8px] min-w-[360px]"
			>
				<Descriptions.Item label={t('firstName')}>
					{applicantInfo?.firstName}
				</Descriptions.Item>
				<Descriptions.Item label={t('middleName')}>
					{applicantInfo?.middleName}
				</Descriptions.Item>
				<Descriptions.Item label={t('lastName')}>
					{applicantInfo?.lastName}
				</Descriptions.Item>
				<Descriptions.Item label={t('idCardNo')}>
					{applicantInfo?.srcmid}
				</Descriptions.Item>
				<Descriptions.Item label={t('age')}>
					{applicantInfo?.age}
				</Descriptions.Item>
				<Descriptions.Item label={t('email')}>
					{applicantInfo?.email}
				</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>
					{applicantInfo?.mobile}
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};
