import { ExclamationCircleFilled } from '@ant-design/icons';
import { Card, theme } from 'antd';
import { useTranslation } from 'react-i18next';

export const NotAPreceptorCard = () => {
	const { t } = useTranslation();
	const {
		token: { red5 },
	} = theme.useToken();

	return (
		<Card
			title={
				<div className="flex row items-center">
					<div className="pr-[8px]">
						<ExclamationCircleFilled
							style={{ fontSize: '16px', color: red5 }}
						/>
					</div>
					<div className="pl-[8px]">{t('notAPreceptor')}</div>
				</div>
			}
		>
			<div className="flex row items-center">
				<div className="pl-[8px]">
					<p>{t('allFunctionariesShouldBePreceptors')}</p>
				</div>
			</div>
		</Card>
	);
};
