import { Space, TableColumnsType, Tooltip } from 'antd';
import { CandidateExaminationDetail } from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const HeldCandidateTableColumns = (
	hanldeApproveClick: (candidateExamDetail: CandidateExaminationDetail) => void,
	displayApprove: boolean
): TableColumnsType<CandidateExaminationDetail> => {
	const { t } = useTranslation();

	const handleApproveCandidateClick = useCallback(
		(candidate: CandidateExaminationDetail) => {
			hanldeApproveClick(candidate);
		},
		[hanldeApproveClick]
	);

	return [
		{
			title: t('abhyasiId'),
			dataIndex: 'srcmId',
			key: 'abhyasiId',
			render: (__, record, index) => (
				<span key={index}>{record?.profile.srcmId}</span>
			),
		},
		{
			title: t('name'),
			dataIndex: 'profile.name',
			key: 'name',
			render: (__, record, index) => (
				<Tooltip title={record?.profile.name} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record?.profile.name}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('testCenter'),
			dataIndex: 'center',
			key: 'testCenter',
			render: (__, record, index) => (
				<span key={index}>{record?.examinationCenter.centerName}</span>
			),
		},
		{
			title: t('candidateCenter'),
			dataIndex: 'profile.center',
			key: 'candidateCenter',
			render: (__, record, index) => (
				<span key={index}>{record?.profile.center}</span>
			),
		},
		{
			title: t('examLanguage'),
			dataIndex: 'languages',
			key: 'languages',
			render: (__, record, index) => (
				<span key={index}>{record?.preferredLanguageForExamination}</span>
			),
		},
		{
			title: t('reason'),
			dataIndex: 'reason',
			key: 'reason',
			render: (__, record, index) => (
				<Tooltip title={record?.onHoldReason} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record?.onHoldReason}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (__, record, index) => (
				<span key={index}>
					{record?.registrationStatus === 'REGISTERED'
						? t('registered')
						: t('notRegistered')}
				</span>
			),
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					{displayApprove && (
						<a
							className="underline hover:underline-offset-4"
							onClick={() => handleApproveCandidateClick(record)}
						>
							{t('approve')}
						</a>
					)}
				</Space>
			),
		},
	];
};
