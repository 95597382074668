import { CategoryApplicantAnswersReviewStep } from './CategoryApplicantAnswersReviewStep';
import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { CharacterAndHabitsUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantAnswersReview } from './useApplicantAnswersReview';

const UiConfig = CharacterAndHabitsUIConfig;

export const CharacterAndHabitsAnswersReviewStep = () => {
	const { characterAndHabits, setCharacterAndHabits, application } =
		useApplicantAnswersReview();

	const { form } = useCategoryFeedbackFormHandling(
		characterAndHabits,
		setCharacterAndHabits
	);

	return (
		<CategoryApplicantAnswersReviewStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
