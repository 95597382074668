import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
	useEffect,
} from 'react';
import {
	ApplicantReference,
	Application,
	Feedback,
	FunctionaryFeedbackRequest,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { submitPreceptorInterviewFeedback } from '../../../service/VolunteerApplicationService';
import { useApplicationId } from '../../../hooks/useApplicationId';
import { useAuth } from '../../../hooks/useAuth';
import { INTERVIEW_LANDING } from '../../../shared/Routes';
import { useInterviewWizard } from '../useInterviewWizard';
import { Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReviewAction } from '../../../shared/Constants';
import {
	addMandatoryQuestionsToFunctionaryFeedback,
	transformToAnswersForMandatoryQuestionsFromGRPC,
} from '../../../shared/Utils';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

export interface PreceptorInterviewContextType {
	inspirationAndWillingness: Feedback;
	setInspirationAndWillingness: (feedback: Feedback) => void;
	practice: Feedback;
	setPractice: (feedback: Feedback) => void;
	characterAndHabits: Feedback;
	setCharacterAndHabits: (feedback: Feedback) => void;
	availabilityAfterProgram: Feedback;
	setAvailabilityAfterProgram: (feedback: Feedback) => void;
	support: Feedback;
	setSupport: (feedback: Feedback) => void;
	understandingOfHeartfulness: Feedback;
	setUnderstandingOfHeartfulness: (feedback: Feedback) => void;
	dietaryHabitsAndHealth: Feedback;
	setDietaryHabitsAndHealth: (feedback: Feedback) => void;
	application: Partial<Application>;
	preceptorRecommendation: Feedback;
	setPreceptorRecommendation: (feedback: Feedback) => void;
	preceptorReference: ApplicantReference;
	setPreceptorReference: (reference: ApplicantReference) => void;
	onSubmitClick: () => void;
	onCancelClick: () => void;
}

interface Props {
	children: ReactNode;
}

const PreceptorInterviewContext = createContext<PreceptorInterviewContextType>({
	inspirationAndWillingness: {},
	setInspirationAndWillingness: () => {},
	practice: {},
	setPractice: () => {},
	characterAndHabits: {},
	setCharacterAndHabits: () => {},
	availabilityAfterProgram: {},
	setAvailabilityAfterProgram: () => {},
	support: {},
	setSupport: () => {},
	understandingOfHeartfulness: {},
	setUnderstandingOfHeartfulness: () => {},
	dietaryHabitsAndHealth: {},
	setDietaryHabitsAndHealth: () => {},
	preceptorRecommendation: {},
	setPreceptorRecommendation: () => {},
	application: {},
	preceptorReference: {},
	setPreceptorReference: () => {},
	onSubmitClick: () => {},
	onCancelClick: () => {},
});

export const PreceptorInterviewProvider: FC<Props> = ({ children }: Props) => {
	const { t, i18n } = useTranslation();
	const { srcmId } = useAuth();
	const [inspirationAndWillingness, setInspirationAndWillingness] =
		useState<Feedback>({});
	const [practice, setPractice] = useState<Feedback>({});
	const [characterAndHabits, setCharacterAndHabits] = useState<Feedback>({});
	const [availabilityAfterProgram, setAvailabilityAfterProgram] =
		useState<Feedback>({});
	const [support, setSupport] = useState<Feedback>({});
	const [understandingOfHeartfulness, setUnderstandingOfHeartfulness] =
		useState<Feedback>({});
	const [dietaryHabitsAndHealth, setDietaryHabitsAndHealth] =
		useState<Feedback>({});
	const [preceptorRecommendation, setPreceptorRecommendation] =
		useState<Feedback>({});
	const [application, setApplication] = useState<Partial<Application>>({});
	const [preceptorReference, setPreceptorReference] = useState<
		Partial<ApplicantReference>
	>({});
	const {
		applicationId,
		navigateWithApplicationId,
		fetchApplication: callFetchApplication,
	} = useApplicationId();
	const { confirmAndSubmit, onCancelClick, callApi } = useInterviewWizard();
	const { role } = useRedirectToLogin();

	const saveFeedback = useCallback(async () => {
		const feedback: FunctionaryFeedbackRequest = {
			applicationId,
			categoryReviewFeedback: {
				availabilityAfterProgram,
				characterAndHabits,
				inspirationAndWillingness,
				practice,
				support,
				understandingOfHeartfulness,
				dietaryHabitsAndHealth,
			},
			feedbackCategory: 'PRECEPTOR_INTERVIEW',
			functionarySrcmId: srcmId as string,
		};

		const feedbackWithMandatoryQuestionsAndAnswers =
			addMandatoryQuestionsToFunctionaryFeedback(feedback, i18n.language);

		const request = {
			feedback: feedbackWithMandatoryQuestionsAndAnswers,
			recommendation: preceptorRecommendation,
			preceptorReference,
		};

		if (preceptorRecommendation.review === ReviewAction.APPROVE) {
			request.recommendation.predefinedReasons = [];
		}

		try {
			await callApi(
				() => submitPreceptorInterviewFeedback(request),
				'errorOccurredWhileSavingFeedback'
			);
			Modal.success({
				content: t('successfullySubmitted'),
				afterClose: () => navigateWithApplicationId(INTERVIEW_LANDING),
			});
		} catch (e) {
			/* empty */
		}
	}, [
		applicationId,
		availabilityAfterProgram,
		callApi,
		characterAndHabits,
		dietaryHabitsAndHealth,
		i18n.language,
		inspirationAndWillingness,
		navigateWithApplicationId,
		practice,
		preceptorRecommendation,
		preceptorReference,
		srcmId,
		support,
		t,
		understandingOfHeartfulness,
	]);

	const onSubmitClick = useCallback(() => {
		confirmAndSubmit(saveFeedback);
	}, [confirmAndSubmit, saveFeedback]);

	const value = useMemo<PreceptorInterviewContextType>(() => {
		return {
			inspirationAndWillingness,
			setInspirationAndWillingness,
			practice,
			setPractice,
			characterAndHabits,
			setCharacterAndHabits,
			availabilityAfterProgram,
			setAvailabilityAfterProgram,
			support,
			setSupport,
			understandingOfHeartfulness,
			setUnderstandingOfHeartfulness,
			dietaryHabitsAndHealth,
			setDietaryHabitsAndHealth,
			application,
			preceptorRecommendation,
			setPreceptorRecommendation,
			preceptorReference,
			setPreceptorReference,
			onSubmitClick,
			onCancelClick,
		};
	}, [
		inspirationAndWillingness,
		practice,
		characterAndHabits,
		availabilityAfterProgram,
		support,
		understandingOfHeartfulness,
		dietaryHabitsAndHealth,
		application,
		preceptorRecommendation,
		preceptorReference,
		onSubmitClick,
		onCancelClick,
	]);

	const fetchApplication = useCallback(async () => {
		try {
			const fetchedApplication = await callFetchApplication();

			const preceptorInterviewFeedbackWithAnswersForMandatoryQuestions =
				transformToAnswersForMandatoryQuestionsFromGRPC(
					fetchedApplication.preceptorInterviewFeedback
				);

			setInspirationAndWillingness(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.inspirationAndWillingness ??
					{}
			);
			setPractice(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.practice ??
					{}
			);
			setAvailabilityAfterProgram(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.availabilityAfterProgram ??
					{}
			);
			setCharacterAndHabits(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.characterAndHabits ??
					{}
			);
			setSupport(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.support ??
					{}
			);
			setUnderstandingOfHeartfulness(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.understandingOfHeartfulness ??
					{}
			);
			setDietaryHabitsAndHealth(
				preceptorInterviewFeedbackWithAnswersForMandatoryQuestions?.dietaryHabitsAndHealth ??
					{}
			);
			setPreceptorRecommendation(fetchedApplication.preceptorRecommendation);
			setPreceptorReference(fetchedApplication.preceptorReference);
			setApplication(fetchedApplication);
		} catch (e) {
			// Do Nothing
		}
	}, [callFetchApplication]);

	useEffect(() => {
		void fetchApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PreceptorInterviewContext.Provider value={value}>
			{application?.isApprover && (
				<Alert
					showIcon
					message="Approvers are not allowed to submit their interview feedback"
					type="warning"
				/>
			)}
			{role === UserRole.ZC && (
				<Alert
					showIcon
					message="ZC is not allowed to submit the interview feedback"
					type="warning"
				/>
			)}
			{children}
		</PreceptorInterviewContext.Provider>
	);
};

export const usePreceptorInterview = () => {
	return useContext(PreceptorInterviewContext);
};
