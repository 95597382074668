import { Form, Input, Radio, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import { AnswerOptionsField } from '../../../shared/Constants';
import { RuleObject } from 'antd/es/form';

const { Text } = Typography;

export interface MultipleChoiceQuestionAnswer {
	selectedOption: number;
	comments?: string;
}

export interface MultipleChoiceQuestionProps {
	uiConfig: MultipleChoiceQuestionUIConfig;
	value?: MultipleChoiceQuestionAnswer;
}

export const MultipleChoiceQuestion = ({
	uiConfig: {
		questionNo,
		questionId,
		questionText,
		answerOptionsField,
		answerOptions,
		required,
		displayAnswerOptionsVertically = false,
		hideComments = false,
		commentsText,
		disabled = false,
	},
}: MultipleChoiceQuestionProps) => {
	const { t } = useTranslation();

	const radios = answerOptions.map((ao) => (
		<Radio value={ao.optionId} key={ao.optionId}>
			{t(ao.optionText)}
		</Radio>
	));

	const selectOptions = answerOptions.map((ao) => ({
		value: ao.optionId,
		label: t(ao.optionText),
	}));

	const checkForEmptySpaces = (_: RuleObject, value: string) => {
		if (value && value.trim() === '') {
			return Promise.reject(new Error('Empty spaces are not allowed.'));
		}
		return Promise.resolve();
	};

	return (
		<div className="flex flex-row items-start">
			<Text className="mr-[8px] min-w-[24px]">{`${questionNo})`}</Text>
			<div className="flex flex-col w-full">
				<Text className="mb-[8px]">{t(questionText)}</Text>
				<Form.Item
					name={[questionId, 'selectedOption']}
					rules={[
						{
							required,
							message: <span>{t('answerIsRequired')}</span>,
						},
					]}
				>
					{answerOptionsField === AnswerOptionsField.RADIO && (
						<Radio.Group
							className={`flex ${
								displayAnswerOptionsVertically ? 'flex-col' : 'inline-flex'
							}`}
							disabled={disabled}
						>
							{radios}
						</Radio.Group>
					)}
					{answerOptionsField === AnswerOptionsField.SELECT && (
						<Select options={selectOptions} disabled={disabled} />
					)}
				</Form.Item>
				{!hideComments && (
					<>
						<Text>{commentsText ? t(commentsText) : t('comments')}</Text>
						<Form.Item
							name={[questionId, 'comments']}
							rules={[{ validator: checkForEmptySpaces }]}
						>
							<Input.TextArea showCount rows={4} disabled={disabled} />
						</Form.Item>
					</>
				)}
			</div>
		</div>
	);
};
