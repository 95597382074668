import { useTranslation } from 'react-i18next';
import { CenterForm } from './CenterForm';

export const UpdateCenter = () => {
	const { t } = useTranslation();
	return (
		<>
			<h2>{t('centerUpdation')}</h2>
			<CenterForm forUpdating />
		</>
	);
};
