import { SendEmail } from '../send-email/SendEmail';

export const SendResultsEmail = () => {
	return (
		<>
			<h1>Results Email</h1>
			<SendEmail templateKey="EXAM_MODULE_RESULT_EMAIL_TEMPLATE" />
		</>
	);
};
