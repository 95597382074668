import { useCallback } from 'react';
import { Button, Divider } from 'antd';
import { Banner } from '../../components/Banner';
import HFNBlackLogo from '../../assets/images/hfn-black-logo.svg';
import { useRedirectToLogin } from '../../hooks/useRedirectToLogin';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import {
	GROUPS,
	FUNCTIONARY_HOME,
	EXAM_MODULE_HOME,
} from '../../shared/Routes';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { useGroupId } from '../../hooks/useGroupId';
import { GroupTabs } from '../../shared/Constants';

export const SelectModule = () => {
	const { t } = useTranslation();
	const { role, logout } = useRedirectToLogin();
	const { navigateWithBatchIdAndGroupTab } = useGroupId();
	const isAdmin = role === UserRole.ADMIN;
	let roleName;
	if (role === UserRole.APPROVER) {
		roleName = 'AC';
	} else if (role === UserRole.ZC) {
		roleName = UserRole.ZC;
	} else if (role === UserRole.ADMIN) {
		roleName = 'Admin';
	}

	const handleScreeningClick = useCallback(() => {
		navigateWithBatchIdAndGroupTab(FUNCTIONARY_HOME);
	}, [navigateWithBatchIdAndGroupTab]);

	const handleNewPreceptorSupportClick = useCallback(() => {
		navigateWithBatchIdAndGroupTab(GROUPS, GroupTabs.CREATE_GROUP);
	}, [navigateWithBatchIdAndGroupTab]);

	const handleExamModuleClick = useCallback(() => {
		navigateWithBatchIdAndGroupTab(EXAM_MODULE_HOME);
	}, [navigateWithBatchIdAndGroupTab]);

	const handleLogoutClick = useCallback(() => {
		if (logout) {
			void logout();
		}
	}, [logout]);

	return (
		<>
			<Banner />
			<div className="relative flex flex-col items-center justify-center h-screen w-65">
				<div className="relative z-10 flex flex-col items-center">
					<img src={HFNBlackLogo} alt="HFN Logo" />
					<div className="text-2xl font-bold text-nav-blue">
						{t('welcomeRCAC', { roleName })}
					</div>
					<Divider className="h-1 w-2/5 bg-nav-blue" />
					<div className="flex flex-col items-center w-full">
						<Button
							className="w-full mb-5"
							type="primary"
							icon={<LoginOutlined />}
							size="large"
							onClick={handleScreeningClick}
						>
							{t('screening')}
						</Button>
						<Button
							className="w-full mb-5"
							type="primary"
							danger
							icon={<LoginOutlined />}
							size="large"
							onClick={handleNewPreceptorSupportClick}
						>
							{t('newPreceptorSupport')}
						</Button>
						{isAdmin && (
							<Button
								className="w-full mb-5 bg-green hover:!bg-hover-green"
								type="primary"
								icon={<LoginOutlined />}
								size="large"
								onClick={handleExamModuleClick}
							>
								{t('examModule')}
							</Button>
						)}
						<Button
							className="w-full mb-5"
							icon={<LogoutOutlined />}
							size="large"
							onClick={handleLogoutClick}
						>
							{t('logout')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
