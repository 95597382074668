import { FC } from 'react';
import { useRedirectToLogin } from '../hooks/useRedirectToLogin';
import { MeProfile } from '../shared/MySrcm';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';

type InjectedProps = {
	logout?: () => Promise<void>;
	firebaseId?: string | null;
	srcmId?: string;
	mySrcmProfile?: MeProfile | null;
	loading?: boolean;
	role?: UserRole;
};

export function withRedirectToLogin<T>(
	WrappedComponent: FC<InjectedProps & T>
) {
	return function HOC(props: T) {
		const { logout, firebaseId, srcmId, mySrcmProfile, loading, role } =
			useRedirectToLogin();
		return (
			<WrappedComponent
				{...props}
				logout={logout}
				firebaseId={firebaseId}
				srcmId={srcmId}
				mySrcmProfile={mySrcmProfile}
				loading={loading}
				role={role}
			/>
		);
	};
}
