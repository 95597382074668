import { useState, useCallback, useEffect } from 'react';
import { Application } from '../../../shared/VolunteerApplicationServiceTypes';
import { useApplicationId } from '../../../hooks/useApplicationId';

export const useInterviewLandingPage = () => {
	const [application, setApplication] = useState<Partial<Application>>({});
	const {
		applicationId,
		navigateWithApplicationId,
		fetchApplication: callFetchApplication,
	} = useApplicationId();

	const navigateToPage = useCallback(
		(route: string) => {
			navigateWithApplicationId(route);
		},
		[navigateWithApplicationId]
	);

	const fetchApplication = useCallback(async () => {
		try {
			const fetchedApplication = await callFetchApplication();
			setApplication(fetchedApplication);
		} catch (e) {
			// DO Nothing
		}
	}, [callFetchApplication]);

	useEffect(() => {
		void fetchApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applicationId]);

	return { application, navigateToPage };
};
