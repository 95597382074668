import React, { useEffect } from 'react';
import { Checkbox, Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TermsAndConditions } from './TermsAndConditionsType';
import { useWizard } from '../../../components/wizard/useWizard';
import { useNewApplication } from './useNewApplication';

const { Title, Text } = Typography;

const SectionHeader = ({ heading }: { heading: string }) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px] text-justify">
				{t('weExpressOurGratitude')}
			</Text>
			<Text className="mb-[8px] text-justify">
				{t('shouldYouHaveAnyConcern')}{' '}
				<a href="mailto:concerns.pcms@heartfulness.org">{t('concernsEmail')}</a>
			</Text>
		</div>
	);
};

export const TermsAndConditionStep: React.FC<{ form: FormInstance }> = ({
	form,
}: {
	form: FormInstance;
}) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader heading={t('termsAndConditions')} />
				<Form.Item
					name="iAmAwareThatThisProcessLeadsToTraining"
					valuePropName="checked"
				>
					<Checkbox>{t('iAmAwareThatThisProcessLeadsToTraining')}</Checkbox>
				</Form.Item>
				<Form.Item name="iAgreeToTermsAndConditions" valuePropName="checked">
					<Checkbox>
						{`${t('iHaveReadAndAgree')}`}{' '}
						<a
							href="https://heartfulness.org/en/terms/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('termsOfUse')}
						</a>{' '}
						{t('and')}{' '}
						<a
							href="https://heartfulness.org/en/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('privacyPolicy')}
						</a>
					</Checkbox>
				</Form.Item>
				<Form.Item name="iConsentToUndertakeTraining" valuePropName="checked">
					<Checkbox>{t('iConsentToUndertakeTraining')}</Checkbox>
				</Form.Item>
				<Form.Item name="iCertifyAllDetailsAreTrue" valuePropName="checked">
					<Checkbox>{t('iCertifyAllDetailsAreTrue')}</Checkbox>
				</Form.Item>
			</Form>
		</div>
	);
};

export const TermsAndConditionStepContainer = () => {
	const [form] = Form.useForm<TermsAndConditions>();
	const { setSubmitButtonDisabled } = useWizard();
	const values = Form.useWatch<TermsAndConditions>([], form);
	const { termsAndConditions, setTermsAndConditions } = useNewApplication();

	useEffect(() => {
		if (values) {
			const areAllTermsAndConditionsAgreed =
				(values.iAgreeToTermsAndConditions &&
					values.iAmAwareThatThisProcessLeadsToTraining &&
					values.iConsentToUndertakeTraining &&
					values.iCertifyAllDetailsAreTrue) as boolean;
			setSubmitButtonDisabled(!areAllTermsAndConditionsAgreed);
			setTermsAndConditions(values);
		} else {
			setSubmitButtonDisabled(true);
		}
	}, [setSubmitButtonDisabled, setTermsAndConditions, values]);

	useEffect(() => {
		form.setFieldsValue(termsAndConditions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <TermsAndConditionStep form={form} />;
};
