import { message } from 'antd';
import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useCallback,
} from 'react';

export type MessageType = 'info' | 'success' | 'error' | 'warning' | 'loading';

export interface ToastMessageType {
	showToastMessage: (messageType: MessageType, content: string) => void;
}

interface Props {
	children: ReactNode;
}

const ToastMessageContext = createContext<ToastMessageType>({
	showToastMessage: () => {},
});

export const ToastMessageProvider: FC<Props> = ({ children }: Props) => {
	const [messageApi, contextHolder] = message.useMessage();

	const showToastMessage = useCallback(
		(messageType: MessageType, content: string) => {
			void messageApi.open({
				type: messageType,
				content,
				duration: 5,
			});
		},
		[messageApi]
	);

	const value = useMemo<ToastMessageType>(() => {
		return {
			showToastMessage,
		};
	}, [showToastMessage]);

	return (
		<ToastMessageContext.Provider value={value}>
			{contextHolder}
			{children}
		</ToastMessageContext.Provider>
	);
};

export const useToastMessage = () => {
	return useContext(ToastMessageContext);
};
