import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface WizardContextType {
	nextButtonDisabled: boolean;
	setNextButtonDisabled: (disabled: boolean) => void;
	submitButtonDisabled: boolean;
	setSubmitButtonDisabled: (disabled: boolean) => void;
	cancelButtonDisabled: boolean;
	setCancelButtonDisabled: (disabled: boolean) => void;
	submitButtonText: string;
	setSubmitButtonText: (buttonText: string) => void;
}

interface Props {
	children: ReactNode;
}

const WizardContext = createContext<WizardContextType>({
	nextButtonDisabled: false,
	setNextButtonDisabled: () => {},
	submitButtonDisabled: false,
	setSubmitButtonDisabled: () => {},
	cancelButtonDisabled: false,
	setCancelButtonDisabled: () => {},
	submitButtonText: '',
	setSubmitButtonText: () => {},
});

export const WizardProvider: FC<Props> = ({ children }: Props) => {
	const { t } = useTranslation();
	const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
	const [submitButtonDisabled, setSubmitButtonDisabled] =
		useState<boolean>(false);
	const [cancelButtonDisabled, setCancelButtonDisabled] =
		useState<boolean>(false);
	const [submitButtonText, setSubmitButtonText] = useState<string>(
		`${t('submit')}`
	);

	const value = useMemo<WizardContextType>(() => {
		return {
			nextButtonDisabled,
			setNextButtonDisabled,
			submitButtonDisabled,
			setSubmitButtonDisabled,
			cancelButtonDisabled,
			setCancelButtonDisabled,
			submitButtonText,
			setSubmitButtonText,
		};
	}, [
		cancelButtonDisabled,
		nextButtonDisabled,
		submitButtonDisabled,
		submitButtonText,
	]);

	return (
		<WizardContext.Provider value={value}>{children}</WizardContext.Provider>
	);
};

export const useWizard = () => {
	return useContext(WizardContext);
};
