import { Form, FormInstance, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';
import useApplicantReferenceFormHandling from './useApplicantReferenceFormHandling';

const { Text, Title } = Typography;

interface ApplicantAdditionalDetailsFormProps {
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col mb-[8px]">
			<Text className="mt-[24px]" type="secondary">
				{t('candidatePreceptorInterview')}
			</Text>
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const PreceptorEmailAndSRCMIDCollectionStep: React.FC<
	ApplicantAdditionalDetailsFormProps
> = ({ form }: ApplicantAdditionalDetailsFormProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={t('preceptorContact')}
					description={t('collectSRCMIDAndEmail')}
				/>
				<Form.Item label={t('preceptorName')} name="name">
					<Input disabled />
				</Form.Item>
				<Form.Item label={t('preceptorMobileNo')} name="mobile">
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={t('preceptorEmail')}
					name="email"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: <span>{t('invalidEmail')}</span>,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label={t('preceptorSRCMID')}
					name="srcmid"
					hasFeedback
					rules={[
						{
							required: true,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: /^[a-zA-Z0-9]+$/,
							message: <span>{t('invalidSRCMID')}</span>,
						},
					]}
				>
					<Input maxLength={20} />
				</Form.Item>
			</Form>
		</div>
	);
};

export const PreceptorEmailAndSRCMIDCollectionStepContainer = () => {
	const { preceptorReference, setPreceptorReference } = usePreceptorInterview();

	const { form } = useApplicantReferenceFormHandling(
		preceptorReference,
		setPreceptorReference
	);

	return <PreceptorEmailAndSRCMIDCollectionStep form={form} />;
};
