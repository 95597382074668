import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { getRegionsOfLoggedInPreceptor } from '../service/VolunteerApplicationService';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { Region } from '../shared/VolunteerApplicationServiceTypes';
import { useSnapshot } from 'valtio';
import { PreceptorBatchState } from '../pages/nps/CreateGroupPage/PreceptorsList.store';

const { Option } = Select;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: OptionData) => void;
	value?: OptionData | null | undefined;
	functionarySrcmId?: string;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	functionarySrcmId,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			let regionsPage;

			if (functionarySrcmId && preceptorBatchSnap.selectedBatch?.id) {
				regionsPage = await getRegionsOfLoggedInPreceptor({
					functionarySrcmId,
					preceptorBatchId: preceptorBatchSnap.selectedBatch?.id,
				});
			}

			if (regionsPage?.regions) {
				const transformedOptions: OptionData[] = regionsPage.regions
					.filter((item: Region) =>
						item.regionName?.toLowerCase().includes(searchValue.toLowerCase())
					)
					.map((item: Region) => ({
						value: item.regionId,
						label: item.regionName,
					}));

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}
			setLoading(false);
		},
		[functionarySrcmId, preceptorBatchSnap.selectedBatch?.id]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={true}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchRegions')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default RemoteSingleSelect;
