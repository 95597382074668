import { useCallback, useState } from 'react';
import { Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { CandidateExaminationDetail } from '../../../shared/ExamModuleTypes';

interface HoldCandidateModalProps {
	openModal: boolean;
	onClose: () => void;
	onSubmit: (reason: string) => void;
	candidateExaminationDetail: CandidateExaminationDetail;
}

export const HoldCandidateModal = ({
	openModal,
	onClose,
	onSubmit,
	candidateExaminationDetail,
}: HoldCandidateModalProps) => {
	const { t } = useTranslation();
	const [holdReason, setHoldReason] = useState('');
	const [error, setError] = useState('');
	const candidateName = candidateExaminationDetail?.profile.name;

	const handleOk = useCallback(() => {
		if (!holdReason) {
			const errorMsg = t('reasonRequired');
			setError(errorMsg);
		} else {
			setError('');
			onSubmit(holdReason);
			onClose();
		}
	}, [holdReason, onClose, onSubmit, t]);

	const handleCancel = useCallback(() => {
		setError('');
		onClose();
	}, [onClose]);

	return (
		<Modal
			title={t('holdCandidate')}
			open={openModal}
			onOk={handleOk}
			onCancel={handleCancel}
			okText={t('hold')}
			cancelText={t('cancel')}
			okButtonProps={{ className: 'bg-nav-blue hover:!bg-hover-nav-blue' }}
		>
			<p>{t('holdCandidateMessage', { candidateName })}</p>
			<div className="ml-1 mb-0">
				<label className="block text-gray-700">
					{t('reason')} <span className="text-red-500">*</span>
				</label>
			</div>
			<Input.TextArea
				value={holdReason}
				onChange={(e) => {
					setHoldReason(e.target.value);
					setError('');
				}}
				rows={4}
			/>
			{error && <p className="text-red-500 ml-1 mt-0">{error}</p>}
		</Modal>
	);
};
