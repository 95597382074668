import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { MeProfile } from '../../../shared/MySrcm';
import { useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import DescriptionPhotoValue from '../../../components/DescriptionPhotoValue';
import { useNewApplication } from './useNewApplication';

const { Text, Title, Link } = Typography;

interface HeartfulnessProfileStepProps {
	mySrcmProfile?: MeProfile | null;
}

const DescriptionLabel = ({
	text,
	hint,
}: {
	text: string;
	hint?: string | null;
}) => {
	return (
		<div className="flex flex-col">
			<Text>{text}</Text>
			<Text className="text-xs" type="secondary">
				{hint}
			</Text>
		</div>
	);
};

const DescriptionTextValue = ({
	value,
	errorMessage,
}: {
	value?: string | number | null;
	errorMessage: string;
}) => {
	if (value === undefined || value === null || value == '') {
		return <Text type="danger">{errorMessage}</Text>;
	}

	return <Text>{value}</Text>;
};

const Header = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mb-[8px] pl-[16px]">
			<Title level={4}>{t('heartfulnessProfile')}</Title>
			<Text className="mb-[8px]">
				{t('detailsFetchedFromHeartfulnessProfile')}
			</Text>
		</div>
	);
};

const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mt-[8px] pl-[16px]">
			<Text>
				{t('toUpdateYourDetails')}{' '}
				<Link
					href="https://my.heartfulness.org"
					target="_blank"
					className="px-1 color-black"
					underline
				>
					{t('heartfulnessProfilePage')}
				</Link>
				{t('comeBackHereToProceed')}
			</Text>
		</div>
	);
};

export const HeartfulnessProfileStep = ({
	mySrcmProfile,
}: HeartfulnessProfileStepProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Header />
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				layout="vertical"
				className="my-[8px]"
			>
				<Descriptions.Item
					label={
						<DescriptionLabel
							text={t('photo')}
							hint={t('yourFaceShouldBeClearInPhoto')}
						/>
					}
				>
					<DescriptionPhotoValue
						value={mySrcmProfile?.photo_url}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item
					label={
						<DescriptionLabel
							text={t('srcmId')}
							hint={t('linkSrcmIdWithLogin')}
						/>
					}
				>
					<DescriptionTextValue
						value={mySrcmProfile?.ref}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={<DescriptionLabel text={t('firstName')} />}>
					<DescriptionTextValue
						value={mySrcmProfile?.first_name}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={<DescriptionLabel text={t('lastName')} />}>
					<DescriptionTextValue
						value={mySrcmProfile?.last_name}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={<DescriptionLabel text={t('gender')} />}>
					<DescriptionTextValue
						value={mySrcmProfile?.gender}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item
					label={
						<DescriptionLabel
							text={t('email')}
							hint={t('applicationStatusWillBeSentHere')}
						/>
					}
				>
					<DescriptionTextValue
						value={mySrcmProfile?.email}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
				<Descriptions.Item
					label={
						<DescriptionLabel
							text={t('mobile')}
							hint={t('teamWillContactHere')}
						/>
					}
				>
					<DescriptionTextValue
						value={mySrcmProfile?.mobile}
						errorMessage={t('required')}
					/>
				</Descriptions.Item>
			</Descriptions>
			<Footer />
		</div>
	);
};

export const HeartfulnessProfileStepContainer = () => {
	const { mySrcmProfile } = useAuth();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const { setMySrcmProfile } = useNewApplication();

	useEffect(() => {
		const someOfMandatoryFieldsAreNotProvided = [
			mySrcmProfile?.photo_url,
			mySrcmProfile?.ref,
			mySrcmProfile?.first_name,
			mySrcmProfile?.last_name,
			mySrcmProfile?.gender,
			mySrcmProfile?.email,
			mySrcmProfile?.mobile,
		].some((value) => value === null || value === undefined || !value);
		setNextButtonDisabled(someOfMandatoryFieldsAreNotProvided);
		setMySrcmProfile(mySrcmProfile ? mySrcmProfile : {});
		setCancelButtonDisabled(true);
	}, [
		mySrcmProfile,
		mySrcmProfile?.email,
		mySrcmProfile?.first_name,
		mySrcmProfile?.gender,
		mySrcmProfile?.last_name,
		mySrcmProfile?.mobile,
		mySrcmProfile?.photo_url,
		mySrcmProfile?.ref,
		setCancelButtonDisabled,
		setMySrcmProfile,
		setNextButtonDisabled,
	]);

	return <HeartfulnessProfileStep mySrcmProfile={mySrcmProfile} />;
};
