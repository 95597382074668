import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { InspirationAndWillingnessUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = InspirationAndWillingnessUIConfig;

export const InspirationAndWillingnessStep = () => {
	const {
		inspirationAndWillingnessCategoryAnswers,
		setInspirationAndWillingnessCategoryAnswers,
	} = useNewApplication();

	const { form } = useCategoryFormHandling(
		inspirationAndWillingnessCategoryAnswers,
		setInspirationAndWillingnessCategoryAnswers
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
