import { Card, Select } from 'antd';
import { useRegistrationGraph } from './useRegistrationGraph';
import { ExaminationCenterMapping } from '../../../../shared/ExamModuleTypes';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartData } from 'chart.js';
import StackedHorizontalBar from '../../../../components/StackedHorizontalBar';
import { HorizontalBarGraph } from '../../../../components/HorizontalBarGraph';
import { useSnapshot } from 'valtio';
import { ExamState } from '../../ExamModule.store';
import { DoughnutChart } from '../../../../components/DoughnutChart';

export const RegistrationGraph = () => {
	const { t } = useTranslation();
	const { examGraphicalViewSummary, onCentersChange } = useRegistrationGraph();
	const examSnap = useSnapshot(ExamState);
	const examInSnap = examSnap.selectedExam;
	const examCenterOptions = examInSnap?.examinationCenterMappings.map(
		(item: ExaminationCenterMapping) => ({
			value: item.examinationCenter.id,
			label: item.examinationCenter.centerName,
		})
	);
	examCenterOptions?.unshift({
		value: 0,
		label: 'All',
	});
	const [selectedCenterId, setSelectedCenterId] = useState<number>();
	const [seatsData, setSeatsData] = useState<ChartData<'bar'> | null>();
	const [attendanceData, setAttendanceData] =
		useState<ChartData<'bar'> | null>();
	const [languagesData, setLanguagesData] = useState<{
		yAxisLabels: string[];
		xAxisLabels: number[];
	}>();

	useEffect(() => {
		setSelectedCenterId(0);
		onCentersChange(0);
	}, [examInSnap, onCentersChange]);

	useEffect(() => {
		const data = examGraphicalViewSummary?.examinationGraphicalViewSummary;
		if (!data || selectedCenterId) {
			return;
		}
		const labels = data.map((item) => item.examinationCenter.centerName);
		const seatsAvailable = data.map((item) => {
			const totalSeats = Number(item.examinationCenter.seatsCapacity) || 0;
			const seatsBooked = Number(item.seatsBooked) || 0;
			return totalSeats - seatsBooked;
		});
		const seatsBooked = data.map((item) => Number(item.seatsBooked) || 0);
		const seatsGraphData = {
			labels,
			datasets: [
				{
					label: t('seatsAvailable'),
					data: seatsAvailable,
					backgroundColor: 'rgba(71, 145, 255)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('seatsBooked'),
					data: seatsBooked,
					backgroundColor: 'rgba(2, 186, 177)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setSeatsData(seatsGraphData);
	}, [
		examGraphicalViewSummary?.examinationGraphicalViewSummary,
		t,
		selectedCenterId,
	]);

	useEffect(() => {
		const data = examGraphicalViewSummary?.examinationGraphicalViewSummary;
		if (!data || selectedCenterId) {
			return;
		}
		const labels = data.map((item) => item.examinationCenter.centerName);
		const attendancePresent = data.map((item) => Number(item.noOfAttendees));
		const attendanceAbsent = data.map((item) => Number(item.noOfAbsentees));
		const attendanceGraphData = {
			labels,
			datasets: [
				{
					label: t('attendancePresent'),
					data: attendancePresent,
					backgroundColor: 'rgba(255, 217, 80)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('attendanceAbsent'),
					data: attendanceAbsent,
					backgroundColor: 'rgba(219, 31, 88)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setAttendanceData(attendanceGraphData);
	}, [
		examGraphicalViewSummary?.examinationGraphicalViewSummary,
		t,
		selectedCenterId,
	]);

	useEffect(() => {
		const data = examGraphicalViewSummary?.examinationGraphicalViewSummary;
		if (!data || selectedCenterId) {
			return;
		}
		const languageCounts: { [key: string]: number } = {};

		data.forEach((item) => {
			if (item.languageSummary) {
				Object.entries(item.languageSummary).forEach(([language, count]) => {
					if (languageCounts[language]) {
						languageCounts[language] += Number(count);
					} else {
						languageCounts[language] = Number(count);
					}
				});
			}
		});

		const labels = Object.keys(languageCounts);
		const candidates = Object.values(languageCounts);

		setLanguagesData({
			yAxisLabels: labels,
			xAxisLabels: candidates,
		});
	}, [
		examGraphicalViewSummary?.examinationGraphicalViewSummary,
		selectedCenterId,
	]);

	const handleCenterChange = useCallback(
		(value: number) => {
			setSelectedCenterId(value);
			onCentersChange(value);
		},
		[onCentersChange]
	);

	const DisplayAllCentersGraphs = () => {
		return (
			<>
				{seatsData && (
					<StackedHorizontalBar
						title={t('headingForSeatsGraph')}
						data={seatsData}
						displayPercentage={false}
					/>
				)}
				{attendanceData && (
					<StackedHorizontalBar
						title={t('headingForAttendanceGraph')}
						data={attendanceData}
						displayPercentage={false}
					/>
				)}
				{languagesData && (
					<HorizontalBarGraph
						title={t('languageGraph')}
						titleAlign="center"
						titleFontSize={20}
						xAxisTitle="Candidates"
						yAxisTitle="Language"
						barColorInRGB="rgb(5, 50, 85, 1)"
						data={languagesData}
					/>
				)}
			</>
		);
	};

	const DisplaySelectedCenterGraphs = () => {
		const data = examGraphicalViewSummary?.examinationGraphicalViewSummary;
		const selectedCenterData = data && data[0];

		const displaySeatsDoughnut =
			selectedCenterData && selectedCenterData?.examinationCenter.seatsCapacity;
		const seatsAvailable =
			(selectedCenterData &&
				selectedCenterData?.examinationCenter.seatsCapacity -
					(selectedCenterData?.seatsBooked || 0)) ||
			0;
		const seatsBooked =
			(selectedCenterData && selectedCenterData.seatsBooked) || 0;
		const totalSeats =
			(selectedCenterData &&
				selectedCenterData?.examinationCenter.seatsCapacity) ||
			0;

		const displayAccommodationsDoughnut =
			selectedCenterData &&
			selectedCenterData.examinationCenter.accommodationsCapacity;
		const accommodationsAvailable =
			(selectedCenterData &&
				selectedCenterData.examinationCenter.accommodationsCapacity -
					(selectedCenterData.accommodationsBooked || 0)) ||
			0;
		const accommodationsBooked =
			(selectedCenterData && selectedCenterData.accommodationsBooked) || 0;
		const totalAccommodations =
			(selectedCenterData &&
				selectedCenterData.examinationCenter.accommodationsCapacity) ||
			0;

		const displayAttendanceDoughnut =
			selectedCenterData &&
			(selectedCenterData.noOfAttendees ||
				selectedCenterData.noOfAbsentees ||
				selectedCenterData.cancelledCount ||
				selectedCenterData.onHoldCount);
		const attendancePresent =
			(selectedCenterData && selectedCenterData.noOfAttendees) || 0;
		const attendanceAbsent =
			(selectedCenterData && selectedCenterData.noOfAbsentees) || 0;
		const cancelledCount =
			(selectedCenterData && selectedCenterData.cancelledCount) || 0;
		const holdCount =
			(selectedCenterData && selectedCenterData.onHoldCount) || 0;
		const totalRegistrations =
			(selectedCenterData && selectedCenterData.registeredCount) || 0;

		const displayLanguageGraph =
			selectedCenterData && selectedCenterData.languageSummary;
		const languageCounts: { [key: string]: number } = {};
		if (selectedCenterData && selectedCenterData.languageSummary) {
			Object.entries(selectedCenterData.languageSummary).forEach(
				([language, count]) => {
					if (languageCounts[language]) {
						languageCounts[language] += Number(count);
					} else {
						languageCounts[language] = Number(count);
					}
				}
			);
		}
		const labels = Object.keys(languageCounts);
		const candidates = Object.values(languageCounts);
		const centerLanguageData: {
			yAxisLabels: string[];
			xAxisLabels: number[];
		} = {
			yAxisLabels: labels,
			xAxisLabels: candidates,
		};

		return (
			<>
				<div className="flex gap-5">
					{displaySeatsDoughnut && (
						<Card className="w-1/3 m-8 ml-0 flex justify-center items-center shadow-lg">
							<h1 className="text-dark-gray m-0 mb-4 text-center">
								{t('seatsTotal', { totalSeats })}
							</h1>
							<DoughnutChart
								labels={['Available', 'Booked']}
								dataValues={[seatsAvailable, seatsBooked]}
								backgroundColors={[
									'rgb(20, 122, 214, 1)',
									'rgb(137, 137, 137, 1)',
								]}
								borderColors={['rgb(20, 122, 214, 1)', 'rgb(137, 137, 137, 1)']}
								displayPercentage={false}
							/>
						</Card>
					)}
					{displayAccommodationsDoughnut && (
						<Card className="w-1/3 m-8 ml-0 flex justify-center items-center shadow-lg">
							<h1 className="text-dark-gray m-0 mb-4 text-center">
								{t('accommodationsTotal', { totalAccommodations })}
							</h1>
							<DoughnutChart
								labels={['Available', 'Booked']}
								dataValues={[accommodationsAvailable, accommodationsBooked]}
								backgroundColors={[
									'rgb(236, 102, 102, 1)',
									'rgb(137, 137, 137, 1)',
								]}
								borderColors={[
									'rgb(236, 102, 102, 1)',
									'rgb(137, 137, 137, 1)',
								]}
								displayPercentage={false}
							/>
						</Card>
					)}
					{displayAttendanceDoughnut && (
						<Card className="w-1/3 m-8 ml-0 flex justify-center items-center shadow-lg">
							<h1 className="text-dark-gray m-0 mb-4 text-center">
								{t('totalRegistrations', { totalRegistrations })}
							</h1>
							<DoughnutChart
								labels={['Present', 'Absent', 'Cancelled', 'Hold']}
								dataValues={[
									attendancePresent,
									attendanceAbsent,
									cancelledCount,
									holdCount,
								]}
								backgroundColors={[
									'rgb(84, 213, 224, 1)',
									'rgb(137, 137, 137, 1)',
									'rgb(135, 111, 212, 1)',
									'rgb(99, 153, 61, 1)',
								]}
								borderColors={[
									'rgb(84, 213, 224, 1)',
									'rgb(137, 137, 137, 1)',
									'rgb(135, 111, 212, 1)',
									'rgb(99, 153, 61, 1)',
								]}
								displayPercentage={false}
							/>
						</Card>
					)}
				</div>
				{displayLanguageGraph && centerLanguageData && (
					<HorizontalBarGraph
						title={t('languageGraph')}
						titleAlign="center"
						titleFontSize={20}
						xAxisTitle="Candidates"
						yAxisTitle="Language"
						barColorInRGB="rgb(5, 50, 85, 1)"
						data={centerLanguageData}
					/>
				)}
				{!displaySeatsDoughnut &&
					!displayAccommodationsDoughnut &&
					!displayAttendanceDoughnut &&
					!displayLanguageGraph && <h1>{t('noDataFound')}</h1>}
			</>
		);
	};

	return (
		<>
			<div className="flex items-center gap-2">
				<p className="font-medium">{t('selectCenter')}</p>
				<Select
					className="w-48"
					showSearch
					options={examCenterOptions}
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					}
					value={
						selectedCenterId ||
						(examCenterOptions && examCenterOptions[0].value)
					}
					onChange={handleCenterChange}
				/>
			</div>
			{selectedCenterId ? (
				<DisplaySelectedCenterGraphs />
			) : (
				<DisplayAllCentersGraphs />
			)}
		</>
	);
};
