import { Typography, Form, FormInstance, Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useProposerRecommendationPage } from '../ProposerRecommendationPage/useProposerRecommendationPage';
import { RecommendationComponent } from '../RecommendationComponent';
import {
	PredefinedReasonCategory,
	ReviewAction,
} from '../../../shared/Constants';

const { Text, Title } = Typography;

interface SkipAllInterviewsCollectionPageProps {
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const SkipAllInterviewsCollectionPage = ({
	form,
}: SkipAllInterviewsCollectionPageProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{
					remember: true,
					review: ReviewAction.REJECT,
				}}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={t('proposerNotAbleToProceedWithInterview')}
					description={t('provideReasonWhyInterviewCannotBeDone')}
				/>
				<div className="flex flex-row items-start">
					<div className="flex flex-col w-full">
						<Text>{t('iRecommendTheCandidatesApplicationToBe')}</Text>
						<RecommendationComponent
							commentsMandatory
							commentsLabel="yourCommentsToSupportAboveRecommendation"
							commentsPlaceholder="commentsAreMandatory"
							form={form}
							category={PredefinedReasonCategory.SKIP}
						/>
					</div>
				</div>
			</Form>
		</div>
	);
};

export const SkipAllInterviewsCollectionPageContainer = () => {
	const { t } = useTranslation();
	const {
		form,
		onSubmitClickForSkipAllInterviews,
		onCancelClick,
		submitButtonDisabled,
		application,
	} = useProposerRecommendationPage();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<SkipAllInterviewsCollectionPage form={form} />
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClickForSkipAllInterviews}
					type="primary"
					disabled={submitButtonDisabled || application?.isApprover}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
			{application?.isApprover && (
				<Alert
					showIcon
					message={t('onlyProposersAreAllowedToSubmitFeedback')}
					type="warning"
				/>
			)}
		</div>
	);
};
