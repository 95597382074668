import React, { useEffect } from 'react';
import { Checkbox, Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { WelcomeAndSelfConsciousness } from './WelcomeAndSelfConsciousness';
import { useWizard } from '../../../components/wizard/useWizard';
import { useNewApplication } from './useNewApplication';

const { Title, Text } = Typography;

const SectionHeader = () => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{t('aboutTheProcess')}</Title>
			<Text className="mb-[8px]">{t('dearSisterBrother')}</Text>
			<Text className="mb-[8px] text-justify">{t('welcomeNote')}</Text>
			<Text className="mb-[8px] text-justify">
				{t('selfConsciousnessNote')}
			</Text>
		</div>
	);
};

export const WelcomeAndSelfConsciousnessStep: React.FC<{
	form: FormInstance;
}> = ({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader />
				<Form.Item name="iAcceptTermsAndWantToProceed" valuePropName="checked">
					<Checkbox>{t('iAcceptTermsAndWantToProceed')}</Checkbox>
				</Form.Item>
				<Form.Item name="iConsentSelfConsciousness" valuePropName="checked">
					<Checkbox>{t('iConsentSelfConsciousness')}</Checkbox>
				</Form.Item>
			</Form>
		</div>
	);
};

export const WelcomeAndSelfConsciousnessContainer = () => {
	const [form] = Form.useForm<WelcomeAndSelfConsciousness>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<WelcomeAndSelfConsciousness>([], form);
	const { welcomeAndSelfConsciousness, setWelcomeAndSelfConsciousness } =
		useNewApplication();

	useEffect(() => {
		if (values) {
			const areAllTermsAndConditionsAgreed =
				(values.iAcceptTermsAndWantToProceed &&
					values.iConsentSelfConsciousness) as boolean;
			setNextButtonDisabled(!areAllTermsAndConditionsAgreed);
			setWelcomeAndSelfConsciousness(values);
		} else {
			setNextButtonDisabled(true);
		}
		setCancelButtonDisabled(false);
	}, [
		setNextButtonDisabled,
		setCancelButtonDisabled,
		setWelcomeAndSelfConsciousness,
		values,
	]);

	useEffect(() => {
		form.setFieldsValue(welcomeAndSelfConsciousness);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <WelcomeAndSelfConsciousnessStep form={form} />;
};
