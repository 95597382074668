import { useCallback } from 'react';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useExamsList } from './useExamsList';
import { ExamTableColumns } from './ExamTableColumns';
import { CREATE_EXAM } from '../../../shared/Routes';
import { tablePaginationConfiguration } from '../../../shared/Utils';
import { useExamAndCenterId } from '../../../hooks/useExamAndCenterId';
import { FilterExams } from './FilterExams';

export const ExamsList = () => {
	const { t } = useTranslation();
	const { navigateWithoutExamAndCenterIds } = useExamAndCenterId();
	const {
		examFilter,
		examsList,
		examsPaging,
		onExamsPageChange,
		onExamsFilterChange,
	} = useExamsList({});
	const { exams, pagination } = examsList;
	const tablePaginationConfig = tablePaginationConfiguration(
		examsPaging,
		pagination,
		onExamsPageChange
	);
	const columns = ExamTableColumns();

	const handleCreateExamClick = useCallback(() => {
		navigateWithoutExamAndCenterIds(CREATE_EXAM);
	}, [navigateWithoutExamAndCenterIds]);

	return (
		<>
			<div className="flex justify-between items-center mb-2.5">
				<h3>{t('examsList')}</h3>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={handleCreateExamClick}
				>
					{t('createExam')}
				</Button>
			</div>
			<FilterExams value={examFilter} onFilterChange={onExamsFilterChange} />
			<Table
				dataSource={exams}
				columns={columns}
				pagination={tablePaginationConfig}
				scroll={{ x: 'max-content' }}
				className="overflow-x-auto mt-4"
			/>
		</>
	);
};
