import { useCallback, useMemo } from 'react';
import { useNavigate, NavigateOptions } from 'react-router-dom';
import useQueryParams from './useQueryParams';

export const useBatchId = () => {
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const batchId = useMemo(() => {
		const batchIdParam = Number(queryParams.get('batchId'));

		if (typeof batchIdParam !== 'number') {
			return -1;
		}

		return batchIdParam;
	}, [queryParams]);

	const getRouteWithBatchId = useCallback(
		(route: string, removeSrcmParam?: boolean) => {
			queryParams.set('batchId', `${batchId}`);
			if (removeSrcmParam) {
				queryParams.delete('functionarySrcmIdParam');
			}
			return `${route}?${queryParams.toString()}`;
		},
		[batchId, queryParams]
	);

	const navigateWithBatchId = useCallback(
		(route: string, params?: NavigateOptions) => {
			navigate(`${getRouteWithBatchId(route)}`, params);
		},
		[getRouteWithBatchId, navigate]
	);

	const preceptorBatchId = useMemo(() => {
		const batchIdParam = Number(queryParams.get('preceptorBatchId'));
		if (typeof batchIdParam !== 'number') {
			return -1;
		}
		return batchIdParam;
	}, [queryParams]);

	const navigateWithPreceptorBatchId = useCallback(
		(
			route: string,
			preceptorBatchIdParam?: number,
			params?: NavigateOptions
		) => {
			const batchIdToUse = preceptorBatchIdParam
				? `${preceptorBatchIdParam}`
				: preceptorBatchId;
			queryParams.set('preceptorBatchId', `${batchIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[preceptorBatchId, navigateWithBatchId, queryParams]
	);

	return {
		batchId,
		getRouteWithBatchId,
		navigateWithBatchId,
		preceptorBatchId,
		navigateWithPreceptorBatchId,
		queryParams,
	};
};
