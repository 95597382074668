import { CheckCircleFilled } from '@ant-design/icons';
import { Card, theme, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { useCallback } from 'react';
import { PRE_WORK } from '../../shared/Routes';
import { PreworkCompletionStatusType } from '../../shared/VolunteerApplicationServiceTypes';
import { PreworkCompletionStatus } from '../../shared/Constants';
import { useApplicationId } from '../../hooks/useApplicationId';

export const ApplicationApprovedCard = ({
	pastApplicationId,
	preworkCompletionStatus,
}: {
	pastApplicationId?: number;
	preworkCompletionStatus?: PreworkCompletionStatusType;
}) => {
	const { t } = useTranslation();
	const {
		token: { green6 },
	} = theme.useToken();
	const { mySrcmProfile } = useAuth();
	const { navigateWithApplicationId } = useApplicationId();
	const candidateName = mySrcmProfile?.name;
	const showProceedToPreWorkButton =
		preworkCompletionStatus !== PreworkCompletionStatus.COMPLETED;

	const handleClickToProceedClick = useCallback(() => {
		navigateWithApplicationId(PRE_WORK, pastApplicationId);
	}, [navigateWithApplicationId, pastApplicationId]);

	return (
		<Card
			title={
				<div className="flex row items-center">
					<div className="pr-[8px]">
						<CheckCircleFilled style={{ fontSize: '16px', color: green6 }} />
					</div>
					<div className="pl-[8px]">{t('approved')}</div>
				</div>
			}
		>
			<div className="flex row items-center">
				<div className="pl-[8px] text-justify">
					<p>{t('dearCandidateName', { candidateName })}</p>
					<p>{t('thankYouForYourApplication')}</p>
					<p>{t('yourApplicationHasBeenApproved')}</p>
					<p>
						{t('heartfulRespectsAndRegards')}
						<br />
						{t('pcmsTeam')}
					</p>
					{showProceedToPreWorkButton ? (
						<Button type="primary" onClick={handleClickToProceedClick}>
							{t('clickToProceed')}
						</Button>
					) : (
						<p>{t('preworkCompleted')}</p>
					)}
				</div>
			</div>
		</Card>
	);
};
