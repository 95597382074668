import { Form, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
	BulkSaveFunctionariesOfZoneRequest,
	FunctionaryInfo,
	FunctionaryOfZone,
	SaveFunctionaryOfZoneRequest,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { VIEW_FUNCTIONARIES } from '../../../shared/Routes';
import { useBatchId } from '../../../hooks/useBatchId';
import { useAuth } from '../../../hooks/useAuth';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	bulkSaveFunctionariesOfZone,
	getFunctionary,
} from '../../../service/VolunteerApplicationService';
import { useFunctionariesList } from '../FunctionariesList/useFunctionariesList';
import { useTranslation } from 'react-i18next';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { FunctionaryType } from '../../../shared/Constants';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

export const useAddOrEditFunctionaryPage = () => {
	const [submitButtonDisabled, setSubmitButtonDisabled] =
		useState<boolean>(false);
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { getRouteWithBatchId } = useBatchId();
	const [form] = Form.useForm<FunctionaryInfo>();
	const values = Form.useWatch<FunctionaryInfo>([], form);
	const [functionary, setFunctionary] = useState<FunctionaryInfo | undefined>();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { functionarySrcmIdParam } = useFunctionariesList();
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const navigate = useNavigate();
	const { role } = useRedirectToLogin();

	const navigateWithoutSrcmId = useCallback(
		(route: string, params?: NavigateOptions) => {
			navigate(`${getRouteWithBatchId(route, true)}`, params);
		},
		[getRouteWithBatchId, navigate]
	);

	const buildSaveFunctionaryOfZoneRequest = useCallback(
		(functionaryInfo: FunctionaryInfo) => {
			const request: SaveFunctionaryOfZoneRequest = {
				srcmId: functionaryInfo.srcmId,
				printName: functionaryInfo.printName,
				email: functionaryInfo.email,
				mobile: functionaryInfo.mobile,
				gender: functionaryInfo.gender,
				homeZone: functionaryInfo.homeZoneToDisplay?.value,
			};

			if (role === UserRole.ADMIN) {
				request.isCareTeam =
					functionaryInfo.functionaryType === FunctionaryType.IS_CARE_TEAM;
				request.proposerOfZones =
					functionaryInfo.functionaryType === FunctionaryType.IS_PROPOSER
						? functionaryInfo.zone?.map((z) => z.value)
						: undefined;
				request.approverOfZones =
					functionaryInfo.functionaryType === FunctionaryType.IS_APPROVER
						? functionaryInfo.zone?.map((z) => z.value)
						: undefined;
				request.zcOfZones =
					functionaryInfo.functionaryType === FunctionaryType.IS_ZC
						? functionaryInfo.zone?.map((z) => z.value)
						: undefined;
			} else {
				request.proposerOfZones = functionaryInfo.zone?.map((z) => z.value);
			}
			return request;
		},
		[role]
	);

	const onConfirmSubmit = useCallback(async () => {
		if (functionary) {
			const saveFunctionaryOfZoneRequest =
				buildSaveFunctionaryOfZoneRequest(functionary);
			const bulkRequest: BulkSaveFunctionariesOfZoneRequest = {
				functionaryOfZones: [saveFunctionaryOfZoneRequest],
				functionarySrcmId: srcmId,
			};
			try {
				await callApi(
					() => bulkSaveFunctionariesOfZone(bulkRequest),
					'errorOccurredWhileSubmittingApplication'
				);
				const successContent =
					functionarySrcmIdParam !== '-1'
						? t('successfullyUpdated')
						: t('successfullyAdded');
				Modal.success({
					content: successContent,
					afterClose: () => navigateWithoutSrcmId(VIEW_FUNCTIONARIES),
				});
			} catch (e) {
				// handled in callApi
			}
		}
	}, [
		functionary,
		buildSaveFunctionaryOfZoneRequest,
		srcmId,
		callApi,
		functionarySrcmIdParam,
		t,
		navigateWithoutSrcmId,
	]);

	const onSubmitClick = useCallback(() => {
		const confirmContent =
			role === UserRole.ADMIN
				? 'youAreAboutToAddOrUpdateFunctionary'
				: 'youAreAboutToAddOrUpdateProposer';
		confirmBeforeAction('confirmSubmit', confirmContent, onConfirmSubmit);
	}, [confirmBeforeAction, onConfirmSubmit, role]);

	const onCancelClick = useCallback(() => {
		navigateWithoutSrcmId(VIEW_FUNCTIONARIES);
	}, [navigateWithoutSrcmId]);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setFunctionary(fieldValues);
			setSubmitButtonDisabled(false);
		} catch (e) {
			const fields = e as { values: FunctionaryInfo; outOfDate: boolean };

			if (!fields.outOfDate) {
				setFunctionary(fields.values);
				setSubmitButtonDisabled(true);
			}
		}
	}, [form]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	const buildFunctionaryInfo = useCallback(
		(functionaryOfZone: FunctionaryOfZone): FunctionaryInfo => {
			const functionaryInfo: FunctionaryInfo = functionaryOfZone.functionary;
			const homeZoneToDisplay = { value: functionaryInfo.homeZone };
			functionaryInfo.homeZoneToDisplay = homeZoneToDisplay;
			if (functionaryOfZone.functionary.isApprover) {
				functionaryInfo.functionaryType = FunctionaryType.IS_APPROVER;
				functionaryInfo.zone = functionaryOfZone.approverZone?.map((z) => ({
					value: z,
				}));
			} else if (functionaryOfZone.functionary.isZC) {
				functionaryInfo.functionaryType = FunctionaryType.IS_ZC;
				functionaryInfo.zone = functionaryOfZone.zcZone?.map((z) => ({
					value: z,
				}));
			} else if (functionaryOfZone.functionary.isCareTeam) {
				functionaryInfo.functionaryType = FunctionaryType.IS_CARE_TEAM;
				functionaryInfo.zone = [];
			} else {
				functionaryInfo.functionaryType = FunctionaryType.IS_PROPOSER;
				functionaryInfo.zone = functionaryOfZone.zone?.map((z) => ({
					value: z,
				}));
			}
			return functionaryInfo;
		},
		[]
	);

	const loadProposer = useCallback(async () => {
		if (functionarySrcmIdParam && functionarySrcmIdParam !== '-1') {
			try {
				const response = await getFunctionary({
					functionarySrcmId: functionarySrcmIdParam,
				});
				const functionaryInfo = buildFunctionaryInfo(response);
				setFunctionary(functionaryInfo);
				form.setFieldsValue(functionaryInfo);
				setSubmitButtonDisabled(false);
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			}
		}
	}, [buildFunctionaryInfo, form, functionarySrcmIdParam, showToastMessage]);

	useEffect(() => {
		void loadProposer();
	}, [loadProposer]);

	return {
		form,
		functionary,
		submitButtonDisabled,
		onSubmitClick,
		onCancelClick,
	};
};
