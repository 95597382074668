import { Button, Modal } from 'antd';
import {
	ApplicationSummary,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { ApplicationStatus } from '../../../shared/Constants';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

interface VerifyResultsModalProps {
	openModal: boolean;
	onClose: () => void;
	application: ApplicationSummary | undefined;
}

export const VerifyResultsModal: React.FC<VerifyResultsModalProps> = ({
	openModal,
	onClose,
	application,
}: VerifyResultsModalProps) => {
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();
	const roleName = role === UserRole.APPROVER ? 'RC' : UserRole.ZC;

	const transposedTextList = useCallback(() => {
		const approverReasons =
			application?.approverRecommendation?.predefinedReasons?.map(
				(reason) => reason
			) || [];

		const careTeamReasons =
			application?.careTeamRecommendation?.predefinedReasons?.map(
				(reason) => reason
			) || [];

		const reasonsList = [...approverReasons, ...careTeamReasons];

		const jsxList = reasonsList && reasonsList.length && (
			<ul className="list-disc list-inside">
				{reasonsList.map((comment) => (
					<li key={comment.id} className="flex items-center justify-between">
						<>
							<b>&bull; &nbsp; {comment.reasonTextForCandidate}</b>
						</>
					</li>
				))}
			</ul>
		);

		return jsxList;
	}, [application]);

	const content = useCallback(() => {
		if (application?.applicationStatus === ApplicationStatus.APPROVED) {
			return (
				<>
					<p>{t('screeningResultOfApproved')}</p>
					<p>{t('kindlyMaintainConfidentialityOfRC')}</p>
				</>
			);
		} else if (application?.applicationStatus === ApplicationStatus.REJECTED) {
			return (
				<>
					<p>{t('screeningResultOfNGF')}</p>
					{transposedTextList()}
					<p>{t('kindlyMaintainConfidentiality')}</p>
				</>
			);
		}
	}, [application?.applicationStatus, t, transposedTextList]);

	return (
		<Modal
			open={openModal}
			onOk={onClose}
			onCancel={onClose}
			footer={[
				<Button key="ok" type="primary" onClick={onClose}>
					OK
				</Button>,
			]}
		>
			<p>{t('dearZCRC', { roleName })}</p>
			{content()}
		</Modal>
	);
};
