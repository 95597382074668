import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { getExamPreferredLanguages } from '../../service/ExamModuleService';
import { useCallApi } from '../../hooks/useCallApi';

const { Option } = Select;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

interface LanguagesDropdownProps {
	onChange?: (value: OptionData) => void;
	value?: OptionData | null | undefined;
	examIdInSnap: number;
	size?: 'large' | 'middle' | 'small';
}

const LanguagesDropdown: React.FC<LanguagesDropdownProps> = ({
	onChange,
	value,
	examIdInSnap,
	size,
}: LanguagesDropdownProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const selectSize = size || 'middle';

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			let examPreferredLanguages;

			if (srcmId) {
				examPreferredLanguages = await callApi(
					() =>
						getExamPreferredLanguages({
							functionarySrcmId: srcmId,
							examId: examIdInSnap,
						}),
					'errorOccurredWhileFetchingLanguages'
				);
			}

			if (
				examPreferredLanguages?.languages &&
				examPreferredLanguages?.languages.length
			) {
				const transformedOptions: OptionData[] =
					examPreferredLanguages.languages
						.filter((item: string) =>
							item.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((item: string) => ({
							value: item,
							label: item,
						}));

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}
			setLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[srcmId, examIdInSnap]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={true}
			filterOption={false}
			placeholder={t('searchLanguages')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
			size={selectSize}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default LanguagesDropdown;
