import { Typography, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { QuestionsToAsk } from '../QuestionsToAsk';
import { FeedbackComponent } from '../Feedback';

const { Text, Title } = Typography;

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CategoryStep = ({
	uiConfig: { title, subTitle, questionsToAskInOtherReferenceInterview },
	form,
}: CategoryStepProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader heading={t(title)} description={t(subTitle)} />
				<QuestionsToAsk uiConfig={questionsToAskInOtherReferenceInterview} />
				<Title level={5}>
					{t('yourFeedbackForCategory', { categoryTitle: t(title) })}
				</Title>
				<FeedbackComponent />
			</Form>
		</div>
	);
};
