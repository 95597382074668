import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	FC,
	ReactNode,
	useEffect,
} from 'react';
import { PredefinedReason } from '../shared/VolunteerApplicationServiceTypes';
import { useLoadingIndicator } from './useLoadingIndicator';
import { getAllPredefinedReasons } from '../service/VolunteerApplicationService';
import { useTranslation } from 'react-i18next';

export interface PredefinedReasonsContextType {
	predefinedReasons: Array<PredefinedReason>;
	setPredefinedReasons: (predefinedReasons: Array<PredefinedReason>) => void;
}

interface Props {
	children: ReactNode;
}

const PredefinedReasonsContext = createContext<
	Partial<PredefinedReasonsContextType>
>({});

export const PredefinedReasonsProvider: FC<Props> = ({ children }: Props) => {
	const [loading, setDeterminingLoginStatus] = useState<boolean>(false);
	const [predefinedReasons, setPredefinedReasons] = useState<
		Array<PredefinedReason>
	>([]);
	const { setLoading: showLoadingIndicator } = useLoadingIndicator();
	const { i18n } = useTranslation();

	useEffect(() => {
		showLoadingIndicator(loading);
	}, [loading, showLoadingIndicator]);

	const fetchPredefinedReasons = useCallback(async () => {
		setDeterminingLoginStatus(true);

		const reasonsPage = await getAllPredefinedReasons({
			pageIndex: 0,
			pageSize: 100,
			language: i18n.language,
		});
		setPredefinedReasons([...reasonsPage.predefinedReasons]);

		setDeterminingLoginStatus(false);
	}, [i18n.language]);

	useEffect(() => {
		void fetchPredefinedReasons();
	}, [fetchPredefinedReasons]);

	const value = useMemo<PredefinedReasonsContextType>(
		() => ({
			predefinedReasons,
			setPredefinedReasons,
		}),
		[predefinedReasons, setPredefinedReasons]
	);

	return (
		<PredefinedReasonsContext.Provider value={value}>
			{children}
		</PredefinedReasonsContext.Provider>
	);
};

export const usePredefinedReasons = () => {
	return useContext(PredefinedReasonsContext);
};
