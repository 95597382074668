import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Country } from '../shared/VolunteerApplicationServiceTypes';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { getCountryProfile } from '../service/MySrcmService';

const { Option } = Select;

interface CountryOptionData {
	value?: number | string;
	label?: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: string | CountryOptionData) => void;
	value?: Country;
}

const CountrySingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<CountryOptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			const countryPage = await getCountryProfile(searchValue);

			if (countryPage) {
				const transformedOptions: CountryOptionData[] = countryPage.results.map(
					(item) => ({
						value: item?.id,
						label: item?.name,
					})
				);

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}

			setLoading(false);
		},
		[setLoading, setOptions]
	);

	const debouncedFetchData = useMemo(
		() =>
			debounce((searchValue: string) => {
				void fetchData(searchValue);
			}, 500),
		[fetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	const handleSearch = (searchKey: SelectValue) => {
		debouncedFetchData(searchKey as string);
	};

	return (
		<Select
			showSearch
			defaultActiveFirstOption={false}
			defaultValue={{ value: '358', label: 'India' }}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchCountry')}
			notFoundContent={loading ? <Spin size="small" /> : 'No results found'}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option?.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default CountrySingleSelect;
