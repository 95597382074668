import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { UnderstandingOfHeartfulnessUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useApplicantInterviewWizard } from './useApplicantInterviewWizard';

const UiConfig = UnderstandingOfHeartfulnessUIConfig;

export const UnderstandingOfHeartfulnessStep = () => {
	const {
		understandingOfHeartfulness,
		setUnderstandingOfHeartfulness,
		application,
	} = useApplicantInterviewWizard();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			understandingOfHeartfulness,
			setUnderstandingOfHeartfulness
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
			answers={application?.userAnswers}
		/>
	);
};
