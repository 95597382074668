import firebase from 'firebase/compat/app';
import { firebaseConfig } from './EnvService';

const app = firebase.initializeApp(firebaseConfig as object);
export default app;

export const getLoggedInUser = (): Promise<firebase.User | null> => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged(
			(user: firebase.User | null) => {
				unsubscribe();
				resolve(user);
			},
			(e: firebase.auth.Error) => {
				unsubscribe();
				reject(e);
			}
		);
	});
};

export const signOut = (): Promise<void> => {
	return firebase.auth().signOut();
};
