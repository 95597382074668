import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { DoughnutChart } from '../../../../components/DoughnutChart';
import { useExamResultsGraph } from './useExamResultsGraph';
import { FilterGraphs } from './FilterGraphs';
import { Card } from 'antd';
import { HorizontalBarGraph } from '../../../../components/HorizontalBarGraph';
import {
	ExamResultGraphicalViewResponse,
	StringToIntMap,
} from '../../../../shared/ExamModuleTypes';
import { useSnapshot } from 'valtio';
import { ExamState } from '../../ExamModule.store';

interface ResultsGraphProps {
	result: ExamResultGraphicalViewResponse;
}

const ResultsGraph = ({ result }: ResultsGraphProps) => {
	const { t } = useTranslation();
	const passPercentage = result.passPercentage || 0;
	const failPercentage = result.failPercentage || 0;

	const processRanges = (
		obj: StringToIntMap
	): { sortedKeys: string[]; sortedValues: number[] } => {
		if (obj) {
			const keys = Object.keys(obj);

			const sortedKeys = keys.sort((a, b) => {
				const [startA] = a.split('To').map(Number);
				const [startB] = b.split('To').map(Number);
				return startA - startB;
			});

			const sortedValues = sortedKeys.map((key) => obj[key]).reverse();

			const formattedKeys = sortedKeys
				.map((key) => key.replace('To', '-'))
				.reverse();

			return {
				sortedKeys: formattedKeys,
				sortedValues,
			};
		} else {
			return {
				sortedKeys: [],
				sortedValues: [],
			};
		}
	};

	const { sortedKeys: totalMarkRanges, sortedValues: totalMarkValues } =
		processRanges(result.totalMarkRangeWiseGraphicalView);
	const total = {
		yAxisLabels: totalMarkRanges,
		xAxisLabels: totalMarkValues,
	};

	const { sortedKeys: sectionARanges, sortedValues: sectionAValues } =
		processRanges(result.sectionARangeWiseGraphicalView);
	const sectionA = {
		yAxisLabels: sectionARanges,
		xAxisLabels: sectionAValues,
	};
	const displaySectionA =
		sectionA.xAxisLabels.length > 0 && sectionA.yAxisLabels.length > 0;

	const { sortedKeys: sectionBRanges, sortedValues: sectionBValues } =
		processRanges(result.sectionBRangeWiseGraphicalView);
	const sectionB = {
		yAxisLabels: sectionBRanges,
		xAxisLabels: sectionBValues,
	};
	const displaySectionB =
		sectionB.xAxisLabels.length > 0 && sectionB.yAxisLabels.length > 0;

	const { sortedKeys: sectionCRanges, sortedValues: sectionCValues } =
		processRanges(result.sectionCRangeWiseGraphicalView);
	const sectionC = {
		yAxisLabels: sectionCRanges,
		xAxisLabels: sectionCValues,
	};
	const displaySectionC =
		sectionC.xAxisLabels.length > 0 && sectionC.yAxisLabels.length > 0;

	const totalNoOfCandidates = result.totalNoOfCandidates;

	const areAllValuesZero = (obj: StringToIntMap): boolean => {
		if (obj) {
			return Object.values(obj).every((value) => Number(value) === 0);
		} else {
			return true;
		}
	};

	if (
		(areAllValuesZero(result.totalMarkRangeWiseGraphicalView) &&
			areAllValuesZero(result.sectionARangeWiseGraphicalView) &&
			areAllValuesZero(result.sectionBRangeWiseGraphicalView) &&
			areAllValuesZero(result.sectionCRangeWiseGraphicalView)) ||
		(isEmpty(result.totalMarkRangeWiseGraphicalView) &&
			isEmpty(result.sectionARangeWiseGraphicalView) &&
			isEmpty(result.sectionBRangeWiseGraphicalView) &&
			isEmpty(result.sectionCRangeWiseGraphicalView))
	) {
		return (
			<>
				<h1>{t('noDataFound')}</h1>
			</>
		);
	}

	return (
		<>
			<div className="flex">
				<Card className="w-1/3 m-8 ml-0 flex justify-center items-center shadow-lg">
					<h1 className="text-dark-gray m-0 mb-8 text-center">
						{t('clearedNotClearedText')}
					</h1>
					<DoughnutChart
						title="Cleared / Not Cleared"
						labels={['Cleared', 'Not Cleared']}
						dataValues={[passPercentage, failPercentage]}
						backgroundColors={['rgb(33, 70, 110, 1)', 'rgb(0, 123, 219, 1)']}
						borderColors={['rgba(33, 70, 110, 1)', 'rgba(0, 123, 219, 1)']}
						displayPercentage
					/>
				</Card>
				<Card className="w-2/3 m-8 ml-0 shadow-lg">
					<h2 className="text-dark-gray m-0">
						{t('totalNoOfCandidates', { totalNoOfCandidates })}
					</h2>
					<HorizontalBarGraph
						title="Total - Mark Range"
						xAxisTitle="Candidates"
						yAxisTitle="Mark Range"
						barColorInRGB="rgb(90, 51, 153, 1)"
						data={total}
					/>
				</Card>
			</div>
			{displaySectionA && (
				<Card className="m-8 ml-0 shadow-lg">
					<HorizontalBarGraph
						title="Section A"
						xAxisTitle="Candidates"
						yAxisTitle="Mark Range"
						barColorInRGB="rgb(71, 145, 255, 1)"
						data={sectionA}
					/>
				</Card>
			)}
			{displaySectionB && (
				<Card className="m-8 ml-0 shadow-lg">
					<HorizontalBarGraph
						title="Section B"
						xAxisTitle="Candidates"
						yAxisTitle="Mark Range"
						barColorInRGB="rgb(7, 186, 117, 1)"
						data={sectionB}
					/>
				</Card>
			)}
			{displaySectionC && (
				<Card className="m-8 ml-0 shadow-lg">
					<HorizontalBarGraph
						title="Section C"
						xAxisTitle="Candidates"
						yAxisTitle="Mark Range"
						barColorInRGB="rgb(224, 66, 113, 1)"
						data={sectionC}
					/>
				</Card>
			)}
		</>
	);
};

export const ExamResultsGraph = () => {
	const { t } = useTranslation();
	const examSnap = useSnapshot(ExamState);
	const examInSnap = examSnap.selectedExam;
	const { examResult, examFilter, onExamResultFilterChange } =
		useExamResultsGraph();

	const DisplayGraphs = () => {
		return (
			<>
				{examInSnap && (
					<FilterGraphs
						key={examInSnap.id}
						value={examFilter}
						onFilterChange={onExamResultFilterChange}
						examIdInSnap={examInSnap.id}
					/>
				)}
				{examResult && examInSnap?.resultUploaded && (
					<ResultsGraph result={examResult} />
				)}
			</>
		);
	};

	if (examInSnap === undefined) {
		return (
			<>
				<h3>{t('examResults')}</h3>
			</>
		);
	}

	return (
		<>
			<h3>{t('examResults')}</h3>
			{examInSnap?.resultUploaded ? (
				<DisplayGraphs />
			) : (
				<>
					<h1>{t('resultsNotUploaded')}</h1>
				</>
			)}
		</>
	);
};
