import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { SupportUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';

const UiConfig = SupportUIConfig;

export const SupportStep = () => {
	const { support, setSupport } = usePreceptorInterview();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(support, setSupport);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
