import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useBatchId } from '../../../hooks/useBatchId';
import { BatchFormType } from './BatchFormType';
import {
	PreceptorBatch,
	PreceptorBatchFilter,
	PreceptorBatchForCreation,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	createPreceptorBatch,
	getPreceptorBatches,
	updatePreceptorBatch,
} from '../../../service/VolunteerApplicationService';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { convertDateToUTCString } from '../../../shared/Utils';

export const useCreateOrUpdateBatch = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { callApi } = useCallApi();
	const navigate = useNavigate();
	const { preceptorBatchId } = useBatchId();
	const [preceptorBatchFilter, setPreceptorBatchFilter] =
		useState<PreceptorBatchFilter>({ preceptorBatchId });

	useEffect(() => {
		if (preceptorBatchId) {
			const filter: PreceptorBatchFilter = {
				preceptorBatchId,
			};
			setPreceptorBatchFilter(filter);
		}
	}, [preceptorBatchId]);

	const generateBatchForCreation = useCallback(
		(batchDetails: BatchFormType) => {
			const batchForCreation: PreceptorBatchForCreation = {
				batchName: batchDetails.batchName,
				startDate: convertDateToUTCString(batchDetails.startDate),
				endDate: convertDateToUTCString(batchDetails.endDate, true),
				description: batchDetails.description,
			};
			if (preceptorBatchId > 0) {
				batchForCreation.id = preceptorBatchId;
				batchForCreation.status = batchDetails.status;
				batchForCreation.arePreceptorsLoadedFromMySRCM =
					batchDetails.arePreceptorsLoadedFromMySRCM;
				batchForCreation.totalNoOfPreceptorsToBeLoaded =
					batchDetails.totalNoOfPreceptorsToBeLoaded;
				batchForCreation.totalNoOfPreceptorsLoaded =
					batchDetails.totalNoOfPreceptorsLoaded;
			}
			return batchForCreation;
		},
		[preceptorBatchId]
	);

	const onConfirmSubmit = useCallback(
		async (batchDetails: BatchFormType) => {
			if (batchDetails && srcmId) {
				try {
					const batch = generateBatchForCreation(batchDetails);
					const request = {
						functionarySrcmId: srcmId,
						preceptorBatch: batch,
					};
					let response: PreceptorBatch;
					if (preceptorBatchId > 0) {
						response = await callApi(
							() => updatePreceptorBatch(request),
							'errorOccurredWhileUpdatingBatch'
						);
					} else {
						response = await callApi(
							() => createPreceptorBatch(request),
							'errorOccurredWhileCreatingBatch'
						);
					}
					const successMessage =
						preceptorBatchId > 0
							? t('updatedBatchSuccessfully')
							: t('createdBatchSuccessfully');
					if (response) {
						Modal.success({
							title: t('successful'),
							content: successMessage,
							afterClose: () => {
								navigate(-1);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[navigate, callApi, srcmId, t, generateBatchForCreation, preceptorBatchId]
	);

	const onSubmitClick = useCallback(
		(batchDetails: BatchFormType) => {
			const confirmationMessage =
				preceptorBatchId > 0
					? t('batchUpdationConfirmMessage')
					: t('batchCreationConfirmMessage');
			confirmBeforeAction(
				'confirmSubmit',
				confirmationMessage,
				() => onConfirmSubmit(batchDetails),
				true
			);
		},
		[confirmBeforeAction, onConfirmSubmit, t, preceptorBatchId]
	);

	const fetchBatchById = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const batchData = await getPreceptorBatches({
					functionarySrcmId: srcmId,
					pageIndex: 0,
					pageSize: 1,
					preceptorBatchFilter,
				});
				return batchData.preceptorBatches[0];
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [srcmId, setLoading, showToastMessage, preceptorBatchFilter]);

	return {
		fetchBatchById,
		preceptorBatchId,
		onSubmitClick,
	};
};
