import { useCallback, useEffect, useState } from 'react';
import { useExamsList } from '../exams-list/useExamsList';
import {
	ExamFilter,
	GetCandidateExamDetailsResponse,
} from '../../../shared/ExamModuleTypes';
import { useAuth } from '../../../hooks/useAuth';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	approveHeldCandidate,
	getCandidateExamDetails,
} from '../../../service/ExamModuleService';
import { PagingConfig } from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { AxiosError } from 'axios';

export const useViewHeldCandidates = () => {
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { examById } = useExamsList({});
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const [heldCandidatesFilter, setHeldCandidatesFilter] = useState<ExamFilter>({
		examId: examById?.id,
		keptHold: true,
	});
	const [heldCandidateExamDetailList, setHeldCandidateExamDetailList] =
		useState<GetCandidateExamDetailsResponse>({
			candidateExamDetails: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [heldCandidateExamDetailPaging, setHeldCandidateExamDetailPaging] =
		useState<PagingConfig>({ pageNo: 1, pageSize: DEFAULT_PAGE_SIZE });

	useEffect(() => {
		if (examById?.id) {
			const filter: ExamFilter = {
				examId: examById.id,
				keptHold: true,
			};
			setHeldCandidatesFilter(filter);
		}
	}, [examById]);

	const onHeldCandidateExamDetailsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setHeldCandidateExamDetailPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onHeldCandidateExamDetailsFilterChange = useCallback(
		(filterParam: ExamFilter) => {
			setHeldCandidatesFilter(filterParam);
			onHeldCandidateExamDetailsPageChange(
				1,
				heldCandidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE
			);
		},
		[
			onHeldCandidateExamDetailsPageChange,
			heldCandidateExamDetailPaging.pageSize,
		]
	);

	const fetchHeldCandidateExamDetailList = useCallback(
		async (pageNumber: number, pageSize: number, filter: ExamFilter) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;

				const candidateExamDetailWithoutKey = await callApi(
					() =>
						getCandidateExamDetails({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							examFilter: filter,
						}),
					'errorOccurredWhileLoading'
				);
				const CED = {
					...candidateExamDetailWithoutKey,
					...(candidateExamDetailWithoutKey.candidateExamDetails && {
						candidateExamDetails:
							candidateExamDetailWithoutKey.candidateExamDetails.map(
								(candidate) => ({
									...candidate,
									key: candidate.id,
								})
							),
					}),
				};

				if (
					CED.pagination.totalNoOfPages &&
					CED.pagination.totalNoOfRecords > 0 &&
					pageNumber > CED.pagination.totalNoOfPages
				) {
					onHeldCandidateExamDetailsPageChange(
						CED.pagination.totalNoOfPages ?? 1,
						pageSize
					);
				} else {
					setHeldCandidateExamDetailList(CED);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onHeldCandidateExamDetailsPageChange, srcmId]
	);

	const callFetchHeldCandidateExamDetailList = useCallback(() => {
		const examId = examById?.id;
		if (examId && heldCandidatesFilter.examId) {
			void fetchHeldCandidateExamDetailList(
				heldCandidateExamDetailPaging.pageNo ?? 1,
				heldCandidateExamDetailPaging.pageSize ?? DEFAULT_PAGE_SIZE,
				heldCandidatesFilter
			);
		}
	}, [
		examById,
		heldCandidateExamDetailPaging,
		heldCandidatesFilter,
		fetchHeldCandidateExamDetailList,
	]);

	useEffect(() => {
		void callFetchHeldCandidateExamDetailList();
	}, [callFetchHeldCandidateExamDetailList]);

	const approveCandidate = useCallback(
		async (candidateExaminationDetailId: number) => {
			if (srcmId) {
				setLoading(true);
				try {
					const candidateExaminationDetail = await approveHeldCandidate({
						functionarySrcmId: srcmId,
						candidateExaminationDetailId,
					});
					if (candidateExaminationDetail.id) {
						return true;
					} else {
						return false;
					}
				} catch (e) {
					if (e instanceof AxiosError) {
						// eslint-disable-next-line
						showToastMessage('error', e?.response?.data?.message || e.message);
					} else if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
					return false;
				} finally {
					setLoading(false);
				}
			}
		},
		[setLoading, showToastMessage, srcmId]
	);

	return {
		approveCandidate,
		heldCandidatesFilter,
		examById,
		heldCandidateExamDetailList,
		heldCandidateExamDetailPaging,
		callFetchHeldCandidateExamDetailList,
		onHeldCandidateExamDetailsPageChange,
		onHeldCandidateExamDetailsFilterChange,
	};
};
