import { proxy } from 'valtio';
import {
	ApplicationsFilter,
	PagingConfig,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';

export const FunctionariesListState = proxy<{
	filterBy: ApplicationsFilter | undefined;
	paging: PagingConfig;
}>({
	filterBy: undefined,
	paging: {
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
});
