import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
} from 'react';
import { MeProfile } from '../../../shared/MySrcm';
import { ApplicantAdditionalDetailsType } from '../../../components/ApplicantAdditionalDetailsType';
import { CategoryAnswerType } from './CategoryAnswerType';
import { TermsAndConditions } from './TermsAndConditionsType';
import { useTranslation } from 'react-i18next';
import { submitNewApplication } from '../../../service/VolunteerApplicationService';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	transformAdditionalDetailsToGRPC,
	transformAnswerToGRPC,
	transformMySrcmProfileToGRPCUser,
} from '../../../shared/Utils';
import { Answer } from '../../../shared/VolunteerApplicationServiceTypes';
import { EvaluationCategoryID } from '../../../shared/Constants';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useCallApi } from '../../../hooks/useCallApi';
import { Modal } from 'antd';
import { WelcomeAndSelfConsciousness } from './WelcomeAndSelfConsciousness';

export interface NewApplicationContextType {
	mySrcmProfile: MeProfile;
	setMySrcmProfile: (mySrcmProfile: MeProfile) => void;
	additionalApplicantDetails: ApplicantAdditionalDetailsType;
	setAdditionalApplicantDetails: (
		additionalDetails: ApplicantAdditionalDetailsType
	) => void;
	basicEligibilityCategoryAnswers: CategoryAnswerType;
	setBasicEligibilityCategoryAnswers: (answers: CategoryAnswerType) => void;
	inspirationAndWillingnessCategoryAnswers: CategoryAnswerType;
	setInspirationAndWillingnessCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	practiceCategoryAnswers: CategoryAnswerType;
	setPracticeCategoryAnswers: (answers: CategoryAnswerType) => void;
	characterAndHabitsCategoryAnswers: CategoryAnswerType;
	setCharacterAndHabitsCategoryAnswers: (answers: CategoryAnswerType) => void;
	availabilityAfterProgramCategoryAnswers: CategoryAnswerType;
	setAvailabilityAfterProgramCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	supportCategoryAnswers: CategoryAnswerType;
	setSupportCategoryAnswers: (answers: CategoryAnswerType) => void;
	understandingOfHeartfulnessCategoryAnswers: CategoryAnswerType;
	setUnderstandingOfHeartfulnessCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	dietaryHabitsAndHealthCategoryAnswers: CategoryAnswerType;
	setDietaryHabitsAndHealthCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	termsAndConditions: TermsAndConditions;
	setTermsAndConditions: (tnc: TermsAndConditions) => void;
	welcomeAndSelfConsciousness: WelcomeAndSelfConsciousness;
	setWelcomeAndSelfConsciousness: (wsc: WelcomeAndSelfConsciousness) => void;
	onSubmitClick: () => void;
	isEmailVerified: boolean;
	setIsEmailVerified: (isEmailVerified: boolean) => void;
}

interface Props {
	children: ReactNode;
}

const NewApplicationContext = createContext<NewApplicationContextType>({
	mySrcmProfile: {},
	setMySrcmProfile: () => {},
	additionalApplicantDetails: {},
	setAdditionalApplicantDetails: () => {},
	basicEligibilityCategoryAnswers: {},
	setBasicEligibilityCategoryAnswers: () => {},
	inspirationAndWillingnessCategoryAnswers: {},
	setInspirationAndWillingnessCategoryAnswers: () => {},
	practiceCategoryAnswers: {},
	setPracticeCategoryAnswers: () => {},
	characterAndHabitsCategoryAnswers: {},
	setCharacterAndHabitsCategoryAnswers: () => {},
	availabilityAfterProgramCategoryAnswers: {},
	setAvailabilityAfterProgramCategoryAnswers: () => {},
	supportCategoryAnswers: {},
	setSupportCategoryAnswers: () => {},
	understandingOfHeartfulnessCategoryAnswers: {},
	setUnderstandingOfHeartfulnessCategoryAnswers: () => {},
	dietaryHabitsAndHealthCategoryAnswers: {},
	setDietaryHabitsAndHealthCategoryAnswers: () => {},
	termsAndConditions: {},
	setTermsAndConditions: () => {},
	welcomeAndSelfConsciousness: {},
	setWelcomeAndSelfConsciousness: () => {},
	onSubmitClick: () => {},
	isEmailVerified: false,
	setIsEmailVerified: () => {},
});

export const NewApplicationProvider: FC<Props> = ({ children }: Props) => {
	const [mySrcmProfile, setMySrcmProfile] = useState<MeProfile>({});
	const [additionalApplicantDetails, setAdditionalApplicantDetails] =
		useState<ApplicantAdditionalDetailsType>({});
	const [basicEligibilityCategoryAnswers, setBasicEligibilityCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		inspirationAndWillingnessCategoryAnswers,
		setInspirationAndWillingnessCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [practiceCategoryAnswers, setPracticeCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		characterAndHabitsCategoryAnswers,
		setCharacterAndHabitsCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [
		availabilityAfterProgramCategoryAnswers,
		setAvailabilityAfterProgramCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [supportCategoryAnswers, setSupportCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		understandingOfHeartfulnessCategoryAnswers,
		setUnderstandingOfHeartfulnessCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [
		dietaryHabitsAndHealthCategoryAnswers,
		setDietaryHabitsAndHealthCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [termsAndConditions, setTermsAndConditions] =
		useState<TermsAndConditions>({});
	const [welcomeAndSelfConsciousness, setWelcomeAndSelfConsciousness] =
		useState<WelcomeAndSelfConsciousness>({});
	const { t, i18n } = useTranslation();
	const { batchId } = useBatchId();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { callApi } = useCallApi();
	const [isEmailVerified, setIsEmailVerified] = useState(false);

	const onConfirmSubmit = useCallback(async () => {
		const answers: Array<Answer> = [];
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.BASIC_ELIGIBILITY,
				basicEligibilityCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.INSPIRATION_AND_WILLINGNESS,
				inspirationAndWillingnessCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.PRACTICE,
				practiceCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.CHARACTER_AND_HABITS,
				characterAndHabitsCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.AVAILABILITY_AFTER_PROGRAM,
				availabilityAfterProgramCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.SUPPORT,
				supportCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.UNDERSTANDING_OF_HEARTFULNESS,
				understandingOfHeartfulnessCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.DIETARY_HABITS_AND_HEALTH,
				dietaryHabitsAndHealthCategoryAnswers
			)
		);

		const request = {
			userDetails: transformMySrcmProfileToGRPCUser(mySrcmProfile),
			additionalDetails: transformAdditionalDetailsToGRPC(
				additionalApplicantDetails
			),
			userAnswers: {
				questionsAndAnswers: answers,
				language: i18n.language,
			},
			batchId,
		};

		try {
			await callApi(
				() => submitNewApplication(request),
				'errorOccurredWhileSubmittingApplication'
			);
			Modal.success({
				content: t('submittedApplicationSuccessfully'),
				afterClose: () => window.location.reload(),
			});
		} catch (e) {
			// handled in callApi
		}
	}, [
		additionalApplicantDetails,
		availabilityAfterProgramCategoryAnswers,
		basicEligibilityCategoryAnswers,
		batchId,
		callApi,
		characterAndHabitsCategoryAnswers,
		dietaryHabitsAndHealthCategoryAnswers,
		i18n.language,
		inspirationAndWillingnessCategoryAnswers,
		mySrcmProfile,
		practiceCategoryAnswers,
		supportCategoryAnswers,
		t,
		understandingOfHeartfulnessCategoryAnswers,
	]);

	const onSubmitClick = useCallback(() => {
		confirmBeforeAction(
			'confirmSubmit',
			'applicationOnceSubmittedCanNotBeChanged',
			onConfirmSubmit
		);
	}, [confirmBeforeAction, onConfirmSubmit]);

	const value = useMemo<NewApplicationContextType>(() => {
		return {
			mySrcmProfile,
			setMySrcmProfile,
			additionalApplicantDetails,
			setAdditionalApplicantDetails,
			basicEligibilityCategoryAnswers,
			setBasicEligibilityCategoryAnswers,
			inspirationAndWillingnessCategoryAnswers,
			setInspirationAndWillingnessCategoryAnswers,
			practiceCategoryAnswers,
			setPracticeCategoryAnswers,
			characterAndHabitsCategoryAnswers,
			setCharacterAndHabitsCategoryAnswers,
			availabilityAfterProgramCategoryAnswers,
			setAvailabilityAfterProgramCategoryAnswers,
			supportCategoryAnswers,
			setSupportCategoryAnswers,
			understandingOfHeartfulnessCategoryAnswers,
			setUnderstandingOfHeartfulnessCategoryAnswers,
			dietaryHabitsAndHealthCategoryAnswers,
			setDietaryHabitsAndHealthCategoryAnswers,
			termsAndConditions,
			setTermsAndConditions,
			welcomeAndSelfConsciousness,
			setWelcomeAndSelfConsciousness,
			onSubmitClick,
			isEmailVerified,
			setIsEmailVerified,
		};
	}, [
		mySrcmProfile,
		additionalApplicantDetails,
		basicEligibilityCategoryAnswers,
		inspirationAndWillingnessCategoryAnswers,
		practiceCategoryAnswers,
		characterAndHabitsCategoryAnswers,
		availabilityAfterProgramCategoryAnswers,
		supportCategoryAnswers,
		understandingOfHeartfulnessCategoryAnswers,
		dietaryHabitsAndHealthCategoryAnswers,
		termsAndConditions,
		welcomeAndSelfConsciousness,
		isEmailVerified,
		onSubmitClick,
	]);

	return (
		<NewApplicationContext.Provider value={value}>
			{children}
		</NewApplicationContext.Provider>
	);
};

export const useNewApplication = () => {
	return useContext(NewApplicationContext);
};
