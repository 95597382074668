import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
	getFunctionary,
	getZonesOfLoggedInPreceptor,
} from '../service/VolunteerApplicationService';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useRedirectToLogin } from '../hooks/useRedirectToLogin';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';
import { useSnapshot } from 'valtio';
import { PreceptorBatchState } from '../pages/nps/CreateGroupPage/PreceptorsList.store';
import { Zone } from '../shared/ExamModuleTypes';

const { Option } = Select;

interface OptionData {
	value: string;
}

interface RemoteSingleSelectProps {
	onChange?: (value: Array<OptionData>) => void;
	value?: Array<OptionData> | null | undefined;
	functionarySrcmId?: string;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
	functionarySrcmId,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const preceptorBatchSnap = useSnapshot(PreceptorBatchState);
	const selectedBatchId = preceptorBatchSnap.selectedBatch?.id;
	const { role } = useRedirectToLogin();
	const isAdmin = role === UserRole.ADMIN;

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);

			if (functionarySrcmId && !isAdmin) {
				const zonesPage = await getFunctionary({
					functionarySrcmId,
				});

				if (zonesPage?.zcZone) {
					const transformedOptions: OptionData[] = zonesPage.zcZone
						.filter((item: string) =>
							item.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((item: string) => ({
							value: item,
						}));

					setOptions(transformedOptions);
				} else if (zonesPage?.approverZone) {
					const transformedOptions: OptionData[] = zonesPage.approverZone
						.filter((item: string) =>
							item.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((item: string) => ({
							value: item,
						}));

					setOptions(transformedOptions);
				} else if (zonesPage?.zone) {
					const transformedOptions: OptionData[] = zonesPage.zone
						.filter((item: string) =>
							item.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((item: string) => ({
							value: item,
						}));

					setOptions(transformedOptions);
				} else {
					setOptions([]);
				}
			} else if (functionarySrcmId && isAdmin && selectedBatchId) {
				const zonesPage = await getZonesOfLoggedInPreceptor({
					functionarySrcmId,
					preceptorBatchId: selectedBatchId,
				});
				if (zonesPage?.zones) {
					const transformedOptions: OptionData[] = zonesPage.zones
						.filter((item: Zone) =>
							item.zoneName?.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((item: Zone) => ({
							value: item.zoneName,
						}));

					setOptions(transformedOptions);
				} else {
					setOptions([]);
				}
			}

			setLoading(false);
		},
		[functionarySrcmId, selectedBatchId, isAdmin]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={true}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchZones')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				// eslint-disable-next-line
				<Option key={option.value} value={option.value}>
					{option.value}
				</Option>
			))}
		</Select>
	);
};

export default RemoteSingleSelect;
