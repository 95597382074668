import { mySRCMClient, myStaticProfileUpdateClient } from './HTTPClient';
import { MeProfilePage, StatesResponse } from '../shared/MySrcm';

export const getMeProfile = async () => {
	const response = await mySRCMClient.get<MeProfilePage>('me/');
	return response.data;
};

export const getStates = async (): Promise<StatesResponse> => {
	const response = await mySRCMClient.get<StatesResponse>('states/');
	return response.data;
};

export const getCountryProfile = async (searchValue: string) => {
	const response = await mySRCMClient.get<MeProfilePage>(
		`countries/?page_size=200&name__icontains=${searchValue}`
	);
	return response.data;
};
export const getStateProfile = async (
	CountryValue: string | number,
	searchValue: string
) => {
	const response = await mySRCMClient.get<MeProfilePage>(
		`states/?country=${CountryValue}&page_size=200&name__icontains=${searchValue}`
	);
	return response.data;
};
export const getCityProfile = async (
	StateValue: number | string,
	searchValue: string | number
) => {
	const response = await mySRCMClient.get<MeProfilePage>(
		`cities/?state=${StateValue}&page_size=200&name__icontains=${searchValue}`
	);
	return response.data;
};

export const getZonesProfile = async () => {
	const response = await myStaticProfileUpdateClient.get<MeProfilePage>(
		'/groups/zone/all.json'
	);
	return response.data;
};

export const getCenterProfile = async (parentID: number, searchKey: string) => {
	const response = await mySRCMClient.get<MeProfilePage>(
		`/groups/?group_type=center&parent=${parentID}&page_size=200&name__icontains=${searchKey}`
	);
	return response.data;
};
