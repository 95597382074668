import { useCallback, useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import { Form } from 'antd';
import { ApplicantReference } from '../../../shared/VolunteerApplicationServiceTypes';

export default function useApplicantReferenceFormHandling(
	reference: ApplicantReference,
	setReference: (value: ApplicantReference) => void
) {
	const { setNextButtonDisabled } = useWizard();
	const [form] = Form.useForm<ApplicantReference>();
	const values = Form.useWatch<ApplicantReference>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setReference(fieldValues);
			setNextButtonDisabled(false);
		} catch (e) {
			const fields = e as { values: ApplicantReference };
			setReference(fields.values);
			setNextButtonDisabled(true);
		}
	}, [form, setReference, setNextButtonDisabled]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(reference);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return { form };
}
