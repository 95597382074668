import { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import { BatchFormType } from './BatchFormType';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useCreateOrUpdateBatch } from './useCreateOrUpdateBatch';
import { VALID_BATCH_NAME_REGEX } from '../../../shared/Constants';
import { Status } from '../../../shared/ExamModuleTypes';

extend(dayjsUTC);

const { TextArea } = Input;

export const BatchForm = ({ toUpdate }: { toUpdate?: boolean }) => {
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const navigate = useNavigate();
	const [form] = Form.useForm<BatchFormType>();
	const formValues = Form.useWatch<BatchFormType>([], form);
	const { onSubmitClick, fetchBatchById, preceptorBatchId } =
		useCreateOrUpdateBatch();
	const [extraFields, setExtraFields] = useState({
		status: 'INACTIVE' as Status,
		arePreceptorsLoadedFromMySRCM: false,
		totalNoOfPreceptorsToBeLoaded: 0,
		totalNoOfPreceptorsLoaded: 0,
	});

	const initializeForm = useCallback(async () => {
		try {
			const fetchedBatch = await fetchBatchById();
			if (fetchedBatch) {
				const initialValues: BatchFormType = {
					batchName: fetchedBatch.batchName,
					startDate: dayjs(fetchedBatch.startDate).utc() as Moment,
					endDate: dayjs(fetchedBatch?.endDate).utc() as Moment,
					description: fetchedBatch.description,
				};
				form.setFieldsValue(initialValues);

				setExtraFields({
					status: fetchedBatch.status,
					arePreceptorsLoadedFromMySRCM:
						fetchedBatch.arePreceptorsLoadedFromMySRCM,
					totalNoOfPreceptorsToBeLoaded:
						fetchedBatch.totalNoOfPreceptorsToBeLoaded,
					totalNoOfPreceptorsLoaded: fetchedBatch.totalNoOfPreceptorsLoaded,
				});
			}
		} catch (e) {
			//
		}
	}, [fetchBatchById, form]);

	useEffect(() => {
		if (preceptorBatchId) {
			void initializeForm();
		}
	}, [initializeForm, preceptorBatchId]);

	const handleSubmitClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				const finalFormValues = {
					...formValues,
					...(toUpdate ? extraFields : { status: 'ACTIVE' as Status }),
				};
				onSubmitClick(finalFormValues);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [
		extraFields,
		formValues,
		form,
		showToastMessage,
		t,
		toUpdate,
		onSubmitClick,
	]);

	return (
		<Form
			id="examCreationForm"
			name="examCreationForm"
			layout="horizontal"
			size="middle"
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			autoComplete="off"
			form={form}
		>
			<div className="flex flex-col md:flex-row justify-between">
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-wrap gap-4">
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchName')}
									</span>
								}
								name="batchName"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_BATCH_NAME_REGEX,
										message: <span>{t('spacesNotAllowed')}</span>,
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('newPreceptorStartDate')}
									</span>
								}
								name="startDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={'DD MMM YYYY'}
									disabled={toUpdate}
									disabledDate={(current) =>
										current && current < moment().endOf('day')
									}
									onChange={(date) => {
										const endDate = form.getFieldValue(
											'endDate'
										) as dayjs.Dayjs;
										if (date && endDate && date.isAfter(endDate, 'day')) {
											form.setFieldsValue({ endDate: undefined });
										}
									}}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('newPreceptorEndDate')}
									</span>
								}
								name="endDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								dependencies={['startDate']}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={'DD MMM YYYY'}
									disabled={toUpdate}
									disabledDate={(current: dayjs.Dayjs) => {
										const startDate = form.getFieldValue(
											'startDate'
										) as dayjs.Dayjs;
										if (!startDate) return true;
										return (
											current &&
											current.isBefore(dayjs(startDate).add(1, 'day'), 'day')
										);
									}}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/4">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchDescription')}
									</span>
								}
								name="description"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_BATCH_NAME_REGEX,
										message: <span>{t('spacesNotAllowed')}</span>,
									},
								]}
							>
								<TextArea rows={4} />
							</Form.Item>
						</div>
					</div>
				</div>
			</div>
			<div className="flex gap-4 mt-10">
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 text-base"
						htmlType="reset"
						onClick={() => navigate(-1)}
						disabled={false}
					>
						{t('back')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button className="w-36 h-12 mb-5 text-base" htmlType="reset" danger>
						{t('clear')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 bg-nav-blue hover:!bg-hover-nav-blue text-base"
						type="primary"
						htmlType="submit"
						onClick={handleSubmitClick}
					>
						{t('submit')}
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};
