import { withRedirectToLogin } from '../../hocs/withRedirectToLogin';
import { Layout } from 'antd';
import { AppHeader } from '../AppHeader';

import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { MeProfile } from '../../shared/MySrcm';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { useBatchId } from '../../hooks/useBatchId';
import { FUNCTIONARY_HOME } from '../../shared/Routes';

const { Content } = Layout;

interface LayoutProps {
	logout?: () => Promise<void>;
	firebaseId?: string | null;
	srcmId?: string;
	mySrcmProfile?: MeProfile | null;
	loading?: boolean;
	role?: UserRole;
}

export const ApplicantLayout: FC<LayoutProps> = ({
	logout,
	firebaseId,
	srcmId,
	loading,
	role,
}: LayoutProps) => {
	const { navigateWithBatchId } = useBatchId();

	if (
		role &&
		[
			UserRole.FUNCTIONARY,
			UserRole.APPROVER,
			UserRole.CARE_TEAM,
			UserRole.ZC,
			UserRole.BATCH_ADMIN,
			UserRole.ADMIN,
		].includes(role)
	) {
		navigateWithBatchId(FUNCTIONARY_HOME);
	}

	const isApplicantRole = role === UserRole.APPLICANT;

	return (
		<div className="flex justify-center bg-neutral-50">
			<div className="border max-wpx-[8px] max-w-[600px]">
				<Layout className="mainlayout rounded-md p-[8px] bg-white">
					<AppHeader logout={logout} />
					<Content className="bg-white">
						{!loading && isApplicantRole && (
							<Outlet context={{ srcmId, firebaseId, role }} />
						)}
					</Content>
				</Layout>
			</div>
		</div>
	);
};

export default withRedirectToLogin<object>(ApplicantLayout);
