import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PreceptorBatchFilter } from '../../../shared/VolunteerApplicationServiceTypes';
import { Button, Form, Input, Typography } from 'antd';
import { VALID_BATCH_NAME_REGEX } from '../../../shared/Constants';

interface FilterProps {
	value?: PreceptorBatchFilter;
	onFilterChange: (value: PreceptorBatchFilter) => void;
}

interface PreceptorBatchFilterFormType {
	batchName?: string;
}

const { Text } = Typography;

export const FilterBatches = ({ value, onFilterChange }: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<PreceptorBatchFilterFormType>();
	const values = Form.useWatch<PreceptorBatchFilterFormType>([], form);

	const handleFilterButtonClick = useCallback(() => {
		const filterValues: PreceptorBatchFilter = {
			...values,
			batchName: values.batchName as string,
		};
		onFilterChange(filterValues);
	}, [onFilterChange, values]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({ batchName: undefined });
		onFilterChange({});
	}, [form, onFilterChange]);

	return (
		<div className="flex flex-col mb-4">
			<Form
				name="basic-group"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				initialValues={value}
				autoComplete="off"
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 min-w-[170px]">
							<Text className="pl-1">{t('batchName')}</Text>
							<Form.Item
								className="mb-2"
								name={'batchName'}
								rules={[
									{
										pattern: VALID_BATCH_NAME_REGEX,
										message: <span>{t('spacesNotAllowed')}</span>,
									},
								]}
							>
								<Input />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start my-2">
					<Button type="primary" onClick={handleFilterButtonClick}>
						{t('search')}
					</Button>
				</div>
				<div className="self-start my-2">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
