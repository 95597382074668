import { useCallback, useEffect, useState } from 'react';
import { Exam } from '../../shared/ExamModuleTypes';
import { useAuth } from '../../hooks/useAuth';
import { useCallApi } from '../../hooks/useCallApi';
import { getActiveExam } from '../../service/ExamModuleService';

export const useExamModule = () => {
	const [activeExam, setActiveExam] = useState<Exam>();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();

	const fetchActiveExam = useCallback(async () => {
		if (srcmId) {
			const response = await callApi(
				() =>
					getActiveExam({
						srcmId,
					}),
				'errorOccurredWhileLoading'
			);
			setActiveExam(response);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [srcmId]);

	useEffect(() => {
		if (srcmId) {
			void fetchActiveExam();
		}
	}, [fetchActiveExam, srcmId]);

	return { activeExam, fetchActiveExam };
};
