import { useCallback } from 'react';
import { CenterCreationFormType } from './CenterForm';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../hooks/useAuth';
import { useConfirmationBeforeAction } from '../../../../hooks/useConfirmationBeforeAction';
import { useBatchId } from '../../../../hooks/useBatchId';
import { useCallApi } from '../../../../hooks/useCallApi';
import { EXAM_MODULE_CENTERS_LIST } from '../../../../shared/Routes';
import { Modal } from 'antd';
import {
	createExaminationCenter,
	getExaminationCenterById,
	updateExaminationCenter,
} from '../../../../service/ExamModuleService';
import {
	ExaminationCenter,
	ExaminationCenterForCreation,
} from '../../../../shared/ExamModuleTypes';
import { useExamAndCenterId } from '../../../../hooks/useExamAndCenterId';

export const useCreateCenter = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { navigateWithBatchId } = useBatchId();
	const { centerId } = useExamAndCenterId();
	const { callApi } = useCallApi();

	const generateCenterForCreation = useCallback(
		(centerDetails: CenterCreationFormType) => {
			const centerForCreation: ExaminationCenterForCreation = {
				centerCode: centerDetails.centerCode,
				centerName: centerDetails.centerName,
				seatsCapacity: centerDetails.seatsCapacity,
				accommodationsCapacity: centerDetails.accommodationsCapacity,
				status: centerDetails.status,
				address: centerDetails.address,
				contactPersonName: centerDetails.contactPersonName,
				email: centerDetails.email,
				phone: centerDetails.phone,
			};
			if (centerId) {
				centerForCreation.id = centerId;
			}
			return centerForCreation;
		},
		[centerId]
	);

	const onConfirmSubmit = useCallback(
		async (centerDetails: CenterCreationFormType) => {
			if (centerDetails && srcmId) {
				try {
					const center = generateCenterForCreation(centerDetails);
					const request = {
						functionarySrcmId: srcmId,
						examinationCenter: center,
					};
					let response: ExaminationCenter;
					if (centerId > 0) {
						response = await callApi(
							() => updateExaminationCenter(request),
							'errorOccurredWhileUpdatingCenter'
						);
					} else {
						response = await callApi(
							() => createExaminationCenter(request),
							'errorOccurredWhileCreatingCenter'
						);
					}
					const successMessage =
						centerId > 0
							? t('updatedCenterSuccessfully')
							: t('createdCenterSuccessfully');
					if (response) {
						Modal.success({
							title: t('successful'),
							content: successMessage,
							afterClose: () => {
								navigateWithBatchId(EXAM_MODULE_CENTERS_LIST);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[
			navigateWithBatchId,
			callApi,
			srcmId,
			t,
			generateCenterForCreation,
			centerId,
		]
	);

	const onSubmitClick = useCallback(
		(centerDetails: CenterCreationFormType) => {
			const confirmationMessage =
				centerId > 0
					? t('centerUpdationConfirmMessage')
					: t('centerCreationConfirmMessage');
			confirmBeforeAction(
				'confirmSubmit',
				confirmationMessage,
				() => onConfirmSubmit(centerDetails),
				true
			);
		},
		[confirmBeforeAction, onConfirmSubmit, t, centerId]
	);

	const fetchCenterById = useCallback(async () => {
		if (srcmId && centerId) {
			const response = await callApi(
				() =>
					getExaminationCenterById({ srcmId, examinationCenterId: centerId }),
				'errorOccurredWhileFetchingCenter'
			);
			return response;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centerId, srcmId]);

	return {
		centerId,
		fetchCenterById,
		onSubmitClick,
	};
};
