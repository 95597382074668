import { Typography, Form, FormInstance, Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { RecommendationComponent } from '../RecommendationComponent';
import { PredefinedReasonCategory } from '../../../shared/Constants';
import { useCareTeamRecommendationPage } from './useCareTeamRecommendationPage';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import { UserRole } from '../../../shared/VolunteerApplicationServiceTypes';

const { Text, Title } = Typography;

interface CareTeamRecommendationCollectionPageProps {
	form: FormInstance;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CareTeamRecommendationCollectionPage = ({
	form,
}: CareTeamRecommendationCollectionPageProps) => {
	const { t } = useTranslation();
	const { role } = useRedirectToLogin();
	const canDisplayComments =
		role === UserRole.CARE_TEAM || role === UserRole.APPROVER;
	const disableReview = role !== UserRole.CARE_TEAM;

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader
					heading={t('provideCareTeamFinalRecommendation')}
					description={t('pleaseProvideBasedOnAllInterviews')}
				/>
				<div className="flex flex-row items-start">
					<div className="flex flex-col w-full">
						<Text>{t('iRecommendTheCandidatesApplicationToBe')}</Text>
						<RecommendationComponent
							commentsMandatory
							commentsLabel="yourCommentsToSupportAboveRecommendation"
							commentsPlaceholder="commentsAreMandatory"
							form={form}
							category={PredefinedReasonCategory.CARE_TEAM_REJECT}
							displayComments={canDisplayComments}
							disableReview={disableReview}
						/>
					</div>
				</div>
			</Form>
		</div>
	);
};

export const CareTeamRecommendationCollectionPageContainer = () => {
	const { t } = useTranslation();
	const { form, onSubmitClick, onCancelClick, submitButtonDisabled } =
		useCareTeamRecommendationPage();
	const { role } = useRedirectToLogin();

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<CareTeamRecommendationCollectionPage form={form} />
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled || role !== UserRole.CARE_TEAM}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
			{role !== UserRole.CARE_TEAM && (
				<Alert
					showIcon
					message={t('onlyCareTeamIsAllowedToSubmitFeedback')}
					type="warning"
				/>
			)}
		</div>
	);
};
