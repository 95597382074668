import { Button, Space, TableColumnsType, Tooltip, Typography } from 'antd';
import {
	Preceptor,
	PreceptorBatch,
	PreceptorGroup,
	PreceptorLanguage,
} from './VolunteerApplicationServiceTypes';
import { useTranslation } from 'react-i18next';
import { useGroupId } from '../hooks/useGroupId';
import { useCallback } from 'react';
import { ASSIGN_MENTOR, EDIT_BATCH, EDIT_GROUP, VIEW_GROUP } from './Routes';
import { utc } from 'moment';
import { CandidateExaminationDetail } from './ExamModuleTypes';
import { CalendarOutlined } from '@ant-design/icons';
import { useBatchId } from '../hooks/useBatchId';
import { DATE_DD_MM_YYYY } from './Constants';

export const PreceptorTableColumns = (
	hideSorter?: boolean
): TableColumnsType<Preceptor> => {
	const { t } = useTranslation();

	const concatLanguageNames = (languages: Array<PreceptorLanguage>): string => {
		return languages
			? languages?.map((language) => language.name).join(', ')
			: '-';
	};

	const COLUMN_STYLE = 'w-48';

	return [
		{
			title: t('batch'),
			dataIndex: 'batch',
			key: 'batch',
			render: (value, __, index) => (
				<Typography.Text key={index} ellipsis={true}>
					{value}
				</Typography.Text>
			),
		},
		{
			title: t('name'),
			dataIndex: 'firstName',
			key: 'name',
			render: (value, record, index) => (
				<Typography.Text
					key={index}
					ellipsis={true}
					className={`truncate ${COLUMN_STYLE}`}
				>
					{value} {record.middleName} {record.lastName}
				</Typography.Text>
			),
		},
		{
			title: t('gender'),
			dataIndex: 'gender',
			key: 'gender',
		},
		{
			title: t('cityFilterName'),
			dataIndex: 'city',
			key: 'city',
			sorter: !hideSorter && {
				multiple: 1,
			},
		},
		{
			title: t('pincode'),
			dataIndex: 'postalCode',
			key: 'postalCode',
			sorter: !hideSorter && {
				multiple: 2,
			},
		},
		{
			title: t('address'),
			dataIndex: 'addressLine1',
			key: 'address',
			render: (__, record, index) => {
				const address = `${record?.addressLine1} ${record?.addressLine2} ${record?.addressLine3} ${record?.addressLine4}`;
				const lines = address.split(' ');
				const halfIndex = Math.ceil(lines.length / 2);
				const firstHalf = lines.slice(0, halfIndex).join(' ');
				const secondHalf = lines.slice(halfIndex).join(' ');

				return (
					<Tooltip title={address}>
						<div>
							<Typography.Text
								key={`${index}-line1`}
								ellipsis={true}
								className={`truncate ${COLUMN_STYLE}`}
							>
								{firstHalf}
							</Typography.Text>
							<br />
							<Typography.Text
								key={`${index}-line2`}
								ellipsis={true}
								className={`truncate ${COLUMN_STYLE}`}
							>
								{secondHalf}
							</Typography.Text>
						</div>
					</Tooltip>
				);
			},
		},
		{
			title: t('state'),
			dataIndex: 'state',
			key: 'state',
			sorter: !hideSorter && {
				multiple: 3,
			},
			render: (value, __, index) => (
				<Typography.Text key={index} ellipsis={true}>
					{value}
				</Typography.Text>
			),
		},
		{
			title: t('zone'),
			dataIndex: 'zone',
			key: 'zone',
			render: (value, __, index) => (
				<Typography.Text key={index} ellipsis={true}>
					{value}
				</Typography.Text>
			),
		},
		{
			title: t('mobile'),
			dataIndex: 'mobile',
			key: 'mobile',
		},
		{
			title: t('email'),
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: t('abhyasiId'),
			dataIndex: 'srcmId',
			key: 'abhyasiId',
		},
		{
			title: t('country'),
			dataIndex: 'country',
			key: 'country',
		},
		{
			title: t('dateOfPreceptor'),
			dataIndex: 'prefectStartDate',
			key: 'prefectStartDate',
			render: (__, record, index) => (
				<Typography.Text key={index} ellipsis={true}>
					{utc(record.prefectStartDate).format('DD-MM-YYYY')}
				</Typography.Text>
			),
		},
		{
			title: t('languages'),
			dataIndex: 'languages',
			key: 'languages',
			render: (__, record, index) => (
				<span key={index}>{concatLanguageNames(record?.languages)}</span>
			),
		},
		{
			title: t('preceptor'),
			dataIndex: 'respPrefectName',
			key: 'preceptor',
			render: (value, __, index) => (
				<Typography.Text key={index} ellipsis={true}>
					{value}
				</Typography.Text>
			),
		},
	];
};

const getPreceptorNames = (preceptorGroupMembers: Array<Preceptor>) => {
	return preceptorGroupMembers
		.map((item) => {
			const { firstName, lastName } = item;
			return `${firstName} ${lastName}`;
		})
		.join(', ');
};

export const GroupsTableColumns = (): TableColumnsType<PreceptorGroup> => {
	const { t } = useTranslation();
	const { navigateWithGroupId } = useGroupId();

	const handleAssignMentorClick = useCallback(
		(groupId: number) => {
			navigateWithGroupId(ASSIGN_MENTOR, groupId);
		},
		[navigateWithGroupId]
	);

	const handleEditGroupClick = useCallback(
		(groupId: number) => {
			navigateWithGroupId(EDIT_GROUP, groupId);
		},
		[navigateWithGroupId]
	);

	const handleViewGroupClick = useCallback(
		(groupId: number) => {
			navigateWithGroupId(VIEW_GROUP, groupId);
		},
		[navigateWithGroupId]
	);

	return [
		{
			title: t('groupId'),
			dataIndex: 'groupNumber',
			key: 'groupNumber',
		},
		{
			title: t('newPreceptorNames'),
			dataIndex: 'preceptorGroupMembers',
			key: 'preceptorGroupMembers',
			render: (__, record, index) => (
				<span key={index}>
					{getPreceptorNames(record.preceptorGroupMembers)}
				</span>
			),
		},
		{
			title: t('mentorName'),
			dataIndex: 'mentor',
			key: 'mentorName',
			render: (__, record, index) =>
				record.mentor ? (
					<span key={index}>
						{record.mentor.firstName} {record.mentor.middleName}{' '}
						{record.mentor.lastName}
					</span>
				) : (
					'-'
				),
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleEditGroupClick(record.id)}
					>
						{t('editOrUpdate')}
					</a>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewGroupClick(record.id)}
					>
						{t('view')}
					</a>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleAssignMentorClick(record.id)}
					>
						{t('assignMentor')}
					</a>
				</Space>
			),
		},
	];
};

export const CandidateTableColumns = (
	hanldeHoldClick: (candidate: CandidateExaminationDetail) => void,
	hanldeEditClick: (candidate: CandidateExaminationDetail) => void,
	displayHold: boolean
): TableColumnsType<CandidateExaminationDetail> => {
	const { t } = useTranslation();

	const handleHoldCandidateClick = useCallback(
		(candidate: CandidateExaminationDetail) => {
			hanldeHoldClick(candidate);
		},
		[hanldeHoldClick]
	);

	const handleEditCandidateClick = useCallback(
		(candidate: CandidateExaminationDetail) => {
			hanldeEditClick(candidate);
		},
		[hanldeEditClick]
	);

	const candidateStatus = useCallback(
		(record: CandidateExaminationDetail) => {
			if (
				record.reasonForCancellingExam &&
				record.reasonForCancellingExam !== ''
			) {
				return t('cancelled');
			} else if (record.registrationStatus === 'REGISTERED') {
				return t('registered');
			} else if (record.registrationStatus === 'NOT_REGISTERED') {
				return t('notRegistered');
			}
		},
		[t]
	);

	return [
		{
			title: t('abhyasiId'),
			dataIndex: 'srcmId',
			key: 'abhyasiId',
			render: (__, record, index) => (
				<span key={index}>{record?.profile.srcmId}</span>
			),
		},
		{
			title: t('name'),
			dataIndex: 'profile.name',
			key: 'name',
			render: (__, record, index) => (
				<Tooltip title={record?.profile.name} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record?.profile.name}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('testCenter'),
			dataIndex: 'center',
			key: 'testCenter',
			render: (__, record, index) => (
				<span key={index}>{record?.examinationCenter.centerName}</span>
			),
		},
		{
			title: t('candidateCenter'),
			dataIndex: 'profile.center',
			key: 'candidateCenter',
			render: (__, record, index) => (
				<span key={index}>{record?.profile.center}</span>
			),
		},
		{
			title: t('examLanguage'),
			dataIndex: 'languages',
			key: 'languages',
			render: (__, record, index) => (
				<span key={index}>{record?.preferredLanguageForExamination}</span>
			),
		},
		{
			title: t('accommodation'),
			dataIndex: 'noOfAccommodations',
			key: 'noOfAccommodations',
			render: (__, record, index) => (
				<span key={index}>{record?.noOfAccommodations || 0}</span>
			),
		},
		{
			title: t('specialRequests'),
			dataIndex: 'specialRequest',
			key: 'specialRequest',
			render: (__, record, index) => (
				<Tooltip title={record?.specialRequest} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record?.specialRequest}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('admitCard'),
			dataIndex: 'admitCardNumber',
			key: 'admitCardNumber',
			render: (__, record, index) => (
				<span key={index}>{record?.admitCardNumber}</span>
			),
		},
		{
			title: t('totalMarks'),
			dataIndex: 'totalMarksObtained',
			key: 'totalMarksObtained',
			render: (__, record, index) => (
				<span key={index}>{record?.totalMarksObtained}</span>
			),
		},
		{
			title: t('percentage'),
			dataIndex: 'totalPercentage',
			key: 'totalPercentage',
			render: (__, record, index) => {
				const percent = record.totalPercentage && `${record.totalPercentage}%`;
				return <span key={index}>{percent}</span>;
			},
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (__, record, index) => (
				<span key={index}>{candidateStatus(record)}</span>
			),
		},
		{
			title: t('reasonForCancellingExam'),
			dataIndex: 'reasonForCancellingExam',
			key: 'reasonForCancellingExam',
			width: 150,
			render: (__, record, index) => (
				<Tooltip title={record?.reasonForCancellingExam} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record?.reasonForCancellingExam}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => {
				const showHoldLink =
					displayHold &&
					(!record.reasonForCancellingExam ||
						record.reasonForCancellingExam === '');

				return (
					<Space size="middle" className="whitespace-nowrap">
						<a
							className={`underline hover:underline-offset-4 ${
								showHoldLink ? '' : 'pointer-events-none text-gray'
							}`}
							onClick={() => handleHoldCandidateClick(record)}
						>
							{t('hold')}
						</a>
						<a
							className={`underline hover:underline-offset-4 ${
								showHoldLink ? '' : 'pointer-events-none text-gray'
							}`}
							onClick={() => handleEditCandidateClick(record)}
						>
							{t('editOrUpdate')}
						</a>
					</Space>
				);
			},
		},
	];
};

export const EligibleCandidateTableColumns =
	(): TableColumnsType<CandidateExaminationDetail> => {
		const { t } = useTranslation();

		return [
			{
				title: t('abhyasiId'),
				dataIndex: 'srcmId',
				key: 'abhyasiId',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.srcmId}</span>
				),
			},
			{
				title: t('name'),
				dataIndex: 'profile.name',
				key: 'name',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.name}</span>
				),
			},
			{
				title: t('candidateCenter'),
				dataIndex: 'profile.center',
				key: 'candidateCenter',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.center}</span>
				),
			},
			{
				title: t('registrationStatus'),
				dataIndex: 'registrationStatus',
				key: 'registrationStatus',
				render: (__, record, index) => {
					if (record.registrationStatus === 'NOT_REGISTERED') {
						return <span key={index}>{t('notRegistered')}</span>;
					} else if (record.registrationStatus === 'REGISTERED') {
						return <span key={index}>{t('registered')}</span>;
					}
				},
			},
			// {
			// 	title: t('examResultStatus'),
			// 	dataIndex: 'examResultStatus',
			// 	key: 'examResultStatus',
			// 	render: (__, record, index) => {
			// 		if (record.examResultStatus === 'CLEARED') {
			// 			return <span key={index}>{t('cleared')}</span>;
			// 		} else if (record.examResultStatus === 'NOT_CLEARED') {
			// 			return <span key={index}>{t('notCleared')}</span>;
			// 		}
			// 	},
			// },
		];
	};

export const BatchTableColumns = (
	loadPreceptorsClick: (batchId: number) => void
): TableColumnsType<PreceptorBatch> => {
	const { t } = useTranslation();
	const { navigateWithPreceptorBatchId } = useBatchId();

	const handleLoadPreceptorsClick = useCallback(
		(batchId: number) => {
			loadPreceptorsClick(batchId);
		},
		[loadPreceptorsClick]
	);

	const handleEditBatchClick = useCallback(
		(batchId: number) => {
			navigateWithPreceptorBatchId(EDIT_BATCH, batchId);
		},
		[navigateWithPreceptorBatchId]
	);

	return [
		{
			title: t('batchName'),
			dataIndex: 'batchName',
			key: 'batchName',
		},
		{
			title: t('startDate'),
			dataIndex: 'startDate',
			key: 'startDate',
			render: (__, record, index) => (
				<span key={index} className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{utc(record.startDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			render: (__, record, index) => (
				<span key={index} className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{utc(record.endDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('preceptorsInAims'),
			dataIndex: 'totalNoOfPreceptorsToBeLoaded',
			key: 'totalNoOfPreceptorsToBeLoaded',
			render: (__, record, index) => (
				<span key={index}>{record.totalNoOfPreceptorsToBeLoaded || 0}</span>
			),
		},
		{
			title: t('preceptorsLoaded'),
			dataIndex: 'totalNoOfPreceptorsLoaded',
			key: 'totalNoOfPreceptorsLoaded',
			render: (__, record, index) => (
				<span key={index}>{record.totalNoOfPreceptorsLoaded || 0}</span>
			),
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 250,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleEditBatchClick(record.id)}
					>
						{t('editOrUpdate')}
					</a>
					<Button
						onClick={() => handleLoadPreceptorsClick(record.id)}
						disabled={record.arePreceptorsLoadedFromMySRCM}
					>
						{t('loadPreceptors')}
					</Button>
				</Space>
			),
		},
	];
};
