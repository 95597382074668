import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from '../shared/Constants';

export const useApplicationStatusTranslation = () => {
	const { t } = useTranslation();

	const getTranslatedValue = useCallback(
		(applicationStatus?: string) => {
			switch (applicationStatus) {
				case ApplicationStatus.USER_SUBMITTED_APPLICATION:
					return t('proposerNotAssigned');
				case ApplicationStatus.FUNCTIONARY_ASSIGNED:
					return t('waitingForProposerReview');
				case ApplicationStatus.FUNCTIONARY_SUBMITTED:
					return t('proposerProvidedRecommendation');
				case ApplicationStatus.SYS_ANOTHER_FUNCTIONARY_ASSIGNED:
					return t('waitingForApproverReview');
				case ApplicationStatus.WAITING_FOR_CARE_TEAM:
					return t('waitingForCareTeamReview');
				case ApplicationStatus.APPROVED:
					return t('approved');
				case ApplicationStatus.REJECTED:
					return t('notGoingForward');
			}

			return null;
		},
		[t]
	);

	return { getTranslatedValue };
};
