import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { AvailabilityAfterProgramStep } from './AvailabilityAfterProgramStep';
import { CharacterAndHabitsStep } from './CharacterAndHabitsStep';
import { DietaryHabitsAndHealthStep } from './DietaryHabitsAndHealthStep';
import { InspirationAndWillingnessStep } from './InspirationAndWillingnessStep';
import { LandingPage } from './LandingPage';
import { PracticeStep } from './PracticeStep';
import { SupportStep } from './SupportStep';
import { UnderstandingOfHeartfulnessStep } from './UnderstandingOfHeartfulnessStep';
import {
	ApplicantInterviewProvider,
	useApplicantInterviewWizard,
} from './useApplicantInterviewWizard';

export const ApplicantInterviewWizard = () => {
	const { onSubmitClick, application, onCancelClick } =
		useApplicantInterviewWizard();

	const { applicant } = application;

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={onSubmitClick}
				onCancelClick={onCancelClick}
				steps={[
					<LandingPage applicantInfo={applicant} key={1} />,
					<InspirationAndWillingnessStep key={2} />,
					<PracticeStep key={4} />,
					<CharacterAndHabitsStep key={5} />,
					<AvailabilityAfterProgramStep key={6} />,
					<SupportStep key={7} />,
					<UnderstandingOfHeartfulnessStep key={8} />,
					<DietaryHabitsAndHealthStep key={9} />,
				]}
			/>
		</div>
	);
};

export const ApplicantInterviewWizardContainer = () => {
	return (
		<ApplicantInterviewProvider>
			<WizardProvider>
				<ApplicantInterviewWizard />
			</WizardProvider>
		</ApplicantInterviewProvider>
	);
};
