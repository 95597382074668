import { useCallback, useEffect, useState } from 'react';
import {
	ExamFilter,
	ExamResultGraphicalViewResponse,
} from '../../../../shared/ExamModuleTypes';
import { useAuth } from '../../../../hooks/useAuth';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useLoadingIndicator } from '../../../../hooks/useLoadingIndicator';
import { getExamResultGraphicalView } from '../../../../service/ExamModuleService';
import { useSnapshot } from 'valtio';
import { ExamState } from '../../ExamModule.store';

export const useExamResultsGraph = () => {
	const [examResult, setExamResult] = useState<
		ExamResultGraphicalViewResponse | undefined
	>();
	const { srcmId } = useAuth();
	const { showToastMessage } = useToastMessage();
	const { setLoading } = useLoadingIndicator();
	const examSnap = useSnapshot(ExamState);
	const examInSnap = examSnap.selectedExam;
	const [examFilter, setExamFilter] = useState<ExamFilter>({
		examId: examInSnap?.id,
	});

	useEffect(() => {
		if (examInSnap && examInSnap.id) {
			setExamFilter({ examId: examInSnap.id });
		}
	}, [examInSnap]);

	const onExamResultFilterChange = useCallback((filterParam: ExamFilter) => {
		setExamFilter(filterParam);
	}, []);

	const fetchExamResults = useCallback(
		async (filter: ExamFilter) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const response = await getExamResultGraphicalView({
					functionarySrcmId: srcmId,
					examFilter: filter,
				});
				setExamResult(response);
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
				setExamResult(undefined);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, showToastMessage, srcmId]
	);

	useEffect(() => {
		if (examInSnap?.id && examFilter.examId) {
			void fetchExamResults(examFilter);
		}
	}, [examInSnap, examFilter, fetchExamResults]);

	return {
		examResult,
		examFilter,
		onExamResultFilterChange,
	};
};
