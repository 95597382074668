import { Typography, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import { Answers } from '../../../shared/VolunteerApplicationServiceTypes';
import { ApplicantAnswerToQuestion } from '../ApplicantAnswerToQuestion';

const { Text, Title } = Typography;

interface ApplicantAnswersToCategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
	answers?: Answers;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mb-[8px]">
			<Text className="mt-[24px]" type="secondary">
				{t('candidateApplication')}
			</Text>
			<Title level={4} className="mt-0">
				{heading}
			</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const CategoryViewApplicantAnswersStep = ({
	uiConfig: { title, subTitle, questions },
	form,
	answers,
}: ApplicantAnswersToCategoryStepProps) => {
	const { t } = useTranslation();

	const allQuestions = questions.map(
		(question: MultipleChoiceQuestionUIConfig) => {
			const value = answers?.questionsAndAnswers.find(
				(answer) => answer.questionId === question.questionId
			);
			return (
				<div key={question.questionId} className="my-[8px]">
					<ApplicantAnswerToQuestion uiConfig={question} value={value} />
				</div>
			);
		}
	);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader heading={t(title)} description={t(subTitle)} />
				{allQuestions}
			</Form>
		</div>
	);
};
