import { Form, FormInstance, Input, Radio, Typography, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	PredefinedReasonCategory,
	ReviewAction,
	VALID_STRING_INPUT_REGEX,
} from '../../shared/Constants';
import { useEffect, useState } from 'react';
import PredefinedReasonsDropdown from '../../components/PredefinedReasonsDropdown';
import {
	Feedback,
	UserRole,
} from '../../shared/VolunteerApplicationServiceTypes';
import { useRedirectToLogin } from '../../hooks/useRedirectToLogin';

const { Text } = Typography;

export interface RecommendationComponentUIConfig {
	commentsMandatory?: boolean;
	commentsLabel?: string;
	commentsPlaceholder?: string;
	form?: FormInstance;
	category?: string;
	displayComments?: boolean;
	disableReview?: boolean;
}

export const RecommendationComponent = ({
	commentsMandatory,
	commentsLabel = 'comments',
	commentsPlaceholder,
	form,
	category,
	displayComments = true,
	disableReview = false,
}: RecommendationComponentUIConfig) => {
	const { t } = useTranslation();
	const [commentsRequired, setCommentsRequired] = useState<boolean>(
		commentsMandatory ?? false
	);
	const [reasonDropdownVisible, setReasonDropdownVisible] = useState<boolean>();
	const values = Form.useWatch<Feedback>([], form);
	const { role } = useRedirectToLogin();
	const isZC = role === UserRole.ZC;

	useEffect(() => {
		const isDisapproved =
			values?.review === ReviewAction.REJECT ||
			category === PredefinedReasonCategory.SKIP;

		if (commentsMandatory) {
			setCommentsRequired(true);
		} else {
			setCommentsRequired(isDisapproved);
		}

		if (values?.review || category === PredefinedReasonCategory.SKIP) {
			setReasonDropdownVisible(isDisapproved);
		} else {
			setReasonDropdownVisible(false);
		}
	}, [commentsMandatory, values, category]);

	return (
		<div className="flex flex-row items-start">
			<div className="flex flex-col w-full">
				<Form.Item
					name={['review']}
					rules={[
						{
							required: true,
							message: <span>{t('answerIsRequired')}</span>,
						},
					]}
				>
					<Radio.Group
						disabled={
							category === PredefinedReasonCategory.SKIP || disableReview
						}
						className={`flex ${isZC ? 'flex-col' : 'inline-flex'}`}
					>
						<Radio value={ReviewAction.APPROVE}>{t('approve')}</Radio>
						{isZC && (
							<Radio value={ReviewAction.APPROVE_IDK_CANDIDATE}>
								{t('approveIDoNotKnowTheCandidate')}
							</Radio>
						)}
						<Radio value={ReviewAction.REJECT}>{t('notGoingForward')}</Radio>
					</Radio.Group>
				</Form.Item>
				{reasonDropdownVisible && (
					<>
						<Text>
							{t('reason')}{' '}
							{
								<span className="text-red-500">
									<b>*</b>
								</span>
							}
						</Text>
						<Form.Item
							name={['predefinedReasons']}
							rules={[
								{
									required: true,
									message: <span>{t('required')}</span>,
								},
							]}
						>
							<PredefinedReasonsDropdown
								category={category}
								disabled={disableReview}
							/>
						</Form.Item>
					</>
				)}
				{displayComments && (
					<>
						<Text>
							{t(commentsLabel)}{' '}
							{commentsRequired && (
								<span className="text-red-500">
									<b>*</b>
								</span>
							)}
						</Text>
						<Form.Item
							name={['comments']}
							rules={[
								{
									required: commentsRequired,
									message: <span>{t('required')}</span>,
								},
								{
									pattern: VALID_STRING_INPUT_REGEX,
									message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
								},
							]}
						>
							<Input.TextArea
								showCount
								rows={4}
								placeholder={
									commentsRequired
										? commentsPlaceholder
											? `${t(commentsPlaceholder)}`
											: `${t('commentsAreMandatoryNGF')}`
										: ''
								}
							/>
						</Form.Item>
					</>
				)}
				{isZC && (
					<Form.Item name="shouldCareTeamReview" valuePropName="checked">
						<Checkbox>{t('forwardItToCareTeam')}</Checkbox>
					</Form.Item>
				)}
			</div>
		</div>
	);
};
