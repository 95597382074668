import { Pagination as PaginationBar } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationSummaryCard } from './ApplicationSummaryCard';
import {
	ApplicationSummary,
	ApplicationSummaryList,
	PagingConfig,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import isEmpty from 'lodash/isEmpty';

const EmptyList = () => {
	const { t } = useTranslation();

	return (
		<div className="justify-center items-center border border-solid rounded-lg border-slate-200 w-[480px]">
			{t('noApplications')}
		</div>
	);
};

export const ApplicationsList = ({
	data: { applicationSummary: applications, pagination },
	onPageChange,
	onEvaluateClick,
	onVerifyResultsClick,
	pageConfig,
}: {
	data: ApplicationSummaryList;
	onPageChange: (page: number, pageSize: number) => void;
	onEvaluateClick: (application: ApplicationSummary) => void;
	onVerifyResultsClick: (application: ApplicationSummary) => void;
	pageConfig: PagingConfig;
}) => {
	if (
		isEmpty(pagination.totalNoOfRecords) ||
		pagination.totalNoOfRecords === 0
	) {
		return <EmptyList />;
	}

	const applicationCards = applications?.map((application) => (
		<div className="mb-[16px]" key={application.applicationId}>
			<ApplicationSummaryCard
				{...application}
				onEvaluateClick={onEvaluateClick}
				onVerifyResultsClick={onVerifyResultsClick}
			/>{' '}
		</div>
	));

	return (
		<div className="flex flex-col">
			{applicationCards}
			<PaginationBar
				current={pageConfig.pageNo ?? 1}
				total={pagination.totalNoOfRecords}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				defaultPageSize={pageConfig.pageSize ?? DEFAULT_PAGE_SIZE}
				onChange={onPageChange}
			/>
		</div>
	);
};
