import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { CharacterAndHabitsUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = CharacterAndHabitsUIConfig;

export const CharacterAndHabitsStep = () => {
	const {
		characterAndHabitsCategoryAnswers,
		setCharacterAndHabitsCategoryAnswers,
	} = useNewApplication();

	const { form } = useCategoryFormHandling(
		characterAndHabitsCategoryAnswers,
		setCharacterAndHabitsCategoryAnswers
	);

	return <CategoryStep uiConfig={UiConfig} form={form} />;
};
