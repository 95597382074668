import { useEffect, useState } from 'react';
import { NPSDetailCardsList } from '../../../components/NPSDetailCardsList';
import StackedHorizontalBar from '../../../components/StackedHorizontalBar';
import { FilterGraphs } from './FilterGraphs';
import { useGraphView } from './useGraphView';
import { ChartData } from 'chart.js';
import { useTranslation } from 'react-i18next';

export const NPSGraphicalView = ({
	selectedBatchId,
}: {
	selectedBatchId: number | undefined;
}) => {
	const { t } = useTranslation();
	const { graphViewData, graphViewFilter, onGraphViewFilterChange } =
		useGraphView();
	const [regionGroupData, setRegionGroupData] =
		useState<ChartData<'bar'> | null>();
	const [regionMentorData, setRegionMentorData] =
		useState<ChartData<'bar'> | null>();
	const [zoneGroupData, setZoneGroupData] = useState<ChartData<'bar'> | null>();
	const [zoneMentorData, setZoneMentorData] =
		useState<ChartData<'bar'> | null>();

	useEffect(() => {
		const regionData = graphViewData?.regionWiseGraphicalViews;
		if (!regionData) {
			return;
		}
		const labels = regionData.map((view) => view.region);
		const mappedToGroupData = regionData.map((view) =>
			Number(view.preceptorMappedWithGroupCount || '0')
		);
		const notMappedToGroupData = regionData.map((view) =>
			Number(view.preceptorNotMappedWithGroupCount || '0')
		);
		const data = {
			labels,
			datasets: [
				{
					label: t('mappedToGroup'),
					data: mappedToGroupData,
					backgroundColor: 'rgba(71, 145, 255)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('notMappedToGroup'),
					data: notMappedToGroupData,
					backgroundColor: 'rgba(2, 186, 177)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setRegionGroupData(data);
	}, [graphViewData?.regionWiseGraphicalViews, t]);

	useEffect(() => {
		const regionData = graphViewData?.regionWiseGraphicalViews;
		if (!regionData) {
			return;
		}
		const labels = regionData.map((view) => view.region);
		const mappedToMentorData = regionData.map((view) =>
			Number(view.preceptorAssignedWithMentorCount || '0')
		);
		const notMappedToMentorData = regionData.map((view) =>
			Number(view.preceptorNotAssignedWithMentorCount || '0')
		);
		const data = {
			labels,
			datasets: [
				{
					label: t('mappedWithMentors'),
					data: mappedToMentorData,
					backgroundColor: 'rgba(219, 31, 88)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('notMappedWithMentors'),
					data: notMappedToMentorData,
					backgroundColor: 'rgba(255, 217, 80)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setRegionMentorData(data);
	}, [graphViewData?.regionWiseGraphicalViews, t]);

	useEffect(() => {
		const zoneData = graphViewData?.zoneWiseGraphicalViews;
		if (!zoneData) {
			return;
		}
		const labels = zoneData.map((view) => view.zone);
		const mappedToGroupData = zoneData.map((view) =>
			Number(view.preceptorMappedWithGroupCount || '0')
		);
		const notMappedToGroupData = zoneData.map((view) =>
			Number(view.preceptorNotMappedWithGroupCount || '0')
		);
		const data = {
			labels,
			datasets: [
				{
					label: t('mappedToGroup'),
					data: mappedToGroupData,
					backgroundColor: 'rgba(71, 145, 255)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('notMappedToGroup'),
					data: notMappedToGroupData,
					backgroundColor: 'rgba(2, 186, 177)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setZoneGroupData(data);
	}, [graphViewData?.zoneWiseGraphicalViews, t]);

	useEffect(() => {
		const zoneData = graphViewData?.zoneWiseGraphicalViews;
		if (!zoneData) {
			return;
		}
		const labels = zoneData.map((view) => view.zone);
		const mappedToMentorData = zoneData.map((view) =>
			Number(view.preceptorAssignedWithMentorCount || '0')
		);
		const notMappedToMentorData = zoneData.map((view) =>
			Number(view.preceptorNotAssignedWithMentorCount || '0')
		);
		const data = {
			labels,
			datasets: [
				{
					label: t('mappedWithMentors'),
					data: mappedToMentorData,
					backgroundColor: 'rgba(219, 31, 88)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
				{
					label: t('notMappedWithMentors'),
					data: notMappedToMentorData,
					backgroundColor: 'rgba(255, 217, 80)',
					borderColor: 'lightGray',
					borderWidth: 1,
					barThickness: 40,
				},
			],
		};
		setZoneMentorData(data);
	}, [graphViewData?.zoneWiseGraphicalViews, t]);

	return (
		<>
			<FilterGraphs
				value={graphViewFilter}
				onFilterChange={onGraphViewFilterChange}
				selectedBatchId={selectedBatchId}
			/>
			<NPSDetailCardsList
				dashboardDetail={graphViewData?.preceptorDashboardCardDetail}
			/>
			{regionGroupData && !graphViewFilter.regionIds?.length && (
				<StackedHorizontalBar
					key={`regionGroup-${JSON.stringify(regionGroupData)}`}
					title={t('groupHeadingForGraph')}
					data={regionGroupData}
					displayPercentage
				/>
			)}
			{regionMentorData && !graphViewFilter.regionIds?.length && (
				<StackedHorizontalBar
					key={`regionMentor-${JSON.stringify(regionMentorData)}`}
					title={t('mentorHeadingForGraph')}
					data={regionMentorData}
					displayPercentage
				/>
			)}
			{zoneGroupData && graphViewFilter.regionIds?.length ? (
				<StackedHorizontalBar
					key={`zoneGroup-${JSON.stringify(zoneGroupData)}`}
					title={t('groupHeadingForGraph')}
					data={zoneGroupData}
					displayPercentage
				/>
			) : null}
			{zoneMentorData && graphViewFilter.regionIds?.length ? (
				<StackedHorizontalBar
					key={`zoneMentor-${JSON.stringify(zoneMentorData)}`}
					title={t('mentorHeadingForGraph')}
					data={zoneMentorData}
					displayPercentage
				/>
			) : null}
		</>
	);
};
