import { useCallback } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterFunctionaries } from './FilterFunctionaries';
import { useFunctionariesList } from './useFunctionariesList';
import { FunctionariesList } from './FunctionariesList';
import {
	ADD_FUNCTIONARY,
	EDIT_FUNCTIONARY,
	FUNCTIONARY_HOME,
} from '../../../shared/Routes';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	FunctionaryInfo,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';

export const FunctionariesListContainer = () => {
	const {
		functionaryList,
		onPageChange,
		filter,
		onFilterChange,
		paging,
		navigateWithFunctionarySrcmId,
	} = useFunctionariesList();
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const { role } = useRedirectToLogin();

	const displayFunctionariesLink = role === UserRole.ADMIN;

	const handleAddProposerClick = useCallback(() => {
		navigateWithBatchId(ADD_FUNCTIONARY);
	}, [navigateWithBatchId]);

	const handleEditProposerClick = useCallback(
		(functionary: FunctionaryInfo) => {
			navigateWithFunctionarySrcmId(EDIT_FUNCTIONARY, functionary.srcmId);
		},
		[navigateWithFunctionarySrcmId]
	);

	const handleBackClick = useCallback(() => {
		navigateWithBatchId(FUNCTIONARY_HOME);
	}, [navigateWithBatchId]);

	return (
		<div className="flex flex-col min-w-[370px] max-w-[480px] p-[8px]">
			<div className="flex justify-end">
				<Button type="link" onClick={handleAddProposerClick}>
					{displayFunctionariesLink ? t('addFunctionary') : t('addProposer')}
				</Button>
			</div>
			<div className="mb-[16px]">
				<FilterFunctionaries value={filter} onFilterChange={onFilterChange} />
			</div>
			<FunctionariesList
				data={functionaryList}
				onPageChange={onPageChange}
				onEditClick={handleEditProposerClick}
				pageConfig={paging}
			/>
			<div className="mt-[16px] flex justify-center">
				<Button onClick={handleBackClick}>{t('back')}</Button>
			</div>
		</div>
	);
};
