import { useCallback } from 'react';
import { useConfirmationBeforeAction } from '../../hooks/useConfirmationBeforeAction';
import { useApplicationId } from '../../hooks/useApplicationId';
import { INTERVIEW_LANDING } from '../../shared/Routes';
import { useCallApi } from '../../hooks/useCallApi';

export const useInterviewWizard = () => {
	const { navigateWithApplicationId } = useApplicationId();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { callApi } = useCallApi();

	const confirmAndSubmit = useCallback(
		<Resp,>(onConfirmSubmit: () => Promise<Resp>) =>
			confirmBeforeAction(
				'confirmSubmit',
				'areYouSureYouWantToSubmit',
				onConfirmSubmit
			),
		[confirmBeforeAction]
	);

	const onConfirmCancel = useCallback(() => {
		navigateWithApplicationId(INTERVIEW_LANDING);
	}, [navigateWithApplicationId]);

	const onCancelClick = useCallback(() => {
		confirmBeforeAction(
			'confirmCancel',
			'areYouSureYouWantToCancel',
			onConfirmCancel
		);
	}, [confirmBeforeAction, onConfirmCancel]);

	return { confirmAndSubmit, onCancelClick, callApi };
};
