import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	ExamFilter,
	ExamTemplate,
	ExamTemplateFilter,
	SendEmailRequest,
	UpdateExamTemplateRequest,
} from '../../../shared/ExamModuleTypes';
import {
	getExamTemplates,
	sendExamAdmitCardEmail,
	sendExamRegistrationInviteEmail,
	sendExamResultEmail,
	updateExamTemplate,
} from '../../../service/ExamModuleService';
import { useCallApi } from '../../../hooks/useCallApi';
import { BooleanValue } from '../../../shared/VolunteerApplicationServiceTypes';
import { useExamsList } from '../exams-list/useExamsList';

export const useSendEmail = () => {
	const [emailTemplate, setEmailTemplate] = useState<ExamTemplate>();
	const { srcmId } = useAuth();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { examById, fetchExamById } = useExamsList({});
	const [examTemplateFilter, setExamTemplateFilter] =
		useState<ExamTemplateFilter>();
	const [examFilter, setExamFilter] = useState<ExamFilter>();

	useEffect(() => {
		if (examById && examById.id) {
			setExamFilter((prevState) => ({ ...prevState, examId: examById?.id }));
		}
	}, [examById]);

	const onTemplateFilterChange = useCallback(
		(filterParam: ExamTemplateFilter) => {
			setExamTemplateFilter(filterParam);
		},
		[]
	);

	const fetchTemplate = useCallback(
		async (filter: ExamTemplateFilter) => {
			if (!srcmId) {
				return;
			}
			const templatesResponse = await callApi(
				() =>
					getExamTemplates({
						functionarySrcmId: srcmId,
						pageIndex: 0,
						pageSize: 2000,
						templateFilter: filter,
					}),
				'errorOccurredWhileLoading'
			);
			if (
				templatesResponse.examTemplates &&
				templatesResponse.examTemplates.length
			) {
				setEmailTemplate(templatesResponse.examTemplates[0]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[srcmId]
	);

	useEffect(() => {
		if (examTemplateFilter) {
			void fetchTemplate(examTemplateFilter);
		}
	}, [examTemplateFilter, fetchTemplate]);

	const updateTemplate = useCallback(
		async (examTemplate: ExamTemplate) => {
			if (!srcmId) {
				return;
			}
			const request: UpdateExamTemplateRequest = {
				functionarySrcmId: srcmId,
				examTemplate,
			};
			const response = await callApi(
				() => updateExamTemplate(request),
				'errorOccurredWhileLoading'
			);
			if (response) {
				return true;
			} else {
				return false;
			}
		},
		[callApi, srcmId]
	);

	const sendEmail = useCallback(async () => {
		if (!srcmId) {
			return;
		}
		if (examById?.id) {
			const request: SendEmailRequest = {
				functionarySrcmId: srcmId,
				examFilter,
			};
			let response: BooleanValue;
			if (
				examTemplateFilter?.templateKeys?.includes(
					'EXAM_MODULE_ADMIT_CARD_EMAIL_TEMPLATE'
				)
			) {
				response = await callApi(
					() => sendExamAdmitCardEmail(request),
					'errorOccurredWhileSendingEmail'
				);
			} else if (
				examTemplateFilter?.templateKeys?.includes(
					'EXAM_MODULE_REGISTRATION_INVITE_EMAIL_TEMPLATE'
				)
			) {
				response = await callApi(
					() => sendExamRegistrationInviteEmail(request),
					'errorOccurredWhileSendingEmail'
				);
			} else {
				response = await callApi(
					() => sendExamResultEmail(request),
					'errorOccurredWhileSendingEmail'
				);
			}
			if (response) {
				showToastMessage('success', 'Email sent successfully');
				if (fetchExamById) {
					void fetchExamById();
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examById, examFilter, examTemplateFilter, showToastMessage, srcmId]);

	return {
		examById,
		emailTemplate,
		fetchExamById,
		onTemplateFilterChange,
		sendEmail,
		updateTemplate,
	};
};
