import { useCallback, useEffect } from 'react';
import { useWizard } from '../../components/wizard/useWizard';
import { Form } from 'antd';
import { Feedback } from '../../shared/VolunteerApplicationServiceTypes';
import { CategoryAnswerType } from '../applicant/NewApplicationWizard/CategoryAnswerType';
import isEmpty from 'lodash/isEmpty';

export default function useCategoryFeedbackFormHandling(
	categoryValue: Feedback,
	setCategoryValue: (value: Feedback) => void,
	isLastPage?: boolean
) {
	const { setNextButtonDisabled, setSubmitButtonDisabled } = useWizard();
	const [form] = Form.useForm<Feedback>();
	const [mandatoryQuestionsAndAnswersForm] = Form.useForm<CategoryAnswerType>();
	const values = Form.useWatch<Feedback>([], form);
	const mandatoryQuestionsAndAnswersValues = Form.useWatch<CategoryAnswerType>(
		[],
		mandatoryQuestionsAndAnswersForm
	);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			const mandatoryQuestionsAndAnswersFieldValues =
				await mandatoryQuestionsAndAnswersForm.validateFields({
					validateOnly: true,
				});
			fieldValues.mandatoryQuestionsWithAnswers =
				mandatoryQuestionsAndAnswersFieldValues;
			setCategoryValue(fieldValues);

			if (isLastPage) {
				setSubmitButtonDisabled(false);
			} else {
				setNextButtonDisabled(false);
			}
		} catch (e) {
			const fields = e as { values: Feedback };
			setCategoryValue(fields.values);

			if (isLastPage) {
				setSubmitButtonDisabled(true);
			} else {
				setNextButtonDisabled(true);
			}
		}
	}, [
		form,
		isLastPage,
		mandatoryQuestionsAndAnswersForm,
		setCategoryValue,
		setNextButtonDisabled,
		setSubmitButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, mandatoryQuestionsAndAnswersValues, values]);

	useEffect(() => {
		form.setFieldsValue(categoryValue);
		if (!isEmpty(categoryValue.mandatoryQuestionsWithAnswers)) {
			mandatoryQuestionsAndAnswersForm.setFieldsValue(
				categoryValue.mandatoryQuestionsWithAnswers
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, mandatoryQuestionsAndAnswersForm]);

	return { form, mandatoryQuestionsAndAnswersForm };
}
