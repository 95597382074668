import { Button, Table, TablePaginationConfig, TableProps } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	GetPreceptorsResponse,
	NPHHSortingFieldName,
	NPHHSortingRequest,
	PagingConfig,
	Preceptor,
	PreceptorDashboardCardDetail,
	PreceptorDashboardCardFilter,
	PreceptorsFilter,
	SortingOrder,
} from '../shared/VolunteerApplicationServiceTypes';
import { PreceptorTableColumns } from '../shared/Columns';
import { FilterPreceptors } from '../pages/nps/CreateGroupPage/FilterPreceptors';
import {
	DEFAULT_PAGE_SIZE,
	MAX_PRECEPTORS_FOR_GROUP,
} from '../shared/Constants';
import { useBatchId } from '../hooks/useBatchId';
import { EDIT_GROUP, GROUPS } from '../shared/Routes';
import { useCreateGroup } from '../pages/nps/CreateGroupPage/useCreateGroup';
import { useGroupId } from '../hooks/useGroupId';
import { useEditGroup } from '../pages/nps/EditGroupPage/useEditGroup';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import isArray from 'lodash/isArray';
import { useNavigate } from 'react-router-dom';
import { NPSDetailCardsList } from './NPSDetailCardsList';
import { tablePaginationConfiguration } from '../shared/Utils';

export const PreceptorsTable = ({
	data: { preceptors, pagination },
	onPreceptorsPageChange,
	preceptorPageConfig,
	preceptorsFilter,
	onPreceptorFilterChange,
	isMentorsTable = false,
	onCreateGroupClick,
	onAssignMentorClick,
	existingPreceptorsIdsForAddMore,
	isDashboardTable = false,
	dashboardDetail,
	onDashboardDetailFilterChange,
	activeTabKey,
	selectedBatchId,
}: {
	data: GetPreceptorsResponse;
	onPreceptorsPageChange: (page: number, pageSize: number) => void;
	preceptorPageConfig: PagingConfig;
	preceptorsFilter: PreceptorsFilter;
	onPreceptorFilterChange: (value: PreceptorsFilter) => void;
	isMentorsTable?: boolean;
	onCreateGroupClick?: (preceptorIds: Array<number>) => void;
	onAssignMentorClick?: (mentorId: number) => void;
	existingPreceptorsIdsForAddMore?: Array<number>;
	isDashboardTable?: boolean;
	dashboardDetail?: PreceptorDashboardCardDetail;
	onDashboardDetailFilterChange?: (value: PreceptorsFilter) => void;
	activeTabKey?: string | null;
	selectedBatchId: number | undefined;
}) => {
	const existingPreceptorsCount =
		existingPreceptorsIdsForAddMore && existingPreceptorsIdsForAddMore.length;
	const [selectedPreceptors, setSelectedPreceptors] = useState<React.Key[]>([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { navigateWithBatchId } = useBatchId();
	const { setActiveTabKey } = useCreateGroup();
	const { groupId, navigateWithGroupId } = useGroupId();
	const { updateGroup } = useEditGroup();
	const tablePaginationConfig = tablePaginationConfiguration(
		preceptorPageConfig,
		pagination,
		onPreceptorsPageChange
	);

	useEffect(() => {
		onPreceptorsPageChange(1, 10);
	}, [onPreceptorsPageChange]);

	useEffect(() => {
		setSelectedPreceptors([]);
	}, [selectedBatchId]);

	const onSelectChange = (newSelectedPreceptorKeys: React.Key[]) => {
		setSelectedPreceptors(newSelectedPreceptorKeys);
	};

	const hasSelected =
		selectedPreceptors.length > 0 &&
		(isMentorsTable
			? selectedPreceptors.length === 1
			: selectedPreceptors.length <= 5);

	const isSelectionDisabled =
		selectedPreceptors.length >=
		(isMentorsTable
			? 1
			: existingPreceptorsCount
			? MAX_PRECEPTORS_FOR_GROUP - existingPreceptorsCount
			: MAX_PRECEPTORS_FOR_GROUP);

	const preceptorSelection = {
		selectedPreceptors,
		onChange: onSelectChange,
		preserveSelectedRowKeys: true,
		getCheckboxProps: (record: Preceptor) => ({
			disabled:
				isSelectionDisabled &&
				!selectedPreceptors.includes(record?.key as React.Key),
		}),
	};

	const numberOfPreceptors = useCallback((): string => {
		const noOfPreceptors = selectedPreceptors.length;
		if (hasSelected && noOfPreceptors === 1) {
			return `${t('selectedPreceptor', { noOfPreceptors })}`;
		} else if (hasSelected && noOfPreceptors > 1) {
			return `${t('selectedPreceptors', { noOfPreceptors })}`;
		} else {
			return '';
		}
	}, [hasSelected, selectedPreceptors, t]);

	const handleCreateGroupClick = useCallback(() => {
		if (onCreateGroupClick) {
			onCreateGroupClick(selectedPreceptors as Array<number>);
			setSelectedPreceptors([]);
		}
	}, [onCreateGroupClick, selectedPreceptors]);

	const handleAssignMentorClick = useCallback(() => {
		if (onAssignMentorClick) {
			onAssignMentorClick(selectedPreceptors[0] as number);
		}
	}, [onAssignMentorClick, selectedPreceptors]);

	const handleAddToGroupClick = useCallback(() => {
		const preceptorIdsToBeUpdated = [
			...(existingPreceptorsIdsForAddMore as Array<number>),
			...selectedPreceptors,
		];
		updateGroup(
			preceptorIdsToBeUpdated as Array<number>,
			'areYouSureToAddGroup',
			true
		);
	}, [existingPreceptorsIdsForAddMore, selectedPreceptors, updateGroup]);

	const handleBackInAddMoreClick = useCallback(() => {
		if (groupId) {
			navigateWithGroupId(EDIT_GROUP, groupId);
		}
	}, [groupId, navigateWithGroupId]);

	const handleBackClick = useCallback(() => {
		if (isMentorsTable) {
			navigate(-1);
		} else {
			setActiveTabKey('2');
			navigateWithBatchId(GROUPS);
		}
	}, [navigateWithBatchId, setActiveTabKey, isMentorsTable, navigate]);

	const handleNPSCardClick = useCallback(
		(
			preceptorDashboardCardFilter: PreceptorDashboardCardFilter,
			cardIndex: number
		) => {
			const updatedPreceptorsFilter = { ...preceptorsFilter };
			updatedPreceptorsFilter.preceptorDashboardCardFilter =
				preceptorDashboardCardFilter;
			updatedPreceptorsFilter.dashboardCardFilterEnabled = false;
			if (cardIndex !== 0) {
				updatedPreceptorsFilter.dashboardCardFilterEnabled = true;
			}
			onPreceptorFilterChange(updatedPreceptorsFilter);
		},
		[preceptorsFilter, onPreceptorFilterChange]
	);

	const columns = PreceptorTableColumns();

	const displayButtons = useCallback(() => {
		if (isMentorsTable) {
			return (
				<>
					<Button
						type="primary"
						onClick={handleAssignMentorClick}
						disabled={!hasSelected}
					>
						{t('assignMentor')}
					</Button>
					<Button type="default" onClick={handleBackClick}>
						{t('back')}
					</Button>
				</>
			);
		} else if (
			existingPreceptorsIdsForAddMore &&
			existingPreceptorsIdsForAddMore.length
		) {
			return (
				<>
					<Button
						type="primary"
						onClick={handleAddToGroupClick}
						disabled={!hasSelected}
					>
						{t('addToGroup')}
					</Button>
					<Button type="default" onClick={handleBackInAddMoreClick}>
						{t('back')}
					</Button>
					<span className="ml-8">{numberOfPreceptors()}</span>
				</>
			);
		} else {
			return (
				<>
					<Button
						type="primary"
						onClick={handleCreateGroupClick}
						disabled={!hasSelected}
					>
						{t('createGroup')}
					</Button>
					<span className="ml-8">{numberOfPreceptors()}</span>
				</>
			);
		}
	}, [
		existingPreceptorsIdsForAddMore,
		handleAddToGroupClick,
		handleAssignMentorClick,
		handleBackClick,
		handleBackInAddMoreClick,
		handleCreateGroupClick,
		hasSelected,
		isMentorsTable,
		numberOfPreceptors,
		t,
	]);

	const buildSortingRequest = (
		sorter: SorterResult<Preceptor>
	): NPHHSortingRequest => {
		const sortRequest: NPHHSortingRequest = {
			sortingFieldName: 'SORTING_FIELD_NAME_UNSPECIFIED',
			sortingOrder: 'SORTING_ORDER_UNSPECIFIED',
		};

		if (!sorter || !sorter.column || !sorter.field) {
			return sortRequest;
		}

		const sortingFieldNameMap: Record<string, string> = {
			city: 'CITY',
			postalCode: 'POSTAL_CODE',
			addressLine1: 'ADDRESS',
			state: 'STATE',
		};

		const sortingOrderMap: Record<string, string> = {
			ascend: 'ASCENDING',
			descend: 'DESCENDING',
		};

		const sortingFieldName =
			(sortingFieldNameMap[sorter.field as string] as NPHHSortingFieldName) ||
			'SORTING_FIELD_NAME_UNSPECIFIED';
		const sortingOrder =
			(sortingOrderMap[sorter.order as string] as SortingOrder) ||
			'SORTING_ORDER_UNSPECIFIED';

		return {
			sortingFieldName,
			sortingOrder,
		};
	};

	const handleTableChange: TableProps<Preceptor>['onChange'] = (
		tablePagination: TablePaginationConfig,
		__: Record<string, FilterValue | null>,
		sorter: SorterResult<Preceptor> | SorterResult<Preceptor>[]
	) => {
		if (
			tablePagination.current !== preceptorPageConfig.pageNo ||
			tablePagination.pageSize !== preceptorPageConfig.pageSize
		) {
			onPreceptorsPageChange(
				tablePagination.current ?? 1,
				tablePagination.pageSize ?? DEFAULT_PAGE_SIZE
			);
			return;
		}

		if (!sorter) {
			return;
		}

		const updatedPreceptorsFilter = { ...preceptorsFilter };
		const sortingRequests: Array<NPHHSortingRequest> = [];

		if (isArray(sorter)) {
			sorter.map((sort) => {
				const sortRequest = buildSortingRequest(sort);
				sortingRequests.push(sortRequest);
			});
		} else {
			const sortRequest = buildSortingRequest(sorter);
			sortingRequests.push(sortRequest);
		}
		updatedPreceptorsFilter.sortingRequests = sortingRequests;
		onPreceptorFilterChange(updatedPreceptorsFilter);
	};

	if (preceptors && preceptors.length && preceptors.length === 0) {
		return <div>{t('noDataFound')}</div>;
	}

	return (
		<>
			<FilterPreceptors
				value={preceptorsFilter}
				onFilterChange={onPreceptorFilterChange}
				onDashboardDetailFilterChange={onDashboardDetailFilterChange}
				isMentorsFilter={isMentorsTable}
				formName={
					onDashboardDetailFilterChange
						? 'AllPreceptorsFilterForm'
						: 'NewPreceptorsFilterForm'
				}
				activeTabKey={activeTabKey}
				selectedBatchId={selectedBatchId}
			/>
			{isDashboardTable && (
				<NPSDetailCardsList
					dashboardDetail={dashboardDetail}
					onCardClick={handleNPSCardClick}
				/>
			)}
			<Table
				key={selectedBatchId}
				rowSelection={!isDashboardTable ? preceptorSelection : undefined}
				dataSource={preceptors}
				columns={columns}
				scroll={{ x: true }}
				pagination={tablePaginationConfig}
				onChange={handleTableChange}
			/>
			<div className="flex flex-row-reverse items-center gap-4 my-8">
				{!isDashboardTable && displayButtons()}
			</div>
		</>
	);
};
