import { useCallback, useEffect } from 'react';
import { useWizard } from '../../../components/wizard/useWizard';
import { Form } from 'antd';
import { Feedback } from '../../../shared/VolunteerApplicationServiceTypes';

export default function useCategoryFeedbackFormHandling(
	categoryValue: Feedback,
	setCategoryValue: (value: Feedback) => void,
	isLastPage?: boolean
) {
	const { setNextButtonDisabled, setSubmitButtonDisabled } = useWizard();
	const [form] = Form.useForm<Feedback>();
	const values = Form.useWatch<Feedback>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setCategoryValue(fieldValues);
			if (isLastPage) {
				setSubmitButtonDisabled(false);
			} else {
				setNextButtonDisabled(false);
			}
		} catch (e) {
			const fields = e as { values: Feedback };
			setCategoryValue(fields.values);
			if (isLastPage) {
				setSubmitButtonDisabled(true);
			} else {
				setNextButtonDisabled(true);
			}
		}
	}, [
		form,
		isLastPage,
		setCategoryValue,
		setNextButtonDisabled,
		setSubmitButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(categoryValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return { form };
}
