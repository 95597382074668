import useCategoryFeedbackFormHandling from './useCategoryFeedbackFormHandling';
import { SupportUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useViewApplicantAnswers } from './useViewApplicantAnswers';
import { CategoryViewApplicantAnswersStep } from './CategoryViewApplicantAnswersStep';

const UiConfig = SupportUIConfig;

export const SupportViewAnswersStep = () => {
	const { application } = useViewApplicantAnswers();

	const { form } = useCategoryFeedbackFormHandling();

	return (
		<CategoryViewApplicantAnswersStep
			uiConfig={UiConfig}
			form={form}
			answers={application?.userAnswers}
		/>
	);
};
