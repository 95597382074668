import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

export interface QuestionsToAskProps {
	uiConfig: Array<string>;
}

export const QuestionsToAsk = ({ uiConfig }: QuestionsToAskProps) => {
	const { t } = useTranslation();

	const questions = uiConfig.map((question, index) => (
		<li key={index}>{t(question)}</li>
	));

	if (questions.length <= 0) {
		return null;
	}

	return (
		<div className="flex flex-col items-start">
			<Title level={5}>{t('askTheFollowingQuestions')}</Title>
			<Text>
				<ol>{questions}</ol>
			</Text>
		</div>
	);
};
