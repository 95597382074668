import { CategoryStep } from './CategoryStep';
import useCategoryFeedbackFormHandling from '../useCategoryFeedbackFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';
import { usePreceptorInterview } from './usePreceptorInterviewWizard';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramStep = () => {
	const { availabilityAfterProgram, setAvailabilityAfterProgram } =
		usePreceptorInterview();

	const { form, mandatoryQuestionsAndAnswersForm } =
		useCategoryFeedbackFormHandling(
			availabilityAfterProgram,
			setAvailabilityAfterProgram
		);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			mandatoryQuestionsAndAnswersForm={mandatoryQuestionsAndAnswersForm}
		/>
	);
};
