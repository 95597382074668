import { Form, Input, Radio, RadioChangeEvent, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReviewAction, VALID_STRING_INPUT_REGEX } from '../../shared/Constants';
import { useCallback, useState } from 'react';

const { Text } = Typography;

export interface FeedbackComponentUIConfig {
	commentsLabel?: string;
	commentsMandatory?: boolean;
}

export const FeedbackComponent = ({
	commentsLabel = 'comments',
	commentsMandatory = false,
}: FeedbackComponentUIConfig) => {
	const { t } = useTranslation();
	const [commentsRequired, setCommentsRequired] =
		useState<boolean>(commentsMandatory);

	const handleReviewChange = useCallback(
		(e: RadioChangeEvent) => {
			if (commentsMandatory) {
				setCommentsRequired(true);
				return;
			}

			if (e.target.value && e.target.value !== ReviewAction.APPROVE) {
				setCommentsRequired(true);
				return;
			}

			setCommentsRequired(false);
		},
		[commentsMandatory]
	);

	return (
		<div className="flex flex-row items-start">
			<div className="flex flex-col w-full">
				<Form.Item
					name={['review']}
					rules={[
						{
							required: true,
							message: <span>{t('answerIsRequired')}</span>,
						},
					]}
				>
					<Radio.Group onChange={handleReviewChange}>
						<Radio value={ReviewAction.APPROVE}>{t('approve')}</Radio>
						<Radio value={ReviewAction.REJECT}>{t('notGoingForward')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Text>
					{t(commentsLabel)}{' '}
					{commentsRequired && (
						<span className="text-red-500">
							<b>*</b>
						</span>
					)}
				</Text>
				<Form.Item
					name={['comments']}
					rules={[
						{
							required: commentsRequired,
							message: <span>{t('required')}</span>,
						},
						{
							pattern: VALID_STRING_INPUT_REGEX,
							message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
						},
					]}
				>
					<Input.TextArea
						showCount
						rows={4}
						placeholder={
							commentsRequired ? `${t('commentsAreMandatoryNGF')}` : ''
						}
					/>
				</Form.Item>
			</div>
		</div>
	);
};
