import { WizardContainer } from '../../../components/wizard/Wizard';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { AvailabilityAfterProgramAnswersReviewStep } from './AvailabilityAfterProgramAnswersReviewStep';
import { BasicEligibilityAnswersReviewStep } from './BasicEligibilityAnswersReviewStep';
import { CharacterAndHabitsAnswersReviewStep } from './CharacterAndHabitsAnswersReviewStep';
import { DietaryHabitsAndHealthAnswersReviewStep } from './DietaryHabitsAndHealthAnswersReviewStep';
import { InspirationAndWillingnessAnswersReviewStep } from './InspirationAndWillingnessAnswersReviewStep';
import { ApplicantAnswersReviewLandingPage } from './LandingPage';
import { PracticeAnswersReviewStep } from './PracticeAnswersReviewStep';
import { SupportAnswersReviewStep } from './SupportAnswersReviewStep';
import { UnderstandingOfHeartfulnessAnswersReviewStep } from './UnderstandingOfHeartfulnessAnswersReviewStep';
import {
	ApplicantAnswersReviewProvider,
	useApplicantAnswersReview,
} from './useApplicantAnswersReview';

export const ApplicantAnswersReviewWizard = () => {
	const { onSubmitClick, application, onCancelClick } =
		useApplicantAnswersReview();

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onCancelClick={onCancelClick}
				onSubmitClick={onSubmitClick}
				steps={[
					<ApplicantAnswersReviewLandingPage
						key={1}
						applicantInfo={application.applicant}
					/>,
					<BasicEligibilityAnswersReviewStep key={2} />,
					<InspirationAndWillingnessAnswersReviewStep key={3} />,
					<PracticeAnswersReviewStep key={4} />,
					<CharacterAndHabitsAnswersReviewStep key={5} />,
					<AvailabilityAfterProgramAnswersReviewStep key={6} />,
					<SupportAnswersReviewStep key={7} />,
					<UnderstandingOfHeartfulnessAnswersReviewStep key={8} />,
					<DietaryHabitsAndHealthAnswersReviewStep key={9} />,
				]}
			/>
		</div>
	);
};

export const ApplicantAnswersReviewWizardContainer = () => {
	return (
		<ApplicantAnswersReviewProvider>
			<WizardProvider>
				<ApplicantAnswersReviewWizard />
			</WizardProvider>
		</ApplicantAnswersReviewProvider>
	);
};
