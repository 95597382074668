import { Button, Collapse, Input, Popover, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	ApplicationsFilter,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApplicationStatusTranslation } from '../../../hooks/useApplicationStatusTranslation';
import {
	ApplicationStatus,
	Gender,
	ReviewAction,
} from '../../../shared/Constants';
import ZoneDropdown from '../../../components/ZonesDropdown';
import { useAuth } from '../../../hooks/useAuth';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { isProposerPartCompleted } from '../../../shared/Utils';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import moment from 'moment';
import { useSnapshot } from 'valtio';
import { BatchInfoState } from '../../../components/BatchInfo.store';

interface FilterProps {
	value: ApplicationsFilter;
	onFilterChange: (value: ApplicationsFilter) => void;
}

const { Text } = Typography;

export const Filter = ({
	value: {
		status,
		searchByApplicantName,
		gender,
		zoneNames,
		applicantSrcmId,
		assignedToLevel1FunctionarySrcmId,
		preceptorReview = [],
		proposerReview = [],
	},
	onFilterChange,
}: FilterProps) => {
	const { t } = useTranslation();
	const { getTranslatedValue } = useApplicationStatusTranslation();
	const { srcmId } = useAuth();
	const { role } = useRedirectToLogin();
	const batchStateSnap = useSnapshot(BatchInfoState);
	const batchInfo = batchStateSnap.batchInfo;
	const [statusValue, setStatusValue] = useState<string | undefined>(
		status && status.length > 0 ? status[0] : undefined
	);
	const [preceptorRecommendationValue, setPreceptorRecommendationValue] =
		useState<Array<string>>(preceptorReview);
	const [proposerRecommendationValue, setProposerRecommendationValue] =
		useState<Array<string>>(proposerReview);
	const [nameValue, setNameValue] = useState<string | undefined>(
		searchByApplicantName
	);
	const [genderValue, setGenderValue] = useState<string | undefined>(gender);
	const [zones, setZones] = useState<Array<string> | undefined>(zoneNames);
	const [applicantSrcmIdValue, setApplicantSrcmId] = useState<
		string | undefined
	>(applicantSrcmId);
	const [proposerSrcmId, setProposerSrcmId] = useState<string | undefined>(
		assignedToLevel1FunctionarySrcmId
	);
	const [showStatusFilter, setShowStatusFilter] = useState<boolean>(true);
	const [showFilterText, setShowFilterText] = useState<boolean>(true);
	const [showFilterByZone, setShowFilterByZone] = useState<boolean>(true);
	const [showFilterByProposerSRCMId, setShowFilterByProposerSRCMId] =
		useState<boolean>(true);
	const [showFilterByApplicantName, setShowFilterByApplicantName] =
		useState<boolean>(false);

	useEffect(() => {
		if (status && status.length > 0) {
			setStatusValue(status[0]);
		} else {
			setStatusValue(undefined);
		}
	}, [status]);

	useEffect(() => {
		if (
			role === UserRole.CARE_TEAM ||
			(role === UserRole.ZC &&
				batchInfo?.snpResultDateForZC &&
				batchInfo.snpResultDateForZC !== '1970-01-01T00:00:00Z' &&
				moment().isSameOrBefore(batchInfo?.snpResultDateForZC))
		) {
			setShowStatusFilter(false);
			setShowFilterByProposerSRCMId(false);
			setShowFilterText(false);
		}
		if (role === UserRole.CARE_TEAM) {
			setShowFilterByApplicantName(true);
			setShowFilterByZone(false);
		}
	}, [role, batchInfo?.snpResultDateForZC]);

	const statusFilterOptions = useMemo(() => {
		if (
			(role === UserRole.ZC &&
				batchInfo?.snpResultDateForZC &&
				batchInfo.snpResultDateForZC !== '1970-01-01T00:00:00Z' &&
				moment().isSameOrAfter(batchInfo?.snpResultDateForZC)) ||
			(role === UserRole.APPROVER &&
				batchInfo?.snpResultDateForApprover &&
				batchInfo.snpResultDateForApprover !== '1970-01-01T00:00:00Z' &&
				moment().isSameOrAfter(batchInfo?.snpResultDateForApprover))
		) {
			return [
				{
					value: ApplicationStatus.APPROVED,
					label: getTranslatedValue(ApplicationStatus.APPROVED),
				},
				{
					value: ApplicationStatus.REJECTED,
					label: getTranslatedValue(ApplicationStatus.REJECTED),
				},
			];
		} else {
			return [
				{
					value: ApplicationStatus.USER_SUBMITTED_APPLICATION,
					label: getTranslatedValue(
						ApplicationStatus.USER_SUBMITTED_APPLICATION
					),
				},
				{
					value: ApplicationStatus.FUNCTIONARY_ASSIGNED,
					label: getTranslatedValue(ApplicationStatus.FUNCTIONARY_ASSIGNED),
				},
				{
					value: ApplicationStatus.FUNCTIONARY_SUBMITTED,
					label: getTranslatedValue(ApplicationStatus.FUNCTIONARY_SUBMITTED),
				},
				{
					value: ApplicationStatus.SYS_ANOTHER_FUNCTIONARY_ASSIGNED,
					label: getTranslatedValue(
						ApplicationStatus.SYS_ANOTHER_FUNCTIONARY_ASSIGNED
					),
				},
				{
					value: ApplicationStatus.WAITING_FOR_CARE_TEAM,
					label: getTranslatedValue(ApplicationStatus.WAITING_FOR_CARE_TEAM),
				},
				{
					value: ApplicationStatus.APPROVED,
					label: getTranslatedValue(ApplicationStatus.APPROVED),
				},
				{
					value: ApplicationStatus.REJECTED,
					label: getTranslatedValue(ApplicationStatus.REJECTED),
				},
			];
		}
	}, [
		getTranslatedValue,
		batchInfo?.snpResultDateForZC,
		batchInfo?.snpResultDateForApprover,
		role,
	]);

	const genderDropdownOptions = useMemo(() => {
		return [
			{
				value: Gender.NOT_PROVIDED,
				label: t('bothGenders'),
			},
			{
				value: Gender.MALE,
				label: t('male'),
			},
			{
				value: Gender.FEMALE,
				label: t('female'),
			},
		];
	}, [t]);

	const recommendationDropdownOptions = useMemo(() => {
		return [
			{
				value: ReviewAction.APPROVE,
				label: t('approve'),
			},
			{
				value: ReviewAction.REJECT,
				label: t('notGoingForward'),
			},
		];
	}, [t]);

	const handleGenderChange = useCallback((value: string) => {
		setGenderValue(value);
	}, []);

	const handlePreceptorRecommendationChange = useCallback(
		(value: Array<string>) => {
			setPreceptorRecommendationValue(value);
		},
		[]
	);

	const handleProposerRecommendationChange = useCallback(
		(value: Array<string>) => {
			setProposerRecommendationValue(value);
		},
		[]
	);

	const handleZoneChange = useCallback((value: Array<{ value: string }>) => {
		setZones(value.map((z) => z.value));
	}, []);

	const fireFilterChange = useCallback(
		(updatedStatusValue?: string) => {
			onFilterChange({
				status: [updatedStatusValue ?? (statusValue as string)],
				applicantSrcmId: applicantSrcmIdValue,
				searchByApplicantName: nameValue,
				assignedToLevel1FunctionarySrcmId: proposerSrcmId,
				gender: genderValue,
				zoneNames: zones,
				preceptorReview: preceptorRecommendationValue,
				proposerReview: proposerRecommendationValue,
			});
		},
		[
			applicantSrcmIdValue,
			genderValue,
			nameValue,
			onFilterChange,
			preceptorRecommendationValue,
			proposerRecommendationValue,
			proposerSrcmId,
			statusValue,
			zones,
		]
	);

	const handleFilterButtonClick = useCallback(() => {
		fireFilterChange();
	}, [fireFilterChange]);

	const showRecommendationFilter = useMemo(() => {
		return isProposerPartCompleted(statusValue) && role !== UserRole.CARE_TEAM;
	}, [role, statusValue]);

	const handleStatusChange = useCallback(
		(value: string) => {
			if (showRecommendationFilter) {
				setPreceptorRecommendationValue([]);
				setProposerRecommendationValue([]);
			}

			setStatusValue(value);
			fireFilterChange(value);
		},
		[fireFilterChange, showRecommendationFilter]
	);

	const onApplicantNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setNameValue(e.target.value);
		},
		[]
	);

	const onApplicantSRCMIdChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setApplicantSrcmId(e.target.value);
		},
		[]
	);

	const onProposerSRCMIdChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setProposerSrcmId(e.target.value);
		},
		[]
	);

	const filterText = useMemo(() => {
		const filterStrings = [];

		if (showRecommendationFilter) {
			let label = recommendationDropdownOptions
				.filter((dro) => proposerRecommendationValue.includes(dro.value))
				.map((ele) => ele?.label);

			if (label.length > 0) {
				filterStrings.push(`Prps: ${label.join(',')}`);
			}

			label = recommendationDropdownOptions
				.filter((dro) => preceptorRecommendationValue.includes(dro.value))
				.map((ele) => ele?.label);

			if (label.length > 0) {
				filterStrings.push(`Pcpt: ${label.join(',')}`);
			}
		}

		if (!isNil(status)) {
			filterStrings.push(
				...status.map((s) => {
					const label = statusFilterOptions.find((dro) => dro.value === s);
					return label?.label;
				})
			);
		}

		if (!isEmpty(zoneNames)) {
			filterStrings.push(...(zoneNames as Array<string>));
		}

		if (gender) {
			const label = genderDropdownOptions.find((dro) => dro.value === gender);
			filterStrings.push(label?.label);
		}

		if (!isEmpty(applicantSrcmId)) {
			filterStrings.push(applicantSrcmId);
		}

		if (!isEmpty(assignedToLevel1FunctionarySrcmId)) {
			filterStrings.push(
				`Prps: ${assignedToLevel1FunctionarySrcmId as string}`
			);
		}

		return filterStrings.join(', ');
	}, [
		applicantSrcmId,
		assignedToLevel1FunctionarySrcmId,
		gender,
		genderDropdownOptions,
		preceptorRecommendationValue,
		proposerRecommendationValue,
		recommendationDropdownOptions,
		showRecommendationFilter,
		status,
		statusFilterOptions,
		zoneNames,
	]);

	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			{showStatusFilter && (
				<div className="mb-[8px]">
					<Text>{t('filterApplications')}</Text>
					<Select
						className="flex min-w-[200px]"
						value={statusValue}
						options={statusFilterOptions}
						onChange={handleStatusChange}
					/>
				</div>
			)}
			<Collapse collapsible="header">
				<Collapse.Panel header={t('advancedFilter')} key="1">
					{showFilterByZone && (
						<div className="mb-[8px] flex flex-col">
							<Text>{t('filterByZone')}</Text>
							<ZoneDropdown
								functionarySrcmId={srcmId}
								value={zones}
								onChange={handleZoneChange}
							/>
						</div>
					)}
					{showRecommendationFilter && (
						<>
							<div className="mb-[8px] recommendation-multiselect">
								<Text>{t('proposerRecommendation')}</Text>
								<Select
									className="flex min-w-[200px]"
									mode="multiple"
									value={proposerRecommendationValue}
									options={recommendationDropdownOptions}
									onChange={handleProposerRecommendationChange}
									placeholder="Please select"
									showArrow={false}
								/>
							</div>
							<div className="mb-[8px] recommendation-multiselect">
								<Text>{t('preceptorRecommendation')}</Text>
								<Select
									mode="multiple"
									className="flex min-w-[200px] "
									value={preceptorRecommendationValue}
									options={recommendationDropdownOptions}
									onChange={handlePreceptorRecommendationChange}
									placeholder="Please select"
									showArrow={false}
								/>
							</div>
						</>
					)}
					<div className="mb-[8px]">
						<Text>{t('filterByGender')}</Text>
						<Select
							className="flex min-w-[200px]"
							value={genderValue}
							options={genderDropdownOptions}
							onChange={handleGenderChange}
						/>
					</div>
					<div className="mb-[8px]">
						<Text>{t('filterByApplicantSRCMId')}</Text>
						<Input
							className="flex min-w-[200px]"
							value={applicantSrcmIdValue}
							allowClear
							onChange={onApplicantSRCMIdChange}
						/>
					</div>
					{showFilterByProposerSRCMId && (
						<div className="mb-[8px]">
							<Text>{t('filterByProposerSRCMId')}</Text>
							<Input
								className="flex min-w-[200px]"
								value={proposerSrcmId}
								allowClear
								onChange={onProposerSRCMIdChange}
							/>
						</div>
					)}
					{showFilterByApplicantName && (
						<div className="mb-[8px]">
							<Text>{t('filterByApplicantName')}</Text>
							<Input
								className="flex min-w-[200px]"
								value={nameValue}
								allowClear
								onChange={onApplicantNameChange}
							/>
						</div>
					)}
					<div>
						<Button type="primary" onClick={handleFilterButtonClick}>
							{t('filter')}
						</Button>
					</div>
				</Collapse.Panel>
			</Collapse>
			<div>
				<Popover content={filterText} title={t('filter')} trigger="click">
					{showFilterText && <Text ellipsis>{filterText}</Text>}
				</Popover>
			</div>
		</div>
	);
};
