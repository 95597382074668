import { useCallback, useState } from 'react';
import get from 'lodash/get';
import {
	InfoCircleFilled,
	CheckOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import { Card, Typography, Switch, Button, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import {
	Batch,
	FunctionaryPredefinedComment,
} from '../../shared/VolunteerApplicationServiceTypes';
import moment from 'moment';
import { NewApplicationWizardContainer } from './NewApplicationWizard';
import {
	DATE_DO_MMM_YYYY,
	RejectedReasonRectifyOption,
} from '../../shared/Constants';
import { rectifyPastApplicationRejectedReasons } from '../../service/VolunteerApplicationService';
import { useLoadingIndicator } from '../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../hooks/useToastMessage';

const { Paragraph } = Typography;

export const ReApplyAfterSometimeCard = ({
	allowed,
	reApplyDate,
	pastApplicationRejectedReasons,
	pastApplicationBatch,
	currentBatch,
}: {
	allowed?: boolean;
	reApplyDate?: string;
	pastApplicationRejectedReasons?: Array<FunctionaryPredefinedComment>;
	pastApplicationBatch?: Batch;
	currentBatch?: Batch;
}) => {
	const { t } = useTranslation();
	const { mySrcmProfile, srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const {
		token: { orange5 },
	} = theme.useToken();
	const candidateName = mySrcmProfile?.name;
	const formattedReApplyDate = moment(reApplyDate).format(DATE_DO_MMM_YYYY);
	const canShowSwitches =
		allowed === true && pastApplicationBatch?.id !== currentBatch?.id;

	const [switchStates, setSwitchStates] = useState(
		pastApplicationRejectedReasons?.reduce((acc, comment) => {
			acc[comment.id] = false;
			return acc;
		}, {} as Record<string, boolean>) || {}
	);

	const [showNewApplicationWizard, setShowNewApplicationWizard] =
		useState(false);

	const handleChange = (commentId: number) => {
		setSwitchStates((prevStates) => ({
			...prevStates,
			[commentId]: !prevStates[commentId],
		}));
	};

	const isAllSwitchesOn = Object.values(switchStates).every((state) => state);

	const rectifyAndShowNewApplication = useCallback(async () => {
		setLoading(true);
		try {
			const pastApplicationRejectedReasonsList =
				pastApplicationRejectedReasons?.map((reason) => {
					return {
						functionaryPredefinedComment: reason,
						rectifyOption: RejectedReasonRectifyOption.RECTIFIED,
					};
				});
			const rectifyPastApplicationRejectedReasonsRequest = {
				applicantSrcmId: srcmId,
				pastApplicationRejectedReasons: pastApplicationRejectedReasonsList,
			};

			const response = await rectifyPastApplicationRejectedReasons(
				rectifyPastApplicationRejectedReasonsRequest
			);

			if (response) {
				setShowNewApplicationWizard(true);
			}
		} catch (e) {
			const errorMessage: string | undefined = get(e, 'response.data.message');

			if (errorMessage) {
				showToastMessage('error', errorMessage);
			}

			if (e instanceof Error) {
				showToastMessage('error', e.message);
			}

			showToastMessage('error', t('errorOccurredWhileLoading'));
		} finally {
			setLoading(false);
		}
	}, [pastApplicationRejectedReasons, srcmId, setLoading, showToastMessage, t]);

	const renderReasonsList = useCallback(() => {
		return (
			<Paragraph>
				{pastApplicationRejectedReasons &&
					pastApplicationRejectedReasons.length && (
						<ol className="list-disc list-inside">
							{pastApplicationRejectedReasons.map((comment) => (
								<li
									key={comment.id}
									className="flex items-center justify-between"
								>
									<>
										<span className="mr-2">
											&bull; &nbsp;{' '}
											{comment.predefinedReason.reasonTextForCandidate}
										</span>
										{canShowSwitches && (
											<Switch
												size="small"
												checkedChildren={<CheckOutlined />}
												unCheckedChildren={<CloseOutlined />}
												defaultChecked
												checked={switchStates[comment.id]}
												onChange={() => handleChange(comment.id)}
											/>
										)}
									</>
								</li>
							))}
						</ol>
					)}
			</Paragraph>
		);
	}, [canShowSwitches, pastApplicationRejectedReasons, switchStates]);

	const contentWithoutReApplyDate = useCallback(() => {
		if (canShowSwitches) {
			return (
				<>
					<p>{t('thankYouAgain')}</p>
					<p>{t('kindlyConfirm')}</p>
					{renderReasonsList()}
					{isAllSwitchesOn ? (
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						<Button type="primary" onClick={rectifyAndShowNewApplication}>
							{t('proceed')}
						</Button>
					) : (
						<p>{t('pleaseWorkOnConditionsAndReapply')}</p>
					)}
				</>
			);
		} else {
			return (
				<>
					<p>{t('thankYouForYourApplication')}</p>
					<p>{t('asASystematicScreeningProcess')}</p>
					{renderReasonsList()}
					<p>{t('youMayReApply')}</p>
					<p>{t('thankYouAgain')}</p>
				</>
			);
		}
	}, [
		canShowSwitches,
		isAllSwitchesOn,
		renderReasonsList,
		rectifyAndShowNewApplication,
		t,
	]);

	const contentWithReApplyDate = (
		<>
			<p>
				{t('thankYouAgainWithReapplyDate', {
					formattedReApplyDate,
				})}
			</p>
			{canShowSwitches && <p>{t('pleaseConfirmTheBelowConditions')}</p>}
			{renderReasonsList()}
		</>
	);

	if (showNewApplicationWizard) {
		return <NewApplicationWizardContainer />;
	}

	return (
		<Card
			title={
				<div className="flex row items-center">
					<div className="pr-[8px]">
						<InfoCircleFilled style={{ fontSize: '16px', color: orange5 }} />
					</div>
					<div className="pl-[8px]">{t('allowMoreTime')}</div>
				</div>
			}
		>
			<div className="flex row items-center">
				<div className="pl-[8px] text-justify">
					<p>{t('dearCandidateName', { candidateName })}</p>
					{reApplyDate ? (
						<>{contentWithReApplyDate}</>
					) : (
						<>{contentWithoutReApplyDate()}</>
					)}
					<p>
						{t('heartfulRespectsAndRegards')}
						<br />
						{t('pcmsTeam')}
					</p>
				</div>
			</div>
		</Card>
	);
};
