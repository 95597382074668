import { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { ExamCreationFormType } from './ExamCreationFormType';
import { useTranslation } from 'react-i18next';
import { DATE_DD_MMM_YYYY, MARK_RANGES } from '../../../../shared/Constants';
import moment, { Moment } from 'moment';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { useCreateExam } from './useCreateExam';
import {
	ExaminationCenter,
	ExamMarkRange,
} from '../../../../shared/ExamModuleTypes';
import { NumberInput } from '../../NumberInput';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useBatchId } from '../../../../hooks/useBatchId';
import { EXAM_MODULE_EXAMS_LIST } from '../../../../shared/Routes';
import { CloseOutlined } from '@ant-design/icons';

extend(dayjsUTC);

interface ExamFormProps {
	forUpdating?: boolean;
	forViewing?: boolean;
}

export const ExamForm = ({ forUpdating, forViewing }: ExamFormProps) => {
	const { examCenters, onSubmitClick, examById } = useCreateExam();
	const { examinationCenters } = examCenters;
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const { navigateWithBatchId } = useBatchId();
	const [form] = Form.useForm<ExamCreationFormType>();
	const formValues = Form.useWatch<ExamCreationFormType>([], form);
	const [isInactiveExam, setIsInactiveExam] = useState<boolean>(false);
	const [isExamDatePassed, setIsExamDatePassed] = useState<boolean>(false);
	const [registrationEmailSent, setRegistrationEmailSent] =
		useState<boolean>(false);

	const allExamCenterOptions = examinationCenters.map(
		(item: ExaminationCenter) => ({
			value: item.id,
			label: item.centerName,
		})
	);
	const activeExamCenters = examinationCenters.filter(
		(item: ExaminationCenter) => item.status === 'ACTIVE'
	);
	const examCenterOptions = activeExamCenters.map(
		(item: ExaminationCenter) => ({
			value: item.id,
			label: item.centerName,
		})
	);

	const markRangeOptions = MARK_RANGES.map((range) => ({
		value: range,
		label: range,
	}));

	const sectionAMarks = (form.getFieldValue('sectionAMarks') || 0) as number;
	const sectionBMarks = (form.getFieldValue('sectionBMarks') || 0) as number;
	const sectionCMarks = (form.getFieldValue('sectionCMarks') || 0) as number;

	useEffect(() => {
		const totalMarks = sectionAMarks + sectionBMarks + sectionCMarks;
		form.setFieldsValue({ totalMarks });
	}, [sectionAMarks, sectionBMarks, sectionCMarks, form]);

	const examRangeToString = useCallback((examRange: Array<ExamMarkRange>) => {
		if (examRange && examRange.length) {
			const firstRange = examRange[0];
			return `${firstRange.minimum || 0}-${firstRange.maximum}`;
		} else {
			return MARK_RANGES[0];
		}
	}, []);

	const initializeForm = useCallback(() => {
		try {
			if (examById) {
				setIsInactiveExam(examById.status === 'INACTIVE');

				const examDate = moment(examById?.examDate);
				setIsExamDatePassed(examDate.isSameOrBefore(moment()));
				setRegistrationEmailSent(examById.registrationEmailSent);

				const initialValues: ExamCreationFormType = {
					examName: examById?.name,
					examDate: dayjs(examById.examDate).utc() as Moment,
					registrationStartDate: dayjs(
						examById?.registrationStartDate
					).utc() as Moment,
					registrationEndDate: dayjs(
						examById?.registrationEndDate
					).utc() as Moment,
					examCenters: examById?.examinationCenterMappings.map(
						(center) => center.examinationCenter.id
					),
					sectionAMarks: examById?.sectionATotalMarksLimit || 0,
					sectionBMarks: examById?.sectionBTotalMarksLimit || 0,
					sectionCMarks: examById?.sectionCTotalMarksLimit || 0,
					totalMarks: examById?.totalMarksLimit,
					sectionAMarksRange: examRangeToString(examById?.sectionAMarkRanges),
					sectionBMarksRange: examRangeToString(examById?.sectionBMarkRanges),
					sectionCMarksRange: examRangeToString(examById?.sectionCMarkRanges),
					totalMarksRange: examRangeToString(examById?.totalMarkRanges),
					passPercentage: examById?.passPercentage,
				};
				form.setFieldsValue(initialValues);
			}
		} catch (e) {
			//
		}
	}, [examById, examRangeToString, form]);

	useEffect(() => {
		if (forUpdating || forViewing) {
			void initializeForm();
		}
	}, [forUpdating, forViewing, initializeForm]);

	const handleSubmitClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				onSubmitClick(formValues);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [formValues, onSubmitClick, form, showToastMessage, t]);

	const handleBackClick = useCallback(() => {
		navigateWithBatchId(EXAM_MODULE_EXAMS_LIST);
	}, [navigateWithBatchId]);

	const disableForm =
		forViewing || isExamDatePassed || (isInactiveExam && forUpdating);

	return (
		<Form
			id="examCreationForm"
			name="examCreationForm"
			layout="horizontal"
			size="middle"
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			autoComplete="off"
			form={form}
			disabled={disableForm}
		>
			<div className="flex flex-col md:flex-row justify-between">
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-wrap gap-4">
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('examName')}
									</span>
								}
								name="examName"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Input size="large" maxLength={255} />
							</Form.Item>
						</div>

						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('registrationStartDate')}
									</span>
								}
								name="registrationStartDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									disabled={registrationEmailSent}
									disabledDate={(current) =>
										current && current < moment().endOf('day')
									}
									onChange={(date) => {
										const registrationEndDate = form.getFieldValue(
											'registrationEndDate'
										) as dayjs.Dayjs;
										if (
											date &&
											registrationEndDate &&
											date.isAfter(registrationEndDate, 'day')
										) {
											form.setFieldsValue({ registrationEndDate: undefined });
										}
									}}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('registrationEndDate')}
									</span>
								}
								name="registrationEndDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								dependencies={['registrationStartDate']}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									disabled={registrationEmailSent}
									disabledDate={(current: dayjs.Dayjs) => {
										const registrationStartDate = form.getFieldValue(
											'registrationStartDate'
										) as dayjs.Dayjs;
										if (!registrationStartDate) return true;
										return (
											current &&
											current.isBefore(
												dayjs(registrationStartDate).add(1, 'day'),
												'day'
											)
										);
									}}
									onChange={(date) => {
										const examDate = form.getFieldValue(
											'examDate'
										) as dayjs.Dayjs;
										if (date && examDate && date.isAfter(examDate, 'day')) {
											form.setFieldsValue({ examDate: undefined });
										}
									}}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('examDate')}
									</span>
								}
								name="examDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								dependencies={['registrationEndDate']}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									disabled={registrationEmailSent}
									disabledDate={(current: dayjs.Dayjs) => {
										const registrationEndDate = form.getFieldValue(
											'registrationEndDate'
										) as dayjs.Dayjs;
										if (!registrationEndDate) return true;
										return (
											current &&
											current.isBefore(
												dayjs(registrationEndDate).add(1, 'day'),
												'day'
											)
										);
									}}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('examCenters')}
									</span>
								}
								name="examCenters"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Select
									className="max-h-28 overflow-y-auto"
									options={
										isInactiveExam ? allExamCenterOptions : examCenterOptions
									}
									mode="multiple"
									size="large"
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									removeIcon={
										<CloseOutlined className="text-gray-500 pr-2.5 pl-1 cursor-pointer !align-middle" />
									}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionAMarks')}
									</span>
								}
								name="sectionAMarks"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionBMarks')}
									</span>
								}
								name="sectionBMarks"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionCMarks')}
									</span>
								}
								name="sectionCMarks"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('totalMarks')}
									</span>
								}
								name="totalMarks"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput disabled />
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionAMarksRange')}
									</span>
								}
								name="sectionAMarksRange"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Select
									options={markRangeOptions}
									size="large"
									disabled={forViewing || false}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionBMarksRange')}
									</span>
								}
								name="sectionBMarksRange"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Select
									options={markRangeOptions}
									size="large"
									disabled={forViewing || false}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('sectionCMarksRange')}
									</span>
								}
								name="sectionCMarksRange"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Select
									options={markRangeOptions}
									size="large"
									disabled={forViewing || false}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('totalMarksRange')}
									</span>
								}
								name="totalMarksRange"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Select
									options={markRangeOptions}
									size="large"
									disabled={forViewing || false}
								/>
							</Form.Item>
						</div>
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('passPercentage')}
									</span>
								}
								name="passPercentage"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								valuePropName="value"
								getValueFromEvent={(value: number | null) => value}
							>
								<NumberInput max={100} />
							</Form.Item>
						</div>
					</div>
				</div>
			</div>
			<div className="flex gap-4 mt-10">
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 text-base"
						htmlType="reset"
						onClick={handleBackClick}
						disabled={false}
					>
						{t('back')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button className="w-36 h-12 mb-5 text-base" htmlType="reset" danger>
						{t('clear')}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						className="w-36 h-12 mb-5 bg-nav-blue hover:!bg-hover-nav-blue text-base"
						type="primary"
						htmlType="submit"
						onClick={handleSubmitClick}
						disabled={forViewing || false}
					>
						{t('submit')}
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};
