import { Typography, Descriptions, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	Application,
	UserRole,
} from '../../../shared/VolunteerApplicationServiceTypes';
import { InterviewSection } from './InterviewSection';
import { useInterviewLandingPage } from './useInterviewLandingPage';
import { useCallback } from 'react';
import {
	ANSWERS_REVIEW,
	APPLICANT_ADDITIONAL_DETAILS,
	APPLICANT_INTERVIEW,
	APPROVER_RECOMMENDATION,
	CARE_TEAM_RECOMMENDATION,
	FAMILY_MEMBER_INTERVIEW,
	FUNCTIONARY_HOME,
	OTHER_REFERENCE_INTERVIEW,
	PRECEPTOR_INTERVIEW,
	PROPOSER_RECOMMENDATION,
	SKIP_ALL_INTERVIEWS,
	VIEW_ANSWERS,
	ZC_RECOMMENDATION,
} from '../../../shared/Routes';
import isEmpty from 'lodash/isEmpty';
import { useBatchId } from '../../../hooks/useBatchId';
import { isProposerPartCompleted } from '../../../shared/Utils';
import { useApplicationStatusTranslation } from '../../../hooks/useApplicationStatusTranslation';
import { useRedirectToLogin } from '../../../hooks/useRedirectToLogin';
import DescriptionPhotoValue from '../../../components/DescriptionPhotoValue';
import { ApplicationStatus } from '../../../shared/Constants';

const { Title } = Typography;

interface LandingPageProps {
	application: Partial<Application>;
	onReviewAnswersClick: () => void;
	onInterviewCandidateClick: () => void;
	onInterviewPreceptorClick: () => void;
	onInterviewFamilyMemberClick: () => void;
	onInterviewOtherReferenceClick: () => void;
	onProposerRecommendationClick: () => void;
	onCareTeamRecommendationClick: () => void;
	onZCRecommendationClick: () => void;
	onApproverRecommendationClick: () => void;
	onAdditionalDetailsClick: () => void;
	onUnableToProceedClick: () => void;
	onBackClick: () => void;
	onViewAnswersClick: () => void;
}

const SectionHeader = ({ heading }: { heading: string }) => {
	return (
		<div className="flex flex-col align-center">
			<Title level={4}>{heading}</Title>
		</div>
	);
};

export const LandingPage = ({
	application,
	onInterviewCandidateClick,
	onInterviewFamilyMemberClick,
	onInterviewOtherReferenceClick,
	onInterviewPreceptorClick,
	onProposerRecommendationClick,
	onCareTeamRecommendationClick,
	onZCRecommendationClick,
	onApproverRecommendationClick,
	onReviewAnswersClick,
	onViewAnswersClick,
	onAdditionalDetailsClick,
	onUnableToProceedClick,
	onBackClick,
}: LandingPageProps) => {
	const { t } = useTranslation();
	const { getTranslatedValue } = useApplicationStatusTranslation();
	const { role } = useRedirectToLogin();

	const isAllInterviewsDone =
		application?.applicantAnswersReviewed &&
		application?.preceptorInterviewDone &&
		application?.familyMemberInterviewDone;

	const showFinalRecommendationToProposer =
		(application?.applicationStatus === ApplicationStatus.REJECTED ||
			application?.applicationStatus === ApplicationStatus.APPROVED) &&
		role === UserRole.ZC;

	const areReferencesCollected = !isEmpty(application?.preceptorReference);

	const AdditionalDetailsSection = (
		<div className="mt-[8px]">
			<InterviewSection
				buttonText="collectAdditionalDetails"
				description="callTheApplicantAndCollectAdditionalDetails"
				title="candidateAdditionalDetails"
				isCompleted={areReferencesCollected}
				onButtonClick={onAdditionalDetailsClick}
			/>
		</div>
	);

	const CareTeamInterviewSection = (
		<div className="mt-[8px]">
			<InterviewSection
				buttonText="careTeamRecommendation"
				description="provideCareTeamRecommendation"
				title="careTeamRecommendation"
				isCompleted={!isEmpty(application?.careTeamRecommendation)}
				onButtonClick={onCareTeamRecommendationClick}
			/>
		</div>
	);

	const ZCInterviewSection = (
		<div className="mt-[8px]">
			<InterviewSection
				buttonText="ZCRecommendation"
				description="provideZCRecommendation"
				title="ZCRecommendation"
				isCompleted={!isEmpty(application?.zcRecommendation)}
				onButtonClick={onZCRecommendationClick}
			/>
		</div>
	);

	const ViewApplicationSection = (
		<div className="mt-[8px]">
			<InterviewSection
				buttonText="viewApplication"
				description="viewTheAnswersProvidedByCandidateWhileApplying"
				title="candidateApplication"
				isCompleted={true}
				onButtonClick={onViewAnswersClick}
			/>
		</div>
	);

	const ReferenceInterviewSection = (
		<>
			<div className="mt-[8px]">
				<InterviewSection
					buttonText="reviewApplication"
					description="reviewTheAnswersProvidedByCandidateWhileApplying"
					title="candidateApplicationReview"
					isCompleted={application?.applicantAnswersReviewed}
					onButtonClick={onReviewAnswersClick}
				/>
			</div>
			<div className="mt-[8px]">
				<InterviewSection
					buttonText="interviewCandidate"
					description="talkToCandidateAndInterview"
					title="candidateInterview"
					isCompleted={application?.applicantInterviewDone}
					onButtonClick={onInterviewCandidateClick}
				/>
			</div>
			{application?.preceptorReference && (
				<div className="mt-[8px]">
					<InterviewSection
						buttonText="interviewPreceptor"
						description="talkToCandidatePreceptor"
						title="candidatePreceptorInterview"
						isCompleted={application?.preceptorInterviewDone}
						onButtonClick={onInterviewPreceptorClick}
					/>
				</div>
			)}
			{application?.familyMemberReference && (
				<div className="mt-[8px]">
					<InterviewSection
						buttonText="interviewFamilyMember"
						description="talkToCandidateFamilyMember"
						title="familyMemberInterview"
						isCompleted={application?.familyMemberInterviewDone}
						onButtonClick={onInterviewFamilyMemberClick}
					/>
				</div>
			)}
			{application?.otherReferenceProvided && (
				<div className="mt-[8px]">
					<InterviewSection
						buttonText="interviewOtherReference"
						description="talkToOtherReference"
						title="OtherReferenceInterview"
						isCompleted={application?.otherReferenceInterviewDone}
						onButtonClick={onInterviewOtherReferenceClick}
					/>
				</div>
			)}
			{(application?.shouldCareTeamReview ||
				!isEmpty(application?.careTeamRecommendation)) &&
				application?.isApprover &&
				CareTeamInterviewSection}
			{application?.isApprover && ZCInterviewSection}
		</>
	);

	const ProposerSection = (
		<>
			{AdditionalDetailsSection}
			{ReferenceInterviewSection}
			{isAllInterviewsDone && (
				<div className="mt-[8px]">
					<InterviewSection
						buttonText="proposerRecommendation"
						description="provideProposerRecommendation"
						title="proposerRecommendation"
						isCompleted={!isEmpty(application?.proposerRecommendation)}
						onButtonClick={onProposerRecommendationClick}
					/>
				</div>
			)}
		</>
	);

	const ApproverSection = (
		<>
			{AdditionalDetailsSection}
			{ReferenceInterviewSection}
			<div className="mt-[8px]">
				<InterviewSection
					buttonText="regionalCommitteeDecision"
					description="provideRegionalCommitteeDecision"
					title="regionalCommitteeDecision"
					isCompleted={!isEmpty(application?.approverRecommendation)}
					onButtonClick={onApproverRecommendationClick}
				/>
			</div>
		</>
	);

	const ZCSection = showFinalRecommendationToProposer ? (
		<>
			{(application?.shouldCareTeamReview ||
				!isEmpty(application?.careTeamRecommendation)) && (
				<div className="mt-[8px]">
					<InterviewSection
						buttonText="careTeamRecommendation"
						description="provideCareTeamRecommendation"
						title="careTeamRecommendation"
						isCompleted={!isEmpty(application?.careTeamRecommendation)}
						onButtonClick={onCareTeamRecommendationClick}
					/>
				</div>
			)}
			<div className="mt-[8px]">
				<InterviewSection
					buttonText="regionalCommitteeDecision"
					description="provideRegionalCommitteeDecision"
					title="regionalCommitteeDecision"
					isCompleted={!isEmpty(application?.approverRecommendation)}
					onButtonClick={onApproverRecommendationClick}
				/>
			</div>
		</>
	) : (
		<>
			{ViewApplicationSection}
			{ZCInterviewSection}
		</>
	);

	const CareTeamSection = (
		<>
			{ViewApplicationSection}
			{CareTeamInterviewSection}
		</>
	);

	let Body;
	if (role === UserRole.CARE_TEAM) {
		Body = CareTeamSection;
	} else if (role === UserRole.ZC) {
		Body = ZCSection;
	} else if (isProposerPartCompleted(application?.applicationStatus)) {
		Body = ApproverSection;
	} else {
		Body = ProposerSection;
	}

	const displayPhoto = role === UserRole.CARE_TEAM || role === UserRole.ZC;
	const showApplicationStatus =
		role !== UserRole.CARE_TEAM && role !== UserRole.ZC;
	const showSkipToEnd =
		(role === UserRole.FUNCTIONARY ||
			role === UserRole.ADMIN ||
			role === UserRole.BATCH_ADMIN) &&
		!application?.isApprover &&
		application?.applicationStatus === ApplicationStatus.FUNCTIONARY_ASSIGNED;

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px] p-[8px]">
			<SectionHeader heading={t('review')} />
			<Descriptions
				bordered
				column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				size="small"
				className="mb-[16px] min-w-[360px]"
			>
				{displayPhoto && (
					<Descriptions.Item label={t('photo')}>
						<DescriptionPhotoValue
							value={application?.applicant?.photoUrl}
							errorMessage={t('required')}
						/>
					</Descriptions.Item>
				)}
				<Descriptions.Item label={t('firstName')}>
					{application?.applicant?.firstName}
				</Descriptions.Item>
				<Descriptions.Item label={t('middleName')}>
					{application?.applicant?.middleName}
				</Descriptions.Item>
				<Descriptions.Item label={t('lastName')}>
					{application?.applicant?.lastName}
				</Descriptions.Item>
				<Descriptions.Item label={t('idCardNo')}>
					{application?.applicant?.srcmid}
				</Descriptions.Item>
				<Descriptions.Item label={t('age')}>
					{application?.applicant?.age}
				</Descriptions.Item>
				<Descriptions.Item label={t('gender')}>
					{application?.applicant?.gender}
				</Descriptions.Item>
				<Descriptions.Item label={t('email')}>
					{application?.applicant?.email}
				</Descriptions.Item>
				<Descriptions.Item label={t('mobile')}>
					{application?.applicant?.mobile}
				</Descriptions.Item>
				{showApplicationStatus && (
					<Descriptions.Item label={t('applicationStatus')}>
						{getTranslatedValue(application?.applicationStatus)}
					</Descriptions.Item>
				)}
			</Descriptions>
			{Body}
			{showSkipToEnd && (
				<div className="mt-[16px] flex">
					<Button type="link" onClick={onUnableToProceedClick}>
						{t('unableToProceed')}
					</Button>
				</div>
			)}
			<div className="mt-[16px] flex justify-center">
				<Button onClick={onBackClick}>{t('back')}</Button>
			</div>
		</div>
	);
};

export const LandingPageContainer = () => {
	const { application, navigateToPage } = useInterviewLandingPage();
	const { navigateWithBatchId } = useBatchId();

	const handleInterviewCandidateClick = useCallback(
		() => navigateToPage(APPLICANT_INTERVIEW),
		[navigateToPage]
	);
	const handleInterviewPreceptorClick = useCallback(
		() => navigateToPage(PRECEPTOR_INTERVIEW),
		[navigateToPage]
	);
	const handleInterviewFamilyMemberClick = useCallback(
		() => navigateToPage(FAMILY_MEMBER_INTERVIEW),
		[navigateToPage]
	);
	const handleInterviewOtherReferenceClick = useCallback(
		() => navigateToPage(OTHER_REFERENCE_INTERVIEW),
		[navigateToPage]
	);
	const handleProposerRecommendationClick = useCallback(
		() => navigateToPage(PROPOSER_RECOMMENDATION),
		[navigateToPage]
	);
	const handleCareTeamRecommendationClick = useCallback(
		() => navigateToPage(CARE_TEAM_RECOMMENDATION),
		[navigateToPage]
	);
	const handleZCRecommendationClick = useCallback(
		() => navigateToPage(ZC_RECOMMENDATION),
		[navigateToPage]
	);
	const handleApproverRecommendationClick = useCallback(
		() => navigateToPage(APPROVER_RECOMMENDATION),
		[navigateToPage]
	);
	const handleAnswersReviewClick = useCallback(
		() => navigateToPage(ANSWERS_REVIEW),
		[navigateToPage]
	);
	const handleViewAnswersClick = useCallback(
		() => navigateToPage(VIEW_ANSWERS),
		[navigateToPage]
	);
	const handleAdditionalDetailsClick = useCallback(
		() => navigateToPage(APPLICANT_ADDITIONAL_DETAILS),
		[navigateToPage]
	);
	const handleUnableToProceedClick = useCallback(
		() => navigateToPage(SKIP_ALL_INTERVIEWS),
		[navigateToPage]
	);
	const handleBackClick = useCallback(
		() => navigateWithBatchId(FUNCTIONARY_HOME),
		[navigateWithBatchId]
	);

	return (
		<LandingPage
			application={application}
			onReviewAnswersClick={handleAnswersReviewClick}
			onViewAnswersClick={handleViewAnswersClick}
			onInterviewCandidateClick={handleInterviewCandidateClick}
			onInterviewPreceptorClick={handleInterviewPreceptorClick}
			onInterviewFamilyMemberClick={handleInterviewFamilyMemberClick}
			onInterviewOtherReferenceClick={handleInterviewOtherReferenceClick}
			onProposerRecommendationClick={handleProposerRecommendationClick}
			onCareTeamRecommendationClick={handleCareTeamRecommendationClick}
			onZCRecommendationClick={handleZCRecommendationClick}
			onApproverRecommendationClick={handleApproverRecommendationClick}
			onAdditionalDetailsClick={handleAdditionalDetailsClick}
			onUnableToProceedClick={handleUnableToProceedClick}
			onBackClick={handleBackClick}
		/>
	);
};
