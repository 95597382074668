import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	FC,
	ReactNode,
	useEffect,
} from 'react';
import { useBatchId } from './useBatchId';
import { LOGIN } from '../shared/Routes';
import { getMeProfile } from '../service/MySrcmService';
import { getRole } from '../service/VolunteerApplicationService';
import firebase from 'firebase/compat/app';
import { MeProfile } from '../shared/MySrcm';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';
import { useLoadingIndicator } from './useLoadingIndicator';
import { useToastMessage } from './useToastMessage';
import useFirebaseIdToken from './useFirebaseIdToken';
import { signOut } from '../service/HfnFirebase';
import { ApplicationsListState } from '../pages/functionary/ApplicationsList/ApplicationsList.store';
import {
	DashboardDetailState,
	DashboardListState,
	GroupsListState,
	MentorsListState,
	PreceptorBatchState,
	PreceptorsListState,
} from '../pages/nps/CreateGroupPage/PreceptorsList.store';
import { useTranslation } from 'react-i18next';

export interface AuthContextType {
	firebaseId: string | null;
	srcmId?: string;
	mySrcmProfile: MeProfile | null;
	logout: () => Promise<void>;
	loading: boolean;
	onLogin: (loggedInUser: firebase.User) => Promise<void>;
	isLoggedIn: () => boolean;
	initiated: boolean;
	role: UserRole;
}

interface Props {
	children: ReactNode;
}

const AuthContext = createContext<Partial<AuthContextType>>({});

export const AuthProvider: FC<Props> = ({ children }: Props) => {
	const { clearIdToken, idToken, refreshIdToken } = useFirebaseIdToken();
	const [loading, setDeterminingLoginStatus] = useState<boolean>(false);
	const [initiated, setInitiated] = useState<boolean>(false);
	const [firebaseId, setFirebaseId] = useState<string | null>(null);
	const { navigateWithBatchId, batchId } = useBatchId();
	const [mySrcmProfile, setMySrcmProfile] = useState<MeProfile | null>(null);
	const [role, setRole] = useState<UserRole>(UserRole.NOT_SET);
	const { setLoading: showLoadingIndicator } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { i18n } = useTranslation();

	useEffect(() => {
		showLoadingIndicator(loading);
	}, [loading, showLoadingIndicator]);

	const setLoggedInUserData = useCallback(async () => {
		setDeterminingLoginStatus(true);
		try {
			const refreshedFirebaseId = await refreshIdToken();

			if (refreshedFirebaseId) {
				setFirebaseId(refreshedFirebaseId);
				const meProfile = await getMeProfile();

				if (meProfile.results?.length > 0) {
					const profile = meProfile.results[0];
					setMySrcmProfile(profile);

					if (profile.ref) {
						const roleResponse = await getRole({
							firebaseId: refreshedFirebaseId,
							batchID: batchId,
							srcmId: profile.ref,
						});
						setRole(roleResponse.role ? roleResponse.role : UserRole.APPLICANT);
					} else {
						setRole(UserRole.APPLICANT);
					}
				}
			}

			setInitiated(true);
		} catch (e) {
			if (e instanceof Error) {
				showToastMessage('error', e.message);
			} else {
				showToastMessage('error', 'Unknown error occurred');
			}
		}

		setDeterminingLoginStatus(false);
	}, [refreshIdToken, batchId, showToastMessage]);

	useEffect(() => {
		void setLoggedInUserData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLogin = useCallback(
		async () => setLoggedInUserData(),
		[setLoggedInUserData]
	);

	const isLoggedIn = useCallback(() => {
		return idToken !== null;
	}, [idToken]);

	const logout = useCallback(async () => {
		clearIdToken();
		setFirebaseId(null);
		setMySrcmProfile(null);
		ApplicationsListState.filterBy = undefined;
		PreceptorsListState.filterBy = undefined;
		MentorsListState.filterBy = undefined;
		DashboardListState.filterBy = undefined;
		DashboardDetailState.filterBy = undefined;
		GroupsListState.filterBy = undefined;
		PreceptorBatchState.selectedBatch = undefined;

		void i18n.changeLanguage('en');
		await signOut();

		navigateWithBatchId(LOGIN, { replace: true });
	}, [clearIdToken, navigateWithBatchId, i18n]);

	const value = useMemo<AuthContextType>(
		() => ({
			logout,
			loading,
			onLogin,
			isLoggedIn,
			initiated,
			firebaseId,
			srcmId: mySrcmProfile?.ref,
			mySrcmProfile,
			role,
		}),
		[
			logout,
			loading,
			onLogin,
			isLoggedIn,
			initiated,
			firebaseId,
			mySrcmProfile,
			role,
		]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
