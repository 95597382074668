import { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PreceptorsFilter } from '../../../shared/VolunteerApplicationServiceTypes';
import RegionsDropdown from '../../../components/NPSRegionsDropdown';
import { useAuth } from '../../../hooks/useAuth';
import isEmpty from 'lodash/isEmpty';
import { COUNTRY_CODE_INDIA } from '../../../shared/Constants';

interface FilterProps {
	value?: PreceptorsFilter;
	onFilterChange: (value: PreceptorsFilter) => void;
	selectedBatchId: number | undefined;
}

const { Text } = Typography;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

export const FilterGraphs = ({
	value,
	onFilterChange,
	selectedBatchId,
}: FilterProps) => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const [selectedRegion, setSelectedRegion] = useState<OptionData>({});

	const handleFilterButtonClick = useCallback(() => {
		const filterValues: PreceptorsFilter = {
			...value,
			showAllPreceptors: true,
			regionIds: [selectedRegion?.value] as Array<number>,
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
	}, [onFilterChange, selectedRegion, value, selectedBatchId]);

	const handleResetForm = useCallback(() => {
		setSelectedRegion({});
		const filterValues: PreceptorsFilter = {
			...value,
			showAllPreceptors: true,
			countryIds: [COUNTRY_CODE_INDIA],
			regionIds: [] as Array<number>,
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
	}, [onFilterChange, value, selectedBatchId]);

	useEffect(() => {
		setSelectedRegion({});
		const filterValues: PreceptorsFilter = {
			showAllPreceptors: true,
			countryIds: [COUNTRY_CODE_INDIA],
			preceptorBatchId: selectedBatchId,
		};
		onFilterChange(filterValues);
	}, [onFilterChange, selectedBatchId]);

	const handleRegionChange = useCallback((reg: OptionData) => {
		setSelectedRegion(reg);
	}, []);

	return (
		<div className="flex flex-col mb-4">
			<div className="flex flex-col md:mb-0">
				<div className="flex flex-col gap-4 md:flex-row">
					<div className="flex flex-col md:mb-0 min-w-[170px]">
						<Text className="pl-1">{t('selectRegion')}</Text>
						<RegionsDropdown
							value={selectedRegion}
							functionarySrcmId={srcmId}
							onChange={handleRegionChange}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start my-2">
					<Button
						type="primary"
						disabled={isEmpty(selectedRegion)}
						onClick={handleFilterButtonClick}
					>
						{t('search')}
					</Button>
				</div>
				<div className="self-start my-2">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
