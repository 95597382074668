import { useCallback } from 'react';
import { Space, TableColumnsType, Tag, Tooltip } from 'antd';
import { ExaminationCenter } from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import { useExamAndCenterId } from '../../../hooks/useExamAndCenterId';
import { UPDATE_CENTER, VIEW_CENTER } from '../../../shared/Routes';

export const CenterTableColumns = (): TableColumnsType<ExaminationCenter> => {
	const { t } = useTranslation();
	const { navigateWithCenterId } = useExamAndCenterId();

	const handleEditCenterClick = useCallback(
		(centerId: number) => {
			navigateWithCenterId(UPDATE_CENTER, centerId);
		},
		[navigateWithCenterId]
	);

	const handleViewCenterClick = useCallback(
		(centerId: number) => {
			navigateWithCenterId(VIEW_CENTER, centerId);
		},
		[navigateWithCenterId]
	);

	return [
		{
			title: t('centerName'),
			dataIndex: 'centerName',
			key: 'centerName',
			render: (__, record, index) => (
				<Tooltip title={record.centerName} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record.centerName || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('centerCode'),
			dataIndex: 'centerCode',
			key: 'centerCode',
			render: (__, record, index) => (
				<span key={index} className="truncate max-w-[100px] block">
					{record.centerCode || '-'}
				</span>
			),
		},
		{
			title: t('address'),
			dataIndex: 'address',
			key: 'address',
			render: (__, record, index) => (
				<Tooltip title={record.address} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record.address || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('contactPerson'),
			dataIndex: 'contactPersonName',
			key: 'contactPersonName',
			render: (__, record, index) => (
				<Tooltip title={record.contactPersonName} placement="topLeft">
					<span key={index} className="truncate max-w-[200px] block">
						{record.contactPersonName || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('phone'),
			dataIndex: 'phone',
			key: 'phone',
			render: (__, record, index) => (
				<span key={index}>{record.phone || '-'}</span>
			),
		},
		{
			title: t('seatsAvailable'),
			dataIndex: 'seatsCapacity',
			key: 'seatsCapacity',
			render: (__, record, index) => (
				<span key={index}>{record.seatsCapacity || '-'}</span>
			),
		},
		{
			title: t('accommodationAvailable'),
			dataIndex: 'accommodationsCapacity',
			key: 'accommodationsCapacity',
			render: (__, record, index) => (
				<span key={index}>{record.accommodationsCapacity || '-'}</span>
			),
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (__, record, index) => {
				if (record.status === 'ACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="success">
								{t('active')}
							</Tag>
						</span>
					);
				} else if (record.status === 'INACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="error">
								{t('inactive')}
							</Tag>
						</span>
					);
				}
			},
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleEditCenterClick(record.id)}
					>
						{t('editOrUpdate')}
					</a>
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleViewCenterClick(record.id)}
					>
						{t('view')}
					</a>
				</Space>
			),
		},
	];
};
