import React, { useCallback, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { searchCenters } from '../service/VolunteerApplicationService';
import { useBatchId } from '../hooks/useBatchId';
import { Center } from '../shared/VolunteerApplicationServiceTypes';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { centerNameToDisplay } from '../shared/Utils';

const { Option } = Select;

interface OptionData {
	value: string;
	label: string;
}

interface RemoteSingleSelectProps {
	onChange?: () => void;
	value?: Center;
}

const RemoteSingleSelect: React.FC<RemoteSingleSelectProps> = ({
	onChange,
	value,
}: RemoteSingleSelectProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { batchId } = useBatchId();
	const { t } = useTranslation();

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			const centersPage = await searchCenters({
				batchId,
				pageIndex: 0,
				pageSize: 10,
				searchKey: searchValue,
			});

			if (centersPage.centers) {
				const transformedOptions: OptionData[] = centersPage.centers.map(
					(item: Center) => ({
						value: JSON.stringify(item),
						label: centerNameToDisplay(item),
					})
				);

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}

			setLoading(false);
		},
		[setLoading, setOptions, batchId]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={false}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchCenter')}
			notFoundContent={loading ? <Spin size="small" /> : null}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				// eslint-disable-next-line
				<Option key={JSON.parse(option.value).id} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default RemoteSingleSelect;
