import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAddOrEditFunctionaryPage } from './useAddOrEditFunctionaryPage';
import { FunctionaryDetailsForm } from '../../../components/FunctionaryDetailsForm';

export const AddOrEditFunctionaryPageContainer = () => {
	const { t } = useTranslation();
	const {
		form,
		functionary,
		onSubmitClick,
		onCancelClick,
		submitButtonDisabled,
	} = useAddOrEditFunctionaryPage();

	return (
		<div className="flex flex-col px-[8px] min-w-[360px] max-w-[600px]">
			<FunctionaryDetailsForm form={form} functionary={functionary} />
			<div className="flex flex-row p-[8px] justify-center">
				<Button onClick={onCancelClick}>{t('cancel')}</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled}
					className="ml-[16px]"
				>
					{t('submit')}
				</Button>
			</div>
		</div>
	);
};
