import { useCallback, useMemo } from 'react';
import { useBatchId } from './useBatchId';
import { NavigateOptions } from 'react-router-dom';
import { useToastMessage } from './useToastMessage';
import { useCallApi } from './useCallApi';
import { getApplication } from '../service/VolunteerApplicationService';
import { useAuth } from './useAuth';

export const useApplicationId = () => {
	const { navigateWithBatchId, queryParams } = useBatchId();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { srcmId } = useAuth();

	const applicationId = useMemo(() => {
		const applicationIdParam = Number(queryParams.get('applicationId'));

		if (typeof applicationIdParam !== 'number') {
			return -1;
		}

		return applicationIdParam;
	}, [queryParams]);

	const navigateWithApplicationId = useCallback(
		(route: string, applicationIdParam?: number, params?: NavigateOptions) => {
			const applicationIdToUse = applicationIdParam
				? `${applicationIdParam}`
				: applicationId;
			queryParams.set('applicationId', `${applicationIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[applicationId, navigateWithBatchId, queryParams]
	);

	const fetchApplication = useCallback(async () => {
		if (!applicationId) {
			showToastMessage('error', 'applicationId is missing');
			throw Error('ApplicationId is missing while fetching application');
		}

		const response = await callApi(
			() =>
				getApplication({
					applicationId,
					functionarySrcmId: srcmId,
				}),
			'errorOccurredWhileLoading'
		);

		return response;
	}, [applicationId, callApi, showToastMessage, srcmId]);

	return {
		applicationId,
		navigateWithApplicationId,
		fetchApplication,
	};
};
